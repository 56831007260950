import React, { useEffect, useState } from "react";
import "./animatedButton.css";
import { ReactSVG } from "react-svg";
import LeftArrow from "../../images/icons/line-stroke-left.svg";
import RightArrow from "../../images/icons/line-stroke-right.svg";
import ErrorIcon from "../../images/icons/ErrorIcons";
function AnimatedButton(props) {
    const [hovered, setHovered] = useState(false);
    useEffect(() => {
        const btnLeft = document.querySelector(".btn-arrow-wrap.left");
        const btnRight = document.querySelector(".btn-arrow-wrap.right");

        function restartAnimation() {
            btnLeft.classList.remove("animate");
            btnRight.classList.remove("animate");
            void btnLeft.offsetWidth;
            void btnRight.offsetWidth;
            btnLeft.classList.add("animate");
            btnRight.classList.add("animate");
        }
        const intervalId = setInterval(restartAnimation, 3000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <button
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                type="button"
                disabled={props.disabled}
                className={`btn btn-animated ${props.color === "yellow" ? "yellow" : props.color === "red" && "red"
                    } `}
                onClick={() => props.handleAnimationButton()}
            >
                {props.color === "red" ? (
                    props.arrows == 1 ? (
                        <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                    ) : (
                        <>
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                        </>
                    )
                ) : (
                    <div
                        className={`btn-arrow-wrap left animate ${props.color === "yellow" && "yellow"
                            } ${props.type === "inward" && "inward"}`}
                    >
                        {props.arrows == 1 ? (
                            <ReactSVG className="single-arrow" src={LeftArrow} />
                        ) : (
                            <>
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                                <ReactSVG src={LeftArrow} />
                            </>
                        )}
                    </div>
                )}
                <div>{props.buttonText}</div>
                {props.color === "red" ? (
                    props.arrows == 1 ? (
                        <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                    ) : (
                        <>
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                            <ErrorIcon color={hovered ? "#000" : "#FA5A5A"} />
                        </>
                    )
                ) : (
                    <div
                        className={`btn-arrow-wrap right animate ${props.color === "yellow" && "yellow"
                            } ${props.type === "inward" && "inward"}`}
                    >
                        {props.arrows == 1 ? (
                            <ReactSVG className="single-arrow" src={RightArrow} />
                        ) : (
                            <>
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                                <ReactSVG src={RightArrow} />
                            </>
                        )}
                    </div>
                )}
            </button>
        </>
    );
}

export default AnimatedButton;
