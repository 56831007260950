import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./coring.css";
import { ReactSVG } from "react-svg";
import CoreResult from "./CoreResult";
import CoreDetails from "./CoreDetails";
import Map from "../../images/map-inner.png";
import Frame from "../location/frame/Frame";
import MainGrid from "../mainGrid/MainGridCoring_old";
import WatchIcon from "../../images/icons/watch.svg";
import EnergyUnitChart from "../../images/energy-chart.svg";
import CoreDepthChart from "./CoreDepthChart";
import ArrowDown from "../../images/icons/arrow-down.svg";
import MessageDepth1 from "../../images/icons/message-depth-1.svg";
import MessageDepth2 from "../../images/icons/message-depth-2.svg";
import MessageDepth3 from "../../images/icons/message-depth-3.svg";
import Close from "../../images/close-icon.svg";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import { setCoreReportModal } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";
import drill from "../../images/icons/drill.svg";
import coreIcon from "../../images/icons/greencore.svg";
function useScrollHeight() {
  const ref = useRef(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setScrollHeight(ref.current.scrollHeight + 10);
    }
  }, []);

  return [ref, scrollHeight];
}

const CoreReport = (props) => {
  const { mapData } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(null);
  const [coreResult, coreResultHeight] = useScrollHeight();
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const services = {
    authService: new AuthService(),
  };
  const coreservices = {
    coreService: new CoreService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  var str = [];
  var totalEarn = 0;
  const style =
    winWidth > 1024
      ? active === 1
        ? { height: "0px" }
        : { height: coreResultHeight }
      : active === 1
      ? { height: coreResultHeight }
      : { height: "0px" };
  const coreCoordinatesList =
    mapData == null
      ? null
      : mapData.mapDetailList
          .filter((x) => x.result < 3)
          .sort((b, a) => a.digId - b.digId);
  var bestEnergyData =
    coreCoordinatesList == null
      ? null
      : coreCoordinatesList
          .filter((x) => x.result === 1)
          .sort((b, a) => a.energyEarn - b.energyEarn);
  bestEnergyData.forEach((line, index) => {
    if (index <= 3) {
      str.push(bestEnergyData[index]);
    }
    totalEarn = totalEarn + bestEnergyData[index].energyEarn;
  });
  const handleClick = (page) => {
    if (page == "closereport") {
      if (localStorage.getItem("isSetReport") == "active") {
        localStorage.setItem("isSetReport", "");
        dispatch(setCoreReportModal(false));
        navigate("/reports");
      } else {
        dispatch(setCoreReportModal(false));
        props.setLeftOpen(true);
      }
    }
  };
  var sessionTime =
    coreservices.coreService.getMapData() == null
      ? 0
      : secondsToHHMMSS(
          coreservices.coreService.getMapData().userBalance.sessionTimeMine
        );
  //if (coreCoordinatesList.length > 0) {
  //    isOpenOppup = true;
  //}
  function hmsToSeconds(t) {
    const [hours, minutes, seconds] = t.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  function secondsToHMS(secs) {
    return new Date(secs * 1000).toISOString().substr(11, 8);
  }
  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const mapName =
    mapData != null && mapData.imageData != null
      ? mapData.imageData.mapImageNo
      : "";
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = dd + "." + mm + "." + yyyy;
  var a = new Date();
  var b = new Date();
  b.setDate(b.getDate());
  b.setHours(23);
  b.setMinutes(59);
  b.setSeconds(59);
  var h = a.getHours();
  var m = a.getMinutes();
  var s = a.getSeconds();
  var h1 = b.getHours();
  var m1 = b.getMinutes();
  var s1 = b.getSeconds();
  var startTime = h + ":" + m + ":" + s;
  var endTime = h1 + ":" + m1 + ":" + s1;
  var tt = secondsToHMS(hmsToSeconds(endTime) - hmsToSeconds(startTime));
  const moveToOtherMap = (id) => {
    if (id > 0) {
      window.location = "/mining/" + id;
    } else {
      alert("Restricetd Area");
    }
    //coreservices.coreService.getData(id);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);
  return (
    <>
      <div className="reporting-modal-wrap">
        <div
          className="reporting-close-modal"
          onClick={() => {
            handleClick("closereport");
          }}
        >
          <ReactSVG src={Close} className="close-icon" />
        </div>

        <div className="reporting-modal-content">
          <div className="reporting-title">
            <img className="report-core-icon" src={coreIcon}></img>

            <p>Section {mapName} core report</p>

            <div> Nice work, {user.userName}!</div>
            {/* <span className="reporting-date">{formattedToday}</span> */}
            <img className="core-drill-image" src={drill}></img>
          </div>
          <div className="report-modal-message">
            <div className="report-message success-text">
              <p>
                You successfully discovered
                <span> {totalEarn}kg of Ilyrium </span>in this session. Your best yet!
              </p>
            </div>
            <div className="report-message best-discoveries">
              <div>Your best discoveries were at</div>
              <div className="discoveries-wrap2">
                {str.length > 0 &&
                  str.map((item, index) =>
                    index == 0 ? (
                      <div className="dicovery">
                        <span>{item.coreNo}</span>{" "}
                        <img
                          src={
                            item.depthTo == 1
                              ? MessageDepth1
                              : item.depthTo == 2
                              ? MessageDepth2
                              : MessageDepth3
                          }
                          alt=""
                        />
                      </div>
                    ) : index == 1 ? (
                      <>
                        <span className="remove-char">,</span>
                        <div className="dicovery">
                          <span>{item.coreNo}</span>
                          <img
                            src={
                              item.depthTo == 1
                                ? MessageDepth1
                                : item.depthTo == 2
                                ? MessageDepth2
                                : MessageDepth3
                            }
                            alt=""
                          />{" "}
                        </div>
                      </>
                    ) : index == 2 ? (
                      <>
                        <div className="remove-char">and</div>
                        <div className="dicovery">
                          <span>{item.coreNo}</span>
                          <img
                            src={
                              item.depthTo == 1
                                ? MessageDepth1
                                : item.depthTo == 2
                                ? MessageDepth2
                                : MessageDepth3
                            }
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <div className="remove-char">.</div>
                    )
                  )}
              </div>
            </div>
            <div className="report-message">
              We suggest you open mines around these locations.
            </div>
          </div>
          <div className="reporting-wrap-main">
            <div className="reporting-summary-wrap">
              <div className="reporting-summary-left">
                <div className="section-title">SESSION SUMMARY</div>
                {/* Location Start */}
                <div className="reporting-location-title">Location</div>
                <Frame mapName={mapName} reportMode={true} />
                <div className="reporting-location-detail-wrap">
                  <div className="reporting-location-detail">
                    <div className="reporting-location-detail-title">
                      Location
                    </div>
                    <div className="reporting-location-detail-dec">
                      Eastern Rift {mapName}
                    </div>
                  </div>
                  <div className="reporting-location-detail">
                    <div className="reporting-location-detail-title">
                      Latitude
                    </div>
                    <div className="reporting-location-detail-dec">
                      -10.883&deg; N
                    </div>
                  </div>
                  <div className="reporting-location-detail">
                    <div className="reporting-location-detail-title">
                      location
                    </div>
                    <div className="reporting-location-detail-dec">
                      328.219&deg; E
                    </div>
                  </div>
                </div>
                {/* Session Time Start */}
                <div className="section-divider"></div>
                <div className="session-time-wrap">
                  <div className="st-detail">
                    <div className="st-detail-title">SESSION TIME</div>
                    <div className="st-wrap session-start">
                      <div className="st-icon">
                        <ReactSVG src={WatchIcon} />
                      </div>
                      <div className="st-time">{sessionTime}</div>
                    </div>
                  </div>
                  <div className="st-detail">
                    <div className="st-detail-title">Time remaining</div>
                    <div className="st-wrap session-start">
                      <div className="st-icon">
                        <ReactSVG src={WatchIcon} />
                      </div>
                      <div className="st-time">{tt}</div>
                    </div>
                  </div>
                </div>
                <div className="section-divider"></div>
                <div className="energy-usage-chart-wrap">
                  <div className="chart-title-wrap">
                    <div className="chart-title">ENERGY USE</div>
                    <div className="chart-unit eu">
                      3,573 <span>kw</span>
                    </div>
                  </div>
                  <div className="chart-wrap">
                    <img src={EnergyUnitChart} alt="Energy Unit Chart" />
                  </div>
                </div>
                <div className="section-divider"></div>
                {/* Energy Usage Start */}
                <div className="core-depth-chart-wrap">
                  <div className="chart-title-wrap">
                    <div className="chart-title">CORE DEPTHS</div>
                    <div className="chart-unit">
                      1,284 <span>m</span>
                    </div>
                  </div>
                  <div className="chart-wrap">
                    <CoreDepthChart coreCoordinatesList={coreCoordinatesList} />
                  </div>
                </div>
              </div>
              {/* Core Right Content */}
              <div className="reporting-summary-right">
                <div className="section-title">Section {mapName} Map</div>
                <div className="snap-shot">
                  <MainGrid mapData={mapData} />
                  {/*<img src={Map} alt='Map' />*/}
                </div>
              </div>
            </div>
            <div className="cr-bottom-wrapper">
              {winWidth > 1023 ? (
                <>
                  {" "}
                  <div
                    className={`accordion-toggle section-title  ${
                      active === 1 ? "active" : ""
                    }`}
                  >
                    <h4>Core RESULTS</h4>{" "}
                    <ReactSVG src={ArrowDown} className="accordion-icon" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`accordion-toggle section-title  ${
                      active === 1 ? "active" : ""
                    }`}
                    onClick={() => handleToggle(1)}
                  >
                    <h4>Core RESULTS</h4>{" "}
                    <ReactSVG src={ArrowDown} className="accordion-icon" />
                  </div>
                </>
              )}
              <div className="cr-wrap">
                <div className="cr-list-left">
                  <div
                    ref={coreResult}
                    className={`collapse  ${active === 1 ? "show" : ""}`}
                    style={style}
                  >
                    <CoreResult coreCoordinatesList={coreCoordinatesList} />
                  </div>
                </div>
                <div className="cr-list-right">
                  <CoreDetails coreCoordinatesList={coreCoordinatesList} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reporting-footer">
          <div className="rf-container">
            <button
              className="btn"
              onClick={() => {
                localStorage.setItem("isSetReport", "");
                props.handleCoreReportModel(false);
                props.setLeftOpen(true);
              }}
            >
              RETURN TO MAP
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                localStorage.setItem("isSetReport", "");
                moveToOtherMap(props.id);
              }}
            >
              Mine THIS SECTION
            </button>
          </div>
        </div>
      </div>
      <div className="reporting-modal-backdrop"></div>
    </>
  );
};

export default CoreReport;
