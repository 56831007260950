import React, { useEffect, useState } from "react";

const GridPathway = ({ path }) => {
    const gridSize = 32;
    const [gridOverlayStyles, setGridOverlayStyles] = useState([]);
    useEffect(() => {
        const gridOverlayStyles = path.map((point) => {
            const borderTop = shouldShowBorder(point[0], point[1] - 1);
            const borderBottom = shouldShowBorder(point[0], point[1] + 1);
            const borderLeft = shouldShowBorder(point[0] - 1, point[1]);
            const borderRight = shouldShowBorder(point[0] + 1, point[1]);

            return {
                zIndex: -1,
                position: "absolute",
                pointerEvents: "none",
                top: point[1] * gridSize + "px",
                left: point[0] * gridSize + "px",
                borderTop: borderTop ? "1px dashed var(--mine-outline, #FFF)" : "none",
                borderBottom: borderBottom
                    ? "1px dashed var(--mine-outline, #FFF)"
                    : "none",
                borderLeft: borderLeft
                    ? "1px dashed var(--mine-outline, #FFF)"
                    : "none",
                borderRight: borderRight
                    ? "1px dashed var(--mine-outline, #FFF)"
                    : "none",
            };
        });

        setGridOverlayStyles(gridOverlayStyles);
    }, [path]);

    const shouldShowBorder = (x, y) => {
        return (
            x < 0 ||
            y < 0 ||
            x >= gridSize ||
            y >= gridSize ||
            !path.some((point) => point[0] === x && point[1] === y)
        );
    };

    return (
        <div>
            {gridOverlayStyles.map((pointStyles, pointIndex) => (
                <div key={pointIndex} className="pathway" style={pointStyles}></div>
            ))}
        </div>
    );
};

export default GridPathway;
