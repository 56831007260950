import React, { useState, useRef } from 'react'
import { ReactSVG } from 'react-svg'
import ArrowDown from '../../images/icons/arrow-down.svg'

import Frame from './frame/Frame'
import LocationInfo from './locationinfo/LocationInfo'
import { useSelector } from "react-redux";
import './location.css'
const Location = (props, { isActive }) => {
    const { mapData } = useSelector((state) => state.app);
  const contentEl = useRef();
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <>
      <div className='location-wrapper'>
      <div className={`accordion-toggle section-title ${active === 1 ? "active" : ""}`} onClick={() => handleToggle(1)}>
          <h4>Location Details</h4> <ReactSVG src={ArrowDown} className='accordion-icon' />
        </div>
        <div ref={contentEl}
            className={`collapse accordion-content ${active === 1 ? "show" : ""
              }`}
            style={
              active === 1
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }>
                  <LocationInfo mapName={props.mapName } />
          </div>
              <Frame mapName={props.mapName}/>
      </div>
    </>
  )
}

export default Location