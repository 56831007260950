import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import ThumbnailGold from '../../images/poster-thumbnail-gold.png'
import ThumbnailSilver from '../../images/poster-thumbnail-silver.png'
import ThumbnailBronze from '../../images/poster-thumbnail-bronze.png'
import MechThumbnailOne from '../../images/poster-thumbnail-mech-1.png'
import MechThumbnailTwo from '../../images/poster-thumbnail-mech-2.png'
import Badge from '../../images/badge-icon.png'
import Gold from '../../images/verified-gold.png'
import Silver from '../../images/verified-silver.png'
import Bronze from '../../images/verified-bronze.png'
import verifiedIcon from '../../images/Verfied.svg'
import './mylocker.css'
import ProfileNav from './ProfileNav'
import LeftNavBar from '../landscape/LeftNavBar'
import RightNavBar from '../landscape/RightNavBar'
import Loader from '../../Loader'
import CoreService from "../helper/Coreservice";
import AuthService, { AdminUserModel } from "../helper/Authservice";
const Mylocker = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [showContent, setShowContent] = useState();
    const [isActive, setIsActive] = useState();
    const [userLocker, setUserLocker] = useState(null);

    const [winHeight, setWinHeight] = useState(window.innerHeight);
    const coreservices = {
        coreService: new CoreService(),
    };
    const services = {
        authService: new AuthService(),
    };
    var coordinateList = userLocker == null ? null : userLocker.filter(x => x.result == 1);
    useEffect(() => {
        const updateHeight = () => {
            setWinHeight(window.innerHeight - 70);
        }
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, []);

    //useEffect(() => {
    //    setTimeout(() => {
    //        setIsLoading(false);
    //        setShowContent(true);
    //    }, 1000);
    //    setTimeout(() => {
    //        setIsActive(true)
    //    }, 1050)
    //}, []);
    const sendDataAndRedirect = (dataToSend) => {
        //const dataToSend = 'Hello from SourceComponent!';
        //services.authService.handleClickOnButton("assetviewer")
        history('/assetviewer', { state: { data: dataToSend } });
    };

    useEffect(() => {
        coreservices.coreService.getUserProfile("getmylocker").then((data) => {
            setUserLocker(data);
            setIsLoading(false);
            setShowContent(true);
            setTimeout(() => {
                setIsActive(true)
            }, 1050)
        });
    }, []);

    return (
        <>

            <LeftNavBar />
            <RightNavBar />
            <div className='inner-main-container' >
                {isLoading ? (
                    <Loader />
                ) : showContent ? (
                    <>
                        <div className={`inner-container-wrap ${isActive ? 'active fade' : 'fade'}`} style={{ height: winHeight }}>
                            <div className='inner-page-heading'>My Locker</div>
                            <div className='inner-content-container'>
                                <div className='my-locker-container'>
                                    <div className='ml-discoveries'>
                                        <div className='inner-section-heading'>Discoveries</div>
                                        <div className='card-container'>
                                                {coordinateList && coordinateList.length > 0 && coordinateList.map((item, index) => (
                                                    <div className='card ' onClick={() =>sendDataAndRedirect('crystal')}>
                                                    <img src={item.depthTo == 1 ? ThumbnailBronze : item.depthTo == 2 ? ThumbnailSilver:ThumbnailGold} alt='Thumbnail' />
                                                    <div className='card-info gold'>
                                                        <div className='card-title'>ilyrium {item.depthTo == 1 ? "Aventurite" : item.depthTo == 2 ? "onyphyllite" : "Thorianite" } </div>
                                                        <div className='card-verified'><img src={verifiedIcon} alt='Gold' /></div>
                                                    </div>
                                                </div>
                                            ))}

                                            {/*<div className='card '>*/}
                                            {/*    <img src={ThumbnailGold} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info gold'>*/}
                                            {/*        <div className='card-title'>ilyrium Aventurite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Gold' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card'>*/}
                                            {/*    <img src={ThumbnailSilver} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info silver'>*/}
                                            {/*        <div className='card-title'>ilyrium onyphyllite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Bronze' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card'>*/}
                                            {/*    <img src={ThumbnailBronze} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info bronze'>*/}
                                            {/*        <div className='card-title'>ilyrium Thorianite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Bronze' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card '>*/}
                                            {/*    <img src={ThumbnailGold} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info gold'>*/}
                                            {/*        <div className='card-title'>ilyrium Aventurite</div>*/}
                                            {/*        <div className='card-verified gold'><img src={verifiedIcon} alt='Gold' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card'>*/}
                                            {/*    <img src={ThumbnailSilver} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info silver'>*/}
                                            {/*        <div className='card-title'>ilyrium onyphyllite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Silver' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card'>*/}
                                            {/*    <img src={ThumbnailBronze} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info bronze'>*/}
                                            {/*        <div className='card-title'>ilyrium Thorianite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Bronze' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card '>*/}
                                            {/*    <img src={ThumbnailGold} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info gold'>*/}
                                            {/*        <div className='card-title'>ilyrium Aventurite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Gold' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className='card'>*/}
                                            {/*    <img src={ThumbnailSilver} alt='Thumbnail' />*/}
                                            {/*    <div className='card-info silver'>*/}
                                            {/*        <div className='card-title'>ilyrium onyphyllite</div>*/}
                                            {/*        <div className='card-verified'><img src={verifiedIcon} alt='Silver' /></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className='ml-mechs'>
                                        <div className='inner-section-heading'>MECHS</div>
                                        <div className='card-container'>
                                                <div className='card ' onClick={() => sendDataAndRedirect('gun')}>
                                                <img src={MechThumbnailOne} alt='Mech' />
                                                <div className='card-info bronze'>
                                                    <div className='card-title'>ilyrium Aventurite</div>
                                                    <div className='card-verified'><img src={verifiedIcon} alt='Gold' /></div>
                                                </div>
                                            </div>
                                                <div className='card' onClick={() => sendDataAndRedirect('gun')}>
                                                <img src={MechThumbnailTwo} alt='Mech' />
                                                <div className='card-info silver'>
                                                    <div className='card-title'>ilyrium onyphyllite</div>
                                                    <div className='card-verified'><img src={verifiedIcon} alt='Silver' /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-badges'>
                                        <div className='inner-section-heading'>Badges</div>
                                        <div className='badge-container'>
                                                <div className='badge-img-wrap' onClick={() => sendDataAndRedirect('rock')}><img src={Badge} alt='Badge' /></div>
                                            {/*<div className='badge-img-wrap'><img src={Badge} alt='Badge' /></div>*/}
                                            {/*<div className='badge-img-wrap'><img src={Badge} alt='Badge' /></div>*/}
                                            {/*<div className='badge-img-wrap'><img src={Badge} alt='Badge' /></div>*/}
                                            {/*<div className='badge-img-wrap'><img src={Badge} alt='Badge' /></div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                ) : null}
            </div>
            <ProfileNav />

        </>
    )
}

export default Mylocker