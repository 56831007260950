import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Draggable from "react-draggable";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import Lock from "../../images/icons/lock.svg";
import DepthIcon from "../../images/icons/depth-white-icon.svg";
import CoreService from "../helper/Coreservice";
import { useDispatch, useSelector } from "react-redux";
import { setDepthSelect } from "../../appSlice";
const DepthSelector = (props) => {
  const { depthSelect, tooltipCoring, tooltipMining } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const coreservices = {
    coreService: new CoreService(),
  };
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  //if (props.pageOpen == "mining") {
  //    if (localStorage.getItem('depthSelect') == "-1") {
  //        localStorage.setItem('depthSelect', 0);
  //        dispatch(setDepthSelect(0));
  //    }
  //}
  const handleDepthSelector = (index) => {
    if (depthSelect === index) {
      dispatch(setDepthSelect(-1));
      coreservices.coreService.setDepth(-1);
    } else {
      dispatch(setDepthSelect(index));
      coreservices.coreService.setDepth(index);
    }
  };
  return (
    <>
      <Draggable
        defaultPosition={{ x: 0, y: 0 }}
        disabled={winWidth > 1023 ? false : true}
        position={null}
        bounds="parent"
      >
        <div className={`ds-wrap ${props.showDepthSelector ? "" : "hide"}`}>
          {(tooltipCoring == 1 || tooltipMining == 1) && (
            <div className="depth-selector-border fade-in"></div>
          )}
          <div className="ds-title-wrap">
            {winWidth >= 1024 ? (
              <div className="ds-circle-wrap">
                <div className="ds-circle">
                  <img src={DepthIcon} alt="Depth" />
                </div>
              </div>
            ) : (
              <div
                className="ds-circle-wrap"
                onClick={() => props.handelDepthSelector()}
              >
                <div className="ds-circle">
                  <img src={DepthIcon} alt="Depth" />
                </div>
              </div>
            )}
            <div className="ds-title">
              {props.pageOpen == "mining" ? "Mine" : "Core"} depth
              <br />
              selector
            </div>
          </div>
          {/* <div className='ds-title'>Core depth<br/>selector</div> */}
          <div
            className={`ds-row depth-1 ${depthSelect === 0 ? "active" : ""}`}
            onClick={() => handleDepthSelector(0)}
            onTouchStart={() => handleDepthSelector(0)}
          >
            <div className="ds-depth">
              <img src={Depth1} alt="Depth" />
            </div>
            <div className="ds-distance">
              <span>16m</span>
            </div>
            <div className="ds-lock">
              <ReactSVG src={Lock} className="lock-icon" />
            </div>
          </div>
          <div
            className={`ds-row depth-2 ${depthSelect === 1 ? "active" : ""}`}
            onClick={() => handleDepthSelector(1)}
            onTouchStart={() => handleDepthSelector(1)}
          >
            <div className="ds-depth">
              <img src={Depth2} alt="Depth" />
            </div>
            <div className="ds-distance">
              <span>64m</span>
            </div>
            <div className="ds-lock">
              <ReactSVG src={Lock} className="lock-icon" />
            </div>
          </div>
          <div
            className={`ds-row depth-3 ${depthSelect === 2 ? "active" : ""}`}
            onClick={() => handleDepthSelector(2)}
            onTouchStart={() => handleDepthSelector(2)}
          >
            <div className="ds-depth">
              <img src={Depth3} alt="Depth" />
            </div>
            <div className="ds-distance">
              <span>192m</span>
            </div>
            <div className="ds-lock">
              <ReactSVG src={Lock} className="lock-icon" />
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default DepthSelector;
