import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
const ProfileNav = () => {

      const location = useLocation();
      const navigate = useNavigate();
  return (
    <div className='profile-nav-wrap'>
        <ul>
            <li onClick={() => navigate('/profile')} className={location.pathname.startsWith("/profile") ? "active" : ""}>Profile</li>
            <li onClick={() => navigate('/mylocker')} className={location.pathname.startsWith("/mylocker") ? "active" : ""}>My Locker</li>
            <li onClick={() => navigate('/ilyrium')} className={location.pathname.startsWith("/ilyrium") ? "active" : ""}>Ilyrium</li>
            <li onClick={() => navigate('/reports')} className={location.pathname.startsWith("/reports") ? "active" : ""}>Reports</li>
        </ul>
    </div>
  )
}

export default ProfileNav