import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LeftCol from "../leftcol/LeftCol";
import Map from "../map/Map";
import CoringComplete from "../coring/CoringCompleteModal";
import CoreService from "../helper/Coreservice";
import SidebarRight from "../infonav/Infonav";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedGrid,
  setMapData,
  setIsReady,
  setShowButton,
  setShowLoading,
  setCoringComplete,
  setUserBalance,
  setUserTempBalance,
} from "../../appSlice";

const Inspecting = () => {
  const { selectedGrid, userBalance, userTempBalance } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const [isOpenMap, setOpenMap] = useState(false);
  //const [isOpenMech, setOpenMech] = useState(false);
  //const [isCoringComplete, setCoringComplete] = useState(false);
  //const [userBalance, setUserBalance] = useState(0);
  //const [mapData, setMapData] = useState(null);
  //const [depthSelect, setDepthSelect] = useState(0);
  //const [selectedGrid, setselectedGrid] = useState([]);
  //const [selectedPath, setMapPath] = useState([]);
  //const [isReady, isSetReady] = useState(false);
  //const [pathSelect, setPathSelect] = useState(0);
  //const [isShowButton, setShowButton] = useState(true);
  //const [showLoading, setShowLoading] = useState(true);
  const [pageOpen, setPageOpen] = useState("inspect");

  const coreservices = {
    coreService: new CoreService(),
  };
  const mapId = useParams();
  const navigate = useNavigate();
  const deployCores = () => {
    //var depth1Grid = props.selectedGrid.filter(x => { return x.depthTo == 1 });
    //var depth1Price = depth1Grid * 50;
    //var depth2Grid = props.selectedGrid.filter(x => { return x.depthTo == 2 });
    //var depth2Price = depth2Grid * 100;
    //var depth3Grid = props.selectedGrid.filter(x => { return x.depthTo == 3 });
    //var depth3Price = depth3Grid * 150;
    if (selectedGrid.length > 0) {
      setShowButton(false);
      setShowLoading(false);
      localStorage.setItem("isCoreStart", true);
      dispatch(setUserTempBalance(userBalance));
      console.log(new Date() + " Start timer=");
      var stGrid = selectedGrid;
      var stGridDig = JSON.parse(JSON.stringify(selectedGrid)).sort(
        (a, b) => Number(a.digId) - Number(b.digId)
      );
      //if (stGridDig.length > 0) {
      //    localStorage.setItem("setId", stGridDig[0].id);
      //}
      var pathCoordinates = coreservices.coreService.FindAllNodes(
        stGridDig,
        false,
        true
      );
      var imageIdListTemo = [];
      stGridDig.forEach((line) => {
        imageIdListTemo.push({
          imageId: line.id.toString(),
          digId: line.digId.toString(),
          depth: line.depthTo.toString(),
        });
      });
      //   callForUpdate();
      //   CallInterval();
      coreservices.coreService
        .coreGridTime(imageIdListTemo, pathCoordinates)
        .then((data) => {
          // const ids = [];
          // selectedGrid.forEach((grid) => {
          //   ids.push(grid.id);
          // });

          // const tempData = data.mapDetailList.filter((datum, index) => {
          //   return ids.includes(datum.id);
          // });

          //   tempData.forEach((data) => {
          //     setTimeout(()=>{},3000)
          // });
          var tt = 0;
          for (var i = 0; i < selectedGrid.length; i++) {
            setTimeout(() => {
              var data1 = coreservices.coreService.getMapTempData();
              var ggGrid = coreservices.coreService.getMapDataGrid();
              var line = ggGrid.sort(
                (a, b) => Number(a.digId) - Number(b.digId)
              )[0];
              var bal =
                line.depthTo == 1
                  ? coreservices.coreService.getMapTempData().userBalance
                      .firstDepthCharge
                  : line.depthTo == 2
                  ? coreservices.coreService.getMapTempData().userBalance
                      .secondndDepthCharge
                  : coreservices.coreService.getMapTempData().userBalance
                      .thirdDepthCharge;
              var remainBal = userBalance - bal;
              //var bal = 100;// grid.depthTo == 1 ? mapData.userBalance.firstDepthCharge : grid.depthTo == 2 ? mapData.userBalance.secondndDepthCharge : thirdDepthCharge
              if (userTempBalance != remainBal) {
                dispatch(setUserTempBalance(remainBal));
              }
              data1.mapDetailList.find((x) => x.id == line.id).result = 2;
              data1.mapDetailList.find((x) => x.id == line.id).depth1 = -1;
              coreservices.coreService
                .getMapDataGrid()
                .filter((n) => n.id != line.id)
                .forEach((item, inex) => {
                  data1.mapDetailList.find((x) => x.id == item.id).result = -1;
                });
              var sList = ggGrid.filter((n) => n.id != line.id);
              updateSelectedGrid(sList);
              coreservices.coreService.saveData(data1);
              dispatch(setMapData(data1));
            }, i * 3500);
          }
        });
    }
  };
  const updateSelectedGrid = (sList) => {
    console.log(
      new Date() + " stGrid coreCoordinatesList=" + JSON.stringify(selectedGrid)
    );
    if (sList == null || sList.length == 0) {
      if (localStorage.getItem("isCoreStart") == "true") {
        setTimeout(() => {
          clearData();
        }, 500);
      }
    }
    dispatch(setSelectedGrid(sList));
    coreservices.coreService.saveDataEntireGrid(sList);
  };
  const clearData = () => {
    localStorage.removeItem("isCoreStart");
    console.log(new Date() + " end timer=");
    dispatch(setCoringComplete(true));
    dispatch(setShowButton(true));
    dispatch(setShowLoading(true));
    dispatch(setSelectedGrid([]));
    dispatch(setIsReady(false));
    localStorage.setItem("isNeedStart", true);
    dispatch(setMapData(coreservices.coreService.getMapTempData()));
    dispatch(
      setUserBalance(
        coreservices.coreService.getMapData() == null
          ? 0
          : coreservices.coreService.getMapData().userBalance.balance
      )
    );
    dispatch(
      setUserTempBalance(
        coreservices.coreService.getMapData() == null
          ? 0
          : coreservices.coreService.getMapData().userBalance.balance
      )
    );
  };
  useEffect(() => {
    coreservices.coreService.getData(mapId.id).then((data) => {
      dispatch(
        setMapData(
          coreservices.coreService.getMapData() == null
            ? null
            : coreservices.coreService.getMapData()
        )
      );
      dispatch(
        setUserBalance(
          coreservices.coreService.getMapData() == null
            ? 0
            : coreservices.coreService.getMapData().userBalance.balance
        )
      );
      dispatch(
        setUserTempBalance(
          coreservices.coreService.getMapData() == null
            ? 0
            : coreservices.coreService.getMapData().userBalance.balance
        )
      );
      setOpenMap(true);
    });
  }, []);

  return (
    <>
      {isOpenMap ? (
        <LeftCol deployCores={deployCores} pageOpen={pageOpen} id={mapId.id} />
      ) : (
        ""
      )}
      {isOpenMap ? (
        <div className="coring-container">
          <Map deployCores={deployCores} pageOpen={pageOpen} />
        </div>
      ) : (
        ""
      )}

      {isOpenMap ? <SidebarRight /> : <div></div>}
    </>
  );
};

export default Inspecting;
