import "./manual.css";
import logo from "../../images/manual/logo.svg";
import close from "../../images/manual/close.svg";
import bottomRight from "../../images/manual/bottom-right-icon.svg";
import bottomLeft from "../../images/manual/bottom-left-icon.svg";
import leftFrameIcon from "../../images/manual/left-frame-icon.svg";
import rightFrameIcon from "../../images/manual/right-frame-icon.svg";
import top from "../../images/manual/Top.svg";
import hash from "../../images/manual/hash.svg";
import cursor from "../../images/manual/cursor.svg";
import grab from "../../images/manual/grab.svg";
import tooltipLanding1 from "../../images/icons/landing1.png";
import manualMonitor from "../../images/manual/manual-monitor.svg";
import location from "../../images/manual/Location.svg";
import landing from "../../images/manual/section-map-loader.svg";
import monitors from "../../images/manual/monitors-triple.svg";
import icons from "../../images/manual/icons.svg";
import ledger from "../../images/manual/ledger.svg";
import landmarks from "../../images/manual/landmarks.svg";
import depthSelector from "../../images/manual/depthSelector.svg";
import coreHover from "../../images/manual/coreHover.svg";
import pathway from "../../images/manual/pathway.svg";
import findNoFind from "../../images/manual/findNoFind.svg";
import CoreCalculator from "../../images/manual/CoreCalculator.svg";
import mineHover from "../../images/manual/mineHover.svg";
import mineCalculator from "../../images/manual/mineCalculator.svg";
import mineMeter from "../../images/manual/mineMeter.svg";
import minesInit from "../../images/manual/minesInit.svg";
import { setShowManual } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";

import { useRef, useState, useEffect } from "react";

const Manual = () => {
    const { showManual } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [tab, setTab] = useState(showManual);
  const [scrollThumbHeight, setScrollThumbHeight] = useState(0);
  const [scrollThumbTop, setScrollThumbTop] = useState(0);

  const divRef = useRef(null);

  function handleScroll() {
    const scrollTop = divRef.current.scrollTop;
    const scrollHeight = divRef.current.scrollHeight;
    const clientHeight = divRef.current.clientHeight;

    // Calculate scroll percentage
    const percentageScrolled =
      (scrollTop / (scrollHeight - clientHeight)) * 100;

    // Calculate the height of the scrollbar thumb in percentage and then convert to pixels
    const thumbHeightPercentage = (clientHeight / scrollHeight) * 100;
    const thumbHeightPixels = (thumbHeightPercentage / 100) * clientHeight;

    const thumbTop =
      (scrollTop / (scrollHeight - clientHeight)) *
      (clientHeight - thumbHeightPixels);

    setScrollThumbHeight(thumbHeightPercentage);
    setScrollThumbTop(thumbTop);
  }

  useEffect(() => {
    if (!divRef.current) return;

    const scrollHeight = divRef.current.scrollHeight;
    const clientHeight = divRef.current.clientHeight;
    const thumbHeight = (clientHeight / scrollHeight) * 100;
    setScrollThumbHeight(thumbHeight);

      divRef.current.addEventListener("scroll", handleScroll);
      return () => (divRef.current != undefined? divRef.current.removeEventListener("scroll", handleScroll):"");
  }, [divRef]);

  useEffect(() => {
    handleScroll();
    scrollToTop();
  }, [tab]);

  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };
  return (
    <div className="manual-container">
      <div className="manual-wrap">
        <div className="manual-stack">
          <div className="stack-header">
            <div className="header-logo">
              <div className="logo-wrap">
                <img src={logo} />
              </div>
              <div className="header-title">
                <h4>Training Manual</h4>
                <p>TM-3nc97</p>
              </div>
            </div>
                      <div className="header-close" onClick={() =>dispatch(setShowManual(4))}>
              <img src={close} />
            </div>
          </div>
          <div className="manual-content">
            <div className="manual-content-frame">
              <div className="manual-text-frame">
                <div className="top-frame">
                  <div className="left-manual-frame">
                    <img src={leftFrameIcon} />
                  </div>
                  <div className="manual-middle-frame">
                    <div className="manual-text-content">
                      <div className="custom-scrollbar">
                        <div
                          className="custom-bar"
                          style={{
                            height: `${scrollThumbHeight}%`,
                            top: `${scrollThumbTop}px`,
                          }}
                        ></div>
                      </div>
                      <div className="manual-training" ref={divRef}>
                        <div className="quick-summary">
                          <div className="summary-header-wrap">
                            <div className="summary-title-wrap">
                              <h3>The Ilyrium Miner's Handbook</h3>
                              <p>Interface Essentials</p>
                            </div>
                            <div className="summary-separator"></div>
                            <div className="summary-content-wrap">
                              <p>
                                Unlock the secrets of Ilyrium mining in The
                                Eastern Rift with our comprehensive interface
                                guide! From exploration and rock inspection to
                                precise coring and energy-maximizing mining,
                                we've got you covered.
                              </p>
                            </div>
                          </div>
                          <div className="manual-tabs-wrap">
                            <h5>jump to a Chapter</h5>
                            <div className="manual-tabs">
                              <div
                                className={`manual-tab ${
                                  tab === 0 && "clicked"
                                }`}
                                onClick={() => setTab(0)}
                              >
                                <p className="tab-number">01</p>
                                <p className="tab-text">Exploration</p>
                              </div>
                            </div>
                            <div className="manual-tabs">
                              <div
                                className={`manual-tab ${
                                  tab === 1 && "clicked"
                                }`}
                                onClick={() => setTab(1)}
                              >
                                <p className="tab-number">02</p>
                                <p className="tab-text">Control panel</p>
                              </div>
                            </div>
                            <div className="manual-tabs">
                              <div
                                className={`manual-tab ${
                                  tab === 2 && "clicked"
                                }`}
                                onClick={() => setTab(2)}
                              >
                                <p className="tab-number">03</p>
                                <p className="tab-text">coring</p>
                              </div>
                            </div>
                            <div className="manual-tabs">
                              <div
                                className={`manual-tab ${
                                  tab === 3 && "clicked"
                                }`}
                                onClick={() => setTab(3)}
                              >
                                <p className="tab-number">04</p>
                                <p className="tab-text">mining</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {tab === 0 ? (
                          <div className="training-manual-stack">
                            <div className="training-header">
                              <img src={hash} />
                              <p>01</p>
                              <div className="training-header-separator"></div>
                              <p>Exploration</p>
                              <div className="training-header-separator"></div>
                              <p>01</p>
                              <img src={hash} />
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <div className="exploration-column">
                                  <div className="exploration-row">
                                    <img src={cursor} />
                                    <p>Double click to Enter</p>
                                  </div>
                                  <div className="exploration-row">
                                    <img src={grab} />
                                    <p>Drag and Explore</p>
                                  </div>
                                  <img src={tooltipLanding1} />
                                </div>
                              </div>
                              <div className="training-text">
                                <p className="training-number">001</p>
                                <p className="training-title">
                                  Explore the Eastern Rift
                                </p>
                                <p className="training-summary">
                                  Hover over the map to view labeled grid
                                  coordinates. <br></br> Double-click to explore
                                  locations, but steer clear of restricted zones
                                  we've marked for safety.
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : tab === 1 ? (
                          <div className="training-manual-stack">
                            <div className="training-header">
                              <img src={hash} />
                              <p>02</p>
                              <div className="training-header-separator"></div>
                              <p>Control panel</p>
                              <div className="training-header-separator"></div>
                              <p>02</p>
                              <img src={hash} />
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={manualMonitor} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">001</p>
                                <p className="training-title">
                                  Keep an eye on your energy!
                                </p>
                                <p className="training-summary">
                                  The energy monitor allows you to monitor time
                                  elapsed and energy remaining.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={location} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">002</p>
                                <p className="training-title">
                                  Location Details
                                </p>
                                <p className="training-summary">
                                  Stay up to date with your location in this
                                  accordian.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={landing} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">003</p>
                                <p className="training-title">The Navigator</p>
                                <p className="training-summary">
                                  The Quick Navigator allows you to rapidly jump
                                  to anywhere on the map. Just watch out for
                                  restricted areas.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={monitors} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">004</p>
                                <p className="training-title">Control Centre</p>
                                <p className="training-summary">
                                  Monitor the status and results of every core
                                  and mine in the control panel.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={icons} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">005</p>
                                <p className="training-title">
                                  Global Navigation
                                </p>
                                <p className="training-summary">
                                  Get where you want, when you want using this
                                  global navigation side bar that remains in
                                  place throughout the experience.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div
                                className="training-image"
                                style={{ padding: 0 }}
                              >
                                <img src={ledger} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">005</p>
                                <p className="training-title">Energy Ledger</p>
                                <p className="training-summary">
                                  To analyze your energy consumption, check the
                                  energy ledger for your drilling, mining, and
                                  travel history.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={landmarks} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">006</p>
                                <p className="training-title">
                                  Restricted Areas & Landmarks
                                </p>
                                <p className="training-summary">
                                  In the map, you'll find two types of marked
                                  areas: <br></br>
                                  1. Red Squares with Cross Icon: These are
                                  restricted zones for safety or scenic
                                  preservation.<br></br>2. Purple Squares with
                                  Camera Icon: Click to view beautiful
                                  landscapes and download them as wallpapers.
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : tab === 2 ? (
                          <div className="training-manual-stack">
                            <div className="training-header">
                              <img src={hash} />
                              <p>03</p>
                              <div className="training-header-separator"></div>
                              <p>coring</p>
                              <div className="training-header-separator"></div>
                              <p>03</p>
                              <img src={hash} />
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={depthSelector} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">001</p>
                                <p className="training-title">
                                  Effortless Coring: Simplified Depth Selection
                                </p>
                                <p className="training-summary">
                                  For speedy coring, lock your desired depth to
                                  drop a simultaneous array of cores. You have
                                  the flexibility to unlock it at any time and
                                  select multiple depths within a single
                                  session.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={coreHover} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">002</p>
                                <p className="training-title">
                                  Precision Coring: Versatile Depth Control
                                </p>
                                <p className="training-summary">
                                  Master the art of versatile and precise
                                  coring. Use the dropdown to select different
                                  depths for each core, trading speed for
                                  pinpoint accuracy as you explore the depths of
                                  the game world.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={pathway} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">003</p>
                                <p className="training-title">
                                  Navigate with Clarity: Mech Path Visualization
                                </p>
                                <p className="training-summary">
                                  Visualize your mech's path and illyrium
                                  consumption with a helpful dotted line as you
                                  plan your cores across the map. Navigate your
                                  journey wisely and optimize your resources.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div
                                className="training-image"
                                style={{ padding: 0 }}
                              >
                                <img src={findNoFind} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">004</p>
                                <p className="training-title">
                                  Core Control Center
                                </p>
                                <p className="training-summary">
                                  Easily track your core plan's status within
                                  the status bar.. Check core status, remove
                                  cores, and deploy your mech with a single
                                  click when your plan is set.
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={CoreCalculator} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">005</p>
                                <p className="training-title">Control Centre</p>
                                <p className="training-summary">
                                  Monitor the status and results of every core
                                  and mine in the control panel.
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="training-manual-stack">
                            <div className="training-header">
                              <img src={hash} />
                              <p>04</p>
                              <div className="training-header-separator"></div>
                              <p>mining</p>
                              <div className="training-header-separator"></div>
                              <p>04</p>
                              <img src={hash} />
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={mineHover} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">001</p>
                                <p className="training-title">
                                  Effortless Mining:Intuitive Selection Process
                                </p>
                                <p className="training-summary">
                                  When you enter the mining module, your mouse
                                  cursor transforms into a grid-coordinate icon.
                                  Click to activate the dropdown, or choose your
                                  desired depth within the depth selector, and a
                                  draggable box will appear, making area
                                  selection a breeze!
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={mineCalculator} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">002</p>
                                <p className="training-title">
                                  Illyrium Efficiency:Mine Planner & Calculator
                                </p>
                                <p className="training-summary">
                                  Master the art of versatile and precise
                                  coring. Use the dropdown to select different
                                  depths for each core, trading speed for
                                  pinpoint accuracy as you explore the depths of
                                  the game world.
                                </p>
                              </div>
                            </div>

                            <div className="training-content">
                              <div className="training-image">
                                <img src={mineMeter} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">003</p>
                                <p className="training-title">
                                  Energize Your Strategy:Track and Plan Ilyrium
                                  Usage
                                </p>
                                <p className="training-summary">
                                  Monitor your Ilyrium allocation and plan
                                  efficiently by overlaying the estimated energy
                                  required for your mining sites in red. Stay in
                                  control of your resources!
                                </p>
                              </div>
                            </div>
                            <div className="training-content">
                              <div className="training-image">
                                <img src={minesInit} />
                              </div>
                              <div className="training-text">
                                <p className="training-number">004</p>
                                <p className="training-title">
                                  Mining Mastery: Track, Adjust, Succeed
                                </p>
                                <p className="training-summary">
                                  Stay in control with our mine status panel,
                                  from 'Mines Ready' to 'Mining Complete.' If
                                  you exceed your mech's capacity, don't worry;
                                  simply delete excess mines and resize your
                                  selection to match your allocated illyrium.
                                  Achieve mining success with ease!
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="manual-top" onClick={scrollToTop}>
                        <img src={top} />
                      </div>
                    </div>
                  </div>
                  <div className="right-manual-frame">
                    <img src={rightFrameIcon} />
                  </div>
                </div>
                <div className="bottom-frame">
                  <img src={bottomLeft} />
                  <img src={bottomRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manual;
