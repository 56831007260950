import { useMatch, useNavigate, useLocation } from "react-router-dom";
import AuthService, { AdminUserModel, BASE_URL } from "./Authservice";
import * as astar from "javascript-astar/astar.js";
import GraphSearch from "../mainGrid/CustomHooks";
import findUniqueShortestPath from "../../utility/utility";
import { all } from "axios";
export class CoreModel {
    userId = "";
    email = "";
    password = "";
    avatarUrl = "";
    location = "";
    refferCode = "";
    registerDate = "";
    refferCode = "";
    firstName = "";
    lastName = "";
    location = "";
    userName = "";
    fullName = "";
    panImgPath = "";
    balance = 0;
    isProcceed = false;
    user_role_Key_code = "";
    constructor(obj) {
        this.userId = obj.id;
        this.email = obj.email;
        this.password = obj.password;
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.location = obj.location;
        this.userName = obj.userName;
        this.fullName = obj.fullName;
        this.panImgPath = obj.panImgPath;
        this.balance = obj.balance;
        this.registerDate = obj.registerDate;
        this.avatarUrl = obj.avatarUrl;
        this.refferCode = obj.refferCode;
        this.user_role_Key_code = "";
        this.isProcceed = obj.isPlaying;
    }
}
export class mapDetailData {
    id = "";
    digId = "";
    rowNo = "";
    colNo = "";
    mapId = "";
    depth = "";
    depth1 = "";
    depth2 = 0;
    depth3 = 0;
    isRestricted = false;
    coreName = "";
    coreNo = "";
    sampleImage = "";
    depthTo = 0;
    result = 0;
    digStatus = 0;
    energySpent = 0;
    createDate = "";
}

export class mapPathData {
    idFrom = "";
    idTo = "";
    totalPathCovered = 0;
    balanceDeduct = 0;
    pathIds = "";
}
export class userBalanceData {
    balance = 0;
    maxBalance = 0;
    nodeCost = 0;
    firstDepthCharge = 0;
    secondndDepthCharge = 0;
    thirdDepthCharge = 0;
}
export class mapImageData {
    imageUrl = "";
    mapImageNo = "";
}
//export class coreMainClass {
//    mapDetail =[];
//this.pathList = data1.mapPathList;
//this.imageUrl = data1.imageUrl;
//this.leftMapId = data1.leftMapId;
//this.rightMapId = data1.rightMapId;
//this.upperMapId = data1.upperMapId;
//this.lowerMapId = data1.lowerMapId;
//this.userBalance = data1.userBalance;
//this.userDig = this.filterUserDig(this.persons);
//this.userBalPerc = (this.userBalance.balance * 100) / this.userBalance.maxBalance;
//}

export default class CoreService {
    constructor() { }
    services = {
        authService: new AuthService(),
    };
    lastSelectedNode;
    isPageLoad = false;
    $grid = '<div id="search_grid">Loading grid...</div>';
    opts = {
        wallFrequency: ".1",
        gridSize: "24",
        debug: false,
        diagonal: false,
        closest: false,
    };
    isRememberMe = true;
    isGoToSignIn = false;
    navigate = useNavigate();
    location = useLocation();
    CurrentUser = AdminUserModel;
    mapDetail = [];
    pathList = [];
    imageUrl = "";
    leftMapId = 0;
    rightMapId = 0;
    upperMapId = 0;
    lowerMapId = 0;
    userBalance = null;
    //userDig = this.filterUserDig(this.persons);
    //userBalPerc = (this.userBalance.balance * 100) / this.userBalance.maxBalance;
    getMapDetail() {
        var obj = this.getMapData();
        return obj == null ? obj : obj.mapDetailList;
    }
    getPathList() {
        var obj = this.getMapData();
        return obj == null ? obj : obj.mapPathList;
    }
    getImageUrl() {
        var obj = this.getMapData();
        return obj == null ? obj : obj.imageUrl;
    }
    getLeftMapId() {
        var obj = this.getMapData();
        return obj == null ? 0 : obj.leftMapId;
    }
    getRightMapId() {
        var obj = this.getMapData();
        return obj == null ? 0 : obj.rightMapId;
    }
    getUpperiMapId() {
        var obj = this.getMapData();
        return obj == null || obj == undefined ? 0 : obj.upperMapId;
    }
    getLowerMapId() {
        var obj = this.getMapData();
        return obj == null ? 0 : obj.lowerMapId;
    }
    getUserBalance() {
        var obj = this.getMapData();
        return obj == null ? obj : obj.userBalance;
    }

    _CurrentUser() {
        var obj = this.getData();
        if (obj != null) {
            this.CurrentUser = new AdminUserModel(obj);
        } else {
            this.CurrentUser = obj;
        }
        return this.CurrentUser;
    }
    getData = async (id) => {        
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/GetMapDetail",
                {
                    method: "POST",
                    body: JSON.stringify({
                        imageId: id,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            debugger;
            let data = await response.json();
            if (response.status === 200) {
                debugger;
                let data1 = data.data;
                if (data1.isSuccess) {
                    this.saveData(data1);
                    return true;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
            }
        } catch (ex) {
            debugger;
            //this.services.authService.handleClickOnButton("logout");
        }
    };
    getMineData = async (id) => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/GetMineDetail",
                {
                    method: "POST",
                    body: JSON.stringify({
                        imageId: id,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    this.saveMineData(data1);
                    this.saveData(data1);
                    return true;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                    return false;
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    saveMineData(data) {
        localStorage.setItem("mapMineData", JSON.stringify(data));
    }
    getMapMineData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("mapMineData"));
    }
    saveData(data) {
        localStorage.setItem("mapData", JSON.stringify(data));
    }
    getMapData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("mapData"));
    }
    saveMapTempData(data) {
        localStorage.setItem("mapTempData", JSON.stringify(data));
    }
    getMapTempData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("mapTempData"));
    }
    saveMineTempData(data) {
        localStorage.setItem("mineTempData", JSON.stringify(data));
    }
    getMineTempData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("mineTempData"));
    }
    removeMapData() {
        //return JSON.parse(localStorage.getItem('user'));
        localStorage.removeItem("mapData");
    }
    getUpdateMineData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("updateMineData"));
    }
    setUpdateData(data) {
        localStorage.setItem("updateMineData", JSON.stringify(data));
    }
    getPathMineData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("updatePathData"));
    }
    setPathData(data) {
        localStorage.setItem("updatePathData", JSON.stringify(data));
    }
    getUpdateTempMineData() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("updateTempMineData"));
    }
    setUpdateTempData(data) {
        localStorage.setItem("updateTempMineData", JSON.stringify(data));
    }
    getDataById = async () => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/GetMapData",
                {
                    method: "POST",
                    //body: JSON.stringify({
                    //    imageId: mapId.id
                    //}),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            debugger;
            if (response.status === 200) {
                this.services.authService.setUserBalance(data.data.mapList[0].balance);
                this.services.authService.setUserIsGetSignupBonus(
                    data.data.mapList[0].isGetSignupBonus
                );
                return data;
            } else {
                this.services.authService.handleClickOnButton("logout");
                return null;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    saveDataGrid(data) {
        var ar = [];
        ar = this.getMapDataGrid() == null ? [] : this.getMapDataGrid();
        ar.push(data);
        localStorage.setItem("mapDataGrid", JSON.stringify(ar));
    }
    saveDataEntireGrid(data) {
        var ar = data;
        localStorage.setItem("mapDataGrid", JSON.stringify(ar));
    }
    saveDataEntireGridTemp(data) {
        localStorage.setItem("mapDataGridTemp", JSON.stringify(data));
    }
    getMapDataGrid() {
        return JSON.parse(localStorage.getItem("mapDataGrid"));
    }
    getMapDataGridTemp() {
        return JSON.parse(localStorage.getItem("mapDataGridTemp"));
    }
    removeMapDataGrid() {
        localStorage.removeItem("mapDataGrid");
    }
    setDepth(data) {
        localStorage.setItem("depthSelect", data);
    }
    getDepth() {
        return localStorage.getItem("depthSelect");
    }
    coreGrid = async (id, depth, imageIdList) => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/UpdateCoreData",
                {
                    method: "POST",
                    body: JSON.stringify({
                        imageId: id.toString(),
                        depth: depth.toString(),
                        imageIdList: imageIdList,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            debugger;
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    return true;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                    return false;
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            debugger;
            this.services.authService.handleClickOnButton("logout");
        }
    };
    coreGridTime = async (imageIdListTemo, pathList, sessionModel) => {
        debugger;
        var token = this.services.authService.getBearerToken();
        try {
            console.log(BASE_URL);
            debugger;
            let response = await fetch(
                BASE_URL + "/secure/UpdateCoreDataByTimeCopy",
                {
                    method: "POST",
                    body: JSON.stringify({
                        coreRequestList: imageIdListTemo,
                        pathList: pathList,
                        sessionTime: sessionModel,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            debugger;
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    this.saveMapTempData(data1);
                    return data1;
                    //return true;
                } else {
                    debugger
                    //this.services.authService.handleClickOnButton("logout");
                    return false;
                }
            } else {
                debugger
                //this.services.authService.handleClickOnButton("logout");
                return false;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            debugger
            //this.services.authService.handleClickOnButton("logout");
        }
    };
    coreGridFinal = async (id, depth, imageIdList) => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/UpdateCoreDataFinal",
                {
                    method: "POST",
                    body: JSON.stringify({
                        imageId: id.toString(),
                        depth: depth.toString(),
                        imageIdList: imageIdList,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    return true;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                    return false;
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    mineGridTime = async (imageIdListTemo, pathList, sessionModel) => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/UpdateMineDataByTime",
                {
                    method: "POST",
                    body: JSON.stringify({
                        mineObject: imageIdListTemo,
                        pathList: pathList,
                        sessionTime: sessionModel,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    //this.saveData(data1);
                    this.saveMapTempData(data1);
                    return data1;
                    //return true;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                    return false;
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    FindAllNodes = (coreCoordinatesList, istemp = false, isFinal = false) => {
        //var grid1 = demo.GraphSearch1;

        var userNodesAll = [];
        if (coreCoordinatesList.length > 0) {
            //coreCoordinatesList = coreCoordinatesList.sort((a, b) => Number(a.digId) - Number(b.digId))
            var grid = GraphSearch(this.$grid, this.opts, astar.search);
            if (istemp == true) {
                let realUserCoreList =
                    this.getMapData() == null
                        ? null
                        : this.getMapData().mapDetailList.filter((x) => {
                            return x.result < 3 && x.result >= 0;
                        });
                realUserCoreList = this.filterUserDigReal(realUserCoreList);
                if (realUserCoreList.length > 0) {
                    let lastNode = realUserCoreList[realUserCoreList.length - 1];
                    let abc = [];
                    var tt = coreCoordinatesList.find((x) => x.id == lastNode.id);
                    if (tt == null || tt == undefined) {
                        abc.push(lastNode);
                    }
                    for (var k = 0; k < coreCoordinatesList.length; k++) {
                        abc.push(coreCoordinatesList[k]);
                    }
                    coreCoordinatesList = abc;
                }
            }
            let userDigTemp = coreCoordinatesList;
            if (istemp) {
                console.log(coreCoordinatesList);
            }
            var lcorsNode = coreCoordinatesList[coreCoordinatesList.length - 1];
            for (var k = 0; k < coreCoordinatesList.length; k++) {
                let userNodes = [];
                if (userDigTemp.length == 1) {
                    var sss = this.filterUserDigReal(coreCoordinatesList).filter(
                        (z) => z.id < userDigTemp[0].id
                    )[0];
                    var nnn = userDigTemp[0];
                    userDigTemp = [];
                    userDigTemp.push(sss);
                    userDigTemp.push(nnn);
                }
                if (userDigTemp.length >= 2) {
                    var startp = userDigTemp[0];
                    var endp = userDigTemp[1];
                    if (startp != undefined && endp != undefined) {
                        userDigTemp = userDigTemp.filter((x) => x.id != startp.id);
                        if (lcorsNode.id == endp.id) {
                            userDigTemp = userDigTemp.filter((x) => x.id != endp.id);
                        }
                        var userNN = this.GetStraightNodes(
                            startp.rowNo,
                            startp.colNo,
                            endp.rowNo,
                            endp.colNo
                        );
                        if (userNN != undefined && userNN.length > 0) {
                            //var commonElements = this.getMapData().mapDetailList.filter(item1 =>
                            //    userNN.find(item2 => item1.rowNo === item2.rowNo && item1.colNo === item2.colNo)
                            //);
                            //userNodes = commonElements;

                            for (var m = 0; m < userNN.length; m++) {
                                var nn = this.getMapData().mapDetailList.find(
                                    (n) =>
                                        n.rowNo == userNN[m].rowNo && n.colNo == userNN[m].colNo
                                );
                                if (endp.id != nn.id) {
                                    userDigTemp = userDigTemp.filter((x) => x.id != nn.id);
                                }
                                userNodes.push(nn);
                            }
                        } else {
                            var userNood = grid.cellClickedRow(
                                startp.rowNo,
                                startp.colNo,
                                endp.rowNo,
                                endp.colNo
                            );
                            for (var m = 0; m < userNood.length; m++) {
                                var nn = this.getMapData().mapDetailList.find(
                                    (n) =>
                                        n.rowNo == userNood[m].x + 1 && n.colNo == userNood[m].y + 1
                                );
                                if (endp.id != nn.id) {
                                    userDigTemp = userDigTemp.filter((x) => x.id != nn.id);
                                }
                                userNodes.push(nn);
                                //corrdinates.push({ x: nn.colNo - 1, y: nn.rowNo - 1 });
                            }
                        }
                        userNodes = userNodes.filter((x) => x.id != startp.id);
                        userNodes = userNodes.filter((x) => x.id != endp.id);
                        var nk = userNodes.find((l) => l.id == startp.id);
                        if (nk === null || nk === undefined) {
                            //userNodes.push(startp);
                        }
                        var nke = userNodes.find((l) => l.id == endp.id);
                        if (
                            userNodes.length == 1 &&
                            (nke === null || nke === undefined) &&
                            istemp == false
                        ) {
                            userNodes.push(endp);
                        }
                        if (isFinal) {
                            var eep = [];
                            //userNodes.map((item2, index) => (
                            //    eep.push(item2.id.toString())
                            //));
                            eep = userNodes.map((item2) => item2.id.toString());
                            var obb = {
                                imageIdFrom: startp.id,
                                imageIdTo: endp.id,
                                imageIdList: eep,
                                totalPath: eep.length.toString(),
                            };
                            userNodesAll.push(obb);
                        } else {
                            userNodesAll.push(userNodes);
                        }
                    } else {
                    }
                }
            }
            if (userNodesAll.length > 0) {
                if (isFinal) {
                    return userNodesAll;
                } else {
                    let ppCordinate = [];
                    ppCordinate = userNodesAll.map((line) =>
                        line.map((item2) => ({
                            x: item2.colNo - 1,
                            y: item2.rowNo - 1,
                            id: item2.id,
                        }))
                    );
                    //userNodesAll.forEach((line) => {
                    //    //console.log(line);
                    //    let mmt = line.map((item, index) => (
                    //        { x: item.colNo - 1, y: item.rowNo - 1, id: item.id }
                    //    ));
                    //    ppCordinate.push(mmt);
                    //});
                    return ppCordinate;
                }
                //if (!istemp) {
                //    //console.log(ppCordinate);
                //    pathCoordinates = ppCordinate;
                //    RemovePathEnergy(userNodesAll);
                //}
                //else {
                //    pathCoordinatesLatest = ppCordinate;
                //    UpdatePathEnergy(userNodesAll);
                //}
            } else {
                return userNodesAll;
            }
        }

        return userNodesAll;
    };

    GetStraightNodes = (strPRow, strPCol, endPRow, endPCol, isall = false) => {
        var currentStartRow = strPRow;
        var currentStartCol = strPCol;
        let userDNodes = [];
        let totalPath = [];
        if (endPRow >= strPRow) {
            for (var i = strPRow; i <= endPRow; i++) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == i && s.colNo == currentStartCol
                );
                var nn = userDNodes.find(
                    (x) => x.rowNo == i && x.colNo == currentStartCol
                );
                if (nn == null) {
                    userDNodes.push(nc);
                }
                currentStartRow = i;
            }
        }
        if (endPRow < strPRow) {
            for (var i = strPRow; i >= endPRow; i--) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == i && s.colNo == currentStartCol
                );
                var nn = userDNodes.find(
                    (x) => x.rowNo == i && x.colNo == currentStartCol
                );
                if (nn == null) {
                    userDNodes.push(nc);
                }
                currentStartRow = i;
            }
        }
        if (endPCol >= strPCol) {
            for (var i = strPCol; i <= endPCol; i++) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == currentStartRow && s.colNo == i
                );
                var nn = userDNodes.find(
                    (x) => x.rowNo == currentStartRow && x.colNo == i
                );
                if (nn == null) {
                    userDNodes.push(nc);
                }
                currentStartCol = i;
            }
        }
        if (endPCol < strPCol) {
            for (var i = strPCol; i >= endPCol; i--) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == currentStartRow && s.colNo == i
                );
                var nn = userDNodes.find(
                    (x) => x.rowNo == currentStartRow && x.colNo == i
                );
                if (nn == null) {
                    userDNodes.push(nc);
                }
                currentStartCol = i;
            }
        }
        if (userDNodes.filter((x) => x.isRestricted == true).length > 0) {
            userDNodes = [];
        }
        if (userDNodes.length > 0) {
            totalPath.push(1);
        }
        currentStartRow = strPRow;
        currentStartCol = strPCol;
        let userDNodesCol = [];
        if (endPCol >= strPCol) {
            for (var i = strPCol; i <= endPCol; i++) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == currentStartRow && s.colNo == i
                );
                var nn = userDNodesCol.find(
                    (x) => x.rowNo == currentStartRow && x.colNo == i
                );
                if (nn == null) {
                    userDNodesCol.push(nc);
                }
                currentStartCol = i;
            }
        }
        if (endPCol < strPCol) {
            for (var i = strPCol; i >= endPCol; i--) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == currentStartRow && s.colNo == i
                );
                var nn = userDNodesCol.find(
                    (x) => x.rowNo == currentStartRow && x.colNo == i
                );
                if (nn == null) {
                    userDNodesCol.push(nc);
                }
                currentStartCol = i;
            }
        }
        if (endPRow >= strPRow) {
            for (var i = strPRow; i <= endPRow; i++) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == i && s.colNo == currentStartCol
                );
                var nn = userDNodesCol.find(
                    (x) => x.rowNo == i && x.colNo == currentStartCol
                );
                if (nn == null) {
                    userDNodesCol.push(nc);
                }
                currentStartRow = i;
            }
        }
        if (endPRow < strPRow) {
            for (var i = strPRow; i >= endPRow; i--) {
                var nc = this.getMapData().mapDetailList.find(
                    (s) => s.rowNo == i && s.colNo == currentStartCol
                );
                var nn = userDNodesCol.find(
                    (x) => x.rowNo == i && x.colNo == currentStartCol
                );
                if (nn == null) {
                    userDNodesCol.push(nc);
                }
                currentStartRow = i;
            }
        }
        if (userDNodesCol.filter((x) => x.isRestricted == true).length > 0) {
            userDNodesCol = [];
        }
        if (userDNodesCol.length > 0) {
            totalPath.push(2);
        }
        if (totalPath.length > 0) {
            if (isall) {
                var allPath = [];
                if (userDNodes.length > 0) {
                    allPath.push(userDNodes);
                }
                if (userDNodesCol.length > 0) {
                    allPath.push(userDNodesCol);
                }
                return allPath;
            } else {
                var rand = 1; // totalPath[(Math.random() * totalPath.length) | 0]
                if (rand == 1) {
                    return userDNodes;
                } else {
                    return userDNodesCol;
                }
            }
        }
    };
    filterUserDigReal = (myArray) => {
        var abc = myArray
            .filter((x) => x.result < 3)
            .sort((a, b) => Number(a.digId) - Number(b.digId));
        return abc;
    };
    FinAllNodesInMineAll = (coreCoordinatesList, istemp, isFinal = false) => {
        var rrPath = [];
        var AllNodesReal = [];
        for (var n = 0; n < coreCoordinatesList.length - 1; n++) {
            let ppCordinate = [];
            var boxes = [];
            var boxes1 = [];
            var start = [];
            var end = [];
            start.push(coreCoordinatesList[n].start.x);
            start.push(coreCoordinatesList[n].start.y);
            end.push(coreCoordinatesList[n].end.x - 1);
            end.push(coreCoordinatesList[n].end.y - 1);
            boxes1.push(start);
            boxes1.push(end);
            boxes.push(boxes1);
            boxes1 = [];
            start = [];
            end = [];
            start.push(coreCoordinatesList[n + 1].start.x);
            start.push(coreCoordinatesList[n + 1].start.y);
            end.push(coreCoordinatesList[n + 1].end.x - 1);
            end.push(coreCoordinatesList[n + 1].end.y - 1);
            boxes1.push(start);
            boxes1.push(end);
            boxes.push(boxes1);
            const resultPath = findUniqueShortestPath(boxes);
            var allId = [];
            if (resultPath.length > 2) {
                resultPath.forEach((line, index) => {
                    if (
                        line[0] >= coreCoordinatesList[n].start.x &&
                        line[0] <= coreCoordinatesList[n].end.x &&
                        line[1] >= coreCoordinatesList[n].start.y &&
                        line[1] <= coreCoordinatesList[n].end.y
                    ) {
                    } else if (
                        line[0] >= coreCoordinatesList[n + 1].start.x &&
                        line[0] <= coreCoordinatesList[n + 1].end.x &&
                        line[1] >= coreCoordinatesList[n + 1].start.y &&
                        line[1] <= coreCoordinatesList[n + 1].end.y
                    ) {
                    }
                    //else if (line[0] == coreCoordinatesList[n].start.x && line[1] == coreCoordinatesList[n].start.y) {

                    //}
                    //else if (line[0] == coreCoordinatesList[n+1].start.x && line[1] == coreCoordinatesList[n+1].start.y) {

                    //}
                    //else if ((line[0] == (coreCoordinatesList[n].end.x - 1) && line[1] == (coreCoordinatesList[n].end.y - 1))) {
                    //}
                    //else if ((line[0] == (coreCoordinatesList[n+1].end.x - 1) && line[1] == (coreCoordinatesList[n+1].end.y - 1))) {
                    //}
                    else {
                        var item = this.getMapData().mapDetailList.find(
                            (x) => x.rowNo == line[1] + 1 && x.colNo == line[0] + 1
                        );
                        var abc = coreCoordinatesList;
                        var npq = start;
                        var npq2 = end;
                        rrPath.push(line);
                        var mmt = { x: item.colNo - 1, y: item.rowNo - 1, id: item.id };
                        ppCordinate.push(mmt);
                        allId.push(item.id.toString());
                    }
                });
            }
            if (ppCordinate.length > 0) {
                if (isFinal) {
                    AllNodesReal.push({
                        imageIdFrom: JSON.stringify(coreCoordinatesList[n].start),
                        imageIdTo: JSON.stringify(coreCoordinatesList[n].end),
                        depth: coreCoordinatesList[n].depth.toString(),
                        totalPath: ppCordinate.length.toString(),
                        imageIdList: allId,
                    });
                } else {
                    AllNodesReal.push(ppCordinate);
                }
            }
        }
        return { AllNodesReal: AllNodesReal, rrPath: rrPath };
    };

    FinAllNodesInMine = (coreCoordinatesList, istemp, isFinal = false) => {
        var grid = GraphSearch(this.$grid, this.opts, astar.search);
        var userNodesAll = [];
        if (coreCoordinatesList != null && coreCoordinatesList.length > 0) {
            for (var h = 0; h < coreCoordinatesList.length - 1; h++) {
                var firstNode = coreCoordinatesList[h];
                var secondNode = coreCoordinatesList[h + 1];
                var startX = firstNode.start.x;
                var startY = firstNode.start.y;
                var endX = firstNode.end.x - 1;
                var endY = firstNode.end.y - 1;
                var minX = startX < endX ? startX : endX;
                var minY = startY < endY ? startY : endY;
                var maxX = startX > endX ? startX : endX;
                var maxY = startY > endY ? startY : endY;
                var leftTopCornerFirstNode = { colNo: minX, rowNo: minY };
                var leftbuttomCornerFirstNode = { colNo: minX, rowNo: maxY };
                var rightTopCornerFirstNode = { colNo: maxX, rowNo: minY };
                var rightButtomCornerFirstNode = { colNo: maxX, rowNo: maxY };
                var startX2 = secondNode.start.x;
                var startY2 = secondNode.start.y;
                var endX2 = secondNode.end.x - 1;
                var endY2 = secondNode.end.y - 1;
                var minX2 = startX2 < endX2 ? startX2 : endX2;
                var minY2 = startY2 < endY2 ? startY2 : endY2;
                var maxX2 = startX2 > endX2 ? startX2 : endX2;
                var maxY2 = startY2 > endY2 ? startY2 : endY2;
                var leftTopCornerSecondNode = { colNo: minX2, rowNo: minY2 };
                var leftbuttomCornerSecondNode = { colNo: minX2, rowNo: maxY2 };
                var rightTopCornerSecondNode = { colNo: maxX2, rowNo: minY2 };
                var rightButtomCornerSecondNode = { colNo: maxX2, rowNo: maxY2 };
                var obj = [];
                if (
                    minX != null &&
                    minY != null &&
                    maxX != null &&
                    maxY != null &&
                    minX2 != null &&
                    minY2 != null &&
                    maxX2 != null &&
                    maxY2 != null
                ) {
                    var leftTopCorner = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == leftTopCornerFirstNode.rowNo + 1 &&
                            n.colNo == leftTopCornerFirstNode.colNo + 1
                    );
                    var leftbuttomCorner = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == leftbuttomCornerFirstNode.rowNo + 1 &&
                            n.colNo == leftbuttomCornerFirstNode.colNo + 1
                    );
                    var rightTopCorner = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == rightTopCornerFirstNode.rowNo + 1 &&
                            n.colNo == rightTopCornerFirstNode.colNo + 1
                    );
                    var rightButtomCorner = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == rightButtomCornerFirstNode.rowNo + 1 &&
                            n.colNo == rightButtomCornerFirstNode.colNo + 1
                    );
                    var leftTopCornerSecond = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == leftTopCornerSecondNode.rowNo + 1 &&
                            n.colNo == leftTopCornerSecondNode.colNo + 1
                    );
                    var leftbuttomCornerSecond = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == leftbuttomCornerSecondNode.rowNo + 1 &&
                            n.colNo == leftbuttomCornerSecondNode.colNo + 1
                    );
                    var rightTopCornerSecond = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == rightTopCornerSecondNode.rowNo + 1 &&
                            n.colNo == rightTopCornerSecondNode.colNo + 1
                    );
                    var rightButtomCornerSecond = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == rightButtomCornerSecondNode.rowNo + 1 &&
                            n.colNo == rightButtomCornerSecondNode.colNo + 1
                    );
                    let userDigTemp = [];
                    if (h == 0 && userNodesAll.length == 0) {
                        if (leftTopCorner != null && leftTopCornerSecond != null) {
                            userDigTemp.push(leftTopCorner);
                            userDigTemp.push(leftTopCornerSecond);
                            obj.push({ fNode: leftTopCorner, lNode: leftTopCornerSecond });
                            if (leftbuttomCornerSecond != null) {
                                userDigTemp.push(leftbuttomCornerSecond);
                                obj.push({
                                    fNode: leftTopCorner,
                                    lNode: leftbuttomCornerSecond,
                                });
                            }
                            if (rightTopCornerSecond != null) {
                                userDigTemp.push(rightTopCornerSecond);
                                obj.push({ fNode: leftTopCorner, lNode: rightTopCornerSecond });
                            }
                            if (rightButtomCornerSecond != null) {
                                userDigTemp.push(rightButtomCornerSecond);
                                obj.push({
                                    fNode: leftTopCorner,
                                    lNode: rightButtomCornerSecond,
                                });
                            }
                        }
                        if (leftbuttomCorner != null && leftTopCornerSecond != null) {
                            userDigTemp.push(leftbuttomCorner);
                            obj.push({ fNode: leftbuttomCorner, lNode: leftTopCornerSecond });
                            if (leftbuttomCornerSecond != null) {
                                obj.push({
                                    fNode: leftbuttomCorner,
                                    lNode: leftbuttomCornerSecond,
                                });
                            }
                            if (rightTopCornerSecond != null) {
                                obj.push({
                                    fNode: leftbuttomCorner,
                                    lNode: rightTopCornerSecond,
                                });
                            }
                            if (rightButtomCornerSecond != null) {
                                obj.push({
                                    fNode: leftbuttomCorner,
                                    lNode: rightButtomCornerSecond,
                                });
                            }
                        }
                        if (rightTopCorner != null && leftTopCornerSecond != null) {
                            userDigTemp.push(rightTopCorner);
                            obj.push({ fNode: rightTopCorner, lNode: leftTopCornerSecond });
                            if (leftbuttomCornerSecond != null) {
                                obj.push({
                                    fNode: rightTopCorner,
                                    lNode: leftbuttomCornerSecond,
                                });
                            }
                            if (rightTopCornerSecond != null) {
                                obj.push({
                                    fNode: rightTopCorner,
                                    lNode: rightTopCornerSecond,
                                });
                            }
                            if (rightButtomCornerSecond != null) {
                                obj.push({
                                    fNode: rightTopCorner,
                                    lNode: rightButtomCornerSecond,
                                });
                            }
                        }
                        if (rightButtomCorner != null && leftTopCornerSecond != null) {
                            userDigTemp.push(rightButtomCorner);
                            obj.push({
                                fNode: rightButtomCorner,
                                lNode: leftTopCornerSecond,
                            });
                            if (leftbuttomCornerSecond != null) {
                                obj.push({
                                    fNode: rightButtomCorner,
                                    lNode: leftbuttomCornerSecond,
                                });
                            }
                            if (rightTopCornerSecond != null) {
                                obj.push({
                                    fNode: rightButtomCorner,
                                    lNode: rightTopCornerSecond,
                                });
                            }
                            if (rightButtomCornerSecond != null) {
                                obj.push({
                                    fNode: rightButtomCorner,
                                    lNode: rightButtomCornerSecond,
                                });
                            }
                        }
                    } else {
                        var np = userNodesAll[userNodesAll.length - 1];
                        var leftTopCorner = np[np.length - 1];
                        obj.push({ fNode: leftTopCorner, lNode: leftbuttomCornerSecond });
                        obj.push({ fNode: leftTopCorner, lNode: leftTopCornerSecond });
                        obj.push({ fNode: leftTopCorner, lNode: rightButtomCornerSecond });
                        obj.push({ fNode: leftTopCorner, lNode: rightTopCornerSecond });
                    }
                }
                var userNodesTemp = [];
                var userNodes = [];
                for (var i = 0; i < obj.length; i++) {
                    var userNodes = [];
                    var startp = obj[i].fNode;
                    var endp = obj[i].lNode;
                    if (startp != undefined && endp != undefined) {
                        //userDigTemp = userDigTemp.filter(x => x.id != startp.id);
                        //if (lcorsNode.id == endp.id) {
                        //    userDigTemp = userDigTemp.filter(x => x.id != endp.id);
                        //}

                        var userNN1 = this.GetStraightNodes(
                            startp.rowNo,
                            startp.colNo,
                            endp.rowNo,
                            endp.colNo,
                            true
                        );
                        var userNN = [];
                        var userNNTemp = [];
                        for (var l = 0; l < userNN1.length; l++) {
                            var nList = userNN1[l];
                            var nodeExist = nList.filter(
                                (s) =>
                                    s.rowNo >= minY + 1 &&
                                    s.rowNo <= maxY + 1 &&
                                    s.colNo >= minX + 1 &&
                                    s.colNo <= maxX + 1
                            );
                            var nodeExist2 = nList.filter(
                                (s) =>
                                    s.rowNo >= minY2 + 1 &&
                                    s.rowNo <= maxY2 + 1 &&
                                    s.colNo >= minX2 + 1 &&
                                    s.colNo <= maxX2 + 1
                            );
                            if (nodeExist.length <= 1 && nodeExist2.length <= 1) {
                                userNNTemp.push(nList);
                            }
                        }
                        if (userNNTemp.length > 0) {
                            userNN = userNNTemp[0];
                        }
                        if (userNN != undefined && userNN.length > 0) {
                            var abc = { startNode: startp, endNode: endp, allNode: userNN };
                            userNodesTemp.push(abc);
                            //for (var m = 0; m < userNN.length; m++) {
                            //    var nn = this.getMapData().mapDetailList.find(n => n.rowNo == userNN[m].rowNo && n.colNo == userNN[m].colNo);
                            //    //if (endp.id != nn.id) {
                            //    //    userDigTemp = userDigTemp.filter(x => x.id != nn.id);
                            //    //}
                            //    userNodes.push(nn);
                            //}
                        } else {
                            var userNood = grid.cellClickedRow(
                                startp.rowNo,
                                startp.colNo,
                                endp.rowNo,
                                endp.colNo
                            );
                            var aaaaa = [];
                            //userNodesTemp.push(abc);
                            for (var m = 0; m < userNood.length; m++) {
                                var nn = this.getMapData().mapDetailList.find(
                                    (n) =>
                                        n.rowNo == userNood[m].x + 1 && n.colNo == userNood[m].y + 1
                                );
                                //if (endp.id != nn.id) {
                                //    userDigTemp = userDigTemp.filter(x => x.id != nn.id);
                                //}
                                aaaaa.push(nn);
                                //corrdinates.push({ x: nn.colNo - 1, y: nn.rowNo - 1 });
                            }
                            var abc = { startNode: startp, endNode: endp, allNode: aaaaa };
                            userNodesTemp.push(abc);
                        }
                    }
                }
                userNodesTemp = userNodesTemp.sort(
                    (a, b) => Number(a.allNode.length) - Number(b.allNode.length)
                );
                var finalPath = userNodesTemp[0];
                for (var m = 0; m < finalPath.allNode.length; m++) {
                    var nn = this.getMapData().mapDetailList.find(
                        (n) =>
                            n.rowNo == finalPath.allNode[m].rowNo &&
                            n.colNo == finalPath.allNode[m].colNo
                    );
                    userNodes.push(nn);
                }
                userNodesAll.push(userNodes);
            }

            if (userNodesAll.length > 0) {
                if (isFinal) {
                    return userNodesAll;
                } else {
                    let ppCordinate = [];
                    userNodesAll.forEach((line) => {
                        //console.log(line);
                        let mmt = line.map((item, index) => ({
                            x: item.colNo - 1,
                            y: item.rowNo - 1,
                            id: item.id,
                        }));
                        ppCordinate.push(mmt);
                    });
                    return ppCordinate;
                }
            } else {
                return userNodesAll;
            }
        }
    };

    /*Start Profile API*/
    getUserProfile = async (action) => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/GetUserProfileData",
                {
                    method: "POST",
                    body: JSON.stringify({
                        action: action,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                let data1 = data.data;
                if (data1.isSuccess) {
                    //this.saveData(data1);
                    return data1.data;
                } else {
                    this.services.authService.handleClickOnButton("logout");
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                return false;
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    updateMechEnergy = async () => {
        var token = this.services.authService.getBearerToken();
        try {
            let response = await fetch(
                BASE_URL + "/secure/updateMechEnergy",
                {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        Bearer: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                if (data.isSuccess) {
                    window.location.reload();
                } else {
                    this.services.authService.handleClickOnButton("logout");
                }
            } else {
                this.services.authService.handleClickOnButton("logout");
                //setEmailValid(-1);
                //setEmailError(data.errorMessage);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            this.services.authService.handleClickOnButton("logout");
        }
    };
    /*End Profile API*/
}
