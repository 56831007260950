import React from 'react'
import LogoBottom from '../../images/icons/map-bottom-logo.svg'

const CoringSectionInfo = (props) => {
    const mapName = (props.mapData != null && props.mapData.imageData != null) ? props.mapData.imageData.mapImageNo : "";
  return (
    <div className='coring-section-wrap'>
      <div className='coring-section'>
        <div className='coring-logo-bottom'><img src={LogoBottom} alt="Logo" /></div>
        <div className='coring-section-content'>
                  <h2>Section {mapName}</h2>
          <p>105.03° N, 42.79° E</p>
        </div>
      </div>
    </div>
  )
}

export default CoringSectionInfo