import "./warning.css";
import warning from "../../images/warning/warning.svg";
import WeatherIcon from "../../images/warning/weather";
import TerrainIcon from "../../images/warning/terrain";
import BonusIcon from "../../images/warning/bonus";
import EnvironmentIcon from "../../images/warning/environment";
import FactionIcon from "../../images/warning/faction";
import { useState } from "react";
import { setShowWarning, setIsButtonClick } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";
const WarningPopup = ({ risk, factors }) => {
    const [clasesCount, setClassesCount] = useState(0);
    const dispatch = useDispatch();
    const {remainMechEnergy } =useSelector((state) => state.app);
  const riskClass = {
    f1: {
      low: "Caution: Light acid Rain potential",
      medium: "Warning! isolated Acid rain",
      high: "Heavy Acid storm Rain incoming",
    },
    f2: {
      low: "Caution: Unstable Ground Alert",
      medium: "Warning! Quicksand in this Area",
      high: "ALERT! large Sinkholes opening",
    },
    f3: {
      low: "Caution: Apostles' Raid Alert",
      medium: "Warning: Hybrid Warband Warning",
      high: "Alert! apostles Toxic Plasma Leak",
    },
    f4: {
      low: "Caution: HumnX Patrols spotted",
      medium: "Warning: humnx Warband Raid",
      high: "Alert! Humnx assault imminent",
    },
    f5: {
      true: "acid rain Unearthing Ilyrium",
    },
  };
  return (
    <div className="warning-popup-container">
      <div className="wp-wrap">
        <img src={warning} />
        <div className="wp-text-wrap">
          <h3>Grid risk Assesment</h3>
          <div className={`wp-message ${risk}`}>
            {risk == "high"
              ? "ALERT! significant mech damage expected"
              : risk == "medium"
              ? "Warning! Moderate damage possible"
              : risk == "low"
              ? "Caution! Low damage possible"
              : "clear Mining Zone"}
          </div>
          <div className="risk-stack">
            {Object.keys(factors).map((key) => {
              if (["low", "medium", "high", "true"].includes(factors[key])) {
                /*setClassesCount((prev) => prev + 1);*/
                return (
                  <div className={`assessment ${factors[key]}`}>
                    {key == "f1" ? (
                      <WeatherIcon risk={factors[key]} />
                    ) : key == "f2" ? (
                      <TerrainIcon risk={factors[key]} />
                    ) : key == "f3" ? (
                      <EnvironmentIcon risk={factors[key]} />
                    ) : key == "f4" ? (
                      <FactionIcon risk={factors[key]} />
                    ) : (
                      key == "f5" && <BonusIcon />
                    )}
                    <div className="assessment-class-container">
                      <div className={`assessment-class ${factors[key]}`}>
                        {factors[key] == "high"
                          ? "critical"
                          : factors[key] == "medium"
                          ? "moderate"
                          : factors[key] == "low"
                          ? "low"
                          : "bonus detected"}
                      </div>
                    </div>
                    <p>{riskClass[key][factors[key]]}</p>
                  </div>
                );
              }
            })}
            {/*{clasesCount === 0 && (*/}
            {/*  <p className="no-threat">No threats detected.</p>*/}
            {/*)}*/}
          </div>
        </div>
              <div className="assessment-buttons-wrap">
                  <div className="assessment-buttons button-grey" onClick={() => { dispatch(setIsButtonClick(true)); dispatch(setShowWarning(false)) }}>retreat</div>
                  {remainMechEnergy < 0 ? "" : <div className="assessment-buttons button-white" onClick={() => dispatch(setShowWarning(false))}>proceed</div>}
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
