import React, { useEffect, useState } from "react";
import CoreService from '../helper/Coreservice'
const GridPathway = ({ path }) => {
    const coreservices = {
        coreService: new CoreService()
    };
    const gridSize = 32;
    const [gridOverlayStyles, setGridOverlayStyles] = useState([]);

    useEffect(() => {
        const gridOverlayStyles = [];

        path.forEach((line) => {
            const lineStyles = line.map((point) => {
                const borderTop = shouldShowBorder(point.x, point.y - 1);
                const borderBottom = shouldShowBorder(point.x, point.y + 1);
                const borderLeft = shouldShowBorder(point.x - 1, point.y);
                const borderRight = shouldShowBorder(point.x + 1, point.y);

                return {
                    position: "absolute",
                    pointerEvents: "none",
                    top: point.y * gridSize + "px",
                    left: point.x * gridSize + "px",
                    borderTop: borderTop
                        ? "none"
                        : "1px dashed var(--mine-outline, #FFF)",
                    borderBottom: borderBottom
                        ? "none"
                        : "1px dashed var(--mine-outline, #FFF)",
                    borderLeft: borderLeft
                        ? "none"
                        : "1px dashed var(--mine-outline, #FFF)",
                    borderRight: borderRight
                        ? "none"
                        : "1px dashed var(--mine-outline, #FFF)",
                };
            });

            gridOverlayStyles.push(lineStyles);
        });

        setGridOverlayStyles(gridOverlayStyles);
    }, [path]);

    const shouldShowBorder = (x, y) => {
        const pathNew = path.flatMap((line) => line);

        return (
            x < 0 ||
            y < 0 ||
            x >= gridSize ||
            y >= gridSize ||
            pathNew.some((point) => point.x === x && point.y === y)
        );
    };


    return (
        <div className="grid-pathway-container">
            {gridOverlayStyles.map((lineStyles, lineIndex) => (
                <div key={lineIndex}>
                    {lineStyles.map((pointStyles, pointIndex) => (
                        <div key={pointIndex} className="pathway" style={pointStyles}></div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default GridPathway;
