////import ShareableCard from "./ShareableCard";
////import ShareComplete from "./ShareComplete";
////import Thumbnail from "./Thumbnail";
import "../mine/mine.css";
import icon1 from "../../images/icons/Group.svg";
import icon9 from "../../images/icons/Group2.svg";
import icon2 from "../../images/icons/Social1.svg";
import icon4 from "../../images/icons/Social2.svg";
import icon7 from "../../images/icons/Social3.svg";
import icon5 from "../../images/icons/Group3.svg";
import icon3 from "../../images/icons/Social5.svg";
import icon6 from "../../images/icons/Social7.svg";
import icon8 from "../../images/icons/Social8.svg";

import gicon1 from "../../images/icons/instagramg.svg";
import gicon2 from "../../images/icons/tiktokg.svg";
import gicon3 from "../../images/icons/twitterg.svg";
import gicon4 from "../../images/icons/snapchat.svg";
import gicon5 from "../../images/icons/pinterest.svg";
import gicon6 from "../../images/icons/discordg.svg";
import gicon7 from "../../images/icons/twitch.svg";
import gicon8 from "../../images/icons/copy.svg";
import mechImage from "../../images/avatars/Layer-29-2.png";
import logo from "../../images/logo.svg";

import goldThumb from "../../images/aventurite.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";

import closeButton from "../../images/icons/Close-Button.png";
import { useState } from "react";

import AuthService from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import ClipboardJS from "clipboard";
const SocialOverlay = (props) => {
  const services = {
    authService: new AuthService(),
  };
  const coreservices = {
    coreService: new CoreService(),
  };
  var totalEarn = 0;
  //var bestEnergyData = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel;
  var str = [];
  //bestEnergyData.forEach((line, index) => {
  //    if (line.energyEarn > 0) {
  //        str.push(bestEnergyData[index]);
  //    }
  //});
  //var bestEnergyData1 = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mineList == null ? null : coreservices.coreService.getMapData().mineList.filter(x => x.result < 3).sort((b, a) => a.energyEarn - b.energyEarn);
  //bestEnergyData1.forEach((line, index) => {
  //    totalEarn = totalEarn + (line.energyEarn != null ? line.energyEarn : 0)
  //});
  var imageUrl = mechImage; // (sharePic == "gold" ? goldThumb : sharePic == "silver" ? silverThumb : bronzeThumb);
  var text = ""; // coreservices.coreService.getMapData() == null ? "" : coreservices.coreService.getMapData().shareDiscription;
  text = text.replace("@totalMine", totalEarn + "Kg ");
  text = text.replace("@megaCrystal", str.length);
  const [hovered, setHovered] = useState(-1);
  const [shareComplete, setShareComplete] = useState(false);
  var sharePic = props.sharetype;
  const handleHover = (icon) => {
    setHovered(icon);
  };
  const shareOnSocialMedia = (platform) => {
    imageUrl =
      platform == "download" ? imageUrl : "http://18.144.82.135:85" + imageUrl;
    if (platform == "instragram") {
      shareOnInstagram(imageUrl, text);
    }
    if (platform == "tiktok") {
      shareOnTikTok(imageUrl, text);
    }
    if (platform == "twitter") {
      shareOnTwitter(imageUrl, text);
    }
    if (platform == "snapchat") {
      shareOnSnapchat();
      //shareOnSnapchat(imageUrl, text);
    }
    if (platform == "pintrest") {
      var url =
        "https://www.pinterest.com/pin/create/button/?url=http://18.144.82.135:85/&media=" +
        imageUrl +
        "&description=" +
        text +
        "";
      window.open(url, "_blank");
    }
    if (platform == "discord") {
      shareOnDiscord(imageUrl, text);
    }
    if (platform == "twitch") {
      shareOnTwitch(imageUrl, text);
    }
    if (platform == "copy") {
      copyToClipboard(imageUrl);
    }
    if (platform == "download") {
      handleDownload(imageUrl, text);
    }
    props.setsocialOverlay(false);
  };
  function shareOnInstagram(imageUrl, text) {
    const instagramUrl = `instagram://library?AssetPath=${encodeURIComponent(
      imageUrl
    )}&Caption=${encodeURIComponent(text)}`;
    window.location.href = instagramUrl;

    // If the Instagram app is not installed, the user will see an error message.
  }
  function shareOnTwitter(imageUrl, text) {
    const tweetIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(imageUrl)}`;

    // Open the Twitter share dialog in a new window
    window.open(tweetIntentUrl, "_blank");
  }
  function shareOnSnapchat() {
    const tiktokUrl = "snapchat://";
    window.open(tiktokUrl, "_blank");
    // Create a script element
    //const script = document.createElement('script');
    //script.id = 'snapkit-creative-kit-sdk';
    //script.src = 'https://sdk.snapkit.com/js/v1/create.js';

    //// Check if the script with the same ID already exists on the page
    //const existingScript = document.getElementById('snapkit-creative-kit-sdk');
    //if (existingScript) {
    //    return;
    //}

    //// Append the script to the document
    //document.body.appendChild(script);

    //// Clean up the script when it has loaded
    //script.onload = () => {
    //    // You can perform additional initialization here if needed
    //    console.log('Snapchat Creative Kit SDK loaded successfully.');
    //};
    //var timeOutInterval = setTimeout(() => {
    //    clearTimeout(timeOutInterval)
    //    const div = document.getElementById('myButton');
    //    const button = div.querySelector('button');
    //    button.click();
    //}, 1000)
  }

  function shareOnTikTok(imageUrl, text) {
    const tiktokUrl = "tiktok://";
    window.open(tiktokUrl, "_blank");
  }
  function shareOnDiscord(imageDiscord, text) {
    const tiktokUrl = "https://discord.com/";
    window.open(tiktokUrl, "_blank");
  }
  function shareOnTwitch(imageUrl, text) {
    const tiktokUrl = "twitch://open";
    window.open(tiktokUrl, "_blank");
  }
  const copyToClipboard = (imageUrl) => {
    const clipboard = new ClipboardJS(".copy-button", {
      text: () => imageUrl,
    });
    clipboard.on("success", () => {
      alert("URL copied to clipboard!");
    });
    clipboard.on("error", () => {
      console.error("Error copying URL to clipboard");
    });
    // Trigger the copy action
    //clipboard.onClick({ currentTarget: document.querySelector('.copy-button') });
  };
  const handleCopy = (imageUrl, text) => {
    // Use the Clipboard API to copy the image URL to the clipboard
    navigator.clipboard
      .writeText("http://18.144.82.135:85/" + imageUrl)
      .then(() => alert("Image URL copied to clipboard"))
      .catch((error) => console.error("Failed to copy: ", error));
  };

  const handleDownload = (imageUrl, text) => {
    // Create a link to download the image
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "mymech.jpg";
    link.click();
  };
  return (
    <>
      {!shareComplete ? (
        <div className="social-overlay-container">
          <div className="social-overlay-wrap">
            <div
              className="overlay-close-button"
              onClick={() => props.setsocialOverlay(false)}
            >
              <img src={closeButton} alt="close button"></img>
            </div>
            <div className="share-text-wrap">
              <h1>share</h1>
            </div>
            <div className="thumbnail-wrap-2">
              <div className="thumb-header-2">
                <img src={logo} alt="logo"></img>
              </div>
              <div className="thumb-img-no-hover">
                <img src={mechImage} alt="thumbnail"></img>
              </div>
            </div>

            <div className="share-icons-wrap">
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(0)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("instragram");
                }}
              >
                {hovered === 0 ? (
                  <img src={gicon1} alt="glow" />
                ) : (
                  <img src={icon1} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("tiktok");
                }}
              >
                {hovered === 1 ? (
                  <img src={gicon2} alt="glow" />
                ) : (
                  <img src={icon2} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(2)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("twitter");
                }}
              >
                {hovered === 2 ? (
                  <img src={gicon3} alt="glow" />
                ) : (
                  <img src={icon3} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("snapchat");
                }}
              >
                {hovered === 3 ? (
                  <img src={gicon4} alt="glow" />
                ) : (
                  <img src={icon4} alt="social icon" />
                )}
              </div>

              <div
                className="social-icon"
                onMouseEnter={() => handleHover(4)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("pintrest");
                }}
              >
                {hovered === 4 ? (
                  <img src={gicon5} alt="glow" />
                ) : (
                  <img src={icon5} alt="social icon" />
                )}
              </div>

              <div
                className="social-icon"
                onMouseEnter={() => handleHover(5)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => shareOnSocialMedia("discord")}
              >
                {hovered === 5 ? (
                  <img src={gicon6} alt="glow" />
                ) : (
                  <img src={icon6} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(6)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("twitch");
                }}
              >
                {hovered === 6 ? (
                  <img src={gicon7} alt="glow" />
                ) : (
                  <img src={icon7} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon copy-button"
                onMouseEnter={() => handleHover(7)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("copy");
                }}
              >
                {hovered === 7 ? (
                  <img src={gicon8} alt="glow" />
                ) : (
                  <img src={icon8} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(8)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("download");
                }}
              >
                {hovered === 8 ? (
                  <img src={icon9} alt="glow" />
                ) : (
                  <img src={icon9} alt="social icon" />
                )}
              </div>
              <div
                id="myButton"
                class="snapchat-creative-kit-share hide"
                data-theme="dark"
                data-size="large"
                data-text="false"
                data-share-url={"http://18.144.82.135:85/static/media/thorianite.1d45fb07b6ef0f72bd89.png"
                  .replace("%3A", ":")
                  .replace("%2F", "/")}
              ></div>
            </div>
          </div>
        </div>
      ) : shareComplete ? (
        <shareComplete
          sharePic={sharePic}
          handleYesButtom={props.handleYesButtom}
          setShareComplete={setShareComplete}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default SocialOverlay;
