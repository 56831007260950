import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import LocationHoverImage from '../../images/icons/map-hover.png'
import LocationHoverImageRestricted from '../../images/icons/map-hover-restricted.png'
import LocationPoint from '../../images/icons/map-location.png'
import MoveSelectionModal from './frame/MoveSelectionModal'
import CoreService from '../helper/Coreservice'
const LocationGrid = (props) => {
    const coreservices = {
        coreService: new CoreService()
    };
    const [isOpenMap, setOpenMap] = useState(false);
    const [isRestricted, setisRestricted] = useState(false);
    const [mapData, setMapData] = useState(null);
    const [imageId, setImageId] = useState(0);
    const [imageName, setImageName] = useState(null);
    const navigate = useNavigate();
    const [moveModalShow, setMoveModalShow] = useState();
    const handelMoveModal = (id, name, isrestricted) => {
        if (isrestricted) {
            //alert("This is an restricted area");
        }
        else {
            setisRestricted(isrestricted)
            setImageId(id);
            setImageName(name);
            setMoveModalShow('animate')
        }
        
    }
    useEffect(() => {
        coreservices.coreService.getDataById(1).then(data => {
            if (data != null && data.data.isSuccess && data.data.mapList && data.data.mapList.length > 0) {
                var maplist = [];
                for (var j = 1; j <= 10; j++) {
                    for (var i = 1; i <= 16; i++) {
                        var mm = data.data.mapList.find(x => x.rowNo == j && x.colNo == i)
                        if (mm != null) {
                            maplist.push(mm);
                        }
                    }
                }
                setMapData(maplist);
            }
            //setMapData(data == null ? null : data.data.isSuccess ? data.data.mapList : null)
            setOpenMap(true);
        }
        );
    }, []);
    const handleMouseOver = (mapName, isRestricted, id) => {
    var startPoint = mapData.find((x) => x.mapImageNo == props.mapName);
    var endPoint = mapData.find((x) => x.id == id);
    const x1 = startPoint.colNo;
        const y1 = startPoint.rowNo;
        const x2 = endPoint.colNo;
        const y2 = endPoint.rowNo;

        const angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
        var direction = angle;
        props.handleMapDetail(mapName, isRestricted, direction)
    }

    const handleMouseOut = () => {
        props.handleMapDetail(props.mapName, false, '0')
    }
    return (
        <>
            {isOpenMap ?
                (
                    <div className='location-grid'>
                        {mapData.map((item, index) => (
                            props.reportMode ?
                                (
                            <div className='location-grid-item' key={index}>
                                { item.mapImageNo == props.mapName ? <img src={LocationPoint} alt='Location' /> :""}
                                {/*<div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                                    </div>) :
                                (
                                    <div className='location-grid-item' key={index} onMouseOver={() => handleMouseOver(item.mapImageNo,item.isRestricted, item.id)} onMouseOut={() => handleMouseOut()} onClick={() => handelMoveModal(item.id, item.mapImageNo, item.isRestricted)}>
                                        { item.mapImageNo == props.mapName ? <img src={LocationPoint} alt='Location' /> : ""}
                                        <div className='location-hover-wrap'><img src={item.isRestricted ? LocationHoverImageRestricted:LocationHoverImage} alt='Location' /></div>
                                    </div>)
                            ))}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}

                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div></div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div></div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div></div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}

                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <img src={LocationPoint} alt='Location' />*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                        {/*<div className='location-grid-item' onClick={() => handelMoveModal()}>*/}
                        {/*    <div className='location-hover-wrap'><img src={LocationHoverImage} alt='Location' /></div>*/}
                        {/*</div>*/}
                    </div>) : ""}
            {isOpenMap ?
                (<MoveSelectionModal moveModalShow={moveModalShow} setMoveModalShow={setMoveModalShow} imageId={imageId} imageName={imageName} mapName={props.mapName} isRestricted={isRestricted} />) : ""}
         </>
    )
}

export default LocationGrid