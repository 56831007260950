import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Draggable from "react-draggable";
import DepthFrameTop from "../../images/icons/depth-hover-top.svg";
import DepthFrameBottom from "../../images/icons/depth-hover-bottom.svg";
import DepthHoverLeft from "../../images/icons/depth-hover-left.svg";
import DepthHoverRight from "../../images/icons/depth-hover-right.svg";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import NoDepthIcon from "../../images/icons/no-record-depth-icon.svg";
import Travel from "../../images/icons/travel-orange.svg";
import TravelWhite from "../../images/travel-white.svg";
import TravelRed from "../../images/travel-red.svg";
import TotalEngrgy from "../../images/icons/total-energy.svg";
import TotalEnergyYellow from "../../images/total-energy-yellow.svg";
import TotalEnergyRed from "../../images/total-energy-red.svg";
/*import CoringProcess from '../../images/coring-process.svg'*/
import CoringProcess from "../../images/animation.gif";
import EnergyProcess from "../../images/icons/energy-process.svg";
import FXIcon from "../../images/icons/fx-icon.svg";
import CoreService from "../helper/Coreservice";
import { useDispatch, useSelector } from "react-redux";
import AnimatedButton from "../animatedButton/AnimatedButton";
var totalPath = 0;
const MineCalulator = (props) => {
    const {
        selectedGrid,
        mapData,
        showLoading,
        pathSelect,
        userToBeBalance,
        energyLevel,
        tooltipMining,
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const depthIcons = [Depth1, Depth2, Depth3];
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    const coreservices = {
        coreService: new CoreService(),
    };
    const handleLoading = () => {
        props.deployCores();
    };
    var depth1Charge = 0;
    var depth2Charge = 0;
    var depth3Charge = 0;
    var pathCharge = 0;
    depth1Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.firstDepthCharge
            : 0;
    depth2Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.secondndDepthCharge
            : 0;
    depth3Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.thirdDepthCharge
            : 0;
    pathCharge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.nodeCost
            : 0;
    const coreCoordinatesList = mapData == null ? null : mapData.mapGroupModel;

    if (pathSelect != totalPath) {
        totalPath = pathSelect;
    }
    var totalEnergy = 0;
    if (selectedGrid && selectedGrid.length > 0) {
        selectedGrid.map((item, index) => {
            if (
                coreCoordinatesList.find((x) => x.nodeName == item.nodeName) == null
            ) {
                totalEnergy =
                    totalEnergy +
                    (item.totalNode - item.restrictedGrid) *
                    (item.depth === 1
                        ? depth1Charge
                        : item.depth === 2
                            ? depth2Charge
                            : depth3Charge);
            }
        });
    }
    if (coreCoordinatesList && coreCoordinatesList.length > 0) {
        coreCoordinatesList.map((item, index) => {
            totalEnergy =
                totalEnergy +
                (item.totalNode - item.restrictedGrid) *
                (item.depth === 1
                    ? depth1Charge
                    : item.depth === 2
                        ? depth2Charge
                        : depth3Charge);
        });
    }
    totalEnergy = totalEnergy + totalPath * pathCharge;
    useEffect(() => {
        //Implementing the setInterval method
        //const interval = setInterval(() => {
        //    setCount(count + 1);
        //}, 1000);
    }, []);
    var k = 0;
    return (
        <Draggable
            defaultPosition={{ x: 0, y: 0 }}
            disabled={winWidth > 1023 ? false : true}
            position={null}
            bounds="parent"
        >
            <div
                className={`mine-map-wrap ${props.showMineCalculator ? "" : "hide"}`}
            >
                {tooltipMining == 2 && (
                    <div className="mining-calculator-border fade-in"></div>
                )}
                {showLoading ? (
                    <div className="mine-map">
                        <div className="mm-title-wrap">
                            {winWidth >= 1024 ? (
                                <div className="fx-circle-wrap">
                                    <div className="fx-circle">
                                        <img src={FXIcon} alt="FX" />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="fx-circle-wrap"
                                    onClick={() => props.handelMineCalculator()}
                                >
                                    <div className="fx-circle">
                                        <img src={FXIcon} alt="FX" />
                                    </div>
                                </div>
                            )}
                            <div className="mm-title">
                                Mining <br />
                                energy calculator
                            </div>
                        </div>
                        <div className="mm-depth-wrap">
                            <div className="mm-depth-row mm-header">
                                <div className="mm-mine">Mine</div>
                                <div className="mm-depth">Depth</div>
                                <div className="mm-size">Size</div>
                                <div className="mm-consumption">Consumption</div>
                            </div>
                            {selectedGrid &&
                                selectedGrid.length > 0 &&
                                selectedGrid.map((item, index) =>
                                    coreCoordinatesList == null ||
                                        coreCoordinatesList.find(
                                            (x) => x.nodeName == item.nodeName
                                        ) == null ? (
                                        <div className="mm-depth-row">
                                            <div className="mm-mine">
                                                {k + 1 < 10 ? "0" : ""}
                                                {(k = k + 1)}
                                            </div>
                                            <div className="mm-depth">
                                                <div className="depth-icon-wrap depth-1">
                                                    <div className="depth-unit">
                                                        <div className="depth-frame">
                                                            <ReactSVG
                                                                src={DepthFrameTop}
                                                                className="depth-frame-icon"
                                                            />
                                                        </div>
                                                        <div className="depth-unit-wrap">
                                                            <div className="depth-hover-corner left">
                                                                <img src={DepthHoverLeft} alt="Depth" />
                                                            </div>
                                                            <div className="depth-unit-text">192m</div>
                                                            <div className="depth-hover-corner right">
                                                                <img src={DepthHoverRight} alt="Depth" />
                                                            </div>
                                                        </div>
                                                        <div className="depth-frame">
                                                            <ReactSVG
                                                                src={DepthFrameBottom}
                                                                className="depth-frame-icon"
                                                            />
                                                        </div>
                                                    </div>
                                                    <img src={depthIcons[item.depth - 1]} alt="Depth" />
                                                </div>
                                            </div>
                                            <div className="mm-size">
                                                {item.totalNode - item.restrictedGrid}mi
                                            </div>
                                            <div className="mm-consumption">
                                                {(item.totalNode - item.restrictedGrid) *
                                                    (item.depth === 1
                                                        ? depth1Charge
                                                        : item.depth === 2
                                                            ? depth2Charge
                                                            : depth3Charge)}
                                                kW
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                )}
                            {coreCoordinatesList &&
                                coreCoordinatesList.length > 0 &&
                                coreCoordinatesList.map((item, index) => (
                                    <div className="mm-depth-row">
                                        <div className="mm-mine">
                                            {k + 1 < 10 ? "0" : ""}
                                            {(k = k + 1)}
                                        </div>
                                        <div className="mm-depth">
                                            <div className="depth-icon-wrap depth-1">
                                                <div className="depth-unit">
                                                    <div className="depth-frame">
                                                        <ReactSVG
                                                            src={DepthFrameTop}
                                                            className="depth-frame-icon"
                                                        />
                                                    </div>
                                                    <div className="depth-unit-wrap">
                                                        <div className="depth-hover-corner left">
                                                            <img src={DepthHoverLeft} alt="Depth" />
                                                        </div>
                                                        <div className="depth-unit-text">192m</div>
                                                        <div className="depth-hover-corner right">
                                                            <img src={DepthHoverRight} alt="Depth" />
                                                        </div>
                                                    </div>
                                                    <div className="depth-frame">
                                                        <ReactSVG
                                                            src={DepthFrameBottom}
                                                            className="depth-frame-icon"
                                                        />
                                                    </div>
                                                </div>
                                                <img src={depthIcons[item.depth - 1]} alt="Depth" />
                                            </div>
                                        </div>
                                        <div className="mm-size">
                                            {item.totalNode - item.restrictedGrid}mi
                                        </div>
                                        <div className="mm-consumption">
                                            {(item.totalNode - item.restrictedGrid) *
                                                (item.depth === 1
                                                    ? depth1Charge
                                                    : item.depth === 2
                                                        ? depth2Charge
                                                        : depth3Charge)}
                                            kW
                                        </div>
                                    </div>
                                ))}

                            {/*<div className='mm-depth-row'>*/}
                            {/*    <div className="mm-mine">01</div>*/}
                            {/*    <div className="mm-depth">*/}
                            {/*        <div className='depth-icon-wrap depth-1'>*/}
                            {/*            <div className='depth-unit'>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-unit-wrap'>*/}
                            {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                            {/*                    <div className='depth-unit-text'>192m</div>*/}
                            {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <img src={Depth1} alt='Depth' />*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*    <div className="mm-size">{depth1Selector}mi</div>*/}
                            {/*    <div className="mm-consumption">{depth1Selector * depth1Charge}kW</div>*/}
                            {/*</div>*/}
                            {/*<div className='mm-depth-row'>*/}
                            {/*    <div className="mm-mine">02</div>*/}
                            {/*    <div className="mm-depth">*/}
                            {/*        <div className='depth-icon-wrap depth-2'>*/}
                            {/*            <div className='depth-unit'>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-unit-wrap'>*/}
                            {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                            {/*                    <div className='depth-unit-text'>192m</div>*/}
                            {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <img src={Depth2} alt='Depth' />*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*    <div className="mm-size">{depth2Selector}mi</div>*/}
                            {/*    <div className="mm-consumption">{depth2Selector * depth2Charge}kW</div>*/}
                            {/*</div>*/}
                            {/*<div className='mm-depth-row'>*/}
                            {/*    <div className="mm-mine">03</div>*/}
                            {/*    <div className="mm-depth">*/}
                            {/*        <div className='depth-icon-wrap depth-3'>*/}
                            {/*            <div className='depth-unit'>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-unit-wrap'>*/}
                            {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                            {/*                    <div className='depth-unit-text'>192m</div>*/}
                            {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <img src={Depth3} alt='Depth' />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="mm-size">{depth3Selector}mi</div>*/}
                            {/*    <div className="mm-consumption">{depth3Selector * depth3Charge}kW</div>*/}
                            {/*</div>*/}
                            {/*<div className='mm-depth-row'>*/}
                            {/*    <div className="mm-mine">04</div>*/}
                            {/*    <div className="mm-depth">*/}
                            {/*        <div className='depth-icon-wrap depth-1'>*/}
                            {/*            <div className='depth-unit'>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-unit-wrap'>*/}
                            {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                            {/*                    <div className='depth-unit-text'>192m</div>*/}
                            {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                            {/*                </div>*/}
                            {/*                <div className='depth-frame'>*/}
                            {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <img src={Depth1} alt='Depth' />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="mm-size">{depth3Selector}mi</div>*/}
                            {/*    <div className="mm-consumption">{depth3Charge * depth3Selector}kW</div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="mm-divider"></div>
                        <div className="mm-travel-wrap">
                            <div className="mm-travel-icon">
                                <img
                                    src={
                                        energyLevel === "red"
                                            ? TravelRed
                                            : energyLevel === "yellow"
                                                ? Travel
                                                : TravelWhite
                                    }
                                    alt="Travel"
                                />
                            </div>
                            <div className="mm-travel-title">Travel</div>
                            <div className="mm-travel-energy">{totalPath * pathCharge}kw</div>
                        </div>
                        <div className="mm-divider"></div>
                        <div className="mm-total-wrap">
                            <div className="mm-total-icon">
                                <img
                                    src={
                                        energyLevel === "red"
                                            ? TotalEnergyRed
                                            : energyLevel === "yellow"
                                                ? TotalEnergyYellow
                                                : TotalEngrgy
                                    }
                                    alt="Travel"
                                />
                            </div>
                            {/*<img src={TotalEngrgy} alt='Travel' /></div>*/}
                            <div className={"mm-total-title " + energyLevel}>Total</div>
                            <div className={"mm-total-energy " + energyLevel}>
                                {totalEnergy}kw
                            </div>
                        </div>
                        {energyLevel === "yellow" ? (
                            <div>
                                <div className="cm-divider"></div>
                                <div className={"cm-extra-title-yellow"}>
                                    Warning! Ilyrium low
                                </div>
                            </div>
                        ) : (
                            energyLevel === "red" && (
                                <>
                                    <div className="cm-divider"></div>
                                    <div className={"cm-extra-title-red"}>
                                        error! not enough Ilyrium
                                    </div>
                                </>
                            )
                        )}
                        <div className="mm-footer">
                            {selectedGrid != null && selectedGrid.length > 0 && (
                                <div className="mm-footer">
                                    {
                                        <AnimatedButton
                                            buttonText={energyLevel == "red" ? "Cannot mine" : "Deploy mines"}
                                            color={energyLevel}
                                            type="inward"
                                            arrows={1}
                                            disabled={userToBeBalance < 0}
                                            handleAnimationButton={() => handleLoading()}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="mine-map-process">
                        <div className="mm-divider"></div>
                        <div className="mm-process-anim">
                            <img src={CoringProcess} alt="Coring Process" />
                        </div>
                        <div className="mm-divider"></div>
                        <div className="mm-process-wrap">
                            <div className="mm-process-icon">
                                <img src={EnergyProcess} alt="Energy Process" />
                            </div>
                            <div className="mm-process-title">Mining IN PROGRESS</div>
                        </div>
                        <div className="mm-divider"></div>
                    </div>
                )}
            </div>
        </Draggable>
    );
};

export default MineCalulator;
