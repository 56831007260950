import React from 'react'
import { ReactSVG } from 'react-svg'
import CoringSampleImg from '../../images/coring-sample.png'
import Depth1 from '../../images/icons/depth-1.svg'
import Depth2 from '../../images/icons/depth-2.svg'
import Depth3 from '../../images/icons/depth-3.svg'
import DepthFrameTop from '../../images/icons/depth-hover-top.svg'
import DepthFrameBottom from '../../images/icons/depth-hover-bottom.svg'
import DepthHoverLeft from '../../images/icons/depth-hover-left.svg'
import DepthHoverRight from '../../images/icons/depth-hover-right.svg'

import './coring.css'
const CoreResult = (props) => {
    const depthIcons = [Depth1, Depth2, Depth3];
    const handleMouseOver = (id) => {
        var dom = document.getElementById('core_' + id);
        dom.classList.add('coreHover');
    }

    const handleMouseOut = (id) => {
        var dom = document.getElementById('core_' + id);
        dom.classList.remove('coreHover');
    }
  return (
      <><div className='c-wrapper'>
          <button className='status find'>{props.coreCoordinatesList.filter(x => x.result==1).length} SUCCESSFUL CORES</button>
    <div id="report-list" className='pl-main-container results'>
        <div className='pl-wrapper'>
            <div className='pl-row pl-header'>
                <div className='pl-number'>NO#.</div>
                <div className='pl-status'>STATUS</div>
                <div className='pl-sample'>SAMPLE</div>
                <div className='pl-depth'>Depth</div>
                <div className='pl-result'>RESULT</div>
            </div>
        </div>
              <div className='pl-wrapper pl-content-wrap'>
                  {props.coreCoordinatesList && props.coreCoordinatesList.length > 0 && props.coreCoordinatesList.map((item, index) => (
                      <div className={`pl-row ${(item.result === 1 && item.digStatus == 1) ? 'find' : (item.digStatus == 1 && item.result == 0) ? 'no-find' : 'inprogress'}`} onMouseOver={() => handleMouseOver(item.id)} onMouseOut={() => handleMouseOut(item.id)}>
                          <div className='pl-number'>{item.coreNo}</div>
                          <div className='pl-status'>{item.digStatus == 1 ? "COMPLETE" :"IN PROGRESS" }</div>
                          <div className='pl-sample'><div className={`sample ${(item.digStatus == 1) ? '' :'inProgress'}`} style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>
                          <div className='pl-depth'><div className={`depth-icon-wrap ${1 === item.depthTo ? 'depth-1' : 2 === item.depthTo ? 'depth-2' : 'depth-3'}`}>
                              <div className='depth-unit'>
                                  <div className='depth-frame'>
                                      <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />
                                  </div>
                                  <div className='depth-unit-wrap'>
                                      <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>
                                      <div className='depth-unit-text'>{item.depthTo == 1 ? "16m" : item.depthTo == 2 ? "64m" : "192m"}</div>
                                      <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>
                                  </div>
                                  <div className='depth-frame'>
                                      <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />
                                  </div>
                              </div>
                              <img src={depthIcons[item.depthTo - 1]} alt='Depth' />
                          </div>
                          </div>
                          <div className='pl-result'><span className={`result ${(item.result === 1 && item.digStatus == 1) ? 'find' : (item.digStatus == 1 && item.result == 0) ? 'none' : 'inProgress'}`}>{(1 === item.result && item.digStatus == 1) ? 'Find' : (item.digStatus == 1 && item.result == 0) ? 'No-Find' :'•••'}</span></div>
                      </div>
                  ))}


            {/*<div className='pl-row inprogress'>*/}
            {/*    <div className='pl-number'>I-05</div>*/}
            {/*    <div className='pl-status'>IN PROGRESS</div>*/}
            {/*    <div className='pl-sample'><div className='sample inProgress' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result inProgress'>•••</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row no-find'>*/}
            {/*    <div className='pl-number'>N-09</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth2} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result none'>NO-FIND</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row find'>*/}
            {/*    <div className='pl-number'>U-04</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row find'>*/}
            {/*    <div className='pl-number'>U-04</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row find'>*/}
            {/*    <div className='pl-number'>U-04</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth2} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row find'>*/}
            {/*    <div className='pl-number'>U-04</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
            {/*</div>*/}
            {/*<div className='pl-row find'>*/}
            {/*    <div className='pl-number'>U-04</div>*/}
            {/*    <div className='pl-status'>COMPLETE</div>*/}
            {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
            {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
            {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
            {/*</div>*/}
        </div>

    </div>

</div></>
  )
}

export default CoreResult