import React from 'react'

const BottomRuller = (props) => {
    return (
        <div className='bottom ruler '>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}></div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>1</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>2</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>3</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>4</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>5</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>6</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>7</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>8</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>9</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>10</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>11</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>12</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>13</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>14</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>15</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}>16</div>
            <div className={`ruler-item ${props.activeIndex === 1 ? 'ruler-item1' : props.activeIndex === 2 ? 'ruler-item2' : ''}`}></div>
        </div>
    )
}

export default BottomRuller