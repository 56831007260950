import React, { useState } from "react";
import Draggable from "react-draggable";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import Travel from "../../images/icons/travel-orange.svg";
import TotalEngrgy from "../../images/icons/total-energy.svg";
import CoringProcess from "../../images/animation.gif";
import EnergyProcess from "../../images/icons/energy-process.svg";
import FXIcon from "../../images/icons/fx-icon.svg";

const CoreMap = (props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  const handleLoading = () => {
    setShowLoading(false);
  };

  return (
    <div
      className={`core-map-wrap-2 ${props.showCoreCalculator ? "hide" : ""}`}
    >
      {showLoading ? (
        <div className="core-map">
          <div className="cm-title-wrap">
            {winWidth >= 1024 ? (
              <div className="fx-circle-wrap">
                <div className="fx-circle">
                  <img src={FXIcon} alt="FX" />
                </div>
              </div>
            ) : (
              <div
                className="fx-circle-wrap"
                onClick={() => props.handelCoreCalculator()}
              >
                <div className="fx-circle">
                  <img src={FXIcon} alt="FX" />
                </div>
              </div>
            )}
            <div className="cm-title">Core journey energy calculator</div>
          </div>

          <div className="cm-depth-wrap">
            <div className="cm-depth-row">
              <div className="cm-depth-icon">
                <img src={Depth1} alt="Depth 1" />
              </div>
              <div className="cm-depth-value">2</div>
              <div className="cm-depth-divider"></div>
              <div className="cm-energy-ultlize">200kw</div>
            </div>
            <div className="cm-depth-row">
              <div className="cm-depth-icon">
                <img src={Depth2} alt="Depth 2" />
              </div>
              <div className="cm-depth-value">7</div>
              <div className="cm-depth-divider"></div>
              <div className="cm-energy-ultlize">1,050kw</div>
            </div>
            <div className="cm-depth-row">
              <div className="cm-depth-icon">
                <img src={Depth3} alt="Depth 3" />
              </div>
              <div className="cm-depth-value">2</div>
              <div className="cm-depth-divider"></div>
              <div className="cm-energy-ultlize">200kw</div>
            </div>
          </div>
          <div className="cm-divider"></div>
          <div className="cm-travel-wrap">
            <div className="cm-travel-icon">
              <img src={Travel} alt="Travel" />
            </div>
            <div className="cm-travel-title">Travel</div>
            <div className="cm-travel-energy">770kw</div>
          </div>
          <div className="cm-divider"></div>
          <div className="cm-total-wrap">
            <div className="cm-total-icon">
              <img src={TotalEngrgy} alt="Travel" />
            </div>
            <div className="cm-total-title">Total</div>
            <div className="cm-total-energy">2,620kw</div>
          </div>

          <div className="cm-footer">
            {/* <button type='button' onClick={() => props.handelCCModal()} className='btn btn-fluid'>deploy cores</button> */}
            <button
              type="button"
              onClick={() => handleLoading()}
              className="btn btn-fluid"
            >
              deploy cores
            </button>
          </div>
        </div>
      ) : (
        <div className="core-map-process">
          <div className="cm-divider"></div>
          <div className="coring-process-anim">
            <img src={CoringProcess} alt="Coring Process" />
          </div>
          <div className="cm-divider"></div>
          <div className="cm-process-wrap">
            <div className="cm-process-icon">
              <img src={EnergyProcess} alt="Energy Process" />
            </div>
            <div className="cm-process-title">CORING IN PROGRESS</div>
          </div>
          <div className="cm-divider"></div>
        </div>
      )}
    </div>
  );
};

export default CoreMap;
