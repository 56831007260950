import React from 'react'

const RightRuller = () => {
    return (
        <div className='right ruler'>
            <div className="ruler-item"></div>
            <div className="ruler-item">A</div>
            <div className="ruler-item">B</div>
            <div className="ruler-item">C</div>
            <div className="ruler-item">D</div>
            <div className="ruler-item">E</div>
            <div className="ruler-item">F</div>
            <div className="ruler-item">G</div>
            <div className="ruler-item">H</div>
            <div className="ruler-item">I</div>
            <div className="ruler-item">J</div>
            <div className="ruler-item"></div>
        </div>

    )
}

export default RightRuller