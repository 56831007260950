import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Location from "../location/Location";
import CoringSampleImg from "../../images/coring-sample.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
/*import Wave from '../../images/wave.svg'*/
import Wave from "../../images/icons/WaveAnimation.png";
import "./coring.css";
import CoreReport from "../coring/CoreReport";
import Monitor from "../monitor/MonitorNew";
import DepthFrameTop from "../../images/icons/depth-hover-top.svg";
import DepthFrameBottom from "../../images/icons/depth-hover-bottom.svg";
import DepthHoverLeft from "../../images/icons/depth-hover-left.svg";
import DepthHoverRight from "../../images/icons/depth-hover-right.svg";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import { useDispatch, useSelector } from "react-redux";
import {
    setCoreReportModal,
    setSelectedGrid,
    setIsReady,
    setShowManual
} from "../../appSlice";
import CoreIcon from "../../images/icons/CoreIcon";
import AnimatedButton from "../animatedButton/AnimatedButton";
var isOpenOppup = false;
const Coring = (props) => {
    const {
        isCoreReportModal,
        selectedGrid,
        mapData,
        isReady,
        isShowButton,
        userToBeBalance,
        energyLevel,
        tooltipCoring,
        isCoringComplete
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const depthIcons = [Depth1, Depth2, Depth3];
    const services = {
        authService: new AuthService(),
    };
    const coreservices = {
        coreService: new CoreService(),
    };
    const [count, setCount] = useState(0);
    var coreCoordinatesList =
        mapData == null
            ? null
            : mapData.mapDetailList
                .filter((x) => x.result < 3)
                .sort((b, a) => a.digId - b.digId);
    if (localStorage.getItem("isCoreStart") === "true") {
        coreCoordinatesList = coreCoordinatesList.sort(
            (a, b) => Number(a.digId) - Number(b.digId)
        );
        console.log(
            new Date() +
            " end timer=" +
            " data2=" +
            JSON.stringify(coreCoordinatesList)
        );
    } else {
        if (isCoreReportModal) {
            const sortedData = coreCoordinatesList.sort((a, b) => {
                if (a.result === 1 && b.result === 0) {
                    return -1; // `a` should come before `b`
                } else if (a.result === 0 && b.result === 1) {
                    return 1; // `b` should come before `a`
                } else {
                    return 0; // No need to change the order
                }
            });
            coreCoordinatesList = sortedData;

            //coreCoordinatesList = coreCoordinatesList.sort((b, a) => Number(a.result) - Number(b.result));
        }
    }
    if (coreCoordinatesList.length > 0) {
        isOpenOppup = true;
    }
    const mapName =
        mapData != null && mapData.imageData != null
            ? mapData.imageData.mapImageNo
            : "";
    const handleCoreDeploy = () => {
        //setShowButton(false)
        props.deployCores();
    };
    const handleRemoveSelectedGrid = (id) => {
        var sList = selectedGrid;
        sList = sList.filter((x) => x.id != id);
        dispatch(setSelectedGrid(sList));
        coreservices.coreService.saveDataEntireGrid(sList);
        if (sList.length == 0) {
            dispatch(setIsReady(false));
        }
    };
    const handleCoreReportModel = (obj) => {
        dispatch(setCoreReportModal(obj));
    };
    useEffect(() => {
        //   //Implementing the setInterval method
        //   const interval = setInterval(() => {
        setCount(count + 1);
        //   }, 1000);
    }, [selectedGrid]);

    const handleMouseOver = (id) => {
        var dom = document.getElementById("core_" + id);
        dom?.classList.add("coreHover");
    };

    const handleMouseOut = (id) => {
        var dom = document.getElementById("core_" + id);
        dom?.classList.remove("coreHover");
    };
    return (
        <>
            <div className="left-bar-content">
                <Monitor isMiningOpen={false} pageOpen={props.pageOpen} />
                <div id="left-bar-inner" className="col-left-inner">
                    <Location mapName={mapName} />
                    {isReady || isOpenOppup ? (
                        <div className="c-wrapper">
                            {tooltipCoring == 4 && (
                                <div className="ready-wrapper-border fade-in"></div>
                            )}
                            {!isReady ? (
                                <div className={`status ${!isCoringComplete ? "ready" : "warning"} `}>
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                    {!isCoringComplete ? "Ready To Core" : "Coring complete"}
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                    <CoreIcon color={!isCoringComplete ? "ready" : ""} />
                                </div>
                            ) : (
                                ""
                            )}
                            {isReady && isShowButton ? (
                                <div
                                    className={`ready ${energyLevel == "red" ? energyLevel : ""
                                        } status`}
                                >
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />

                                    {userToBeBalance < 0
                                        ? "Ilyrium Depleted"
                                        : "Cores Initialised"}
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <CoreIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            {isReady && !isShowButton ? (
                                <div className="progress status">
                                    <CoreIcon color={"progress"} />
                                    <CoreIcon color={"progress"} />
                                    <CoreIcon color={"progress"} />
                                    Coring In Progress
                                    <CoreIcon color={"progress"} />
                                    <CoreIcon color={"progress"} />
                                    <CoreIcon color={"progress"} />
                                </div>
                            ) : (
                                ""
                            )}
                            <div id="report-list" className="pl-main-container">
                                <div className="pl-wrapper">
                                    <div className="pl-row pl-header">
                                        <div className="pl-number">NO#.</div>
                                        <div className="pl-status">STATUS</div>
                                        <div className="pl-sample">SAMPLE</div>
                                        <div className="pl-depth">Depth</div>
                                        <div className="pl-result">RESULT</div>
                                    </div>
                                </div>

                                <div className="pl-wrapper pl-content-wrap">
                                    {selectedGrid &&
                                        selectedGrid.length > 0 &&
                                        selectedGrid.map((item, index) =>
                                            coreCoordinatesList == null ||
                                                coreCoordinatesList.find((x) => x.id == item.id) ==
                                                null ? (
                                                <div
                                                    className="pl-row ready"
                                                    onMouseOver={() => handleMouseOver(item.id)}
                                                    onMouseOut={() => handleMouseOut(item.id)}
                                                >
                                                    <div
                                                        className={`pl-number ${energyLevel == "red" ? energyLevel : ""
                                                            }`}
                                                    >
                                                        {item.coreNo}
                                                    </div>
                                                    <div
                                                        className={`pl-status ${energyLevel == "red" ? energyLevel : ""
                                                            }`}
                                                    >
                                                        {userToBeBalance < 0 ? "Error!" : "IN PROGRESS"}
                                                    </div>
                                                    <div className="pl-sample">
                                                        <div
                                                            className="sample"
                                                            style={{
                                                                backgroundImage: `url(${CoringSampleImg})`,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div className="pl-depth">
                                                        <div
                                                            className={`depth-icon-wrap ${1 === item.depthTo
                                                                ? "depth-1"
                                                                : 2 === item.depthTo
                                                                    ? "depth-2"
                                                                    : "depth-3"
                                                                }`}
                                                        >
                                                            <div className="depth-unit">
                                                                <div className="depth-frame">
                                                                    <ReactSVG
                                                                        src={DepthFrameTop}
                                                                        className="depth-frame-icon"
                                                                    />
                                                                </div>
                                                                <div className="depth-unit-wrap">
                                                                    <div className="depth-hover-corner left">
                                                                        <img src={DepthHoverLeft} alt="Depth" />
                                                                    </div>
                                                                    <div className="depth-unit-text">
                                                                        {item.depthTo == 1
                                                                            ? "16m"
                                                                            : item.depthTo == 2
                                                                                ? "64m"
                                                                                : "192m"}
                                                                    </div>
                                                                    <div className="depth-hover-corner right">
                                                                        <img src={DepthHoverRight} alt="Depth" />
                                                                    </div>
                                                                </div>
                                                                <div className="depth-frame">
                                                                    <ReactSVG
                                                                        src={DepthFrameBottom}
                                                                        className="depth-frame-icon"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={depthIcons[item.depthTo - 1]}
                                                                alt="Depth"
                                                            />
                                                        </div>
                                                    </div>
                                                    {item.depth1 < 0 ? (
                                                        <div className="pl-result">
                                                            <span className="result inProgress">
                                                                <img src={Wave} alt="wave" />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="pl-result">
                                                            <span
                                                                className={`result ${energyLevel == "red" ? energyLevel : ""
                                                                    } ready`}
                                                            >
                                                                {userToBeBalance < 0 ? "Error!" : "Ready"}
                                                            </span>
                                                        </div>
                                                    )}

                                                    <div
                                                        className="remove-plan"
                                                        onClick={() => handleRemoveSelectedGrid(item.id)}
                                                    >
                                                        <span>Remove from plan</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        )}
                                    {coreCoordinatesList &&
                                        coreCoordinatesList.length > 0 &&
                                        coreCoordinatesList.map((item, index) => (
                                            <div
                                                className={`pl-row ${1 === item.result ? "find" : "no-find"
                                                    }`}
                                                onMouseOver={() => handleMouseOver(item.id)}
                                                onMouseOut={() => handleMouseOut(item.id)}
                                            >
                                                <div className="pl-number">{item.coreNo}</div>
                                                {item.result < 0 ? (
                                                    <div className="pl-status">IN PROGRESS</div>
                                                ) : (
                                                    <div className="pl-status">COMPLETE</div>
                                                )}
                                                <div className="pl-sample">
                                                    <div
                                                        className="sample"
                                                        style={{
                                                            backgroundImage: `url(${CoringSampleImg})`,
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="pl-depth">
                                                    <div
                                                        className={`depth-icon-wrap ${1 === item.depthTo
                                                            ? "depth-1"
                                                            : 2 === item.depthTo
                                                                ? "depth-2"
                                                                : "depth-3"
                                                            }`}
                                                    >
                                                        <div className="depth-unit">
                                                            <div className="depth-frame">
                                                                <ReactSVG
                                                                    src={DepthFrameTop}
                                                                    className="depth-frame-icon"
                                                                />
                                                            </div>
                                                            <div className="depth-unit-wrap">
                                                                <div className="depth-hover-corner left">
                                                                    <img src={DepthHoverLeft} alt="Depth" />
                                                                </div>
                                                                <div className="depth-unit-text">
                                                                    {item.depthTo == 1
                                                                        ? "16m"
                                                                        : item.depthTo == 2
                                                                            ? "64m"
                                                                            : "192m"}
                                                                </div>
                                                                <div className="depth-hover-corner right">
                                                                    <img src={DepthHoverRight} alt="Depth" />
                                                                </div>
                                                            </div>
                                                            <div className="depth-frame">
                                                                <ReactSVG
                                                                    src={DepthFrameBottom}
                                                                    className="depth-frame-icon"
                                                                />
                                                            </div>
                                                        </div>
                                                        <img
                                                            src={depthIcons[item.depthTo - 1]}
                                                            alt="Depth"
                                                        />
                                                    </div>
                                                </div>
                                                {item.depth1 < 0 ? (
                                                    <div className="pl-result">
                                                        <span className="result inProgress">
                                                            <img src={Wave} alt="wave" />
                                                        </span>
                                                    </div>
                                                ) : item.result < 0 ? (
                                                    <div className="pl-result">
                                                        <span className="result ready">Ready</span>
                                                    </div>
                                                ) : (
                                                    <div className="pl-result">
                                                        <span
                                                            className={`result ${1 === item.result ? "find" : "none"
                                                                }`}
                                                        >
                                                            {1 === item.result ? "Find" : "No Find"}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                    {/*<div className='pl-row inprogress'>*/}
                                    {/*    <div className='pl-number'>I-05</div>*/}
                                    {/*    <div className='pl-status'>IN PROGRESS</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample inProgress' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth depth'>*/}
                                    {/*        <div className='depth-icon-wrap depth-1'>*/}
                                    {/*            <div className='depth-unit'>*/}
                                    {/*                <div className='depth-frame'>*/}
                                    {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                                    {/*                </div>*/}
                                    {/*                <div className='depth-unit-wrap'>*/}
                                    {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                                    {/*                    <div className='depth-unit-text'>192m</div>*/}
                                    {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className='depth-frame'>*/}
                                    {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <img src={Depth1} alt='Depth' />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='pl-result'><span className='result inProgress'><img src={Wave} alt='wave' /></span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>N-09</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'>*/}
                                    {/*        <div className='depth-icon-wrap depth-2'>*/}
                                    {/*            <div className='depth-unit'>*/}
                                    {/*                <div className='depth-frame'>*/}
                                    {/*                    <ReactSVG src={DepthFrameTop} className='depth-frame-icon' />*/}
                                    {/*                </div>*/}
                                    {/*                <div className='depth-unit-wrap'>*/}
                                    {/*                    <div className='depth-hover-corner left'><img src={DepthHoverLeft} alt='Depth' /></div>*/}
                                    {/*                    <div className='depth-unit-text'>192m</div>*/}
                                    {/*                    <div className='depth-hover-corner right'><img src={DepthHoverRight} alt='Depth' /></div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className='depth-frame'>*/}
                                    {/*                    <ReactSVG src={DepthFrameBottom} className='depth-frame-icon' />*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <img src={Depth2} alt='Depth' />*/}
                                    {/*        </div>*/}

                                    {/*    </div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*    <div className='remove-plan'><span>Remove from plan</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>U-04</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*    <div className='remove-plan'><span>Remove from plan</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>U-04</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*    <div className='remove-plan'><span>Remove from plan</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>U-04</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth2} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*    <div className='remove-plan'><span>Remove from plan</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row find'>*/}
                                    {/*    <div className='pl-number'>U-04</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row no-find'>*/}
                                    {/*    <div className='pl-number'>U-04</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-sample'><div className='sample' style={{ backgroundImage: `url(${CoringSampleImg})` }}></div></div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result none'>No Find</span></div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {!isReady && isOpenOppup ? (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => dispatch(setCoreReportModal(true))}
                                >
                                    Read Report
                                </button>
                            ) : (
                                ""
                            )}
                            {!isReady && isOpenOppup ? (
                                <button
                                    className="btn"
                                    onClick={() =>
                                        services.authService.handleClickOnButton("basemine")
                                    }
                                >
                                    RETURN TO MAP
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="ready-wrapper">
                            {tooltipCoring == 4 && (
                                <div className="ready-wrapper-border fade-in"></div>
                            )}
                            <div className="status ready">
                                <CoreIcon color={"ready"} />
                                <CoreIcon color={"ready"} />
                                <CoreIcon color={"ready"} />
                                READY TO CORE
                                <CoreIcon color={"ready"} />
                                <CoreIcon color={"ready"} />
                                <CoreIcon color={"ready"} />
                            </div>
                            <div className="explore-text">
                                ilyirium has been detected, drop cores at different depths to
                                chart the territory prior to mining.
                            </div>
                            <button
                                className="btn"
                                    onClick={() => {
                                        dispatch(setShowManual(2));
                                        /*services.authService.handleClickOnButton("training-coring")*/
                                    }
                                }
                            >
                                OPEN TRAINING MANUAL
                            </button>
                            <button
                                className="btn"
                                onClick={() =>
                                    services.authService.handleClickOnButton("basemine")
                                }
                            >
                                RETURN TO MAP
                            </button>
                        </div>
                    )}
                </div>
                {isReady ? (
                    <div id="report-btn">
                        {isShowButton ? (
                            <AnimatedButton
                                buttonText={energyLevel == "red" ? "Cannot Core" : "Deploy Cores"}
                                color={energyLevel}
                                type="inward"
                                disabled={userToBeBalance < 0}
                                handleAnimationButton={() => handleCoreDeploy()}
                            />
                        ) : (
                            ""
                        )}
                        <button
                            className="btn"
                            onClick={() =>
                                services.authService.handleClickOnButton("basemine")
                            }
                        >
                            Return to Map
                        </button>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {isCoreReportModal ? (
                <CoreReport
                    setLeftOpen={props.setLeftOpen}
                    id={props.id}
                    handleCoreReportModel={handleCoreReportModel}
                />
            ) : null}
        </>
    );
};

export default Coring;
