import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import CoreMap from "../../../src/components/coring/CoreMapLegend";
import CoreResult from "../../../src/components/coring/CoreResultWithoutButton";
import coringMap from "../../images/coring-map.png";
import CoringMapLow from "../../images/MapCoreLow.png";
import Trainingcontrols from "../../images/training_controls.png";
import AuthService, { AdminUserModel } from "../helper/Authservice";

import "./training.css";
import Loader from "../../Loader";

const TrainingCoring = () => {
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showLoader, setShowLoader] = useState(false);
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  console.log(user);

  useEffect(() => {
    setShowLoader(true);
    const img = new Image();
    img.src = coringMap;
    img.onload = () => {
      setShowLoader(false);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    console.log(viewportWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls"></div>
        <div class="training_container">
          <div class="trnng_accrdn">
            <div className="trng_accrdn_slf">
              <div
                className={`trng_acrdn_tpttl status ${
                  user.isTrainingComplete ? "complete" : "progress"
                }`}
              >
                {user.isTrainingComplete
                  ? "Training complete"
                  : "training in progress"}
              </div>
              <ul>
                <li>
                  <div className="trnng_prcs_sngl status complete ">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. Energy</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Energy Use: It’s important to use your Ilyrium supply
                          wisely. Each move requires different amounts of
                          energy. Keep an eye on your energy meter to give you
                          an indication on how you’re tracking and how to play
                          your next move.
                        </li>
                        <li>
                          Time Restrictions: Your Ilyrium is only charged for
                          24h once activated. It is activated following your
                          first action – by either dropping a Core or Opening a
                          mine.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="trnng_prcs_sngl status complete first-render animated-fadeIn">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">2. Exploration</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Once inside a section, Ilyrium can be discovered at
                          multiple depths (16m, 64m, 192m). Hover or click on
                          any one grid to either Core or Mine that coordinate.{" "}
                        </li>
                        <li>
                          Coring allows you to gather samples at different
                          depths and is faster than mining. Use cores to explore
                          the section faster and gather information about the
                          location of Ilyrium.{" "}
                        </li>
                        <li>
                          Mining is a more laborious process as the Mech is
                          required to dig through an entire region at a specific
                          depth, rather than simply drop test tubes. In the
                          spirit of effeciency, we recommend you explore a
                          section by dropping a wide variety of cores first.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="active">
                  <div
                    className={`trnng_prcs_sngl status first-render animated-fadeIn ${
                      user.isTrainingComplete
                        ? "complete"
                        : "progress inprogress"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">3. Coring</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "complete" : "in progress"}
                      </span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          With Ilyrium hidden at 3 different depths, we believe
                          it’s best to core multiple different depths inside any
                          given section to gather the best information as to
                          where and at what depth Ilyrium might be hidden.
                        </li>
                        <li>
                          Conserving energy is critical, so it’s best to plan a
                          path from the outline in, or inside out in order to
                          minimise your energy expended on travel.
                        </li>
                        <li>
                          If you are feeling more confident and looking to
                          rapidly drop a large amount of cores at a set depth,
                          you can use the depth locker module, located in the
                          top left of your console. To unlock, simply click the
                          locked depth again to reactive your dropdown on click
                          within the map.
                        </li>
                        <li>
                          Checking the Core Calculator prior to deploying your
                          core will also help you optimise your energy.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                {viewportWidth <= 1350 && (
                  <div
                    class="right-col-con animated-fadeIn"
                    id="right-col-con-for-coring"
                  >
                    <div id="core-map" class="trnng_img">
                      {showLoader ? (
                        <Loader />
                      ) : (
                        <img
                          className="coring-map"
                          src={coringMap} // Use state variable here
                          alt="coring map"
                        />
                      )}
                    </div>
                    <div className="coring-left-col">
                      <CoreResult />
                      <CoreMap />
                    </div>
                  </div>
                )}
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">4. Mining</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">
                        5. Your Discoveries
                      </span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <button class="btn" onClick={() => navigate("/training-mining")}>
                Next
              </button>
            </div>
          </div>
          {viewportWidth > 1350 && (
            <div class="right-col-con">
              <div className="coring-left-col">
                <CoreResult />
                <CoreMap />
              </div>

              <div id="core-map" class="trnng_img">
                {showLoader ? (
                  <Loader />
                ) : (
                  <img
                    className="coring-map"
                    src={coringMap} // Use state variable here
                    alt="coring map"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingCoring;
