import React from 'react'

const HorizontalGridLines = () => {
    return (
        <div className='horizontal-grid-line'>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
        </div>
    )
}

export default HorizontalGridLines