import React from "react";
import coreIcon from "../../images/icons/coring-drop.png";
import whiteCore from "../../images/icons/whiteCore.png"; // Note: whiteCore is imported but not used in your example
import depth1 from "../../images/icons/depth-1.svg";
import depth2 from "../../images/icons/depth-2.svg";
import mineblack from "../../images/icons/Nav.png";
import minewhite from "../../images/icons/minewhite.png";
import depth3 from "../../images/icons/depth-3.svg";
import { useSelector } from "react-redux";
const MiningComponent = ({
  mineNo = null,
  x,
  y,
  expanded = false,
  selectGrid,
  setExpanded,
  setDropLocation = null,
  setCursorDropLocation = null,
}) => {
  const { tooltipMining } = useSelector((state) => state.app);
  return (
    <div
      className="mining-component"
      style={{ top: `${y * 32}px`, left: `${x * 32 - 21}px` }}
      onClick={(e) => {
        console.log("CoringComponent clicked"); // Debugging statement
        e.stopPropagation();
      }}
    >
      {tooltipMining == 1 && (
        <div className="mining-component-border fade-in"></div>
      )}
      <div className="mining-icon-wrap">
        <div className={`mining-icon ${expanded ? "expanded" : ""}`}>
          {expanded ? (
            <img src={minewhite} alt="mine" />
          ) : (
            <img src={mineblack} alt="mine" />
          )}
        </div>
      </div>
      {expanded ? (
        <div className="mining-content-wrap-expanded">
          <p>SELECT DEPTH</p>
          <img
            src={depth1}
            onClick={() => {
              selectGrid(0);

              setExpanded(false);
              if (setCursorDropLocation) {
                setCursorDropLocation({ x, y });
                setDropLocation(null);
              }
            }}
          ></img>
          <img
            src={depth2}
            onClick={() => {
              selectGrid(1);
              setExpanded(false);
              if (setCursorDropLocation) {
                setCursorDropLocation({ x, y });
                setDropLocation(null);
              }
            }}
          ></img>
          <img
            src={depth3}
            onClick={() => {
              selectGrid(2);
              setExpanded(false);
              if (setCursorDropLocation) {
                setCursorDropLocation({ x, y });
                setDropLocation(null);
              }
            }}
          ></img>
        </div>
      ) : (
        <div className="mining-content-wrap">
          <p>
            Drop Mine
            <br />#{mineNo?.mineNo} HERE
          </p>
        </div>
      )}
    </div>
  );
};

export default MiningComponent;
