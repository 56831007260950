import React from "react";
import coreIcon from "../../images/icons/coring-drop.png";
import depth1 from "../../images/icons/depth-1.svg";
import depth2 from "../../images/icons/depth-2.svg";
import mineblack from "../../images/icons/Nav.png";
import minewhite from "../../images/icons/minewhite.png";
import dragIcon from "../../images/icons/Drag.svg";
import depth3 from "../../images/icons/depth-3.svg";

const MineCursor = ({
  startCoords,
  depth,
  endCoords = null,
  isSelecting,
  handleMouseUp = null,
  handleMouseMove = null,
  handleCursorMouseDown = null,
}) => {
  const top = isSelecting
    ? `${Math.min(startCoords.y, endCoords.y) * 32}px`
    : `${startCoords.y * 32}px`;
  const left = isSelecting
    ? `${Math.min(startCoords.x, endCoords.x) * 32}px`
    : `${startCoords.x * 32}px`;
  const width = isSelecting
    ? `${(Math.abs(endCoords.x - startCoords.x) + 1) * 32 + 4}px`
    : `${32}px`;
  const height = isSelecting
    ? `${(Math.abs(endCoords.y - startCoords.y) + 1) * 32 + 4}px`
    : `${32}px`;

  const handleDragStart = (event) => {
    event.preventDefault(); // Prevent the image from being dragged
  };

  return (
    <div
      className="mining-cursor"
      style={{
        top,
        left: !isSelecting ? `${startCoords.x * 32 - 4}px` : left,
      }}
      onMouseUp={() => {
        if (handleMouseUp) {
          handleMouseUp();
        }
      }}
    >
      <div
        className={`mine-icon ${
          depth === "0" ? "depth-1" : depth === "1" ? "depth-2" : "depth-3"
        }`}
        style={{
          top,
          left,
          width,
          height,
          background: isSelecting && "rgba(0, 0, 0, 0.6)", // Remove "!important"

          pointerEvents: isSelecting ? "none" : "auto",
        }}
        onMouseMove={() => {
          console.log("mouse moved");
        }}
      >
        <img src={minewhite} alt="Mine Icon" />
      </div>
      <div
        className="drag-icon"
        onMouseDown={(event) => {
          if (handleCursorMouseDown) {
            handleCursorMouseDown(event);
          }
        }}
        style={{ display: isSelecting ? "none" : "inline-flex" }}
      >
        <img src={dragIcon} alt="Drag Icon" onDragStart={handleDragStart} />
        <p>Drag</p>
      </div>
    </div>
  );
};

export default MineCursor;
