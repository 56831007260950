const ShareModal = (props) => (
  <div className="share-modal-wrap">
    <div className="share-modal">
      <h1>are you sure?</h1>
      <p>
        Sharing your Ilyrium charges Crystals allowing you to have more energy
        to keep mining! Go back and share to charge your Crystals.
      </p>
      <div className="share-buttons">
                <div className="white-share-button" onClick={() => props.setShareModal(false)}>
          <p>No, go back!</p>
        </div>
                <div className="dark-share-button" onClick={() => props.handleYesButtom()}>
          <p>Yes, Continue</p>
        </div>
      </div>
    </div>
  </div>
);

export default ShareModal;
