// src/appSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpenMap: false,
    isOpenMech: false,
    isCoringComplete: false,
    isDiscovery: false,
    isCongratulation: false,
    userBalance: 0,
    mapData: null,
    depthSelect: -1,
    selectedGrid: [],
    gridSelectors: [],
    selectedPath: [],
    gridSelectorsArray: [],
    userBalance: 0,
    userTempBalance: 0,
    userToBeBalance: 0,
    isReady: false,
    pathSelect: 0,
    pathSelectTemp: 0,
    isShowButton: true,
    showLoading: true,
    isCoreReportModal: false,
    isMiningOpen: false,
    sessionTime: null,
    pageName: "",
    energyLevel: "",
    tooltipCoring: 0,
    tooltipLanding: 0,
    tooltipInspecting: 0,
    tooltipMining: 0,
    mechEnergy:0,
    remainMechEnergy: 0,
    showError: false,
    showWarning: false,
    isButtonClick: false,
    risk: "",
    factors: { f1: "none", f2: "none", f3: "none", f4: "none", f5: "false" },
    snackbarQueue: [],
    showManual:4
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setOpenMap: (state, action) => {
            state.isOpenMap = action.payload;
        },
        setIsOpenMech: (state, action) => {
            state.isOpenMech = action.payload;
        },
        setTooltipCoring: (state, action) => {
            state.tooltipCoring = action.payload;
        },
        setTooltipMining: (state, action) => {
            state.tooltipMining = action.payload;
        },
        setTooltipInspecting: (state, action) => {
            state.tooltipInspecting = action.payload;
        },
        setTooltipLanding: (state, action) => {
            state.tooltipLanding = action.payload;
        },
        setCoringComplete: (state, action) => {
            state.isCoringComplete = action.payload;
        },
        setDiscovery: (state, action) => {
            state.isDiscovery = action.payload;
        },
        setCongratulation: (state, action) => {
            state.isCongratulation = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setMapData: (state, action) => {
            state.mapData = action.payload;
        },
        setDepthSelect: (state, action) => {
            state.depthSelect = action.payload;
        },
        setSelectedGrid: (state, action) => {
            state.selectedGrid = action.payload;
        },
        setGridSelectors: (state, action) => {
            state.gridSelectors = action.payload;
        },
        setGridSelectorsArray: (state, action) => {
            state.gridSelectorsArray = action.payload;
        },
        setMapPath: (state, action) => {
            state.selectedPath = action.payload;
        },
        setIsReady: (state, action) => {
            state.isReady = action.payload;
        },
        setPathSelect: (state, action) => {
            state.pathSelect = action.payload;
        },
        setPathSelectTemp: (state, action) => {
            state.pathSelectTemp = action.payload;
        },
        setShowButton: (state, action) => {
            state.isShowButton = action.payload;
        },
        setShowLoading: (state, action) => {
            state.showLoading = action.payload;
        },
        setCoreReportModal: (state, action) => {
            state.isCoreReportModal = action.payload;
        },
        setIsMiningOpen: (state, action) => {
            state.isMiningOpen = action.payload;
        },
        setUserTempBalance: (state, action) => {
            state.userTempBalance = action.payload;
        },
        setUserToBeBalance: (state, action) => {
            state.userToBeBalance = action.payload;
        },
        addToSelectedGrid: (state, action) => {
            state.selectedGrid.push(action.payload);
        },
        setPageName: (state, action) => {
            state.pageName = action.payload;
        },
        setSessionTime: (state, action) => {
            state.sessionTime = action.payload;
        },
        setEnergyLevel: (state, action) => {
            state.energyLevel = action.payload;
        },
        setShowError: (state, action) => {
            state.showError = action.payload;
        },
        setMechEnergy: (state, action) => {
            state.mechEnergy = action.payload;
        },
        setRemainMechEnergy: (state, action) => {
            state.remainMechEnergy = action.payload;
        },
        setShowWarning: (state, action) => {
            state.showWarning = action.payload;
        },
        setRisk: (state, action) => {
            state.risk = action.payload;
        },
        setFactors: (state, action) => {
            state.factors = action.payload;
        },
        setIsButtonClick: (state, action) => {
            state.isButtonClick = action.payload;
        },
        setSnackbarQueue: (state, action) => {
            state.snackbarQueue = action.payload;
        },
        setShowManual: (state, action) => {
            state.showManual = action.payload;
        },
    },
});

export const {
    setCoringComplete,
    setDiscovery,
    setCongratulation,
    setIsOpenMap,
    setIsOpenMech,
    setUserBalance,
    setMapData,
    setDepthSelect,
    setSelectedGrid,
    setGridSelectors,
    setGridSelectorsArray,
    setMapPath,
    setIsReady,
    setPathSelect,
    setPathSelectTemp,
    setShowButton,
    setShowLoading,
    setCoreReportModal,
    setIsMiningOpen,
    addToSelectedGrid,
    setUserTempBalance,
    setUserToBeBalance,
    setPageName,
    setSessionTime,
    setEnergyLevel,
    setTooltipCoring,
    setTooltipLanding,
    setTooltipInspecting,
    setTooltipMining,
    setShowError,
    setMechEnergy,
    setRemainMechEnergy,
    setShowWarning,
    setRisk,
    setFactors,
    setIsButtonClick,
    setSnackbarQueue,
    setShowManual
} = appSlice.actions;

export default appSlice.reducer;
