import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from "react-apple-login";
import AppleSignin from 'react-apple-signin-auth';
import jwt_decode from "jwt-decode";
import AuthLogo from "../../images/icons/auth-logo.svg";
import AuthCloseIcon from "../../images/icons/auth-close-icon.svg";
import googleIcon from "../../images/icons/google.svg";
import appleIcon from "../../images/icons/apple.svg";
import "./auth.css";
import AuthService, { BASE_URL } from "../helper/Authservice";
import { ToastContainer, toast } from "react-toastify";
import { handleRemoveSignIn } from "../landscape/LandscapeMap";
import "react-toastify/dist/ReactToastify.css";
import LeftNavBar from "../landscape/LeftNavBar";
import RightNavBar from "../landscape/RightNavBar";
import Welcome from "./Welcome";

const SignInAuth = (props) => {
    const [welcomePage, setWelcomePage] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const [isEmailValid, setEmailValid] = useState(0);
    const [isPasswordValid, setPasswordValid] = useState(0);
    const [invalidEmailError, setEmailError] = useState("");
    const [invalidPassword, setPasswordError] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const services = {
        authService: new AuthService(),
    };
    const handleSuccess = (response) => {
        debugger;
        console.log('Authentication successful:', response);
        const userObject = jwt_decode(response.credential);
        //console.log(userObject);
        console.log('Authentication successful:', userObject);
        //localStorage.setItem('user', JSON.stringify(userObject));
        const { email, name, sub, picture } = userObject;
        userRegister(email, name, picture, sub)
        // Handle the authentication success here (e.g., store the user information).
    };
    const userRegister = async (email, name, picture, sub,platform) => {
        try {
            console.log(email, name, picture, sub, platform)
            if (name == "" || name == null || name == undefined) {
                setFullNameValid(-1);
                setFullNameError("Name required");
                return;
            }
            if (email == "" || email == null || email == undefined) {
                setEmailValid(-1);
                setEmailError("Email required");
                return;
            }
            let response = await fetch(BASE_URL + "/Account/register", {
                method: "POST",
                body: JSON.stringify({
                    name: name,
                    email: email,
                    profilePic: picture,
                    registerBy: platform,
                    socialMediaId: sub
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response.json();
            console.log('data successful:', data);
            debugger;
            console.log(email,sub,platform);
            if (response.status === 200) {
                if (data.isSuccess) {
                    let response2 = await fetch(BASE_URL + "/Account/login", {
                        method: "POST",
                        body: JSON.stringify({
                            userName: email,
                            password: sub,
                            registerBy: platform,
                        }),
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    }
                    );
                    let data2 = await response2.json();
                    if (response2.status === 200) {
                        services.authService.sendToken(data2);
                        setWelcomePage(true);
                        const timer = setTimeout(() => {
                            clearTimeout(timer);
                            window.location.reload();
                        }, 2000);
                        //services.authService.handleClickOnButton("welcome");
                        //props.handleFactionBadge();
                        //window.location.reload();
                        //props.handleFactionBadge();
                        //window.location.reload();
                        //props.setLoggedIn(true);
                    } else {
                        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    }
                    //toast.success("Register Successfully.", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                } else {
                    setEmailValid(-1);
                    setEmailError(data.errorMessage);
                    //toast.error(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    //alert(response.errorMessage);
                }
            } else {
                setEmailValid(-1);
                setEmailError("Some error occured");
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            debugger;
            //services.authService.handleClickOnButton("logout");
        }
    };
    const handleError = (error) => {
        console.error('Authentication error:', error);
        // Handle the authentication error here.
    };
    const appleResponse = (response) => {
        console.log(response);
        var name = "";
        var email = "";
        var id = "";
        if (response.hasOwnProperty("user")) {
            email = response.user.email;
            if (response.hasOwnProperty("name")) {
                var fName = response.user.name.firstName;
                var lName = response.user.name.lastName;
                name = fName+" " + lName;
            }
        }        
        const userObject = jwt_decode(response.authorization.id_token);
        if (email == '' || email == undefined) {
            email = userObject.email;
        }
        id = userObject != null ? userObject.sub : "";
        if (name == '' || name == undefined) {
            if (email.split("@").length > 0) {
                name = email.split("@")[0];
            }
            else {
                name = "AppleUser"
            }
        }
        console.log(userObject)
        console.log(email, name, "", id, "Apple")
        userRegister(email, name, "", id, "Apple");
        
        //console.log(response.authorization.code);
    };
    const handleClickGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            debugger;
            const access_token = codeResponse.access_token;
            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Accept': 'application/json',
                },
                referrerPolicy: "unsafe-url"
            })
                .then((response) => {
                    console.log('response successful:', response);
                    debugger;
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('data1 successful:', data);
                    debugger;
                    const { email, name, id, picture } = data;
                    userRegister(email, name, picture, id, "Google")
                    // Handle the response data (e.g., googleID, gmail, name, etc.)
                    console.log(data);
                })
                .catch((error) => {
                    console.error('Fetch Error:', error);
                });
        },
        onError: (error) => console.log("Login Failed:", error),
        flow: 'implicit',
    });

    return (
        <>
            {!welcomePage ? (
                <div className="authwrapper">
                    <div className="auth-close hide">
                        <div className="authclose-icon">
                            <ReactSVG src={AuthCloseIcon} />
                        </div>
                        <div className="auth-close-text">EXIT</div>
                    </div>
                    <div className="auth-container">
                        <div className="auth-logo">
                            <img src={AuthLogo} alt="Auth Logo" />
                        </div>
                        <div className="auth-title">Registration</div>

                        <div className="auth-button-wrapper">
                            {/*<div className="auth-buttons">*/}
                            {/*    <div className="auth-button-content">*/}
                            {/*        <img src={appleIcon} alt="apple" />*/}
                            {/*        <p>sign up with apple</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <AppleSignin
                                authOptions={{
                                    clientId: 'com.artemalabs.ps1',
                                    scope: 'name email',
                                    redirectURI: 'https://ps1.artemalabs.com',
                                    state: 'state',
                                    nonce: 'nonce',
                                    usePopup: true,
                                    responseMode:"form_post"
                            }}                            
                            uiType="dark"
                                className="apple-auth-btn auth-buttons"
                            noDefaultStyle={false}
                            buttonExtraChildren="Continue with Apple"
                            /** Extra controlling props */
                            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                                onSuccess={appleResponse}//{(response) => console.log(response)} // default = undefined
                            /** Called upon signin error */
                                onError={appleResponse}//{(error) => console.error(error)} // default = undefined
                            /** Skips loading the apple script if true */
                            skipScript={false} // default = undefined
                            /** Apple image props */
                            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                                render={(props) => <div className="auth-buttons" {...props}><div className="auth-button-content">
                                    <img src={appleIcon} alt="apple" />
                                    <p>sign up with apple</p>
                                </div></div>}
  />
                            {/*<AppleLogin*/}
                            {/*    clientId="com.artemalabs.ps1"*/}
                            {/*    redirectURI="https://ps1.artemalabs.com"*/}
                            {/*    usePopup={true}*/}
                            {/*    callback={appleResponse} // Catch the response*/}
                            {/*    scope="email name"*/}
                            {/*    responseMode="form_post"*/}
                            {/*    render={(*/}
                            {/*        renderProps //Custom Apple Sign in Button*/}
                            {/*    ) => (*/}
                            {/*        <button className="auth-button-content"*/}
                            {/*            onClick={renderProps.onClick}*/}
                            {/*            style={{*/}
                            {/*                backgroundColor: "white",*/}
                            {/*                padding: 10,*/}
                            {/*                border: "1px solid black",*/}
                            {/*                fontFamily: "none",*/}
                            {/*                lineHeight: "25px",*/}
                            {/*                fontSize: "25px"*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <img src={appleIcon} alt="apple" />*/}
                            {/*            <p>sign up with apple</p>*/}
                            {/*        </button>*/}
                            {/*    )}*/}
                            {/*/>*/}


                            <div className="auth-buttons" onClick={() => handleClickGoogle()}>
                                <div className="auth-button-content">
                                    <img src={googleIcon} alt="apple" />
                                    <p>sign up with google</p>
                                </div>
                            </div>
                            {/*<GoogleOAuthProvider clientId="347813789361-072uf4plg177l1846ufae3efl0fg4s48.apps.googleusercontent.com">*/}
                            {/*    <GoogleLogin*/}
                            {/*        id="ggLeLogin"*/}
                            {/*        context="sign up with google"*/}
                            {/*        text="sign up with google"*/}
                            {/*        theme="auth-buttons"*/}
                            {/*        size="large"*/}
                            {/*        shape="rectangular"*/}
                            {/*        type="standard"*/}
                            {/*        width="241px"*/}
                            {/*        ux_mode="popup"*/}
                            {/*        logo_alignment="left"*/}
                            {/*        render={(renderProps) => (*/}
                            {/*            <div className="auth-buttons">*/}
                            {/*                <div className="auth-button-content">*/}
                            {/*                    <img src={googleIcon} alt="apple" />*/}
                            {/*                    <p>sign up with google</p>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            //<button*/}
                            {/*            //    type="button"*/}
                            {/*            //    className=""*/}
                            {/*            //    onClick={renderProps.onClick}*/}
                            {/*            //    disabled={renderProps.disabled}*/}
                            {/*            //>*/}
                            {/*            //    <FcGoogle className="" /> Sign in with google*/}
                            {/*            //</button>*/}
                            {/*        )}*/}
                            {/*        onSuccess={handleSuccess}*/}
                            {/*        onFailure={handleError}*/}
                            {/*        cookiePolicy="single_host_origin"*/}
                            {/*    />*/}
                            {/*</GoogleOAuthProvider>*/}
                            <div className="button-separator">or</div>

                            <div className="auth-buttons" onClick={() => props.handleRegister()} >
                                <div className="auth-button-content align-self-center">
                                    <p>continue with email</p>
                                </div>
                            </div>
                        </div>
                        <div className="auth-footer">
                            <div className="auth-footer-text">Already have an account?</div>
                            <div
                                className="auth-footer-link"
                                onClick={() => props.handleSignIn()}
                            >
                                Log In Here
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Welcome />
            )}
        </>
    );
};

export default SignInAuth;
