import React, { useState, useRef, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import Character from '../../images/game-character.png'
import CloseIcon from "../../images/close-icon.svg";
import Graph from "../../images/graph.png";
import ArrowDown from "../../images/icons/arrow-down.svg";
import EnergyLedgerIcon from "../../images/icons/energy-ledger-icon.svg";
import CoreIcon from "../../images/icons/core.svg";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import Travel from '../../images/icons/travel-orange.svg'
import { setIsOpenMech } from "../../appSlice";
import "./energyLedger.css";
import CoreService from '../helper/Coreservice'

function useScrollHeight() {
    const ref = useRef(null);
    const [scrollHeight, setScrollHeight] = useState();
    useEffect(() => {
        if (ref.current) {
            setScrollHeight(ref.current.scrollHeight + 10);
        }
    }, []);

    return [ref, scrollHeight];
}
var totalCharge = 0;
const EnergyLedger = (props) => {
    const coreservices = {
        coreService: new CoreService()
    };
    const dispatch = useDispatch();
    const { isOpenMech, mapData } = useSelector((state) => state.app);
    const divRef = useRef(null);
    const [isHeightExceeded, setIsHeightExceeded] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [divRef1, scrollHeight1] = useScrollHeight();
    const [divRef2, scrollHeight2] = useScrollHeight();
    const [active, setActive] = useState(2);
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    var depth1Charge = 0;
    var depth2Charge = 0;
    var depth3Charge = 0;
    var pathCost = 0;
    depth1Charge = (mapData != null && mapData.userBalance != null) ? mapData.userBalance.firstDepthCharge : 0;
    depth2Charge = (mapData != null && mapData.userBalance != null) ? mapData.userBalance.secondndDepthCharge : 0;
    depth3Charge = (mapData != null && mapData.userBalance != null) ? mapData.userBalance.thirdDepthCharge : 0;
    pathCost = (mapData != null && mapData.userBalance != null) ? mapData.userBalance.nodeCost : 0;

    const handleDrag = (e, data) => {
        const { x, y } = data;
        setPosition(data);

    };
    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
            setIsHeightExceeded("");
        } else {
            setActive(index);
            setIsHeightExceeded("");
        }
    };
    const handelClose = () => {
        dispatch(setIsOpenMech(false));
        const defaultPosition = { x: 0, y: 0 };
        setPosition(defaultPosition);
        setIsHeightExceeded("");
        setActive(null);
    };

    useEffect(() => {
        const handleResize = () => {
            const windowHeight = window.innerHeight - 92;
            const divHeight = divRef.current?.clientHeight;
            if (divHeight > windowHeight) {
                setIsHeightExceeded("height-exceed");
            }
        };
        // Initial calculation
        handleResize();
        const observer = new ResizeObserver(handleResize);
        observer.observe(divRef.current);
        // Add event listener for window resize
        window.addEventListener("resize", handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            //observer.unobserve(divRef.current);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const energyLedger = isHeightExceeded ? "height-exceed" : "";

    const coreCoordinatesList = mapData == null ? null : mapData.mapDetailList.filter(x => {
        return x.result < 3;
    });
    var pathCoordinates = null;
    var pathCoordinatesList = [];
    if (coreCoordinatesList.length > 0) {
        var depth1Grid = coreCoordinatesList.filter(x => { return x.depthTo == 1 });
        var depth2Grid = coreCoordinatesList.filter(x => { return x.depthTo == 2 });
        var depth3Grid = coreCoordinatesList.filter(x => { return x.depthTo == 3 });
        var tt = (depth1Grid.length * depth1Charge) + (depth2Grid.length * depth2Charge) + (depth3Grid.length * depth3Charge)
        if (tt != totalCharge) {
            totalCharge = tt;
        }
        if (coreCoordinatesList.length > 1) {
            pathCoordinates = coreservices.coreService.FindAllNodes(coreCoordinatesList, false, true);
            if (pathCoordinates != null) {
                for (var i = 0; i < coreCoordinatesList.length; i++) {
                    var obj = { coreNo: coreCoordinatesList[i].coreNo, depthTo: coreCoordinatesList[i], corePath: 0, pathCost: 0 };
                    if (coreCoordinatesList.length > (i + 1)) {
                        var startPoint = coreCoordinatesList[i];
                        var endPoint = coreCoordinatesList[i + 1];
                        var pathList = pathCoordinates.find(x => x.imageIdFrom == startPoint.id && x.imageIdTo == endPoint.id);
                        if (pathList != null) {
                            obj.corePath = pathList.totalPath;
                            obj.pathCost = pathList.totalPath * pathCost
                        }
                    }
                    pathCoordinatesList.push(obj);
                }
            }
        }
    }
    return (
        <>


            <Draggable
                // defaultPosition={position}
                onDrag={handleDrag}
                disabled={winWidth > 1024 ? false : true}
                position={position}
                bounds="parent"
            >
                <div id="energy-ledger" ref={divRef} className={`${energyLedger} el-main-container  ${isOpenMech ? 'open' : ''}`} >
                    <div className='energy-ledger-warapper'>
                        <div className='energy-meter-wrap'>
                            <div className='em-icon'><img src={EnergyLedgerIcon} alt="Energy Ledger" /></div>
                            <div className='em-text'>Energy Metre</div>
                        </div>
                        <div className='close' onClick={() => handelClose()} onTouchStart={() => handelClose()}><img src={CloseIcon} alt='Close' /></div>
                        <div className='energy-ledger-content-wrap'>

                            <div className='game-character-wrap'>
                                <img src={Character} alt='Game-character' />
                            </div>
                            <div className='graph'>
                                <img src={Graph} alt='Graph' />
                            </div>
                            <div className='accordion-wrap'>
                                <div className={`accordion-toggle section-title ${active === 1 ? "active" : ""}`} onClick={() => handleToggle(1)} onTouchStart={() => handleToggle(1)} >
                                    <h4>Mech detail</h4> <ReactSVG src={ArrowDown} className='accordion-icon' />
                                </div>
                                <div ref={divRef1}
                                    className={`collapse  ${active === 1 ? "show" : ""
                                        }`}
                                    style={
                                        active === 1
                                            ? { height: "auto" }
                                            : { height: "0px" }
                                    }>
                                    <div className="accordion-content">
                                        <div className='energy-mech-detail'>
                                            <h2>XTB3-87C</h2>
                                            <p>Lorem ipsum dolor sit amet consectetur. Lorem maecenas orci proin enim lectus orci tempor porttitor urna. Id eget sed at consectetur mauris nisl. Vitae facilisi tempus orci dignissim pellentesque nunc.</p>
                                            <div className='energy-ledger-divider'></div>
                                        </div>
                                        <div className='energy-mech-performance-wrap'>
                                            <div className='emp-title'>Performance</div>
                                            <div className='energy-mech-performance'>
                                                <div className='emp-row'>
                                                    <div className='emp-row-title'>Mileage</div>
                                                    <div className='emp-row-unit'>2002MI</div>
                                                    <div className='emp-row-progress'>
                                                        <div className='emp-progress'>
                                                            <div className='emp-noise'></div>
                                                            <div className='progress' style={{ width: '60%' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='emp-row'>
                                                    <div className='emp-row-title'>Speed</div>
                                                    <div className='emp-row-unit'>32mph</div>
                                                    <div className='emp-row-progress'>
                                                        <div className='emp-progress'>
                                                            <div className='emp-noise'></div>
                                                            <div className='progress' style={{ width: '60%' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='emp-row'>
                                                    <div className='emp-row-title'>Yield</div>
                                                    <div className='emp-row-unit'>2000T</div>
                                                    <div className='emp-row-progress'>
                                                        <div className='emp-progress'>
                                                            <div className='emp-noise'></div>
                                                            <div className='progress' style={{ width: '60%' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='energy-ledger-divider'></div>
                                <div className={`accordion-toggle section-title ${active === 2 ? "active" : ""}`} onClick={() => handleToggle(2)} onTouchStart={() => handleToggle(2)}>
                                    <h4>Energy ledger</h4> <ReactSVG src={ArrowDown} className='accordion-icon' />
                                </div>
                                <div ref={divRef2}
                                    className={`collapse  ${active === 2 ? "show" : ""
                                        }`}
                                    style={
                                        active === 2
                                            ? { height: "auto" }
                                            : { height: "0px" }
                                    }
                                >
                                    <div className="accordion-content">
                                        <div className='e-ledger-used' style={{ marginTop: 0 }}>{totalCharge}kWh <span>USED</span></div>
                                        <div className='pl-main-container'>
                                            <div className='pl-wrapper'>
                                                <div className='pl-row pl-header'>
                                                    <div className='pl-process'>PROCESS.</div>
                                                    <div className='pl-number e-ledger'>NO.</div>
                                                    <div className='pl-detail'>Detail</div>
                                                    <div className='pl-usage'>USAGE</div>
                                                </div>
                                            </div>

                                            <div className='pl-wrapper pl-content-wrap energy-ledger-content'>
                                                {pathCoordinatesList && pathCoordinatesList.length > 0 && pathCoordinatesList.map((item, index) => (
                                                    <>
                                                        <div className='pl-row'>
                                                            <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>
                                                            <div className='pl-number e-ledger'>#{item.coreNo}</div>
                                                            <div className='pl-detail'>{item.depthTo == 1 ? "16m" : item.depthTo == 2 ? "64m" : "192m"}</div>
                                                            <div className='pl-usage'>{item.depthTo == 1 ? depth1Charge + "kw" : item.depthTo == 2 ? depth2Charge + "kw" : depth3Charge + "kw"}</div>
                                                        </div>
                                                        <div className='pl-row'>
                                                            <div className='pl-process'><img src={Travel} alt='Travel' /><div>Travel</div></div>
                                                            <div className='pl-number e-ledger'>-</div>
                                                            <div className='pl-detail'>{item.corePath}m</div>
                                                            <div className='pl-usage'>{item.pathCost}kw</div>
                                                        </div>
                                                    </>

                                                ))}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='pl-row'>*/}
                                                {/*  <div className='pl-process'><ReactSVG src={CoreIcon} className='pl-process-icon' /><div>Coring</div></div>*/}
                                                {/*  <div className='pl-number e-ledger'>#e10-10</div>*/}
                                                {/*  <div className='pl-detail'>24m</div>*/}
                                                {/*  <div className='pl-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </>
    )
}

export default EnergyLedger