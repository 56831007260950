import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from 'react-router-dom';
import logo from "../../images/avatars/logo.svg";
import mechImage from "../../images/avatars/Layer-29-2.png";
import closeIcon from "../../images/avatars/close-button.svg";
import darkGradient from "../../images/avatars/dark-gradient-11.png";
import vector3 from "../../images/avatars/vector3.png";
import vector2 from "../../images/avatars/vector2.png";
import LogoMobile from "../../images/logo-mobile.svg";
import ilyrium from "../../images/ilyrium.png";
import arrow from "../../images/arrow-up.svg";
import badgeTitle from "../../images/icons/usffmmd.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import signal from "../../images/signal.gif";
import circle from "../../images/icons/circlecocentric.svg";
import closebutton from "../../images/icons/assetclosebutton.svg";
import shareIcon from "../../images/icons/share-icon.svg";
import reloadIcon from "../../images/icons/reload-icon.svg";
import gun1 from "../../images/gun1.png";
import gun2 from "../../images/gun2.png";
import gun3 from "../../images/gun3.png";
import gun4 from "../../images/gun4.png";
import crystal from "../../images/crystal.png";
import rock from "../../images/rock.png";
import errorAnimation from "../../images/errorAnimation.svg";
import { Unity, useUnityContext } from "react-unity-webgl";
import ErrorAnimation from "../../images/errorAnimation";
import AuthService, { AdminUserModel } from "../helper/Authservice";
const AssetViewer = (props) => {
    const services = {
        authService: new AuthService(),
    };
    const location = useLocation();
    const dataReceived = location.state?.data;
    debugger;
    const [animateOut, setAnimateOut] = useState(false);
    const [isGrabbing, setIsGrabbing] = useState(false);
    const [isNavOpen, setNavOpen] = useState(false);
    const [button1Clicked, setButton1Clicked] = useState(false);
    const [button2Clicked, setButton2Clicked] = useState(false);
    const [swiper, setSwiper] = useState(null);
    const [clickedIndex, setClickedIndex] = useState(dataReceived == "crystal" ? 2 : dataReceived == "rock"?1:0);
    const [assetType, setAssetType] = useState(dataReceived == "gun" ? 3 : 1);
  const [timer, setTimer] = useState(null);
  const [completedBars, setCompletedBars] = useState(20);
  const guns = [gun1, gun2, gun3, gun4, gun1, gun2, gun3, gun4];
    const [bars, setBars] = useState([]);
    const history = useNavigate();
  useEffect(() => {
    const rectangles = [];
    for (let i = 0; i < completedBars; i++) {
      rectangles.push(<div className="rectangle yellow complete"></div>);
    }
    for (let i = completedBars; i <= 100; i++) {
      rectangles.push(<div className="rectangle yellow"></div>);
    }
    setBars(rectangles);
  }, []);

    const { unityProvider, sendMessage, loadingProgression } =
        new useUnityContext({
            loaderUrl: (dataReceived == "crystal" ? "Build_Cry" : dataReceived=="rock"?"Build_Rock":"Build_Gun")+"/Final.loader.js",
            dataUrl: (dataReceived == "crystal" ? "Build_Cry" : dataReceived == "rock" ? "Build_Rock" : "Build_Gun") +"/Final.data",
            frameworkUrl: (dataReceived == "crystal" ? "Build_Cry" : dataReceived == "rock" ? "Build_Rock" : "Build_Gun") +"/Final.framework.js",
            codeUrl: (dataReceived == "crystal" ? "Build_Cry" : dataReceived == "rock" ? "Build_Rock" : "Build_Gun") +"/Final.wasm",
        });
    const handleClose = (dataToSend) => {
        debugger;
        if (dataToSend != "back") {
            history('/assetviewer', { state: { data: dataToSend } });
            window.location.href = "/assetviewer";
            //history('/assetviewer', { state: { data: dataToSend } });
        }
        else {
            window.location.href = "/mylocker";
        }
    };
    const menuopen = () => {
        setNavOpen(!isNavOpen);
    };
    if (Math.round(loadingProgression * 100) < 100) {
        if (dataReceived == "crystal") {
            console.log(dataReceived)
            sendMessage("Robo", "MyFunction", "cp");
        }
        else if (dataReceived == "rock") {
            sendMessage("Robo", "MyFunction", "ro");
        }
        else {
            sendMessage("Robo", "MyFunction", "g1");
        }
    }

    const handleButton1Click = () => {
        //if (clickedIndex == 0) {
        sendMessage("Robo", "MyFunction", "reset");
        setButton1Clicked(true);
        setTimeout(() => {
            setButton1Clicked(false);
        }, 200);
        //}
    };

    const handleButton2Click = () => {
        setButton2Clicked(true);
        setTimeout(() => {
            setButton2Clicked(false);
        }, 200);
    };

    const handleAssetClick = () => { };

    useEffect(() => {
        const thumbnails = document.getElementsByClassName("thumbnail");

        for (let i = 0; i < thumbnails.length; i++) {
            thumbnails[i].classList.remove("thumbnail-clicked");
        }

        if (
            clickedIndex !== null &&
            clickedIndex >= 0 &&
            clickedIndex < thumbnails.length
        ) {
            thumbnails[clickedIndex].classList.add("thumbnail-clicked");
        }
        console.log(thumbnails);
    }, [clickedIndex]);
    return (
        <div className="main-asset-container">
            <div className="asset-viewer-container align-flex-end">
                <div className="asset-close-button" onClick={() => handleClose("back")}>
                    <img src={closebutton} alt="close"></img>
                </div>
                {assetType == 1 ? (
          <div className=" asset-bottom-left equal-width">
                        <img src={darkGradient} alt="dark gradient"></img>
                        <div className="info">
                            <div className="info-labels">
                                <p>location</p>
                                <p>lattitude</p>
                                <p>longitude</p>
                                <p>depth</p>
                            </div>
                            <div className="info-values">
                                <p>Eastern Rift E-08</p>
                                <p>-10.883° N</p>
                                <p>328.219° E</p>
                                <p>16m</p>
                            </div>
                        </div>
                        <div className="info">
                            <div className="info-labels">
                                <p>YIELD</p>
                                <p>value</p>
                                <p>Energy</p>
                                <p>charged</p>
                            </div>
                            <div className="info-values">
                                <p>2.57kg</p>
                                <p>68,000</p>
                                <p>80,846KW</p>
                                <p>103,846KW</p>
                            </div>
                        </div>
                    </div>
                ) : assetType == 2 ? (
                    <div className="mech-signal equal-width">
                        <img src={signal} alt="signal"></img>
                    </div>
                ) : (
                    assetType == 3 && <div className="equal-width"></div>
                )}
                <div className="middle-frame">
                    <div className="asset-title-wrap">
                        <h2>{dataReceived == "gun" ? "AIZ8 Laser Rifle" : dataReceived=="crystal"?"Ilyrium":"Rock"}</h2>
                    </div>
                    {Math.round(loadingProgression * 100) < 100 ? (
                        <p style={{ textAlign: "center", marginTop: "30%", color: "#fff" }}>
                            Loading Application... {Math.round(loadingProgression * 100)}%
                        </p>
                    ) : (
                           ""
                    )}
                    <div
                        style={{ cursor: isGrabbing ? "grabbing" : "grab" }}
                        className="unity-asset-holder"
                        onMouseDown={() => setIsGrabbing(true)}
                        onMouseUp={() => setIsGrabbing(false)}
                    >
                        <Unity unityProvider={unityProvider}/>
                    </div>
                    <div className="asset-interaction-wrap">
            {/* <div className="refinement-progress-wrap yellow">
              <div className="rp-top">
                <div className="error-animation-wrap">
                  <ErrorAnimation color="red" />
                  <ErrorAnimation color="red" />
                  <ErrorAnimation color="red" />
                </div>

                <p>stage 1 Refinement in progress</p>
                <div className="error-animation-wrap">
                  <ErrorAnimation color="yellow" />
                  <ErrorAnimation color="yellow" />
                  <ErrorAnimation color="yellow" />
                </div>
              </div>
              <div className="rp-mid">{bars}</div>
              <div className="rp-top">
                <p>estimated time remaining: 24:38:08</p>
              </div>
            </div> */}
                        <div className="share-text-frame">
                            <p>
                                Charge crystal by <span>23,000kW</span> extra by sharing
                            </p>
                        </div>
                        <div className="share-cta-frame">
                            <div
                                className={`share-button-1 overlay-none ${button1Clicked && "animate-dissolve"
                                    }`}
                                onClick={handleButton1Click}
                            >
                                <img src={reloadIcon}></img>
                            </div>
                            <div
                                className={`share-button-2 ${button2Clicked && "button-2-clicked"
                                    }`}
                                onClick={handleButton2Click}
                            >
                                <p>share</p>
                                <img src={shareIcon}></img>
                            </div>
                        </div>

                        <div className="thumbnail-slider-wrap">
                            <div
                                className="arrow-left"
                                onClick={() => {
                                    if (swiper) {
                                        swiper.slidePrev();
                                    }
                                }}
                            >
                                <img src={arrow}></img>
                            </div>
                            <Swiper
                slidesPerView={5}
                spaceBetween={1}
                                navigation={{
                                    nextEl: ".arrow-right",
                                    prevEl: ".arrow-left",
                                }}
                                autoplay={{
                                    delay: 1000,
                                }}
                                loop={true}
                                onInit={(swiper) => setSwiper(swiper)}
                                onClick={(swiper) => {
                                    console.log(swiper.clickedIndex);
                  setClickedIndex(swiper.clickedIndex - 1);
                                }}
                            >
                <SwiperSlide></SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="thumbnail"
                                        onClick={() => {
                                            handleClose("gun")
                                            //location.state.data = 'gun';
                                            //setAssetType(3);
                                            //sendMessage("Robo", "MyFunction", "g1");
                                        }}
                                    >
                                        <img src={gun1} alt="Thumbnail 1" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="thumbnail"
                                        onClick={() => {
                                            handleClose("rock")
                                            //location.state.data = 'rock';
                                            //setAssetType(3);
                                            //sendMessage("Robo", "MyFunction", "ro");
                                        }}
                                    >
                                        <img src={rock} alt="Thumbnail 1" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="thumbnail"
                                        onClick={() => {
                                            handleClose("crystal")
                                            //location.state.data = 'crystal';
                                            //setAssetType(1);
                                            //sendMessage("Robo", "MyFunction", "cp");
                                        }}
                                    >
                                        <img src={crystal} alt="Thumbnail 1" />
                                    </div>
                                </SwiperSlide>
                <SwiperSlide></SwiperSlide>
                            </Swiper>

                            <div
                                className="arrow-right"
                                onClick={() => {
                                    if (swiper) {
                                        swiper.slideNext();
                                    }
                                }}
                            >
                                <img src={arrow}></img>
                            </div>
                        </div>
                    </div>
                </div>
                {assetType == 1 ? (
                    <div className="bottom-right-text equal-width right-shrink">
                        <p>e-08</p>
                        <p>e0819h64</p>
                    </div>
                ) : assetType == 2 ? (
                    <div className="mech-bottom-left equal-width">
                        <div className="mech-badge">
                            <div className="badge-info">
                                <img src={badgeTitle}></img>
                                <div className="info-row">
                                    <div className="info-column">
                                        <div>type</div>
                                        <div className="border-field">mine</div>
                                    </div>
                                    <div className="info-column">
                                        <div>series</div>
                                        <div className="border-field">m-28</div>
                                    </div>
                                </div>
                                <div className="info-row border-field-long">
                                    Model: 493629291-876-29
                                </div>
                                <div className="info-row">
                                    <div className="info-column">
                                        <div>G.M.W</div>
                                        <div className="border-field">83973kg</div>
                                    </div>
                                    <div className="info-column">
                                        <div>G.y.c</div>
                                        <div className="border-field">83973kg</div>
                                    </div>
                                </div>
                            </div>
                            <div className="top-left-circle corner-circles">
                                <img src={circle}></img>
                            </div>
                            <div className="top-right-circle corner-circles">
                                <img src={circle}></img>
                            </div>
                            <div className="bottom-left-circle corner-circles">
                                <img src={circle}></img>
                            </div>
                            <div className="bottom-right-circle corner-circles">
                                <img src={circle}></img>
                            </div>
                        </div>
                    </div>
                ) : (
                    assetType == 3 && <div className="equal-width"></div>
                )}
            </div>
        </div>
    );
};

export default AssetViewer;
