import React, { useState, useRef, useEffect } from "react";
import { ReactSVG } from "react-svg";

import Character from "../../images/game-character-2.png";
import CloseIcon from "../../images/close-icon.svg";
import Graph from "../../images/graph.png";
import ArrowDown from "../../images/icons/arrow-down.svg";
import Nav from "../../images/icons/Nav.svg";
import TravelIcon from "../../images/icons/travel.svg";
import EnergyLedgerIcon from "../../images/icons/energy-ledger-icon.svg";
import CoreIcon from "../../images/icons/core.svg";
import MineIcon from "../../images/icons/mine.svg";
import Draggable from "react-draggable";
import "./energyLedger.css";

function useScrollHeight() {
  const ref = useRef(null);
  const [scrollHeight, setScrollHeight] = useState();
  useEffect(() => {
    if (ref.current) {
      setScrollHeight(ref.current.scrollHeight + 10);
    }
  }, []);

  return [ref, scrollHeight];
}

const EnergyLedger = (props) => {
  const divRef = useRef(null);
  const [isHeightExceeded, setIsHeightExceeded] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [divRef1, scrollHeight1] = useScrollHeight();
  const [divRef2, scrollHeight2] = useScrollHeight();
  const [active, setActive] = useState(1);
  const [winWidth, setWinWidth] = useState(window.innerWidth);

  const handleDrag = (e, data) => {
    // const { x, y } = data;
    // setPosition(data);
  };
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
      setIsHeightExceeded("");
    } else {
      setActive(index);
      setIsHeightExceeded("");
    }
  };
  const handelClose = () => {
    props.setOpenMech(false);
    const defaultPosition = { x: 0, y: 0 };
    setPosition(defaultPosition);
    setIsHeightExceeded("");
    setActive(null);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight - 92;
      const divHeight = divRef.current?.clientHeight;
      if (divHeight > windowHeight) {
        setIsHeightExceeded("height-exceed");
      }
    };
    // Initial calculation
    handleResize();
    const observer = new ResizeObserver(handleResize);
    observer.observe(divRef.current);
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      //observer.unobserve(divRef.current);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const energyLedger = isHeightExceeded ? "height-exceed" : "";

  return (
    <>
      {/* <Draggable
        // defaultPosition={position}
        onDrag={handleDrag}
        disabled={winWidth > 1024 ? false : true}
        position={position}
        bounds="parent"
      > */}
      <div
        id="energy-ledger"
        ref={divRef}
        className={`${energyLedger} el-main-container  ${
          props.isOpenMech ? "open" : ""
        }`}
      >
        <div className="energy-ledger-wrapper">
          <div className="ledger-header-wrap">
            <div className="energy-meter-wrap">
              <div className="em-icon">
                <img src={EnergyLedgerIcon} alt="Energy Ledger" />
              </div>
              <div className="em-text">Energy Metre</div>
            </div>
            <div
              className="close"
              onClick={() => handelClose()}
              onTouchStart={() => handelClose()}
            >
              <img src={CloseIcon} alt="Close" />
            </div>
          </div>

          <div className="energy-ledger-content-wrap">
            <div className="game-character-wrap">
              <img src={Character} alt="Game-character" />
            </div>
            {/* <div className="graph">
                <img src={Graph} alt="Graph" />
              </div> */}
            <div className="accordion-wrap">
              <div
                className={`accordion-toggle section-title ${
                  active === 1 ? "active" : ""
                }`}
                onClick={() => handleToggle(1)}
              >
                <h4>Mech detail</h4>{" "}
                <ReactSVG src={ArrowDown} className="accordion-icon" />
              </div>
              <div
                ref={divRef1}
                className={`collapse  ${active === 1 ? "show" : ""}`}
                style={
                  active === 1 ? { height: scrollHeight1 } : { height: "0px" }
                }
              >
                <div className="accordion-content">
                  <div className="energy-mech-detail">
                    <h2>XTB3-87C</h2>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Lorem maecenas
                      orci proin enim lectus orci tempor porttitor urna. Id eget
                      sed at consectetur mauris nisl. Vitae facilisi tempus orci
                      dignissim pellentesque nunc.
                    </p>
                    <div className="energy-ledger-divider"></div>
                  </div>
                  <div className="energy-mech-performance-wrap">
                    <div className="emp-title">Performance</div>
                    <div className="energy-mech-performance">
                      <div className="emp-row">
                        <div className="emp-row-title">Mileage</div>
                        <div className="emp-row-unit">2002MI</div>
                        <div className="emp-row-progress">
                          <div className="emp-progress">
                            <div className="emp-noise"></div>
                            <div
                              className="progress"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="emp-row">
                        <div className="emp-row-title">Speed</div>
                        <div className="emp-row-unit">32mph</div>
                        <div className="emp-row-progress">
                          <div className="emp-progress">
                            <div className="emp-noise"></div>
                            <div
                              className="progress"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="emp-row">
                        <div className="emp-row-title">Yield</div>
                        <div className="emp-row-unit">2000T</div>
                        <div className="emp-row-progress">
                          <div className="emp-progress">
                            <div className="emp-noise"></div>
                            <div
                              className="progress"
                              style={{ width: "60%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="energy-ledger-divider"></div>
              <div
                className={`accordion-toggle section-title ${
                  active === 2 ? "active" : ""
                }`}
                onClick={() => handleToggle(2)}
              >
                <p>Energy ledger</p>
                <ReactSVG src={ArrowDown} className="accordion-icon" />
              </div>
              <div
                ref={divRef2}
                className={`collapse  ${active === 2 ? "show" : ""}`}
                style={
                  active === 2 ? { height: scrollHeight2 } : { height: "0px" }
                }
              >
                <div className="accordion-content">
                  <div className="e-ledger-used" style={{ marginTop: 0 }}>
                    12047kWh <span>USED</span>
                  </div>
                  <div className="pl-main-container">
                    <div className="pl-wrapper">
                      <div className="pl-row pl-header">
                        <div className="pl-process">PROCESS.</div>
                        <div className="pl-number e-ledger">NO.</div>
                        <div className="pl-detail">Detail</div>
                        <div className="pl-usage">USAGE</div>
                      </div>
                    </div>

                    <div className="pl-wrapper pl-content-wrap energy-ledger-content">
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG src={Nav} className="pl-process-icon" />
                          <div>travel</div>
                        </div>
                        <div className="pl-number e-ledger">-</div>
                        <div className="pl-detail">24m</div>
                        <div className="pl-usage">24kw</div>
                      </div>
                      <div className="pl-row">
                        <div className="pl-process">
                          <ReactSVG
                            src={CoreIcon}
                            className="pl-process-icon"
                          />
                          <div>core</div>
                        </div>
                        <div className="pl-number e-ledger">#e10-10</div>
                        <div className="pl-detail">16m</div>
                        <div className="pl-usage">1000kw</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
    </>
  );
};

export default EnergyLedger