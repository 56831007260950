import React, { useEffect, useState } from "react";
import BadgeLogo from "../../images/badge.png";
import AuthService, { BASE_URL } from "../helper/Authservice";
import background from "../../images/faction-badge-bg.jpg";
import Loader from "../../Loader";
const FactionBadge = (props) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    const img = new Image();
    img.src = background;
    img.onload = () => setShowLoader(false);
  }, []);

  window.addEventListener("beforeunload", function () {
    document.body.classList.add("animate-out");
  });
  const services = {
    authService: new AuthService(),
  };

  services.authService.removeWelcome();
  var user = services.authService._CurrentUser();

  const onProceed = async () => {
    var action = "updateProcced";
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          //setUser(services.authService._CurrentUser());
        }
      }
      props.handleClickOnButtonByUser("handleMech");
    }
    //setImages(imageList);
  };

  return (
    <div className={`faction-badge-background animate-in`}>
      {showLoader && <Loader />}
      <div className="faction-badge-wrapper">
        <div className="fb-content-wrapper">
          <img src={BadgeLogo} alt="" />

          <div className="fb-text-wrapper">
            <h5>
              CONGRATULATIONS &nbsp; "{user.userName}",
              <br />
              WELCOME TO USFF
            </h5>
            <p>
              You have earned the honorary Mining Pilot badge. You can view it
              in your gallery.
              <br /> This grants you entry to the Brotherhood and all its lands.
            </p>
            <div className="fb-btn-wrapper">
              <button
                type="button"
                onClick={() => {
                  /* onProceed();*/
                  props.handleClickOnButtonByUser("active2");
                  /*props.handleBadgeProceed();*/
                }}
              >
                <h5>proceed</h5>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactionBadge;
