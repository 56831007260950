import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as astar from "javascript-astar/astar.js";
import GraphSearch from "./CustomHooks";
import LeftRuller from "./LeftRuller";
import TopRuller from "./TopRuller";
import BottomRuller from "./BottomRuller";
import RightRuller from "./RightRuller";
import Grid from "../../images/768-Grid-bg.svg";
import Core from "./Core";
import map from "../../images/map.png";
import RestrictedGrid from "./RestrictedGrid";
import MiningComponent from "./MiningComponent";
import GridSelector from "./GridSelector";
import GridHover from "./GridHover";
import GridPathway from "./GridPathway";
import CoringComponent from "./CoringComponent";
import PathwayComplete from "./PathwayComplete";
import CoreService from "../helper/Coreservice";
var lastSelectedNode;
var isPageLoad = false;
var $grid = '<div id="search_grid">Loading grid...</div>';
var opts = {
  wallFrequency: ".1",
  gridSize: "24",
  debug: false,
  diagonal: false,
  closest: false,
};
const MainGrid = (props) => {
  const gridSize = 24;
  const coreservices = {
    coreService: new CoreService(),
  };
  const [count, setCount] = useState(0);
  //indexes start from 0 to 23 so give corrdinates accordingly

  const filterUserDigRealDecrease = (myArray) => {
    var abc = myArray
      .filter((x) => x.result < 3)
      .sort((b, a) => a.digId - b.digId);
    return abc;
  };
  const filterUserDigReal = (myArray) => {
    var abc = myArray
      .filter((x) => x.result < 3)
      .sort((a, b) => Number(a.digId) - Number(b.digId));
    return abc;
  };
  let userNodesAll = [];
  const UpdatePathEnergy = (coreCoordinatesList) => {
    if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
      for (var i = 0; i < coreCoordinatesList.length; i++) {
        var nodePathList = coreCoordinatesList[i];
        if (nodePathList != undefined && nodePathList.length > 0) {
          var abc = Math.floor(nodePathList.length / 2);
          var middleNode = nodePathList[abc];
          var str =
            "<span className='energyclass' style='background-color: white;font-size: 12px;position: absolute;width: 32px;opacity: 0.9;'>" +
            nodePathList.length.toString() +
            "KW" +
            "</span>";
          const box = document.getElementById("id_" + middleNode.id);
          if (box != null) {
            box.insertAdjacentHTML("beforeend", str);
          }
        }
      }
    }
  };
  const FindAllNodes = (coreCoordinatesList, istemp) => {
    //var grid1 = demo.GraphSearch1;
    var grid = GraphSearch($grid, opts, astar.search);
    userNodesAll = [];
    if (istemp == true) {
      let realUserCoreList =
        props.mapData == null
          ? null
          : props.mapData.mapDetailList.filter((x) => {
              return x.result < 3;
            });
      realUserCoreList = filterUserDigReal(realUserCoreList);
      if (realUserCoreList.length > 0) {
        let lastNode = realUserCoreList[realUserCoreList.length - 1];
        let abc = [];
        var tt = coreCoordinatesList.find((x) => x.id == lastNode.id);
        if (tt == null || tt == undefined) {
          abc.push(lastNode);
        }
        for (var k = 0; k < coreCoordinatesList.length; k++) {
          abc.push(coreCoordinatesList[k]);
        }
        coreCoordinatesList = abc;
      }
    }
    let userDigTemp = coreCoordinatesList;
    var lcorsNode = coreCoordinatesList[coreCoordinatesList.length - 1];
    for (var k = 0; k < coreCoordinatesList.length; k++) {
      let userNodes = [];
      if (userDigTemp.length == 1) {
        var sss = filterUserDigReal(coreCoordinatesList).filter(
          (z) => z.id < userDigTemp[0].id
        )[0];
        var nnn = userDigTemp[0];
        userDigTemp = [];
        userDigTemp.push(sss);
        userDigTemp.push(nnn);
      }
      if (userDigTemp.length >= 2) {
        var startp = userDigTemp[0];
        var endp = userDigTemp[1];
        if (startp != undefined && endp != undefined) {
          userDigTemp = userDigTemp.filter((x) => x.id != startp.id);
          if (lcorsNode.id == endp.id) {
            userDigTemp = userDigTemp.filter((x) => x.id != endp.id);
          }

          var userNN = GetStraightNodes(
            startp.rowNo,
            startp.colNo,
            endp.rowNo,
            endp.colNo
          );
          if (userNN != undefined && userNN.length > 0) {
            for (var m = 0; m < userNN.length; m++) {
              var nn = props.mapData.mapDetailList.find(
                (n) => n.rowNo == userNN[m].rowNo && n.colNo == userNN[m].colNo
              );
              if (endp.id != nn.id) {
                userDigTemp = userDigTemp.filter((x) => x.id != nn.id);
              }
              userNodes.push(nn);
            }
          } else {
            var userNood = grid.cellClickedRow(
              startp.rowNo,
              startp.colNo,
              endp.rowNo,
              endp.colNo
            );
            for (var m = 0; m < userNood.length; m++) {
              var nn = props.mapData.mapDetailList.find(
                (n) =>
                  n.rowNo == userNood[m].x + 1 && n.colNo == userNood[m].y + 1
              );
              if (endp.id != nn.id) {
                userDigTemp = userDigTemp.filter((x) => x.id != nn.id);
              }
              userNodes.push(nn);
              //corrdinates.push({ x: nn.colNo - 1, y: nn.rowNo - 1 });
            }
          }
          userNodes = userNodes.filter((x) => x.id != startp.id);
          userNodes = userNodes.filter((x) => x.id != endp.id);
          var nk = userNodes.find((l) => l.id == startp.id);
          if (nk === null || nk === undefined) {
            //userNodes.push(startp);
          }
          var nke = userNodes.find((l) => l.id == endp.id);
          if (nke === null || nke === undefined) {
            userNodes.push(endp);
          }
          userNodesAll.push(userNodes);
        } else {
          //
        }
      }
    }
    if (userNodesAll.length > 0) {
      let ppCordinate = [];
      userNodesAll.forEach((line) => {
        console.log(line);
        let mmt = line.map((item, index) => ({
          x: item.colNo - 1,
          y: item.rowNo - 1,
        }));
        ppCordinate.push(mmt);
      });
      if (!istemp) {
        //console.log(ppCordinate);
        pathCoordinates = ppCordinate;
        //RemovePathEnergy(userNodesAll);
      } else {
        pathCoordinatesLatest = ppCordinate;
        UpdatePathEnergy(userNodesAll);
      }
    }
  };
  const GetStraightNodes = (strPRow, strPCol, endPRow, endPCol) => {
    var currentStartRow = strPRow;
    var currentStartCol = strPCol;
    let userDNodes = [];
    let totalPath = [];
    if (endPRow >= strPRow) {
      for (var i = strPRow; i <= endPRow; i++) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == i && s.colNo == currentStartCol
        );
        var nn = userDNodes.find(
          (x) => x.rowNo == i && x.colNo == currentStartCol
        );
        if (nn == null) {
          userDNodes.push(nc);
        }
        currentStartRow = i;
      }
    }
    if (endPRow < strPRow) {
      for (var i = strPRow; i >= endPRow; i--) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == i && s.colNo == currentStartCol
        );
        var nn = userDNodes.find(
          (x) => x.rowNo == i && x.colNo == currentStartCol
        );
        if (nn == null) {
          userDNodes.push(nc);
        }
        currentStartRow = i;
      }
    }
    if (endPCol >= strPCol) {
      for (var i = strPCol; i <= endPCol; i++) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == currentStartRow && s.colNo == i
        );
        var nn = userDNodes.find(
          (x) => x.rowNo == currentStartRow && x.colNo == i
        );
        if (nn == null) {
          userDNodes.push(nc);
        }
        currentStartCol = i;
      }
    }
    if (endPCol < strPCol) {
      for (var i = strPCol; i >= endPCol; i--) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == currentStartRow && s.colNo == i
        );
        var nn = userDNodes.find(
          (x) => x.rowNo == currentStartRow && x.colNo == i
        );
        if (nn == null) {
          userDNodes.push(nc);
        }
        currentStartCol = i;
      }
    }
    if (userDNodes.filter((x) => x.isRestricted == true).length > 0) {
      userDNodes = [];
    }
    if (userDNodes.length > 0) {
      totalPath.push(1);
    }
    currentStartRow = strPRow;
    currentStartCol = strPCol;
    let userDNodesCol = [];
    if (endPCol >= strPCol) {
      for (var i = strPCol; i <= endPCol; i++) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == currentStartRow && s.colNo == i
        );
        var nn = userDNodesCol.find(
          (x) => x.rowNo == currentStartRow && x.colNo == i
        );
        if (nn == null) {
          userDNodesCol.push(nc);
        }
        currentStartCol = i;
      }
    }
    if (endPCol < strPCol) {
      for (var i = strPCol; i >= endPCol; i--) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == currentStartRow && s.colNo == i
        );
        var nn = userDNodesCol.find(
          (x) => x.rowNo == currentStartRow && x.colNo == i
        );
        if (nn == null) {
          userDNodesCol.push(nc);
        }
        currentStartCol = i;
      }
    }
    if (endPRow >= strPRow) {
      for (var i = strPRow; i <= endPRow; i++) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == i && s.colNo == currentStartCol
        );
        var nn = userDNodesCol.find(
          (x) => x.rowNo == i && x.colNo == currentStartCol
        );
        if (nn == null) {
          userDNodesCol.push(nc);
        }
        currentStartRow = i;
      }
    }
    if (endPRow < strPRow) {
      for (var i = strPRow; i >= endPRow; i--) {
        var nc = props.mapData.mapDetailList.find(
          (s) => s.rowNo == i && s.colNo == currentStartCol
        );
        var nn = userDNodesCol.find(
          (x) => x.rowNo == i && x.colNo == currentStartCol
        );
        if (nn == null) {
          userDNodesCol.push(nc);
        }
        currentStartRow = i;
      }
    }
    if (userDNodesCol.filter((x) => x.isRestricted == true).length > 0) {
      userDNodesCol = [];
    }
    if (userDNodesCol.length > 0) {
      totalPath.push(2);
    }
    if (totalPath.length > 0) {
      var rand = 1; // totalPath[(Math.random() * totalPath.length) | 0]
      if (rand == 1) {
        return userDNodes;
      } else {
        return userDNodesCol;
      }
    }
  };
  let pathCoordinatesLatest = [];
  let pathCoordinates = [];
  if (props.mapData != null && props.mapData.mapDetailList.length > 0) {
    let coreCoordinatesList =
      props.mapData == null
        ? null
        : props.mapData.mapDetailList.filter((x) => {
            return x.result < 3;
          });
    coreCoordinatesList = filterUserDigReal(coreCoordinatesList);
    if (coreCoordinatesList.length > 0) {
      lastSelectedNode = coreCoordinatesList[coreCoordinatesList.length - 1];
    }
    FindAllNodes(coreCoordinatesList, false);
  }
  useEffect(() => {
    const selectorComponents = [];
  }, []);

  return (
    <div className="map-container reportMapContainer">
      <div className="grid-outer">
        <PathwayComplete path={pathCoordinates} />
        <LeftRuller />
        <TopRuller />
        <BottomRuller />
        <RightRuller />
        <img className="map-image" src={map}></img>
        <img className="grid" src={Grid} alt="Grid" />
        <div className="grid-wrap">
          {props.mapData &&
            props.mapData.mapDetailList &&
            props.mapData.mapDetailList.length > 0 &&
            props.mapData.mapDetailList.map((item, index) =>
              item.isRestricted == true ? (
                <div className="grid-item" key={item.id} id={"id_" + item.id}>
                  <RestrictedGrid />
                </div>
              ) : item.isRestricted == false && item.result === 3 ? (
                <div className="grid-item" key={item.id} id={"id_" + item.id}>
                  {
                    props.selectedGrid != null &&
                    props.selectedGrid.find((x) => x.id == item.id) != null ? (
                      <Core
                        depth={
                          props.selectedGrid.find((x) => x.id == item.id)
                            .depthTo
                        }
                        find={item.result}
                      />
                    ) : (
                      ""
                    ) /*(<CoringComponent />)*/
                  }
                </div>
              ) : (
                <div className="grid-item" key={item.id} id={"id_" + item.id}>
                  <Core
                    depth={item.depthTo}
                    find={item.result}
                    activateFill={true}
                    core={item.coreNo}
                    isComplete={true}
                    id={item.id}
                  />
                </div>
              )
            )}

          {/*<GridPathway path={pathCoordinatesLatest} />*/}
        </div>
      </div>
    </div>
  );
};

export default MainGrid;
