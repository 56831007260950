import depth1 from "../../images/icons/depth-1.svg";
import depth2 from "../../images/icons/depth-2.svg";
import depth3 from "../../images/icons/depth-3.svg";
import depthwrapper1 from "../../images/icons/Depth-1-wrapper.png";
import depthwrapper2 from "../../images/icons/Depth-2-wrapper.png";
import depthwrapper3 from "../../images/icons/Depth-3-wrapper.png";
import closeButton from "../../images/icons/Close-Button.png";

import center1 from "../../images/icons/center1.svg";
import center2 from "../../images/icons/center2.svg";
import center3 from "../../images/icons/center3.svg";
import { useEffect, useState } from "react";
import CoreService from "../helper/Coreservice";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoreReportModal,
  setSelectedGrid,
  setIsReady,
} from "../../appSlice";
const Core = ({
  depth = 1,
  find = 3,
  activateFill = false,
  core = "I-05",
  isComplete = false,
  id = "0",
}) => {
  console.log(find, activateFill);
  const { selectedGrid } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const coreservices = {
    coreService: new CoreService(),
  };
  const activateFillMain = activateFill;
  //const [activateFillMain, setActivateFill] = useState(false);
  const depthIcons = [depth1, depth2, depth3];
  const depthWrappers = [depthwrapper1, depthwrapper2, depthwrapper3];
  const depthCenters = [center1, center2, center3];
  const handleRemoveSelectedGrid = (id) => {
    var sList = selectedGrid;
    sList = sList.filter((x) => x.id != id);
    dispatch(setSelectedGrid(sList));
    coreservices.coreService.saveDataEntireGrid(sList);
    if (sList.length == 0) {
      dispatch(setIsReady(false));
    }
  };
  //if (localStorage.getItem('isCoreStart') === 'true' && props.selectedGrid.length > 0) {
  //    localStorage.setItem('setId', props.selectedGrid[0].id)
  //}

  //useEffect(() => {
  //  setActivateFill(activateFill);
  //}, []);
  return (
    <div className="core" id={"core_" + id.toString()}>
      <div className="core-child">
        {find > 2 ? (
          <div
            style={{
              userSelect: "auto",
              pointerEvents: "auto",
            }}
            className="close-button"
            onClick={() => {
              handleRemoveSelectedGrid(id);
            }}
          >
            <img
              className="core-close-button"
              src={closeButton}
              alt="close"
              onClick={() => {
                handleRemoveSelectedGrid(id);
              }}
            ></img>
          </div>
        ) : (
          ""
        )}
        {find === 2 && activateFill && <div className="border-overlay"></div>}
        {find === 2 && activateFill && (
          <div className="border-overlay-clipped"></div>
        )}
        {find >= 0 && find < 2 && (
          <div className={find === 1 ? "find" : "not-find"}></div>
        )}
        <div class="icon-wrapper">
          <img src={depthWrappers[depth - 1]} class="depth-image" />
          <img src={depthCenters[depth - 1]} class="center-image" />
          <div className="depth-label">
            <p
              className={
                depth === 1
                  ? "depth-label-text depth-1"
                  : depth === 2
                  ? "depth-label-text depth-2"
                  : "depth-label-text depth-3"
              }
            >
              {depth === 1 ? "16" : depth === 2 ? "64" : "192"}
            </p>
          </div>
          {find >= 0 && find < 2 ? (
            <div
              className="mining-content-wrap core-bottom-label"
              style={{ backgroundColor: find == 1 ? "#00ff85" : "#ff4d4d" }}
            >
              <p style={{ backgroundColor: find == 1 ? "#00ff85" : "#ff4d4d" }}>
                core #{core}
                <br />
                {find ? "find" : "not-find"}
              </p>
            </div>
          ) : (
            <div
              className="mining-content-wrap core-bottom-label"
              style={{ backgroundColor: " var(--accents-mint, #BEDFCF)" }}
            >
              <p style={{ backgroundColor: " var(--accents-mint, #BEDFCF)" }}>
                Core #I-05
                <br></br>
                ready
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Core;
