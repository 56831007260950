import React, { useState, useEffect } from "react";
import LineIcon from "../../images/icons/line-icon.svg";
import CoreService from "../helper/Coreservice";
import Authservice from "../helper/Authservice";
import animation from "../../images/animation.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoreReportModal,
  setCoringComplete,
  setDiscovery,
  setCongratulation,
} from "../../appSlice";

const MiningCompleteModal = (props) => {
  const { isCoringComplete } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const coreservices = {
    coreService: new CoreService(),
  };
  const services = {
    authService: new Authservice(),
  };
  var user = services.authService._CurrentUser();
  const handleModal = () => {
    props.setMCModelOpen(false);
  };

  useEffect(() => {
    //Implementing the setInterval method
    //const interval = setInterval(() => {
    //    setCount(count + 1);
    //}, 500);
  }, [count]);
  var totalEarn = 0;
  var bestEnergyData =
    coreservices.coreService.getMapData() == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel == null
      ? null
      : coreservices.coreService
          .getMapData()
          .mapGroupModel.filter((x) => x.energyEarn > 0)
          .sort((b, a) => a.energyEarn - b.energyEarn);
    var str = [];
    var totalBonus = 0
    var mechCharge = 0
  if (bestEnergyData != null && bestEnergyData.length > 0) {
    bestEnergyData.forEach((line, index) => {
      if (index <= 3) {
        str.push(bestEnergyData[index]);
      }
        totalEarn = totalEarn + line.energyEarn;
        totalBonus = totalBonus + line.bonus;
    });
  }
    var lastMine = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel.sort((b, a) => a.id - b.id)[0];
    var allLatMine = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel.filter(x => x.sessionId === lastMine.sessionId)
    allLatMine.forEach((line, index) => {
        mechCharge = mechCharge + line.mechCharge;
    });
  const handleReadReport = () => {
    localStorage.removeItem("updateMineData");
    if (totalEarn > 0) {
      dispatch(setCongratulation(true));
      dispatch(setCoringComplete(false));
    } else {
      dispatch(setCoreReportModal(true));
      dispatch(setCoringComplete(false));
    }
  };
  return (
    <div className={`mc-modal ${isCoringComplete ? "animate" : ""}`}>
      <img src={animation} alt="Line" />

      <div className="mc-modal-title">mining complete</div>
      <div className="mc-modal-info">
        <div className="message success-text">
          <div>
            <span>Nice work, Captain {user.userName}!</span>{" "}
          </div>
          <div>
            Your mines have yielded{" "}
            <span className="find">{totalEarn}kg of Ilyrium </span>
            <br />
                      including <span className="find">4 XXXXX(name tbc) crystals</span>.
                      {totalBonus > 0 ? <><br></br>and
                          <span className="message-green">{` ${totalBonus} Bonus.`}</span></> : ""}
                      {mechCharge > 0 ? <><br></br>Your mech is damaged by {mechCharge}%<br></br>
                          Your mech will repair 20% in next 1 hour.</> : ""}
          </div>
          <div className="highest-yield">
            Your highest yielding mine was{" "}
            {bestEnergyData.length > 0 ? (
              <span>{str[0].nodeName}</span>
            ) : (
              " none"
            )}
          </div>
        </div>
      </div>

      <div className="mc-modal-footer">
        <button
          type="button"
          className="btn btn-secondary "
          onClick={() => handleReadReport()}
        >
          View Discoveries and report
        </button>
        <button
          type="button"
          className="btn"
          onClick={() => dispatch(setCoringComplete(false))}
        >
          Close and keep mining
        </button>
      </div>
    </div>
  );
};

export default MiningCompleteModal;
