import React, { useState, useEffect } from "react";
import arrow from "../../images/icons/Arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { setCoringComplete } from "../../appSlice";

const PathwayComponent = ({ pointStyles, arrowDirection }) => (
  <div className="pathway-complete" style={pointStyles}>
    <div className="mixblend-container"></div>
    <img
      className="arrow"
      src={arrow}
      style={{
        transform: arrowDirection,
        zIndex: 999999,
      }}
      alt="Arrow"
    />
  </div>
);

const PathwayComplete = ({ path, completedTill, setCompletedTill }) => {
  // console.log("completedtill", completedTill);
  const gridSize = 32;
  const [renderedGridItems, setRenderedGridItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(completedTill);
  const dispatch = useDispatch();
  const newGridItems = [];

  useEffect(() => {
    setCurrentIndex(completedTill);
  }, []);
  useEffect(() => {
    path.forEach((line, lineIndex) => {
      line.forEach((point, pointIndex) => {
        const pointStyles = {
          position: "absolute",
          pointerEvents: "none",
          top: point.y * gridSize + 3 + "px",
          left: point.x * gridSize + 3 + "px",
          zIndex: 1,
        };

        const arrowDirection = calculateArrowDirection(point, line, pointIndex);

        const component = (
          <PathwayComponent
            key={`${lineIndex}-${pointIndex}`}
            pointStyles={pointStyles}
            arrowDirection={arrowDirection}
          />
        );

        newGridItems.push(component);
      });
    });
    setCurrentIndex(completedTill);
    setRenderedGridItems(newGridItems);
  }, [path]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < renderedGridItems.length) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        if (setCompletedTill) {
          setCompletedTill(currentIndex);
        }

        clearInterval(interval); // Stop the interval when all items are rendered
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [renderedGridItems, currentIndex]);

    const calculateArrowDirection = (point, line, pointIndex) => {
        if (pointIndex === 0 && line.length > pointIndex+1) {
      if (line[pointIndex].x > line[pointIndex + 1].x) {
        return "rotate(-90deg)";
      } else if (line[pointIndex].x < line[pointIndex + 1].x) {
        return "rotate(90deg)";
      } else if (line[pointIndex].y > line[pointIndex + 1].y) {
        return "rotate(0deg)";
      } else {
        return "rotate(180deg)";
      }
    }

    const prevPoint = line[pointIndex - 1];
    if (prevPoint) {
      if (point.x > prevPoint.x) {
        return "rotate(90deg)";
      } else if (point.x < prevPoint.x) {
        return "rotate(-90deg)";
      } else if (point.y < prevPoint.y) {
        return "rotate(0deg)";
      } else {
        return "rotate(180deg)";
      }
    }
  };

  const renderedItems = renderedGridItems.slice(completedTill, currentIndex);
  const prevRenderedItems = renderedGridItems.slice(0, completedTill);
  return (
    <div className="pathway-complete-container">
      {renderedItems}
      {prevRenderedItems}
    </div>
  );
};

export default PathwayComplete;

