import React, { useState } from "react";
import ApostelBg from "../../images/apostel-bg.jpg";
import ThumbImgApostle from "../../images/apostle-profile-1.png";
import FFHeaderLeftIcon from "../../images/icons/ff-icon-left.svg";
import FFHeaderRightIcon from "../../images/icons/ff-icon-right.svg";
import FFHeaderCenterLeftIcon from "../../images/icons/ff-icon-center-left.svg";
import FFHeaderCenterRightIcon from "../../images/icons/ff-icon-center-right.svg";
import FFBrotherHoodLogo from "../../images/icons/ff-brotherhood-logo.svg";
import { ReactSVG } from "react-svg";
import FFCloseIcon from "../../images/icons/auth-close-icon.svg";
import arrow from "../../images/arrow-up.svg";

const FactionApostles = (props) => {
  return (
    <>
      <div className={`faction-file-wrapper ${props.apostles ? "active" : ""}`}>
        <div
          className="ff-close"
          onClick={() => {
            props.setFactionSelector(true);

            props.setApostles(false);
          }}
        >
          <div className="ff-close-icon">
            <ReactSVG src={FFCloseIcon} />
          </div>
          <div className="ff-close-text">EXIT</div>
        </div>
        <div
          className="ff-bg-container"
          style={{ backgroundImage: `URL(${ApostelBg})` }}
        >
          <div className="ff-slider-wrap apostle">
            <img className="right-arrow " src={arrow} onClick={() => {}}></img>
            <img
              className="left-arrow"
              src={arrow}
              onClick={() => {
                props.setFactionSelector(false);

                props.setApostles();
                props.setFallenBrotherhood("active");
              }}
            ></img>
            <div className="ff-content-container">
              <div className="ff-thumb-img-wrap">
                <img src={ThumbImgApostle} alt="Humanx" />
              </div>

              <div className="ff-content-wrapper brotherhood">
                <div className="ff-content-header">
                  <div className="ff-header-icon left">
                    {" "}
                    <img src={FFHeaderLeftIcon} alt="FFHeaderLeftIcon" />
                  </div>

                  <div className="ff-header-icon right">
                    {" "}
                    <img src={FFHeaderRightIcon} alt="FFHeaderRightIcon" />
                  </div>
                </div>

                <div className="ff-content-wrap">
                  <div className="ff-content-left">
                    <img
                      src={FFHeaderCenterLeftIcon}
                      alt="FFHeaderCenterLeftIcon"
                    />
                  </div>
                  <div className="ff-content-detail-wrap">
                    <div className="ff-content apostle">
                      <div className="ff-logo">
                        <img src={FFBrotherHoodLogo} alt="FBrotherHood" />
                      </div>

                      <div className="ff-title">APOSTLES</div>
                      <div className="ff-m-img">
                        {" "}
                        <img src={ThumbImgApostle} alt="Humanx" />
                      </div>
                      <div className="ff-description">
                        An up and coming faction inhabiting the ancient Navajo
                        lands on the Arizona-New Mexico border. Led by the
                        exiled HumnX scientist that engineered the mineral
                        synthesis that heightens Ilyrium’s innate properties -
                        the tribe considers large areas of The Rift as its
                        sacred ancestral ground and fiercely attacks trespassers
                        belonging to the Brotherhood and HumnX alike.
                      </div>
                      <div className="ff-description">
                        The hybrids - intelligent humanoid creatures spawned by
                        the ore-induced spontaneous mutation that merges a
                        living organism with a machine - are the new force to be
                        reckoned with. Displaying features reminiscent of a
                        winged satyr, every hybrid has a unique shape and a
                        wildly unpredictable nature that makes them a formidable
                        threat to any mining expedition that ventures into The
                        Rift.
                      </div>
                      <div className="ff-list">
                        <div className="ff-list-row">
                          <div className="ff-list-title">Headquarters</div>
                          <div className="ff-list-detail">
                            The Maw (Eastern Rift - Arizona-New Mexico Border)
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Political Structure{" "}
                          </div>
                          <div className="ff-list-detail">Theocracy</div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Head of the Organization
                          </div>
                          <div className="ff-list-detail">
                            Aserak the Pale aka The Outcast, The Messiah,
                            Protector of the People
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">Major Festivals</div>
                          <div className="ff-list-detail">The Core</div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">Mechanized Units</div>
                          <div className="ff-list-detail">Yes</div>
                        </div>
                      </div>

                      <div className="ff-tagline-wrap">
                        <div className="ff-tagline">
                          "Those who make peaceful revolution impossible will
                          make violent revolution inevitable."
                        </div>
                      </div>
                      <div className="ff-commander-info">Aserak the Pale</div>
                    </div>
                  </div>
                  <div className="ff-content-right">
                    <img
                      src={FFHeaderCenterRightIcon}
                      alt="FFHeaderCenterRightIcon"
                    />
                  </div>
                </div>
                <div className="ff-content-footer">
                  <div className="ff-footer-spacing left"></div>
                  <div className="footer-btn-wrap">
                    <button
                      type="button"
                      className="btn btn-fluid"
                      onClick={() => {
                        props.setFactionSelector(true);

                        props.setApostles(false);
                      }}
                    >
                      Back
                    </button>
                    <button type="button" className="btn error btn-fluid">
                      RestricteD – coming 204
                    </button>
                  </div>
                  <div className="ff-footer-spacing right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FactionApostles;
