import React, { useEffect, useState } from "react";

const ZoomController = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const zoomLevel1 = () => {
    const zoomClass1 = document.getElementById("landscapeOuter");
    const zoomscroll = document.getElementById("zoom_scroll");
    const dragEvent = document.getElementById("react-draggable");
    zoomClass1.classList.add("disable-origin");
    zoomscroll.classList.remove("zoomlevel2");
    zoomscroll.classList.remove("zoomlevel3");
    zoomscroll.classList.add("zoomlevel1");
    const windowWidth = window.innerWidth;
    console.log(windowWidth);
    if (windowWidth > 1600) {
      zoomClass1.style.transform = "scale(1)";
    } else if (windowWidth > 1280) {
      zoomClass1.style.transform = "scale(0.9)";
    } else {
      zoomClass1.style.transform = "scale(0.8)";
    }
    dragEvent.style.transform = "translate(0px , 0px)";
    const imgEvent = document.getElementById("imageDiv");
    imgEvent.classList.add("fadeIn");
    setTimeout(() => {
      //imgEvent.classList.remove('fadeIn')
    }, 2000);
  };
  const zoomLevel2 = () => {
    const zoomClass2 = document.getElementById("landscapeOuter");
    const zoomscroll = document.getElementById("zoom_scroll");
    const dragEvent = document.getElementById("react-draggable");
    zoomClass2.classList.remove("disable-origin");
    zoomscroll.classList.remove("zoomlevel1");
    zoomscroll.classList.remove("zoomlevel3");
    zoomscroll.classList.add("zoomlevel2");
    const windowWidth = window.innerWidth;
    if (windowWidth > 1600) {
      zoomClass2.style.transform = "scale(1.5)";
    } else if (windowWidth > 1280) {
      zoomClass2.style.transform = "scale(1.35)";
    } else {
      zoomClass2.style.transform = "scale(1.2)";
    }
    dragEvent.style.transform = "translate(0px , 0px)";
    const imgEvent = document.getElementById("imageDiv");
    imgEvent.classList.add("fadeIn");
    setTimeout(() => {
      //imgEvent.classList.remove('fadeIn')
    }, 2000);
  };
  const zoomLevel3 = () => {
    const zoomClass3 = document.getElementById("landscapeOuter");
    const zoomscroll = document.getElementById("zoom_scroll");
    const dragEvent = document.getElementById("react-draggable");
    zoomClass3.classList.remove("disable-origin");
    zoomscroll.classList.remove("zoomlevel1");
    zoomscroll.classList.remove("zoomlevel2");
    zoomscroll.classList.add("zoomlevel3");
    const windowWidth = window.innerWidth;
    if (windowWidth > 1600) {
      zoomClass3.style.transform = "scale(2)";
    } else if (windowWidth > 1280) {
      zoomClass3.style.transform = "scale(1.8)";
    } else {
      zoomClass3.style.transform = "scale(1.6)";
    }
    dragEvent.style.transform = "translate(0px , 0px)";
    const imgEvent = document.getElementById("imageDiv");
    imgEvent.classList.add("fadeIn");
    setTimeout(() => {
      //imgEvent.classList.remove('fadeIn')
    }, 2000);
  };

  return (
    <div className="zoom-controller">
      <div
        className={`zoom-distance ${activeIndex === 0 ? "active" : ""}`}
        onClick={() => {
          handleClick(0);
          zoomLevel1();
        }}
        onTouchStart={() => handleClick(0)}
      >
        <span>90</span> <span className="unit">MM</span>
      </div>
      <div
        className={`zoom-distance ${activeIndex === 1 ? "active" : ""}`}
        onClick={() => {
          handleClick(1);
          zoomLevel2();
        }}
        onTouchStart={() => handleClick(1)}
      >
        <span>152</span> <span className="unit">MM</span>
      </div>
      <div
        className={`zoom-distance ${activeIndex === 2 ? "active" : ""}`}
        onClick={() => {
          handleClick(2);
          zoomLevel3();
        }}
        onTouchStart={() => handleClick(2)}
      >
        <span>200</span> <span className="unit">MM</span>
      </div>
    </div>
  );
};

export default ZoomController;
