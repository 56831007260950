import React from 'react'
import Depth1 from '../../images/icons/depth-1.svg'
import Depth2 from '../../images/icons/depth-2.svg'
import Depth3 from '../../images/icons/depth-3.svg'
const CoreDepthChart = (props) => {
    return (
        <>
            <div className='core-chart-wrap'>
                <div className='cc-top-wrap'>
                    <div className='cc-icon-wrap'>
                        <div className='cc-depth-icon'><img src={Depth1} alt='Depth' /></div>
                        <div className='cc-depth-icon'><img src={Depth2} alt='Depth' /></div>
                        <div className='cc-depth-icon'><img src={Depth3} alt='Depth' /></div>
                        <div className='cc-depth-title'>Core</div>
                    </div>
                    <div className='cc-depth-main-wrap'>
                        <div className='cc-depth-wrap'>
                            {props.coreCoordinatesList && props.coreCoordinatesList.length > 0 && props.coreCoordinatesList.map((item, index) => (
                                <div className={`cc-depth-col ${(item.result === 1) ? 'find' : ''} ${(item.depthTo === 1) ? 'depth-1' :(item.depthTo === 2) ? 'depth-2' :'depth-3'}`}>
                                <div className='cc-depth-circle'></div>
                            </div>
                            ))}

                            {/*<div className='cc-depth-col find depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-3 find'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col find depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-1'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-3'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-3 find'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                            {/*<div className='cc-depth-col depth-2'>*/}
                            {/*    <div className='cc-depth-circle'></div>*/}
                            {/*</div>*/}
                        </div>
                        <div className='cc-depth-ruler'>
                            {props.coreCoordinatesList && props.coreCoordinatesList.length > 0 && props.coreCoordinatesList.map((item, index) => (
                                <div className='cc-ruler-item find' className={`cc-ruler-item ${(item.result === 1) ? 'find' : ''}`}>{index + 1}</div>
                               
                            ))}
                            {/*<div className='cc-ruler-item find'>1</div>*/}
                            {/*<div className='cc-ruler-item'>2</div>*/}
                            {/*<div className='cc-ruler-item'>3</div>*/}
                            {/*<div className='cc-ruler-item find'>4</div>*/}
                            {/*<div className='cc-ruler-item'>5</div>*/}
                            {/*<div className='cc-ruler-item'>6</div>*/}
                            {/*<div className='cc-ruler-item find'>7</div>*/}
                            {/*<div className='cc-ruler-item'>8</div>*/}
                            {/*<div className='cc-ruler-item'>9</div>*/}
                            {/*<div className='cc-ruler-item'>10</div>*/}
                            {/*<div className='cc-ruler-item find'>11</div>*/}
                            {/*<div className='cc-ruler-item'>12</div>*/}
                            {/*<div className='cc-ruler-item'>13</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoreDepthChart