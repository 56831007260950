import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as astar from "javascript-astar/astar.js";
import GraphSearch from "./CustomHooks";
import LeftRuller from "./LeftRuller";
import TopRuller from "./TopRuller";
import BottomRuller from "./BottomRuller";
import tooltipMiningComponent from "../../images/tooltipMineComponent.svg";
import tooltipEnergy from "../../images/tooltipEnergyMeter.svg";
import tooltipLedger from "../../images/tooltipLedgerMine.svg";
import tooltipCalc from "../../images/tooltipMiningCalc.svg";
import RightRuller from "./RightRuller";
import Grid from "../../images/768-Grid-bg.svg";
import Core from "./Core";
import map from "../../images/map.png";
import RestrictedGrid from "./RestrictedGrid";
import MiningComponent from "./MiningComponent";
import GridHover from "./GridHover";
import GridSelector from "./GridSelector";
import tooltipClose from "../../images/icons/tooltipClose.svg";
import tooltipArrow from "../../images/icons/tooltipArrow.svg";
import Loader from "../../Loader";
import GridPathway from "./GridPathwayMining";
import CoringComponent from "./CoringComponent";
import PathwayComplete from "./PathwayComplete";
import CoreService from "../helper/Coreservice";
import AuthService, { BASE_URL } from "../helper/Authservice";
import { useDispatch, useSelector } from "react-redux";
import {
    addToSelectedGrid,
    setSelectedGrid,
    setGridSelectors,
    setIsReady,
    setPathSelect,
    setGridSelectorsArray,
    setPathSelectTemp,
    setSessionTime,
    setTooltipMining,
    setShowError,
    setMechEnergy,
    remainMechEnergy,
    setEnergyLevel,
    setRemainMechEnergy,
    setShowWarning,
    setRisk,
    setFactors,
    setIsButtonClick
} from "../../appSlice";
import MineCursor from "../mine/MineCursor";
import MineErrorModal from "./MineErrorModal";
import AnimatedButton from "../animatedButton/AnimatedButton";
import WarningPopup from "../warning/WarningPopup";
import Snackbar from "../snackbars/Snackbar";
var lastSelectedNode;
var isPageLoad = false;
var $grid = '<div id="search_grid">Loading grid...</div>';
var opts = {
    wallFrequency: ".1",
    gridSize: "24",
    debug: false,
    diagonal: false,
    closest: false,
};
const MainGrid = () => {
    const gridSize = 32;
    const {
        selectedGrid,
        mapData,
        pathSelect,
        depthSelect,
        gridSelectors,
        gridSelectorsArray,
        pathSelectTemp,
        sessionTime,
        tooltipMining,
        energyLevel,
        showError,
        mechEnergy,
        remainMechEnergy,
        isButtonClick
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const coreservices = {
        coreService: new CoreService(),
    };
    const services = {
        authService: new AuthService(),
    };
    const [user, setUser] = useState(services.authService._CurrentUser());
    //const [showWarning, setShowWarning] = useState(false);
    const [lastId, setLastId] = useState(0);
    //const [riskLevel, setRiskLevel] = useState("high");
    const [mechDamage, setMechDamage] = useState("40");

    //const mapId = useParams();
    //const [userBalance, setUserBalance] = useState(0);
    //const [mapDetailList, setMapDetailList] = useState([]);
    const [gridItems, setGridItems] = useState([]);
    //const [count, setCount] = useState(0);
    const [pathCoordinate, setPathCoordinate] = useState([]);
    const [pathCoordinateComplete, setPathCoordinateComplete] = useState([]);
    const [cursorLocation, setCursorLocation] = useState(null);
    const [dropLocation, setDropLocation] = useState(null);
    const [dropLocation2, setDropLocation2] = useState(null);
    const [expanded, setExpanded] = useState(false);
    //const [factors, setFactors] = useState([{ f1: "high" }, { f2: "low" }]);
    const [closeHovered, setCloseHovered] = useState(false);
    //var obj = { start: 0, end: 0, depth: 1, arr: [] };
    //const [showCursor, setShowCursor] = useState(false);
    let lastTap = null;
    let pathCoordinates = [];
    let pathCoordinatesTemp = [];
    const [gridActual, setGridActual] = useState([]);
    //const [gridSelectorsArray, setGridSelectorsArray] = useState([]);

    const [isSelecting, setIsSelecting] = useState(false);
    const [selectionStart, setSelectionStart] = useState(null);
    const [selectionEnd, setSelectionEnd] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [fadeOut3, setFadeOut3] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 450);
    if (!showError && isClosed) {
        setIsClosed(false);
    }
    useEffect(() => {
        if (fadeOut3) {
            setTimeout(() => {
                updateMiningToolTipComplete();
            }, 1000);
        }
    }, [fadeOut3]);

    const updateMiningToolTipComplete = () => {
        updateImage("updateMineTripComplete");
        coreservices.coreService.saveDataEntireGridTemp(null);
        dispatch(setTooltipMining(0));
        dispatch(setGridSelectors([]));
        dispatch(setSelectedGrid([]));
        dispatch(setGridSelectorsArray([]));
        coreservices.coreService.saveMineTempData(null);
        dispatch(setIsReady(false));
    };
    const updateImage = async (action) => {
        if (action != "") {
            let response2 = await fetch(BASE_URL + "/Account/updateUserImage", {
                method: "POST",
                body: JSON.stringify({
                    userId: user.userId,
                    action: action,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response2.json();
            if (response2.status === 200) {
                if (data.isSuccess) {
                    //toast.success(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    services.authService.setUser(data.data);
                    //window.location.reload();
                    setUser(services.authService._CurrentUser());
                } else {
                    //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                }
            } else {
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        }
    };
    useEffect(() => {
        // Handle resize event
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 450);
        };

        // Attach resize listener
        window.addEventListener("resize", handleResize);

        // Clean up
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const publicLayout = document.querySelector(".main-wrapper");
        const mapContainer = document.querySelector(".grid-wrap-border");
        const miningMonitor = document.querySelector(".monitor-border-mine");
        const miningComponent = document.querySelector(".mining-component-border");
        const miningCalculator = document.querySelector(
            ".mining-calculator-border"
        );
        const miningLedger = document.querySelector(".tt-rtm");
        let relativeX;
        let relativeY;
        let miningX;
        let miningY;
        let containerX;
        let containerY;
        let miningCalcX;
        let miningCalcY;
        let miningMonitorX;
        let miningMonitorY;
        let miningLedgerX;
        let miningLedgerY;

        if (publicLayout && mapContainer) {
            const publicLayoutRect = publicLayout.getBoundingClientRect();
            const mapContainerRect = mapContainer.getBoundingClientRect();

            relativeX = mapContainerRect.left;
            relativeY = mapContainerRect.top;
        }

        if (miningComponent) {
            const miningComponentRect = miningComponent.getBoundingClientRect();
            miningX = miningComponentRect.left;
            miningY = miningComponentRect.top;
        }

        if (miningMonitor) {
            const miningMonitorRect = miningMonitor.getBoundingClientRect();
            miningMonitorX = miningMonitorRect.right;
            miningMonitorY = miningMonitorRect.bottom;
        }

        if (miningLedger) {
            const miningLedgerRect = miningLedger.getBoundingClientRect();
            miningLedgerX = miningLedgerRect.right;
            miningLedgerY = miningLedgerRect.bottom;
        }

        if (miningCalculator) {
            const miningCalculatorRect = miningCalculator.getBoundingClientRect();
            miningCalcX = miningCalculatorRect.left;
            miningCalcY = miningCalculatorRect.top;
        }

        let tooltipContainer = document.querySelector(".tooltip-mining");
        if (!tooltipContainer) return;

        const parentContainer = document.querySelector(".main-wrapper");
        if (!parentContainer) return;

        const container = tooltipContainer.parentElement;

        if (container) {
            const containerRect = container.getBoundingClientRect();
            containerX = containerRect.left;
            containerY = containerRect.top;
        }
        const parentHeight = parentContainer.offsetHeight;
        const parentWidth = parentContainer.offsetWidth;

        const tooltipHeight = tooltipContainer.offsetHeight;
        const tooltipWidth = tooltipContainer.offsetWidth;

        if (tooltipMining == 2) {
            var allNode = [];
            allNode.push({
                selectionStart: { x: 7, y: 2 },
                selectionEnd: { x: 8, y: 3 },
                Depth: "0",
            });
            allNode.push({
                selectionStart: { x: 4, y: 12 },
                selectionEnd: { x: 8, y: 16 },
                Depth: "2",
            });
            allNode.push({
                selectionStart: { x: 17, y: 8 },
                selectionEnd: { x: 18, y: 11 },
                Depth: "1",
            });
            allNode.push({
                selectionStart: { x: 17, y: 17 },
                selectionEnd: { x: 21, y: 20 },
                Depth: "0",
            });
            handleTempOnSelectArea(allNode);
        }
        console.log(miningMonitorX, miningMonitorY, containerX, containerY);
        if (tooltipMining === 1) {
            tooltipContainer.style.top = `120px`;
            tooltipContainer.style.left = `50px`;
        } else if (tooltipMining === 2) {
            const top = miningCalcY - containerY - 180; // 50px from the bottom
            const left = miningCalcX - containerX - 480; // 229px from the right
            tooltipContainer.style.top = `${top}px`;
            tooltipContainer.style.left = `${left}px`;
        } else if (tooltipMining === 3) {
            const top = miningMonitorY - containerY - 100; // 50px from the bottom
            const left = miningMonitorX - containerX + 40; // 229px from the right
            tooltipContainer.style.top = `${top}px`;
            tooltipContainer.style.left = `${left}px`;
        } else if (tooltipMining === 4) {
            const top = miningLedgerY - containerY - 400; // 50px from the bottom
            const left = miningLedgerX - containerX + 40; // 229px from the right
            tooltipContainer.style.top = `${top}px`;
            tooltipContainer.style.left = `${left}px`;
        }
    }, [tooltipMining]);

    useEffect(() => {
        setShowLoader(true);
        const img = new Image();
        img.src = map;
        img.onload = () => {
            setShowLoader(false);
            if (user?.isMineTripComplete == false && fadeOut3 == false) {
                if (tooltipMining == 0) {
                    dispatch(setTooltipMining(1));
                }
            } else {
                if (tooltipMining != 0) {
                    dispatch(setTooltipMining(0));
                }
            }
        };
    }, []);

    const handleCursorMouseDown = (event) => {
        if (localStorage.getItem("isCoreStart") == "true") {
            //alert("Deploy mine uder process. Try after deploy all mines");
            return;
        } else {
            console.log(dropLocation2);
            setIsSelecting(true);
            setCursorLocation(null);
            setSelectionStart({ x: dropLocation2.x, y: dropLocation2.y });
            console.log(selectionStart);
        }
    };

    const handleMouseDown = (event) => {
        if (localStorage.getItem("isCoreStart") == "true") {
            //alert("Deploy mine uder process. Try after deploy all mines");
            return;
        } else {
            if (!closeHovered) {
                console.log(
                    "drop 1",
                    dropLocation,
                    "drop 2",
                    dropLocation2,
                    "cursor",
                    cursorLocation,
                    "depth",
                    coreservices.coreService.getDepth(),
                    "expanded",
                    expanded
                );
                console.log("clicked");
                if (dropLocation2) {
                    setDropLocation(null);

                    coreservices.coreService.setDepth("-1");
                    setDropLocation2(null);
                }
                if (coreservices.coreService.getDepth() == "-1") {
                    if (!expanded && !dropLocation2) {
                        console.log("opening");
                        setExpanded(true);
                        setDropLocation({ x: cursorLocation.x, y: cursorLocation.y });
                    } else {
                        setExpanded(false);
                        setDropLocation(null);
                    }
                } else {
                    console.log(coreservices.coreService.getDepth());
                    setDropLocation2({ x: cursorLocation.x, y: cursorLocation.y });
                }
            }
        }
    };

    const handleMouseMove = (event) => {
        if (localStorage.getItem("isCoreStart") == "true") {
            //alert("Deploy mine uder process. Try after deploy all mines");
            return;
        } else {
            const containerRect = event.currentTarget.getBoundingClientRect();
            let x = Math.floor((event.clientX - containerRect.x) / gridSize);
            let y = Math.floor((event.clientY - containerRect.y) / gridSize);

            // Constrain x and y to be between 0 and 24
            x = Math.min(Math.max(0, x), 23);
            y = Math.min(Math.max(0, y), 23);

            if (isSelecting) {
                setDropLocation2(null);
                setSelectionEnd({ x, y });
            } else {
                setCursorLocation({ x, y });
            }
            console.log(selectionStart, selectionEnd);
        }
    };

    const handleMouseUp = () => {
        if (coreservices.coreService.getDepth() == "-1") {
            //alert("Select depth first.")
            return;
        }
        if (localStorage.getItem("isCoreStart") == "true") {
            //alert("Deploy mine uder process. Try after deploy all mines");
            return;
        }

        if (selectionStart && selectionEnd) {
            const selectionStartTemp = {
                x: Math.min(selectionStart.x, selectionEnd.x),
                y: Math.min(selectionStart.y, selectionEnd.y),
            };
            const selectionEndTemp = {
                x: Math.max(selectionStart.x, selectionEnd.x),
                y: Math.max(selectionStart.y, selectionEnd.y),
            };
            handleOnSelectArea(selectionStartTemp, selectionEndTemp);
        }
        setIsSelecting(false);
        setSelectionStart(null);
        setSelectionEnd(null);
        setDropLocation2(null);
    };
    const handleOnSelectArea = (selectionStart, selectionEnd) => {
        if (selectionStart && selectionEnd) {
            if (sessionTime == null) {
                dispatch(setSessionTime(new Date().toISOString()));
            }
            var startX = selectionStart.x;
            var endX = selectionEnd.x;
            var startY = selectionStart.y;
            var endY = selectionEnd.y;
            var arr = [];
            var isRestricted = false;
            var restrictedGrid = 0;
            var weather = 0; var terrain = 0; var faction = 0; var bonus = 0; var totalwtAverage = 0; var avgWtAvg = 0;
            if ((startX === endX && startY === endY) === false) {
                var m = 0;
                var n = 0;
                for (var i = startX; i <= endX; i++) {
                    n = 0;
                    for (var j = startY; j <= endY; j++) {
                        var minGrid = mapData.mapDetailList.find(
                            (x) => x.rowNo == startY + n + 1 && x.colNo == startX + m + 1
                        );
                        if (minGrid.isRestricted) {
                            isRestricted = true;
                            restrictedGrid = restrictedGrid + 1;
                        }
                        weather = weather + minGrid.weather;
                        terrain = terrain + minGrid.terrain;
                        faction = faction + minGrid.faction;
                        bonus = bonus + minGrid.bonus;
                        var wtAverage = 0;
                        if (minGrid.weather == 3 && minGrid.terrain == 3 && minGrid.faction == 3) {
                            wtAverage = 3;
                        }
                        else if (minGrid.weather == 2 && minGrid.terrain == 3 && minGrid.faction == 3) {
                            wtAverage = 2.70;
                        }
                        else if (minGrid.weather == 1 && minGrid.terrain == 1 && minGrid.faction == 3) {
                            wtAverage = 1.90;
                        }
                        else if (minGrid.weather == 3 && minGrid.terrain == 2 && minGrid.faction == 2) {
                            wtAverage = 2.30;
                        }
                        else if (minGrid.weather == 3 && minGrid.terrain == 3 && minGrid.faction == 1) {
                            wtAverage = 2.10;
                        }
                        else if (minGrid.weather == 1 && minGrid.terrain == 1 && minGrid.faction == 1) {
                            wtAverage = 1.0;
                        }
                        else if (minGrid.weather == 3 && minGrid.terrain == 2 && minGrid.faction == 3) {
                            wtAverage = 2.75;
                        }
                        else if (minGrid.weather == 3 && minGrid.terrain == 3 && minGrid.faction == 1) {
                            wtAverage = 2.10;
                        }
                        else {
                            wtAverage = ((minGrid.weather + minGrid.terrain + minGrid.faction) / 3).toFixed(2);
                        }
                        totalwtAverage = totalwtAverage + parseFloat(wtAverage);
                        arr.push(minGrid);
                        //}
                        n++;
                    }
                    m++;
                }
                var average = parseFloat((totalwtAverage / arr.length).toFixed(1));
                var avgBonus = parseFloat((bonus / arr.length).toFixed(2));
                var avgWeather = parseFloat((weather / arr.length).toFixed(2));
                var avgTerrain = parseFloat((terrain / arr.length).toFixed(2));
                var avgFaction = parseFloat((faction / arr.length).toFixed(2));
                if (average > 0 && average <= 1) {
                    avgWtAvg = 10;
                }
                else if (average > 1 && average <= 1.5) {
                    avgWtAvg = 10;
                }
                else if (average > 1.5 && average <= 2) {
                    avgWtAvg = 20;
                }
                else if (average > 2 && average <= 2.5) {
                    avgWtAvg = 30;
                }
                else if (average > 2.5 && average <= 3) {
                    avgWtAvg = 40;
                }
                var avgWtBonus = 0;
                if (avgBonus > 0 && avgBonus <= 0.19) {
                    avgWtBonus = 5;
                }
                else if (avgBonus > 0.19 && avgBonus <= 0.39) {
                    avgWtBonus = 15;
                }
                else if (avgBonus > 0.39 && avgBonus <= 0.59) {
                    avgWtBonus = 30;
                }
                else if (avgBonus > 0.59 && avgBonus <= 0.79) {
                    avgWtBonus = 50;
                }
                else if (avgBonus > 0.79 && avgBonus <= 1) {
                    avgWtBonus = 100;
                }

                var result = arr.filter((o) => o.result < 0);
                //var depthh = (result != null && result.length > 0) ? result[0].depthTo : 1;
                var depthh = Number(coreservices.coreService.getDepth()) + 1;
                var startNode = mapData.mapDetailList.find(
                    (x) =>
                        x.rowNo == selectionStart.y + 1 && x.colNo == selectionStart.x + 1
                );
                const gridTemp = JSON.parse(JSON.stringify(gridSelectors));
                var idd = gridSelectors.length;
                gridTemp.push({
                    depth: depthh,
                    start: selectionStart,
                    end: selectionEnd,
                    totalNode: arr.length,
                    nodeName: startNode.coreNo,
                    result: 3,
                    depth1: 1,
                    id: idd + 1,
                    result: 3,
                    energyEarn: 0,
                    isRestricted: isRestricted,
                    restrictedGrid: restrictedGrid,
                    bonusPercentage: avgWtBonus,
                    bonus: 0,
                    mechCharge: avgWtAvg
                });
                dispatch(setGridSelectors(gridTemp));
                dispatch(setSelectedGrid(gridTemp));
                coreservices.coreService.saveDataEntireGrid(gridTemp);
                var gridTempraory = [];
                if (gridActual.length > 0) {
                    var lastGrid = gridActual[gridActual.length - 1];
                    gridTempraory.push(lastGrid);
                }
                gridTemp.forEach((line) => {
                    gridTempraory.push(line);
                });
                coreservices.coreService.saveDataEntireGridTemp(gridTempraory);
                dispatch(setIsReady(true));
                var obj = {
                    start: selectionStart,
                    end: selectionEnd,
                    depth: depthh,
                    arr: arr,
                    id: idd + 1,
                    isRestricted: isRestricted,
                    restrictedGrid: restrictedGrid,
                };
                var abc =
                    coreservices.coreService.getMineTempData() != null
                        ? coreservices.coreService.getMineTempData()
                        : [];
                abc.push(obj);
                dispatch(setGridSelectorsArray(abc));
                coreservices.coreService.saveMineTempData(abc);
                var nn = [];
                var nnR = [];
                gridActual.forEach((line) => {
                    nn.push(line);
                });
                gridTemp.forEach((line) => {
                    nn.push(line);
                });
                coreservices.coreService.getMapDataGridTemp().forEach((line) => {
                    nnR.push(line);
                });
                var response = coreservices.coreService.FinAllNodesInMineAll(nn);
                var rrPath = response.rrPath;
                pathCoordinates = response.AllNodesReal;
                var response1 = coreservices.coreService.FinAllNodesInMineAll(nnR);
                var rrPath2 = response1.rrPath;
                pathCoordinatesTemp = response.AllNodesReal;
                setPathCoordinate(rrPath2);
                UpdatePathEnergy(pathCoordinatesTemp);
                //setPathCoordinate(rrPath);
                setPathCoordinateComplete(pathCoordinatesTemp);
                var totalPath = rrPath.length;
                if (pathSelect != totalPath) {
                    dispatch(setPathSelect(totalPath));
                }
                if (pathSelectTemp != totalPath) {
                    dispatch(setPathSelectTemp(totalPath));
                }
                var mechDetail = mapData.mechDetail;
                var remainMechCharge = mechDetail.mechCharge - (mechEnergy + avgWtAvg);
                var useEnergyLevel = avgWtAvg;// + mechEnergy;
                var energyLevel = useEnergyLevel >= 30 ? "high" : (useEnergyLevel < 30 && useEnergyLevel > 10) ? "medium" : "low"
                console.log(remainMechCharge + " " + avgWtAvg + " " + mechEnergy + " " + (idd + 1) + " ")
                setMechDamage(mechEnergy + avgWtAvg);
                var f1 = avgWeather > 2 ? "high" : (avgWeather <= 2 && avgWeather > 1) ? "medium" : (avgWeather > 0 && avgWeather <= 1) ? "low" : "none";
                var f2 = avgTerrain > 2 ? "high" : (avgTerrain <= 2 && avgTerrain > 1) ? "medium" : (avgTerrain > 0 && avgTerrain <= 1) ? "low" : "none";
                var f3 = avgFaction > 2 ? "high" : (avgFaction <= 2 && avgFaction > 1) ? "medium" : (avgFaction > 0 && avgFaction <= 1) ? "low" : "none";
                var f4 = avgWtBonus > 0 ? "true" :"false";
                //setRiskLevel(energyLevel)                       
                dispatch(setFactors({ f1: f1, f2: f2, f3: f3, f4: "none", f5: f4 }));
                dispatch(setRisk(energyLevel));
                dispatch(setRemainMechEnergy(remainMechCharge));
                dispatch(setMechEnergy(mechEnergy + avgWtAvg));
                setLastId(idd + 1);
                dispatch(setShowWarning(true));
                //setShowWarning(true);
            }
            if (depthSelect < 0) {
                coreservices.coreService.setDepth("-1");
            }
        }
    };
    const handleTempOnSelectArea = (allNode) => {
        if (allNode.length > 0) {
            var gridTemp = [];
            var abc =
                coreservices.coreService.getMineTempData() != null
                    ? coreservices.coreService.getMineTempData()
                    : [];
            var idd = 0;
            allNode.forEach((nodex) => {
                idd = idd + 1;
                var selectionStart = nodex.selectionStart;
                var selectionEnd = nodex.selectionEnd;
                var startX = selectionStart.x;
                var endX = selectionEnd.x;
                var startY = selectionStart.y;
                var endY = selectionEnd.y;
                var arr = [];
                var isRestricted = false;
                var restrictedGrid = 0;
                if (
                    startX != endX &&
                    startY != endY &&
                    startX < endX &&
                    startY < endY
                ) {
                    if (startX < endX && startY < endY) {
                        var m = 0;
                        var n = 0;
                        for (var i = startX; i <= endX; i++) {
                            n = 0;
                            for (var j = startY; j <= endY; j++) {
                                var minGrid = mapData.mapDetailList.find(
                                    (x) => x.rowNo == startY + n + 1 && x.colNo == startX + m + 1
                                );
                                if (minGrid.isRestricted) {
                                    isRestricted = true;
                                    restrictedGrid = restrictedGrid + 1;
                                }
                                arr.push(minGrid);
                                //}
                                n++;
                            }
                            m++;
                        }
                    }
                    var depthh = Number(nodex.Depth) + 1;
                    var startNode = mapData.mapDetailList.find(
                        (x) =>
                            x.rowNo == selectionStart.y + 1 && x.colNo == selectionStart.x + 1
                    );
                    gridTemp.push({
                        depth: depthh,
                        start: selectionStart,
                        end: selectionEnd,
                        totalNode: arr.length,
                        nodeName: startNode.coreNo,
                        result: 3,
                        depth1: 1,
                        id: idd + 1,
                        result: 3,
                        energyEarn: 0,
                        isRestricted: isRestricted,
                        restrictedGrid: restrictedGrid,
                    });
                    var obj = {
                        start: selectionStart,
                        end: selectionEnd,
                        depth: depthh,
                        arr: arr,
                        id: idd + 1,
                        isRestricted: isRestricted,
                        restrictedGrid: restrictedGrid,
                    };
                    abc =
                        coreservices.coreService.getMineTempData() != null
                            ? coreservices.coreService.getMineTempData()
                            : [];
                    abc.push(obj);
                }
            });
            dispatch(setGridSelectors(gridTemp));
            dispatch(setSelectedGrid(gridTemp));
            coreservices.coreService.saveDataEntireGrid(gridTemp);
            //if (coreservices.coreService.getMapDataGridTemp() == undefined || coreservices.coreService.getMapDataGridTemp() == null || coreservices.coreService.getMapDataGridTemp().length==0) {
            var gridTempraory = [];
            if (gridActual.length > 0) {
                var lastGrid = gridActual[gridActual.length - 1];
                gridTempraory.push(lastGrid);
            }
            gridTemp.forEach((line) => {
                gridTempraory.push(line);
            });
            coreservices.coreService.saveDataEntireGridTemp(gridTempraory);
            //}
            dispatch(setIsReady(true));
            dispatch(setGridSelectorsArray(abc));
            coreservices.coreService.saveMineTempData(abc);
            var nn = [];
            var nnR = [];
            gridActual.forEach((line) => {
                nn.push(line);
            });
            gridTemp.forEach((line) => {
                nn.push(line);
            });
            coreservices.coreService.getMapDataGridTemp().forEach((line) => {
                nnR.push(line);
            });
            var response = coreservices.coreService.FinAllNodesInMineAll(nn);
            var rrPath = response.rrPath;
            pathCoordinates = response.AllNodesReal;
            var response1 = coreservices.coreService.FinAllNodesInMineAll(nnR);
            var rrPath2 = response1.rrPath;
            pathCoordinatesTemp = response.AllNodesReal;
            setPathCoordinate(rrPath2);
            UpdatePathEnergy(pathCoordinatesTemp);
            //setPathCoordinate(rrPath);
            setPathCoordinateComplete(pathCoordinatesTemp);
            //UpdatePathEnergy(pathCoordinates);
            //setPathCoordinate(rrPath);
            //setPathCoordinateComplete(pathCoordinates);
            var totalPath = rrPath.length;
            if (pathSelect != totalPath) {
                dispatch(setPathSelect(totalPath));
            }
        }
    };

    const handleTouchStart = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        const touch = event.touches[0];
        let x = Math.floor((touch.clientX - containerRect.x) / gridSize);
        let y = Math.floor((touch.clientY - containerRect.y) / gridSize);

        // Constrain x and y to be between 0 and 24
        x = Math.min(Math.max(0, x), 23);
        y = Math.min(Math.max(0, y), 23);

        // Get the current time
        const currentTime = new Date().getTime();

        // Calculate the time difference between this tap and the last one
        const timeDifference = currentTime - lastTap;

        // Check for double tap (timeDifference < 300ms)
        if (
            timeDifference < 300 &&
            timeDifference > 0 &&
            coreservices.coreService.getDepth() === "-1"
        ) {
            if (expanded) {
                setExpanded(false);
            } else {
                setExpanded(true);
                setDropLocation({ x, y });
            }
        } else if (coreservices.coreService.getDepth() !== "-1") {
            setIsSelecting(true);
            setCursorLocation(null);
            setSelectionStart({ x, y });
        }

        // Update the last tap time
        lastTap = currentTime;
    };

    const handleTouchMove = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        const touch = event.touches[0];
        let x = Math.floor((touch.clientX - containerRect.x) / gridSize);
        let y = Math.floor((touch.clientY - containerRect.y) / gridSize);

        // Constrain x and y to be between 0 and 24
        x = Math.min(Math.max(0, x), 23);
        y = Math.min(Math.max(0, y), 23);

        if (isSelecting) {
            setSelectionEnd({ x, y });
        } else {
            setCursorLocation({ x, y });
        }
    };

    const handleTouchEnd = () => {
        if (coreservices.coreService.getDepth() == "-1") {
            //alert("Select depth first.")
            return;
        }
        if (localStorage.getItem("isCoreStart") == "true") {
            //alert("Deploy mine uder process. Try after deploy all mines");
            return;
        }
        if (selectionStart && selectionEnd) {
            const selectionStartTemp = {
                x: Math.min(selectionStart.x, selectionEnd.x),
                y: Math.min(selectionStart.y, selectionEnd.y),
            };
            const selectionEndTemp = {
                x: Math.max(selectionStart.x, selectionEnd.x),
                y: Math.max(selectionStart.y, selectionEnd.y),
            };
            handleOnSelectArea(selectionStartTemp, selectionEndTemp);
        }
        setIsSelecting(false);
        setSelectionStart(null);
        setSelectionEnd(null);
        setDropLocation2(null);
    };

    const handleRemoveSelector = (index) => {
        //const newGridSelectors = [...gridSelectors];
        var newGridSelectors = gridSelectors;
        newGridSelectors = newGridSelectors.filter((x) => x.id != index);
        //newGridSelectors.splice(index, 1);
        dispatch(setGridSelectors(newGridSelectors));
        dispatch(setSelectedGrid(newGridSelectors));
        if (newGridSelectors == null || newGridSelectors.length == 0) {
            dispatch(setIsReady(false));
        }
        var newArr = gridSelectorsArray;
        newArr = newArr.filter((x) => x.id != index);
        dispatch(setGridSelectorsArray(newArr));
        //setGridSelectorsArray(newArr);
        coreservices.coreService.saveMineTempData(newArr);
        var tempArr = coreservices.coreService.getMapDataGridTemp();
        tempArr = tempArr.filter((x) => x.id != index);
        coreservices.coreService.saveDataEntireGridTemp(tempArr);
        coreservices.coreService.saveDataEntireGrid(newGridSelectors);
        var nn = [];
        var nnR = [];
        var totalMech = 0
        gridActual.forEach((line) => {
            nn.push(line);
        });
        newGridSelectors.forEach((line) => {
            nn.push(line);
            totalMech = totalMech + line.mechCharge;
        });
        coreservices.coreService.getMapDataGridTemp().forEach((line) => {
            nnR.push(line);
        });
        var remainMechCharge = mapData.mechDetail.mechCharge - totalMech;
        dispatch(setMechEnergy(totalMech));
        dispatch(setRemainMechEnergy(remainMechCharge));
        RemovePathEnergy(pathCoordinateComplete);
        if (nn.length > 0) {
            var response = coreservices.coreService.FinAllNodesInMineAll(nn);
            var rrPath = response.rrPath;
            pathCoordinates = response.AllNodesReal;
            var response1 = coreservices.coreService.FinAllNodesInMineAll(nnR);
            var rrPath2 = response1.rrPath;
            pathCoordinatesTemp = response.AllNodesReal;
            setPathCoordinate(rrPath2);
            UpdatePathEnergy(pathCoordinatesTemp);
            //setPathCoordinate(rrPath);
            setPathCoordinateComplete(pathCoordinatesTemp);
            var totalPath = rrPath.length;
            if (pathSelect != totalPath) {
                dispatch(setPathSelect(totalPath));
            }
            if (pathSelectTemp != totalPath) {
                dispatch(setPathSelectTemp(totalPath));
            }
        } else {
            setPathCoordinate([]);
            setPathCoordinateComplete([]);
            coreservices.coreService.saveDataEntireGridTemp(null);
            var totalPath = 0;
            if (pathSelect != totalPath) {
                dispatch(setPathSelect(totalPath));
            }
            if (pathSelectTemp != totalPath) {
                dispatch(setPathSelectTemp(totalPath));
            }
        }
    };

    //let userDigTemp = coreCoordinatesList;
    const UpdatePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodePathList[abc];
                    var pathCharge = 1;
                    //mapData != null && mapData.userBalance != null
                    //    ? mapData.userBalance.nodeCost
                    //    : 1;
                    var str =
                        "<span className='energyclass' style='background-color: white;font-size: 10px;position: absolute;width: 32px;opacity: 0.9;top:25%'>" +
                        (nodePathList.length * pathCharge).toString() +
                        "KW" +
                        "</span>";
                    const box = document.getElementById("id_" + middleNode.id);
                    if (box != null) {
                        box.insertAdjacentHTML("beforeend", str);
                    }
                }
            }
        }
    };
    const RemovePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodePathList[abc];
                    const box = document.getElementById("id_" + middleNode.id);
                    if (box != null) {
                        var paras = box.getElementsByTagName("span");
                        while (paras[0]) paras[0].parentNode.removeChild(paras[0]);
                        //for (index = paras.length - 1; index >= 0; index--) {
                        //    paras[index].parentNode.removeChild(paras[index]);
                        //}
                    }
                }
            }
        }
    };
    useEffect(() => {

        if (isButtonClick) {
            handleRemoveSelector(gridSelectors[gridSelectors.length - 1].id);
            dispatch(setIsButtonClick(false));
        }
    }, [isButtonClick]);
    useEffect(() => {
        if (
            coreservices.coreService.getMapData() != null &&
            coreservices.coreService.getMapData().mapGroupModel != null &&
            coreservices.coreService.getMapData().mapGroupModel.length > 0
        ) {
            var abc = [];
            coreservices.coreService
                .getMapData()
                .mapGroupModel.sort((a, b) => Number(a.id) - Number(b.id)).filter(x=>x.result<2)
                .forEach((line) => {
                    var allNode = coreservices.coreService
                        .getMapData()
                        .mineList.filter((x) => x.mineGridId == line.id);
                    abc.push({
                        depth: line.depth,
                        start: JSON.parse(line.start),
                        end: JSON.parse(line.end),
                        totalNode: line.totalNode,
                        nodeName: line.nodeName,
                        result: line.result,
                        depth1: line.depth,
                        id: line.id,
                        energyEarn: line.energyEarn,
                        allNode: allNode,
                        isRestricted: line.isRestricted,
                    });
                    //abc.push({ start: JSON.parse(line.start), end: JSON.parse(line.end), depth: line.depth });
                });
            setGridActual(abc);
        }
        var nn = [];
        var nnR = [];
        gridActual.forEach((line) => {
            nn.push(line);
        });
        gridSelectors.forEach((line) => {
            nn.push(line);
        });
        if (coreservices.coreService.getMapDataGridTemp() != null) {
            coreservices.coreService.getMapDataGridTemp().forEach((line) => {
                nnR.push(line);
            });
        }
        RemovePathEnergy(pathCoordinateComplete);
        var response = coreservices.coreService.FinAllNodesInMineAll(nn);
        var rrPath = response.rrPath;
        pathCoordinates = response.AllNodesReal;
        var response1 = coreservices.coreService.FinAllNodesInMineAll(nnR);
        var rrPath2 = response1.rrPath;
        pathCoordinatesTemp = response1.AllNodesReal;
        setPathCoordinate(rrPath2);
        UpdatePathEnergy(pathCoordinatesTemp);
        setPathCoordinateComplete(pathCoordinatesTemp);
        var totalPath = rrPath.length;
        if (pathSelect != totalPath) {
            dispatch(setPathSelect(totalPath));
        }
        if (localStorage.getItem("isCoreStart") === "true") {
            //dispatch(setGridSelectors([]))
            //dispatch(setGridSelectorsArray([]))
            coreservices.coreService.saveMineTempData([]);
        }
    }, [mapData, gridSelectors]);

    useEffect(() => {
        const emptyObject = {};

        if (mapData && mapData.mapDetailList && mapData.mapDetailList.length > 0) {
            mapData.mapDetailList.forEach((item, index) => {
                const row = item.rowNo - 1;
                const col = item.colNo - 1;

                if (item.isRestricted === true) {
                    emptyObject[row * gridSize + col] = (
                        <div
                            className="grid-item restricted-box"
                            id={"id_" + item.id}
                            key={index}
                        >
                            <RestrictedGrid />
                        </div>
                    );
                } else if (item.result !== 3) {
                    emptyObject[row * gridSize + col] = (
                        <div className="grid-item" id={"id_" + item.id} key={index}>
                            <Core
                                depth={item.depthTo}
                                find={item.result}
                                activateFill={item.depth1 < 0 ? true : false}
                                id={item.id}
                                core={item.coreNo}
                            />
                        </div>
                    );
                } else {
                    emptyObject[row * gridSize + col] = (
                        <div className="grid-item" key={index} id={"id_" + item.id}></div>
                    );
                }
            });
            var pc = coreservices.coreService.getMapData();
            if (
                coreservices.coreService.getMapData() != null &&
                coreservices.coreService.getMapData().mapGroupModel != null &&
                coreservices.coreService.getMapData().mapGroupModel.length > 0
            ) {
                var abc = [];
                var npq = [];
                coreservices.coreService
                    .getMapData()
                    .mapGroupModel.sort((a, b) => Number(a.id) - Number(b.id)).filter(x => x.result < 2)
                    .forEach((line) => {
                        var allNode = coreservices.coreService
                            .getMapData()
                            .mineList.filter((x) => x.mineGridId == line.id);
                        abc.push({
                            depth: line.depth,
                            start: JSON.parse(line.start),
                            end: JSON.parse(line.end),
                            totalNode: line.totalNode,
                            nodeName: line.nodeName,
                            result: line.result,
                            depth1: line.depth,
                            id: line.id,
                            energyEarn: line.energyEarn,
                            allNode: allNode,
                            isRestricted: line.isRestricted,
                        });
                        //abc.push({ start: JSON.parse(line.start), end: JSON.parse(line.end),depth:line.depth });
                    });
                setGridActual(abc);

                var response = coreservices.coreService.FinAllNodesInMineAll(abc);
                var rrPath = response.rrPath;
                pathCoordinates = response.AllNodesReal;
                //UpdatePathEnergy(pathCoordinates);
                //setPathCoordinate(rrPath);
                setPathCoordinateComplete(pathCoordinates);
                var totalPath = rrPath.length;
                if (pathSelect != totalPath) {
                    dispatch(setPathSelect(totalPath));
                }
            }
        }

        setGridItems(emptyObject);
    }, [mapData]);
    return (
        <div className="map-container">
            {showLoader && <Loader />}
            {showError && !isClosed && (
                <MineErrorModal setShowModal={setShowError} setIsClosed={setIsClosed} />
            )}
            {/*{showWarning && (*/}
            {/*    <>*/}
            {/*        {remainMechEnergy < 0 ? <div className="reporting-modal-backdrop"></div> : ""}*/}
            {/*        <div className="popup-container" style={{ top: "200px", left: "85px" }}>*/}
            {/*            <div className="popup-frame">*/}
            {/*                <h1>*/}
            {/*                    WARNING!<br></br> {riskLevel} RISK ZONE!*/}
            {/*                </h1>*/}

            {/*                <p style={{ fontSize: "16px" }}>*/}
            {/*                    Proceed with extreme caution.<br></br>*/}
            {/*                    Mining this area will damage your mech by*/}
            {/*                    <span className="orange-text-popup">{" " + mechDamage}%</span>*/}
            {/*                </p>*/}
            {/*                {remainMechEnergy < 0? <p style={{ fontSize: "16px" }}>*/}
            {/*                    Not enough mech enrgy for mine this area.<br></br>*/}
            {/*                    Mech will filled by 20% in next 1 hour.*/}
            {/*                </p>:""}*/}
            {/*                <div style={{ display: "flex", gap: "20px" }}>*/}
            {/*                    {remainMechEnergy >= 0 ? (<AnimatedButton*/}
            {/*                        arrows={1}*/}
            {/*                        color={riskLevel == "Low" ? "green" : riskLevel == "Medium" ? "yellow" : "red"}*/}
            {/*                        handleAnimationButton={() => setShowWarning(false)}*/}
            {/*                        buttonText={" Continue "}*/}
            {/*                    />) : ""}*/}
            {/*                    <AnimatedButton*/}
            {/*                        arrows={1}*/}
            {/*                        color={"yellow"}*/}
            {/*                        handleAnimationButton={() => { handleRemoveSelector(lastId); setShowWarning(false) }}*/}
            {/*                        buttonText={" quit "}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*)}*/}

            <div className="grid-outer">
                <LeftRuller />
                <TopRuller />
                <BottomRuller />
                <RightRuller />
                <img className="map-image" src={map}></img>
                <img className="grid" src={Grid} alt="Grid" />

                {tooltipMining == 1 ? (
                    <div
                        className={`tooltip-container tooltip-mining tt-mine-component fade-in`}
                    >
                        <div className="tooltip-media">
                            <div className="tooltip-arrow-2">
                                <img src={tooltipArrow} alt="tooltip arrow"></img>
                            </div>
                            <div className="tooltip-close-button-container">
                                <div
                                    className="tt-close-button"
                                    onClick={() => setFadeOut3(true)}
                                >
                                    <img src={tooltipClose} alt="close button"></img>
                                </div>
                            </div>
                            <img src={tooltipMiningComponent} alt="depth selector"></img>
                        </div>
                        <div className="tooltip-info-wrap">
                            <div className="tooltip-info">
                                <h3>Effortless Mining: Intuitive Selection Process</h3>
                                <p>
                                    When you enter the mining module, your mouse cursor transforms
                                    into a grid-coordinate icon. Click to activate the dropdown,
                                    or choose your desired depth within the depth selector, and a
                                    draggable box will appear, making area selection a breeze!
                                </p>
                            </div>
                            <div className="tooltip-info-footer">
                                <div
                                    className="tooltip-next-button"
                                    onClick={() => dispatch(setTooltipMining(2))}
                                >
                                    <h5>Next</h5>
                                </div>
                                <div className="tooltip-pagination">
                                    <h5>1 of 4</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : tooltipMining == 2 ? (
                    <div className={`tooltip-container tooltip-mining tt-calculator `}>
                        <div className="tooltip-media align-item-end">
                            <div className="tooltip-arrow-5">
                                <img src={tooltipArrow} alt="tooltip "></img>
                            </div>
                            <div className="tooltip-close-button-container">
                                <div
                                    className="tt-close-button"
                                    onClick={() => setFadeOut3(true)}
                                >
                                    <img src={tooltipClose} alt="close button"></img>
                                </div>
                            </div>
                            <img src={tooltipCalc} alt="depth selector"></img>
                        </div>
                        <div className="tooltip-info-wrap">
                            <div className="tooltip-info">
                                <h3>Illyrium Efficiency: Mine Planner & Calculator</h3>
                                <p>
                                    Optimize your mining strategy with our Mining Calculator.
                                    Visualize your mine plans, track area, depth, and calculate
                                    illyrium energy needed between mines. Maximize your resources
                                    for peak efficiency!
                                </p>
                            </div>
                            <div className="tooltip-info-footer">
                                <div
                                    className="tooltip-next-button"
                                    onClick={() => {
                                        dispatch(setTooltipMining(3));
                                    }}
                                >
                                    <h5>Next</h5>
                                </div>
                                <div className="tooltip-pagination">
                                    <h5>2 of 4</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : tooltipMining == 3 ? (
                    <div className={`tooltip-container tooltip-mining tt-monitor `}>
                        <div className="tooltip-media ">
                            <div className="tooltip-arrow-1">
                                <img src={tooltipArrow} alt="tooltip "></img>
                            </div>
                            <div className="tooltip-close-button-container">
                                <div
                                    className="tt-close-button"
                                    onClick={() => setFadeOut3(true)}
                                >
                                    <img src={tooltipClose} alt="close button"></img>
                                </div>
                            </div>
                            <img src={tooltipEnergy} alt="depth selector"></img>
                        </div>
                        <div className="tooltip-info-wrap">
                            <div className="tooltip-info">
                                <h3>Energize Your Strategy: Track and Plan Ilyrium Usage</h3>
                                <p>
                                    Monitor your Ilyrium allocation and plan efficiently by
                                    overlaying the estimated energy required for your mining sites
                                    in red. Stay in control of your resources!
                                </p>
                            </div>
                            <div className="tooltip-info-footer">
                                <div
                                    className="tooltip-next-button"
                                    onClick={() => dispatch(setTooltipMining(4))}
                                >
                                    <h5>Next</h5>
                                </div>
                                <div className="tooltip-pagination">
                                    <h5>3 of 4</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    tooltipMining == 4 && (
                        <div
                            className={`tooltip-container tooltip-mining tt-ledger-mine ${fadeOut3 && "fade-out-custom"
                                } `}
                        >
                            <div className="tooltip-media ">
                                <div className="tooltip-arrow-1">
                                    <img src={tooltipArrow} alt="tooltip "></img>
                                </div>
                                <div className="tooltip-close-button-container">
                                    <div
                                        className="tt-close-button"
                                        onClick={() => setFadeOut3(true)}
                                    >
                                        <img src={tooltipClose} alt="close button"></img>
                                    </div>
                                </div>
                                <img src={tooltipLedger} alt="depth selector"></img>
                            </div>
                            <div className="tooltip-info-wrap">
                                <div className="tooltip-info">
                                    <h3>Mining Mastery: Track, Adjust, Succeed</h3>
                                    <p>
                                        Stay in control with our mine status panel, from 'Mines
                                        Ready' to 'Mining Complete.' If you exceed your mech's
                                        capacity, don't worry; simply delete excess mines and resize
                                        your selection to match your allocated illyrium. Achieve
                                        mining success with ease!
                                    </p>
                                </div>
                                <div className="tooltip-info-footer">
                                    <div
                                        className="tooltip-next-button"
                                        onClick={() => setFadeOut3(true)}
                                    >
                                        <h5>Close</h5>
                                    </div>
                                    <div className="tooltip-pagination">
                                        <h5>4 of 4</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}

                {expanded && dropLocation && (
                    <MiningComponent
                        y={dropLocation.y}
                        x={dropLocation.x}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        setCursorDropLocation={setDropLocation2}
                        setDropLocation={setDropLocation}
                        selectGrid={coreservices.coreService.setDepth}
                    />
                )}

                {!expanded && dropLocation2 && (
                    <MineCursor
                        startCoords={dropLocation2}
                        isSelecting={isSelecting}
                        depth={coreservices.coreService.getDepth()}
                        endCoords={dropLocation2}
                        handleCursorMouseDown={handleCursorMouseDown}
                    />
                )}
                {!expanded && selectionStart && selectionEnd && (
                    <MineCursor
                        startCoords={selectionStart}
                        endCoords={selectionEnd}
                        isSelecting={isSelecting}
                        handleMouseMove={handleMouseMove}
                        depth={coreservices.coreService.getDepth()}
                        handleMouseUp={handleMouseUp}
                    />
                )}
                <div
                    className="grid-wrap"
                    onTouchStart={isMobileView ? handleTouchStart : null}
                    onTouchMove={isMobileView ? handleTouchMove : null}
                    onTouchEnd={isMobileView ? handleTouchEnd : null}
                    onMouseDown={isMobileView ? null : handleMouseDown}
                    onMouseMove={isMobileView ? null : handleMouseMove}
                    onMouseUp={isMobileView ? null : handleMouseUp}
                    style={{
                        userSelect: "none",
                        cursor: expanded || dropLocation2 ? "pointer" : "none",
                        zIndex: 1,
                    }}
                >
                    {!closeHovered &&
                        tooltipMining != 1 &&
                        cursorLocation &&
                        !expanded &&
                        !dropLocation2 &&
                        !isMobileView && (
                            <MiningComponent
                                y={cursorLocation.y}
                                x={cursorLocation.x}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                mineNo={mapData.mapDetailList.find(
                                    (x) =>
                                        x.rowNo == cursorLocation.y && x.colNo == cursorLocation.x
                                )}
                            />
                        )}
                    {tooltipMining == 1 && !expanded && !isMobileView && (
                        <MiningComponent
                            y={6}
                            x={18}
                            setExpanded={setExpanded}
                        // mineNo={mapData.mapDetailList.find(
                        //   (x) =>
                        //     x.rowNo == cursorLocation.y && x.colNo == cursorLocation.x
                        // )}
                        />
                    )}

                    <GridPathway path={pathCoordinate} />
                    {Object.values(gridItems)}
                    {gridActual.length > 0 &&
                        gridActual
                            .filter((x) => x.allNode != null && x.allNode.length > 0)
                            .map((selector, index) => (
                                <GridHover
                                    startCoords={selector.start}
                                    endCoords={selector.end}
                                    allNode={selector.allNode}
                                    isRestricted={selector.isRestricted}
                                    depth={selector.depth}
                                    nodeName={selector.nodeName}
                                />
                            ))}
                    {gridSelectors.length > 0 &&
                        gridSelectors.map((selector, index) => (
                            <GridSelector
                                depth={selector.depth}
                                isRestricted={selector.isRestricted}
                                startCoords={selector.start}
                                endCoords={selector.end}
                                onRemove={() => {
                                    handleRemoveSelector(selector.id);
                                }}
                                setCloseHovered={setCloseHovered}
                            />
                        ))}
                    {/* {selectionStart && selectionEnd && (
            <GridSelector
              depth={Number(coreservices.coreService.getDepth()) + 1}
              startCoords={selectionStart}
              endCoords={selectionEnd}
            />
          )} */}
                </div>
            </div>
            {/* {showCursor && <CoringComponent />} */}
        </div>
    );
};

export default MainGrid;
