import React, { useState, useEffect } from "react";
import LineIcon from "../../images/icons/line-icon.svg";
import animation from "../../images/animation.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCoreReportModal, setCoringComplete } from "../../appSlice";
const CoringCompleteModal = (props) => {
  const { isCoringComplete } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const handleReadReport = () => {
    dispatch(setCoreReportModal(true));
    dispatch(setCoringComplete(false));
  };
  useEffect(() => {}, []);
  return (
    <div className={`cc-modal ${isCoringComplete ? "animate" : ""}`}>
      <div className="">
        <object type="image/svg+xml" data={animation}></object>
      </div>
      <div className="cc-modal-title">Coring complete</div>
      <div className="cc-modal-info">
        Nice job, Captain! <br />
        Check out the report for your intel.
      </div>
      <div className="cc-modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handleReadReport()}
        >
          Read report
        </button>
        <button
          type="button"
          className="btn"
          onClick={() => dispatch(setCoringComplete(false))}
        >
          Close and keep coring
        </button>
      </div>
    </div>
  );
};

export default CoringCompleteModal;
