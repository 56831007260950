import React, { useState } from "react";
import "./leftcol.css";
import Coring from "../coring/Coring";
import Inspecting from "../inspect/Inspecting";
import SidebarLeft from "../prospectnav/Prospectnav";
import Mine from "../mine/Mine";

const LeftCol = (props) => {
  const [isLeftOpen, setLeftOpen] = useState(false);
  return (
    <>
      <SidebarLeft
        isLeftOpen={isLeftOpen}
        setLeftOpen={setLeftOpen}
        id={props.id}
        pageOpen={props.pageOpen}
      />
      <div id="left-bar" className={`col left ${isLeftOpen ? "open" : ""}`}>
        {props.pageOpen == "mining" ? (
          <Mine
            isLeftOpen={isLeftOpen}
            setLeftOpen={setLeftOpen}
            deployCores={props.deployCores}
            id={props.id}
            pageOpen={props.pageOpen}
          />
        ) : props.pageOpen == "inspect" ? (
          <Inspecting
            isLeftOpen={isLeftOpen}
            setLeftOpen={setLeftOpen}
            deployCores={props.deployCores}
            id={props.id}
            pageOpen={props.pageOpen}
          />
        ) : (
          <Coring
            isLeftOpen={isLeftOpen}
            setLeftOpen={setLeftOpen}
            deployCores={props.deployCores}
            id={props.id}
            pageOpen={props.pageOpen}
          />
        )}
      </div>
    </>
  );
};

export default LeftCol;
