import React from 'react'
import TopRullerImg from '../../images/top-ruller.svg'

const TopRuller = () => {
    return (
        <>
            <div className="ruler top">
                <img src={TopRullerImg} alt='top Ruller' />
                {/* <div className="ruler-item">1</div>
                <div className="ruler-item">2</div>
                <div className="ruler-item">3</div>
                <div className="ruler-item">4</div>
                <div className="ruler-item">5</div>
                <div className="ruler-item">6</div>
                <div className="ruler-item">7</div>
                <div className="ruler-item">8</div>
                <div className="ruler-item">9</div>
                <div className="ruler-item">10</div>
                <div className="ruler-item">11</div>
                <div className="ruler-item">12</div>
                <div className="ruler-item">13</div>
                <div className="ruler-item">14</div>
                <div className="ruler-item">15</div>
                <div className="ruler-item">16</div>
                <div className="ruler-item">17</div>
                <div className="ruler-item">18</div>
                <div className="ruler-item">19</div>
                <div className="ruler-item">20</div>
                <div className="ruler-item">21</div>
                <div className="ruler-item">22</div>
                <div className="ruler-item">23</div>
                <div className="ruler-item">24</div> */}
               
            </div>
        </>
    )
}

export default TopRuller