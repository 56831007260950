import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LeftCol from "../leftcol/LeftCol";
import Map from "../map/Map";
import CoringComplete from "../coring/CoringCompleteModal";
import CoreService from "../helper/Coreservice";
import AuthService from "../helper/Authservice";
import SidebarRight from "../infonav/Infonav";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedGrid,
  setMapData,
  setIsReady,
  setShowButton,
  setShowLoading,
  setCoringComplete,
  setUserBalance,
  setUserTempBalance,
  setSessionTime,
  setCoreReportModal,
} from "../../appSlice";
var timerTime = 0;
const Coring = () => {
  const {
    selectedGrid,
    userBalance,
    userTempBalance,
    sessionTime,
    isCoreReportModal,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [isOpenMap, setOpenMap] = useState(false);
  //const [isOpenMech, setOpenMech] = useState(false);
  //const [isCoringComplete, setCoringComplete] = useState(false);
  //const [userBalance, setUserBalance] = useState(0);
  //const [mapData, setMapData] = useState(null);
  //const [depthSelect, setDepthSelect] = useState(0);
  //const [selectedGrid, setselectedGrid] = useState([]);
  //const [selectedPath, setMapPath] = useState([]);
  //const [isReady, isSetReady] = useState(false);
  //const [pathSelect, setPathSelect] = useState(0);
  //const [isShowButton, setShowButton] = useState(true);
  //const [showLoading, setShowLoading] = useState(true);
  const [pageOpen, setPageOpen] = useState("coring");

  const coreservices = {
    coreService: new CoreService(),
  };
  const authServices = {
    service: new AuthService(),
  };
  const mapId = useParams();
  const navigate = useNavigate();
  if (localStorage.getItem("isSetReport") == "active") {
    if (!isCoreReportModal) {
      dispatch(setCoreReportModal(true));
    }
  }
  //const deployCores = () => {
  //    //var depth1Grid = props.selectedGrid.filter(x => { return x.depthTo == 1 });
  //    //var depth1Price = depth1Grid * 50;
  //    //var depth2Grid = props.selectedGrid.filter(x => { return x.depthTo == 2 });
  //    //var depth2Price = depth2Grid * 100;
  //    //var depth3Grid = props.selectedGrid.filter(x => { return x.depthTo == 3 });
  //    //var depth3Price = depth3Grid * 150;
  //    if (selectedGrid.length > 0) {
  //        dispatch(setShowButton(false));
  //        dispatch(setShowLoading(false));
  //        localStorage.setItem("isCoreStart", true);
  //        dispatch(setUserTempBalance(userBalance))
  //        console.log(new Date() + " Start timer=");
  //        var stGrid = selectedGrid;
  //        var stGridDig = JSON.parse(JSON.stringify(selectedGrid)).sort(
  //            (a, b) => Number(a.digId) - Number(b.digId)
  //        );
  //        //if (stGridDig.length > 0) {
  //        //    localStorage.setItem("setId", stGridDig[0].id);
  //        //}
  //        var pathCoordinates = coreservices.coreService.FindAllNodes(stGridDig, false, true);
  //        var imageIdListTemo = [];
  //        stGridDig.forEach((line) => {
  //            imageIdListTemo.push({
  //                imageId: line.id.toString(),
  //                digId: line.digId.toString(),
  //                depth: line.depthTo.toString(),
  //            });
  //        });
  //        //   callForUpdate();
  //        //   CallInterval();
  //        coreservices.coreService.coreGridTime(imageIdListTemo, pathCoordinates).then((data) => {
  //            // const ids = [];
  //            // selectedGrid.forEach((grid) => {
  //            //   ids.push(grid.id);
  //            // });

  //            // const tempData = data.mapDetailList.filter((datum, index) => {
  //            //   return ids.includes(datum.id);
  //            // });

  //            //   tempData.forEach((data) => {
  //            //     setTimeout(()=>{},3000)
  //            // });
  //            var tt = 0;
  //            for (var i = 0; i < selectedGrid.length; i++) {
  //                setTimeout(() => {
  //                    ;
  //                    var data1 = coreservices.coreService.getMapTempData();
  //                    var ggGrid = coreservices.coreService.getMapDataGrid();
  //                    var line = ggGrid.sort((a, b) => Number(a.digId) - Number(b.digId))[0];
  //                    var bal = (line.depthTo == 1 ? coreservices.coreService.getMapTempData().userBalance.firstDepthCharge : line.depthTo == 2 ? coreservices.coreService.getMapTempData().userBalance.secondndDepthCharge : coreservices.coreService.getMapTempData().userBalance.thirdDepthCharge)
  //                    var remainBal = userBalance - bal;
  //                    //var bal = 100;// grid.depthTo == 1 ? mapData.userBalance.firstDepthCharge : grid.depthTo == 2 ? mapData.userBalance.secondndDepthCharge : thirdDepthCharge
  //                    if (userTempBalance != remainBal) {
  //                        dispatch(setUserTempBalance(remainBal));
  //                    }
  //                    data1.mapDetailList.find(x => x.id == line.id).result = 2;
  //                    data1.mapDetailList.find(x => x.id == line.id).depth1 = -1;
  //                    coreservices.coreService.getMapDataGrid().filter(n => n.id != line.id).forEach((item, inex) => {
  //                        data1.mapDetailList.find(x => x.id == item.id).result = -1;
  //                    })
  //                    var sList = ggGrid.filter(n => n.id != line.id)
  //                    updateSelectedGrid(sList);
  //                    coreservices.coreService.saveData(data1);
  //                    dispatch(setMapData(data1));
  //                }, i * 3000)
  //            }
  //        });
  //    }
  //};
  //const updateSelectedGrid = (sList) => {
  //    console.log(new Date() + " stGrid coreCoordinatesList=" + JSON.stringify(selectedGrid));
  //    if (sList == null || sList.length == 0) {
  //        if (localStorage.getItem('isCoreStart') == 'true') {
  //            setTimeout(() => {
  //                clearData();
  //    }, 1000);
  //        }
  //    }
  //    dispatch(setSelectedGrid(sList));
  //    coreservices.coreService.saveDataEntireGrid(sList);
  //};
  //const clearData = () => {
  //    localStorage.removeItem("isCoreStart");
  //    console.log(new Date() + " end timer=");
  //    dispatch(setCoringComplete(true));
  //    dispatch(setShowButton(true));
  //    dispatch(setShowLoading(true));
  //    dispatch(setSelectedGrid([]));
  //    dispatch(setIsReady(false));
  //    localStorage.setItem("isNeedStart", true);
  //    dispatch(setMapData(coreservices.coreService.getMapTempData()));
  //    dispatch(setUserBalance(coreservices.coreService.getMapData() == null ? 0 : coreservices.coreService.getMapData().userBalance.balance));
  //    dispatch(setUserTempBalance(coreservices.coreService.getMapData() == null ? 0 : coreservices.coreService.getMapData().userBalance.balance));
  //};
  const deployCores = async () => {
    if (selectedGrid.length > 0) {
      dispatch(setShowButton(false));
      dispatch(setShowLoading(false));
      localStorage.setItem("isCoreStart", true);
      dispatch(setUserTempBalance(userBalance));

      try {
        console.log(new Date() + " Start timer=");
        const stGridDig = JSON.parse(JSON.stringify(selectedGrid)).sort(
          (a, b) => Number(a.digId) - Number(b.digId)
        );
        const pathCoordinates = coreservices.coreService.FindAllNodes(
          stGridDig,
          false,
          true
        );
        const imageIdListTemo = stGridDig.map((line) => ({
          imageId: line.id.toString(),
          digId: line.digId.toString(),
          depth: line.depthTo.toString(),
        }));

        var totalTime = (new Date() - new Date(sessionTime)) / 1000;
        const sessionModel = {
          startFrom: sessionTime,
          endTo: new Date().toISOString(),
          sessionKey: authServices.service.getBearerToken(),
          totalTime: totalTime.toString(),
        };

        const data = await coreservices.coreService.coreGridTime(
          imageIdListTemo,
          pathCoordinates,
          sessionModel
        );

        for (let i = 0; i < selectedGrid.length; i++) {
          const data1 = coreservices.coreService.getMapTempData();
          const ggGrid = coreservices.coreService.getMapDataGrid();
          const line = ggGrid.sort(
            (a, b) => Number(a.digId) - Number(b.digId)
          )[0];
          const bal =
            line.depthTo === 1
              ? coreservices.coreService.getMapTempData().userBalance
                  .firstDepthCharge
              : line.depthTo === 2
              ? coreservices.coreService.getMapTempData().userBalance
                  .secondndDepthCharge
              : coreservices.coreService.getMapTempData().userBalance
                  .thirdDepthCharge;
          const remainBal = userBalance - bal;
          var ball =
            coreservices.coreService.getMapTempData().userBalance.balance;
          if (userTempBalance !== ball) {
            dispatch(setUserTempBalance(ball));
            dispatch(setUserBalance(ball));
            authServices.service.setUserBalance(ball);
          }

          data1.mapDetailList.find((x) => x.id === line.id).result = 2;
          data1.mapDetailList.find((x) => x.id === line.id).depth1 = -1;
          coreservices.coreService
            .getMapDataGrid()
            .filter((n) => n.id !== line.id)
            .forEach((item, inex) => {
              data1.mapDetailList.find((x) => x.id === item.id).result = -1;
            });
          var totalPath = coreservices.coreService
            .getMapTempData()
            .mapDetailList.filter((x) => x.result < 2)
            .find((x) => x.id === line.id);
          if (totalPath != null) {
            var pathData =
              coreservices.coreService.getMapTempData() != null
                ? coreservices.coreService
                    .getMapTempData()
                    .mapPathList.find((x) => x.idTo == totalPath.id)
                : null;
            if (pathData != null) {
              timerTime = Number(pathData.totalPathCovered);
            }
          }

          const sList = ggGrid.filter((n) => n.id !== line.id);
          updateSelectedGrid(sList);
          coreservices.coreService.saveData(data1);
          dispatch(setMapData(data1));
          timerTime = timerTime * 600;
          timerTime =
            timerTime < 4000 ? 4500 : timerTime > 8000 ? 8000 : timerTime;
          await new Promise((resolve) => setTimeout(resolve, timerTime));
        }
      } catch (error) {
        console.error("Error while deploying cores:", error);
      }
    }
  };

  const updateSelectedGrid = (sList) => {
    console.log(
      new Date() + " stGrid coreCoordinatesList=" + JSON.stringify(selectedGrid)
    );
    if (!sList || sList.length === 0) {
      if (localStorage.getItem("isCoreStart") === "true") {
        setTimeout(() => {
          clearData();
        }, timerTime);
      }
    }
    dispatch(setSelectedGrid(sList));
    dispatch(setSessionTime(null));
    coreservices.coreService.saveDataEntireGrid(sList);
  };

  const clearData = () => {
    localStorage.removeItem("isCoreStart");
    console.log(new Date() + " end timer=");
    dispatch(setCoringComplete(true));
    dispatch(setShowButton(true));
    dispatch(setShowLoading(true));
    dispatch(setSelectedGrid([]));
    dispatch(setIsReady(false));
    localStorage.setItem("isNeedStart", true);
    dispatch(setMapData(coreservices.coreService.getMapTempData()));
    dispatch(
      setUserBalance(
        coreservices.coreService.getMapData() === null
          ? 0
          : coreservices.coreService.getMapData().userBalance.balance
      )
    );
    dispatch(
      setUserTempBalance(
        coreservices.coreService.getMapData() === null
          ? 0
          : coreservices.coreService.getMapData().userBalance.balance
      )
    );
  };
  useEffect(() => {
    coreservices.coreService.getData(mapId.id).then((data) => {
      dispatch(
        setMapData(
          coreservices.coreService.getMapData() == null
            ? null
            : coreservices.coreService.getMapData()
        )
      );
      dispatch(
        setUserBalance(
          coreservices.coreService.getMapData() == null
            ? 0
            : coreservices.coreService.getMapData().userBalance.balance
        )
      );
      dispatch(
        setUserTempBalance(
          coreservices.coreService.getMapData() == null
            ? 0
            : coreservices.coreService.getMapData().userBalance.balance
        )
      );
      setOpenMap(true);
    });
  }, []);

  return (
    <>
      {isOpenMap ? (
        <LeftCol deployCores={deployCores} pageOpen={pageOpen} id={mapId.id} />
      ) : (
        ""
      )}
      {isOpenMap ? (
        <div className="coring-container">
          <Map deployCores={deployCores} pageOpen={pageOpen} />
        </div>
      ) : (
        ""
      )}

      {isOpenMap ? <SidebarRight /> : <div></div>}

      {isOpenMap ? <SidebarRight /> : <div></div>}
    </>
  );
};

export default Coring;
