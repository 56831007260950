import React from 'react'
import './progress.css'
import CoreIndicatorIcon from '../../images/icons/core-indicator-icon.svg'
import MineIndicatorIcon from '../../images/icons/mine-indicator-icon.svg'
const Progress = (props) => {
    return (
        <><div className='progress-wrap-main'>
            <div className='progress-wrap'>
                <div className='line-wrapper'>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                </div>
                {/* <div className='progress-bar'></div> */}
                <div className='progress-bar' style={{ width: ((!props.isGameRunning || props.pageOpen == "inspect") ? props.perc:(props.perc > 12 ? props.perc - 12 : props.perc)) + '%' }}>
                    <div className='progress-bar-bg'></div>
                    {(!props.isGameRunning || props.pageOpen == "inspect") ? "" : (
                        <div className='energy-indicator' style={{ left: ((props.perc3) > 12 ? (props.perc3) > (props.perc) ? (100 - props.perc3) : (props.perc - props.perc3) : (props.perc - 12)) + '%', width: (props.perc3 < 12 ? 12 : (props.perc3) > (props.perc) ? (props.perc - (100 - props.perc3)): props.perc3) + '%'}}>
                            <img src={props.pageOpen == "mining" ? MineIndicatorIcon:CoreIndicatorIcon} alt="Energy" />
                        </div>)}
                </div>
                <div className='progress-indicator' style={{ left: (props.perc) + '%' }}></div>
            </div>

        </div></>
    )
}

export default Progress