import { useParams, useNavigate } from "react-router-dom";
import nav from "../../images/icons/inspect.png"; //"./inspect.png";
import coreIcon from "../../images/icons/coring-drop.png";
import nav2 from "../../images/icons/Nav.png";

const InspectingComponent = ({
  inspect = null,
  x,
  y,
  expanded = false,
  setExpanded,
}) => {
  const mapId = useParams();
  const navigateToPage = (page) => {
    if (page == "mining") {
      window.location = "/mining/" + mapId.id;
    } else {
      window.location = "/coring/" + mapId.id;
    }
  };
  return (
    <div
      className="mining-component"
      style={{ top: `${y * 32}px`, left: `${x * 32 - 21}px` }}
      onClick={(e) => {
        // e.stopPropagation();
      }}
    >
      <div className="mining-icon-wrap">
        <div
          className={`mining-icon ${expanded ? "expanded" : ""}`}
          style={{
            background:
              expanded && !inspect?.isRestricted
                ? "var(--accents-64, #F2EF4C)"
                : expanded && inspect?.isRestricted && "#FF4D4D",
          }}
        >
          <img src={nav} alt="mine" />
        </div>
      </div>
      {!expanded ? (
        <div className="mining-content-wrap">
          <p>
            inspect
            <br />
            {inspect?.coreNo}
          </p>
        </div>
      ) : (
        <div className="inspecting-expanded-container">
          <div
            className="inspecting-expanded-wrap"
            onClick={() => {
              if (!inspect?.isRestricted) {
                navigateToPage("coring");
              }
            }}
          >
            <div className="mining-icon-wrap">
              <div className={`mining-icon`}>
                <img src={coreIcon} alt="mine" />
              </div>
            </div>
            <div className="mining-content-wrap">
              <p>core</p>
            </div>
          </div>
          <div
            className="inspecting-expanded-wrap"
            onClick={() => {
              if (!inspect?.isRestricted) {
                navigateToPage("mining");
              }
            }}
          >
            <div className="mining-icon-wrap">
              <div className="mining-icon">
                <img src={nav2} alt="mine" />
              </div>
            </div>
            <div className="mining-content-wrap">
              <p>mine</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InspectingComponent;
