import mineIcon from "../../images/icons/mine-icon-small.png";
import depth1 from "../../images/icons/Depth-1-big.png";
import depth2 from "../../images/icons/Depth-2-big.png";
import depth3 from "../../images/icons/Depth-3-big.png";
import closeButton from "../../images/icons/Close-Button.png";
import CoreService from '../helper/Coreservice'
import { useEffect, useState } from "react";
var intervalTime = 0
let currentIndex = 0;
let interval = null
const GridHover = ({ depth = 1, startCoords, endCoords, allNode, isRestricted = false, nodeName='' }) => {
    const coreservices = {
        coreService: new CoreService(),
    };
    const [busy, setBusy] = useState(0)
    const coordinates = allNode.length;
    const find = allNode;
    //const find = [
    //  0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    //  0, 0, 0, 1,
    //];
    if (localStorage.getItem('isCoreStart') === 'true') {
        intervalTime = 500;
    }
    else {
        intervalTime=0
    }

    const gridSize = 32;
    const overlayBoxStyles = {
        top: startCoords.y * gridSize + "px",
        left: startCoords.x * gridSize + "px",
        width: (endCoords.x - startCoords.x+1) * gridSize + "px",
        height: (endCoords.y - startCoords.y+1) * gridSize + "px",
        position: "absolute",
        pointerEvents: "none",
        outline: isRestricted ? "1px solid var(--status-red, #64BBEB)" : "1px solid var(--brand-white, #FFF)",
    };

    const foundStyle = {
        background: "rgba(251, 190, 94, 0.40)",
        background: "rgba(0, 255, 133, 0.40)",
    };
    const depthIcons = [depth1, depth2, depth3];
    const [renderedDivs, setRenderedDivs] = useState([]);
    useEffect(() => {
        var aaa = coreservices.coreService.getUpdateMineData()
        if ((aaa == undefined || aaa == null || aaa.length == 0 || aaa.find(x => x.startX == startCoords.x && x.endX == endCoords.x && x.startY == startCoords.y && x.endY == endCoords.y) == null)) {
            if (aaa == undefined || aaa == null) {
                aaa = [];
            }
            aaa.push({ startX: startCoords.x, endX: endCoords.x, startY: startCoords.y, endY: endCoords.y });
            coreservices.coreService.setUpdateData(aaa);
            async function runLoop() {
                for (let i = 0; i < allNode.length; i++) { // For demonstration purposes, looping 10 times
                    const div = (
                        <div
                            className={find[i]?.isRestricted ?"restricted-box":find[i]?.result == 1 ? "found-box" : "not-found-box"}
                        ></div>
                    );
                    setRenderedDivs((prevDivs) => [...prevDivs, div]);
                    await new Promise(resolve => setTimeout(resolve, intervalTime)); // Wait for 1 second (1000 ms)
                }
            }
             
            runLoop();
        }


        // Cleanup function to clear the interval when the component is unmounted
        return () => {
            clearInterval(interval);           
        };
    }, []);
    {

    }
    return (
        <div className="grid-selector" style={overlayBoxStyles}>
            <div className="gs-content-wrap">
                <div className="gs-text-content-wrap">
                    <img src={mineIcon} alt="mine-icon"></img>
                    <p>{nodeName }</p>
                </div>
                <img src={depthIcons[depth - 1]} alt="depth"></img>
            </div>
            <div className="grid-boxes">{renderedDivs}</div>
        </div>
    );
};

export default GridHover;
