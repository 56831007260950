import React from 'react'
import TopRuller from './TopRuller'
import BottomRuller from './BottomRuller'
import LeftRuller from './LeftRuller'
import RightRuller from './RightRuller'

const Rullers = () => {
  return (
    <>
    <TopRuller />
    <BottomRuller />
    <LeftRuller />
    <RightRuller />
    </>
  )
}

export default Rullers