import React, { useState, useEffect } from "react";

const ErrorIcon = ({ color }) => {
  const [opacity, setOpacity] = useState(1); // initial opacity

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity === 1 ? 0.2 : 1));
    }, 1000); // toggle every 1 second

    return () => clearInterval(interval); // clear interval on unmount
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        d="M8.71222 2.48488L1.30516 15.5897C0.72204 16.6214 1.46739 17.8988 2.65247 17.8988H17.4666C18.6517 17.8988 19.397 16.6214 18.8139 15.5897L11.4068 2.48488C10.8144 1.43674 9.30465 1.43675 8.71222 2.48488Z"
        fill={color}
        fillOpacity={opacity}
      />
      <rect
        x="9.28569"
        y="6.29163"
        width="1.54762"
        height="6.19048"
        fill={color === "#000" ? "#FA5A5A" : "#000"}
      />
      <rect
        x="9.28568"
        y="14.0297"
        width="1.54762"
        height="1.54762"
        fill={color === "#000" ? "#FA5A5A" : "#000"}
      />
    </svg>
  );
};

export default ErrorIcon;
