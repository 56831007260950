import React, { useState, useRef, useEffect } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
// import './coring.css'
import { ReactSVG } from "react-svg";
import MineResults from "./MineResults";
import Discoveries from "./Discoveries";
import MainGrid from "../mainGrid/MainGridMiningReport";
import Map from "../../images/map-inner.png";
import Frame from "../location/frame/Frame";
import WatchIcon from "../../images/icons/watch.svg";
import EnergyUnitChart from "../../images/energy-chart.svg";
import GroundCoverageIcon from "../../images/icons/ground-coverage.svg";
import MineAreaIcon from "../../images/icons/mine-area.svg";
import Close from "../../images/close-icon.svg";
import Poster from "../../images/mine-poster.png";
import Tiktok from "../../images/icons/tiktok.svg";
import Instragram from "../../images/icons/instagram.svg";
import Discord from "../../images/icons/discord.svg";
import Twitter from "../../images/icons/twitter.svg";
import ARIcon from "../../images/icons/ar-icon.svg";
import CopyIcon from "../../images/icons/copy-icon.svg";
import DepthFrameTop from "../../images/icons/depth-hover-top.svg";
import DepthFrameBottom from "../../images/icons/depth-hover-bottom.svg";
import DepthHoverLeft from "../../images/icons/depth-hover-left.svg";
import DepthHoverRight from "../../images/icons/depth-hover-right.svg";
import Thumbnail from "./Thumbnail";
import Congratulation from "../mine/Congratulation";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoreReportModal,
  setDiscovery,
  setCongratulation,
} from "../../appSlice";

const MineReport = (props) => {
  const { isCoreReportModal, isDiscovery, isCongratulation } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  //const [isDiscovery, setDiscovery] = useState(false);
  const depthIcons = [Depth1, Depth2, Depth3];
  const coreservices = {
    coreService: new CoreService(),
  };
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  const handleClick = (page) => {
    if (page == "discoveries") {
      dispatch(setDiscovery(true));
      //dispatch(setCongratulation(false))
    }
    if (page == "minereport") {
      dispatch(setDiscovery(false));
      dispatch(setCongratulation(false));
      dispatch(setCoreReportModal(true));
      //dispatch(setCongratulation(false))
    }
    if (page == "closereport") {
      if (localStorage.getItem("isSetReport") == "active") {
          localStorage.setItem("isSetReport", "");
          dispatch(setCoreReportModal(false));
        navigate("/reports");
      } else {
        props.handleCoreReportModel(false);
        props.setLeftOpen(true);
      }
    }
  };
  var str = [];
  var totalEarn = 0;

  //const style = (winWidth > 1024) ? (active === 1 ? { height: "0px" } : { height: coreResultHeight }) : (active === 1 ? { height: coreResultHeight } : { height: "0px" });
  //const coreCoordinatesList = props.mapData == null ? null : props.mapData.mapDetailList.filter(x => x.result < 3).sort((b, a) => a.digId - b.digId);
  const coreCoordinatesList =
    coreservices.coreService.getMapData() == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel == null
      ? null
      : coreservices.coreService
          .getMapData()
          .mapGroupModel.filter((x) => x.result <= 1)
          .sort((b, a) => a.energyEarn - b.energyEarn);
  var findCoordinate = coreCoordinatesList.filter((x) => x.energyEarn > 0);
  var totalEarn = 0;
  var totalMile = 0;
  var bestEnergyData =
    coreservices.coreService.getMapData() == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel;
  var str = [];
  bestEnergyData.forEach((line, index) => {
    if (str.length <= 3 && line.energyEarn > 0) {
      str.push(bestEnergyData[index]);
    }
    //totalEarn = totalEarn + line.energyEarn
    totalMile = totalMile + line.totalNode;
  });
  var totalSpend = 0;
  var bestEnergyData1 =
    coreservices.coreService.getMapData() == null
      ? null
      : coreservices.coreService.getMapData().mineList == null
      ? null
      : coreservices.coreService
          .getMapData()
          .mineList.filter((x) => x.result < 3)
          .sort((b, a) => a.energyEarn - b.energyEarn);
  bestEnergyData1.forEach((line, index) => {
    totalEarn = totalEarn + (line.energyEarn != null ? line.energyEarn : 0);
    totalSpend = totalSpend + line.energySpent;
  });
  var sessionTime =
    coreservices.coreService.getMapData() == null
      ? 0
      : secondsToHHMMSS(
          coreservices.coreService.getMapData().userBalance.sessionTimeMine
        );
  //if (coreCoordinatesList.length > 0) {
  //    isOpenOppup = true;
  //}
  function hmsToSeconds(t) {
    const [hours, minutes, seconds] = t.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  function secondsToHMS(secs) {
    return new Date(secs * 1000).toISOString().substr(11, 8);
  }
  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const mapName =
    coreservices.coreService.getMapData() != null &&
    coreservices.coreService.getMapData().imageData != null
      ? coreservices.coreService.getMapData().imageData.mapImageNo
      : "";
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = dd + "." + mm + "." + yyyy;
  var a = new Date();
  var b = new Date();
  b.setDate(b.getDate());
  b.setHours(23);
  b.setMinutes(59);
  b.setSeconds(59);
  var h = a.getHours();
  var m = a.getMinutes();
  var s = a.getSeconds();
  var h1 = b.getHours();
  var m1 = b.getMinutes();
  var s1 = b.getSeconds();
  var startTime = h + ":" + m + ":" + s;
  var endTime = h1 + ":" + m1 + ":" + s1;
  var tt = secondsToHMS(hmsToSeconds(endTime) - hmsToSeconds(startTime));
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);
  return (
    <>
      {!isDiscovery && !isCongratulation ? (
        <div className="reporting-modal-wrap">
          <div
            className="reporting-close-modal"
            onClick={() => {
              //setDiscovery(true)
              handleClick("closereport");
            }}
          >
            <ReactSVG src={Close} className="close-icon" />
          </div>

          <div className="reporting-modal-content">
            <div className="reporting-title">
              Section {mapName} mine report
              <span className="reporting-date">{formattedToday}</span>
            </div>
            <div className="report-modal-message">
              <div className="message success-text">
                <div>
                  <span>Nice work, Captain '{user.userName}'!</span>{" "}
                </div>
                <div className="message-grey">
                  Your mines have yielded{" "}
                  <span id="message-green" className="message-green">
                    {totalEarn}kg of Ilyrium{" "}
                  </span>
                  <br />
                  including
                  <span id="message-green" className="message-green">
                    4 XXXXX(name tbc) crystals.
                  </span>
                </div>
                <div className="highest-yield message-grey">
                  Your highest yielding mine was{" "}
                  {str.length > 0 ? (
                    <span className="message-white">
                      {str[0].nodeName + "-" + str[0].totalNode?.toString()}
                    </span>
                  ) : (
                    " none"
                  )}
                  {str.length > 0 ? (
                    <div className="depth-icon-wrap depth-2">
                      <div className="depth-unit">
                        <div className="depth-frame">
                          <ReactSVG
                            src={DepthFrameTop}
                            className="depth-frame-icon"
                          />
                        </div>
                        <div className="depth-unit-wrap">
                          <div className="depth-hover-corner left">
                            <img src={DepthHoverLeft} alt="Depth" />
                          </div>
                          <div className="depth-unit-text">
                            {str[0].depthTo == 1
                              ? "16m"
                              : str[0].depthTo == 2
                              ? "64m"
                              : "120m"}
                          </div>
                          <div className="depth-hover-corner right">
                            <img src={DepthHoverRight} alt="Depth" />
                          </div>
                        </div>
                        <div className="depth-frame">
                          <ReactSVG
                            src={DepthFrameBottom}
                            className="depth-frame-icon"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  deep mine spanning{" "}
                  <span className="message-white">
                    {totalMile} square miles
                  </span>{" "}
                  using <span className="message-white"> {totalSpend}kW,</span>{" "}
                  <br />
                  with a total yield of{" "}
                  <span id="message-green" className="message-green">
                    12.86 tonne
                  </span>{" "}
                  of Ilyrium, including{" "}
                  <span id="message-green" className="message-green">
                    {findCoordinate.length} mega crystal.
                  </span>
                </div>
                <div> </div>
              </div>
            </div>

            <div className="reporting-wrap-main">
              <div className="reporting-summary-wrap">
                <div className="reporting-summary-left">
                  <div className="section-title">SESSION SUMMARY</div>
                  {/* Location Start */}
                  <div className="reporting-location-title">Location</div>
                  <Frame mapName={mapName} reportMode={true} />
                  <div className="mining-reporting-left-section">
                    <div className="reporting-location-detail-wrap">
                      <div className="reporting-location-detail">
                        <div className="reporting-location-detail-title">
                          Location
                        </div>
                        <div className="reporting-location-detail-dec">
                          Eastern Rift {mapName}
                        </div>
                      </div>
                      <div className="reporting-location-detail">
                        <div className="reporting-location-detail-title">
                          Latitude
                        </div>
                        <div className="reporting-location-detail-dec">
                          -10.883&deg; N
                        </div>
                      </div>
                      <div className="reporting-location-detail">
                        <div className="reporting-location-detail-title">
                          location
                        </div>
                        <div className="reporting-location-detail-dec">
                          328.219&deg; E
                        </div>
                      </div>
                    </div>
                    {/* Session Time Start */}
                    <div className="session-time-wrap ">
                      <div className="st-detail">
                        <div className="st-detail-title">SESSION TIME</div>
                        <div className="st-wrap session-start">
                          <div className="st-icon">
                            <ReactSVG src={WatchIcon} />
                          </div>
                          <div className="st-time">{sessionTime}</div>
                        </div>
                      </div>
                      <div className="st-detail">
                        <div className="st-detail-title">Time remaining</div>
                        <div className="st-wrap session-start">
                          <div className="st-icon">
                            <ReactSVG src={WatchIcon} />
                          </div>
                          <div className="st-time">{tt}</div>
                        </div>
                      </div>
                    </div>
                    <div className="session-time-wrap">
                      <div className="st-detail">
                        <div className="st-detail-title">ground covered</div>
                        <div className="st-wrap session-process">
                          <div className="st-icon">
                            <ReactSVG src={GroundCoverageIcon} />
                          </div>
                          <div className="st-time">{totalMile}m2</div>
                        </div>
                      </div>
                      <div className="st-detail">
                        <div className="st-detail-title">mine area</div>
                        <div className="st-wrap session-process">
                          <div className="st-icon">
                            <ReactSVG src={MineAreaIcon} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <MineResults coreCoordinatesList={coreCoordinatesList} />
                    <div className="energy-usage-chart-wrap">
                      <div className="chart-title-wrap">
                        <div className="chart-title">ENERGY USE</div>
                        <div className="chart-unit eu">
                          {totalSpend} <span>kw</span>
                        </div>
                      </div>
                      <div className="chart-wrap">
                        <img src={EnergyUnitChart} alt="Energy Unit Chart" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Core Right Content */}
                <div className="reporting-summary-right">
                  <div className="section-title mine-section-title">
                    Section {mapName} Map
                  </div>
                  <div className="snap-shot">
                    {/*<img src={Map} alt="Map" />*/}
                    <MainGrid />
                  </div>
                </div>
              </div>
            </div>
            {/*            <div className="thumbnail-container">*/}
            {/*                {findCoordinate.length > 0 ? (<Thumbnail badge="gold" setDiscovery={setDiscovery} />) : ""}*/}
            {/*                {findCoordinate.length > 1 ? (<Thumbnail badge="silver" setDiscovery={setDiscovery} />) : ""}*/}
            {/*                {findCoordinate.length > 2 ? (<Thumbnail badge="bronze" setDiscovery={setDiscovery} />) : ""}*/}
            {/*                {findCoordinate.length > 3 ? (<Thumbnail badge="gold" setDiscovery={setDiscovery} />): ""}*/}
            {/*</div>*/}
          </div>
          <div className="reporting-footer">
            <div className="rf-container">
              <button
                className="btn"
                onClick={() => {
                  localStorage.setItem("isSetReport", "");
                  props.handleCoreReportModel(false);
                  props.setLeftOpen(true);
                }}
              >
                RETURN TO MAP
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  localStorage.setItem("isSetReport", "");
                  //setDiscovery(true)
                  props.handleCoreReportModel(false);
                  props.setLeftOpen(true);
                }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      ) : isDiscovery ? (
        <Discoveries
          coreCoordinatesList={coreCoordinatesList}
          handleClick={handleClick}
          id={props.id}
        />
      ) : isCongratulation ? (
        <Congratulation handleClick={handleClick} />
      ) : (
        ""
      )}
      <div className="reporting-modal-backdrop"></div>
    </>
  );
};

export default MineReport;
