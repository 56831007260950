import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "swiper/modules";
import background1 from "../../images/background-blur-1.png";
import background2 from "../../images/background-blur-2.png";
import background3 from "../../images/background-blur-3.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import closeIcon from "../../images/avatars/close-button.svg";
import "swiper/css/scrollbar";
import Thumbnail from "./Thumbnail";
import DiscoveryInspect from "./DiscoveryInspect";
import ShareModal from "./ShareModal";
import shareIcon from "../../images/icons/shareIcon.png";
import CoreService from "../helper/Coreservice";

export const Discoveries = (props) => {
  const coreservices = {
    coreService: new CoreService(),
  };
  const [activeIndex, setActiveIndex] = useState(3);
  const [shareThumbnail, setShareThumbnail] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [shareType, setshareType] = useState("");
  const thumbs = [background1, background2, background3];
  var sharetype = "";
  const handleShare = (type) => {
    setshareType(type);
    setAnimateOut(true);
    setShareThumbnail(true);
  };
  const handleClose = () => {
    setShareModal(true);
    setShareThumbnail(false);
  };
  const handleYesButtom = () => {
    setAnimateOut(true);
    setShareThumbnail(false);
    setShareModal(false);
    props.handleClick("minereport");
  };
  const moveToOtherMap = (id) => {
    if (id > 0) {
      window.location = "/mining/" + id;
    } else {
      alert("Restricetd Area");
    }
    //coreservices.coreService.getData(id);
  };
  const mapId = useParams();
  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };
  var bestEnergyData =
    coreservices.coreService.getMapData() == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel == null
      ? null
      : coreservices.coreService.getMapData().mapGroupModel;
  var str = [];
  var maxDepth = [];

  bestEnergyData.forEach((line, index) => {
    if (line.energyEarn > 0) {
      str.push(bestEnergyData[index]);
    }
    if (maxDepth.find((x) => x == line.depthTo) == null) {
      maxDepth.push(line.depthTo);
    }
    //totalMile = totalMile + line.totalNode
  });
  return (
    <>
      {!shareThumbnail ? (
        <div className="discoveries-container">
          <img src={thumbs[activeIndex % 3]}></img>
          <div className="discoveries-wrap">
            <div
              className="mech-inspect-close-button"
              onClick={() => {
                handleClose();
              }}
            >
              <img src={closeIcon} alt="close"></img>
            </div>
            {shareModal ? (
              <ShareModal
                handleYesButtom={handleYesButtom}
                setShareModal={setShareModal}
              />
            ) : (
              <>
                <h2>Your Discoveries</h2>
                <div className="slider-container">
                  <Swiper
                    slidesPerView={1}
                    centeredSlides={false}
                    //   navigation={{ clickable: true }}
                    loop={true}
                  >
                    {maxDepth.find((x) => x == 3) != null ? (
                      <SwiperSlide
                        className={`swiper-item ${
                          1 === activeIndex ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(1)}
                      >
                        <div className="slide-wrap">
                          <div
                            className="gallery-element"
                            onClick={() => {
                              handleShare("gold");
                            }}
                          >
                            <Thumbnail badge="gold" />
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )}
                    {maxDepth.find((x) => x == 2) != null ? (
                      <SwiperSlide
                        className={`swiper-item ${
                          2 === activeIndex ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(2)}
                      >
                        <div className="slide-wrap">
                          <div
                            className="gallery-element"
                            onClick={() => {
                              handleShare("silver");
                            }}
                          >
                            <Thumbnail badge="silver" />
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )}
                    {maxDepth.find((x) => x == 1) != null ? (
                      <SwiperSlide
                        className={`swiper-item ${
                          3 === activeIndex ? "active" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter(3)}
                      >
                        <div className="slide-wrap">
                          <div
                            className="gallery-element"
                            onClick={() => handleShare("bronze")}
                          >
                            <Thumbnail badge="bronze" />
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )}
                    {/*<SwiperSlide*/}
                    {/*    className={`swiper-item ${4 === activeIndex ? "active" : ""}`}*/}
                    {/*    onMouseEnter={() => handleMouseEnter(4)}*/}
                    {/*    onClick={() => { setAnimateOut(true); setShareThumbnail(true) }}*/}
                    {/*>*/}
                    {/*    <div className="slide-wrap">*/}
                    {/*        <div className="gallery-element">*/}
                    {/*            <Thumbnail badge="gold" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                  </Swiper>
                </div>
                <div className="discoveries-footer">
                  <div className="footer-text-wrap">
                    <p>
                      Charge crystal by{" "}
                      <span className="message-green">23,000kW</span> extra by
                      sharing
                    </p>
                  </div>
                  <div
                    className="white-button"
                    onClick={() => {
                      handleShare(
                        activeIndex == 1
                          ? "gold"
                          : activeIndex == 2
                          ? "silver"
                          : "bronze"
                      );
                    }}
                  >
                    <h5>share</h5>
                    <img src={shareIcon}></img>
                  </div>
                  <div
                    className="dark-button"
                    onClick={() => props.handleClick("minereport")}
                  >
                    <h5>Skip this, I don't want to earn ilyriym</h5>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : shareThumbnail ? (
        <DiscoveryInspect
          handleYesButtom={handleYesButtom}
          handleClick={props.handleClick}
          handleClose={handleClose}
          setAnimateOut={setAnimateOut}
          animateOut={animateOut}
          sharetype={shareType}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Discoveries;
