import React, { useState } from "react";
import HumanXBg from "../../images/humanx-bg.jpg";
import ThumbImgHumanx from "../../images/brotherhood-thumb-humanx.png";
import FFHeaderLeftIcon from "../../images/icons/ff-icon-left.svg";
import FFHeaderRightIcon from "../../images/icons/ff-icon-right.svg";
import FFHeaderCenterLeftIcon from "../../images/icons/ff-icon-center-left.svg";
import FFHeaderCenterRightIcon from "../../images/icons/ff-icon-center-right.svg";
import FFBrotherHoodLogo from "../../images/icons/ff-brotherhood-logo.svg";
import { ReactSVG } from "react-svg";
import FFCloseIcon from "../../images/icons/auth-close-icon.svg";
import FactionConfirmation from "./FactionConfirmation";
import arrow from "../../images/arrow-up.svg";

const FactionHumanx = (props) => {
  const [confirmFaction, setConfirmFaction] = useState();

  const handleConfirm = () => {
    setConfirmFaction("active");
  };
  const handleComfirmClose = () => {
    setConfirmFaction();
  };
  return (
    <>
      <div className={`faction-file-wrapper ${props.humanx ? "active" : ""}`}>
        <div
          className="ff-close"
          onClick={() => {
            props.setFactionSelector(true);

            props.setHumanx(false);
          }}
        >
          <div className="ff-close-icon">
            <ReactSVG src={FFCloseIcon} />
          </div>
          <div className="ff-close-text">EXIT</div>
        </div>
        <div
          className="ff-bg-container"
          style={{ backgroundImage: `URL(${HumanXBg})` }}
        >
          <div className="ff-slider-wrap humanx">
            <img
              className="right-arrow "
              src={arrow}
              onClick={() => {
                props.setFactionSelector(false);

                props.setHumanx();
                props.setFallenBrotherhood("active");
              }}
            ></img>
            <img className="left-arrow" src={arrow} onClick={() => {}}></img>
            <div className="ff-content-container">
              <div className="ff-thumb-img-wrap">
                <img src={ThumbImgHumanx} alt="Humanx" />
              </div>

              <div className="ff-content-wrapper humanx">
                <div className="ff-content-header">
                  <div className="ff-header-icon left">
                    {" "}
                    <img src={FFHeaderLeftIcon} alt="FFHeaderLeftIcon" />
                  </div>

                  <div className="ff-header-icon right">
                    {" "}
                    <img src={FFHeaderRightIcon} alt="FFHeaderRightIcon" />
                  </div>
                </div>

                <div className="ff-content-wrap">
                  <div className="ff-content-left">
                    <img
                      src={FFHeaderCenterLeftIcon}
                      alt="FFHeaderCenterLeftIcon"
                    />
                  </div>
                  <div className="ff-content-detail-wrap">
                    <div className="ff-content humanx">
                      <div className="ff-logo">
                        <img src={FFBrotherHoodLogo} alt="FBrotherHood" />
                      </div>
                      <div className="ff-title">HUMANX</div>
                      <div className="ff-m-img">
                        {" "}
                        <img src={ThumbImgHumanx} alt="Humanx" />
                      </div>
                      <div className="ff-description">
                        Incredibly prosperous and influential before the onset
                        of The Great Mech War, the HumnX corporation is today a
                        shell of its former self. And yet, it remains the
                        largest, richest organization on the continent and quite
                        possibly in the world.  
                      </div>
                      <div className="ff-description">
                        With headquarters underneath the ruins of Las Vegas, the
                        corporation is run by the conniving Council of Five, an
                        inner circle composed by the patriarchs of the five most
                        prominent merchant houses and noble families. They
                        strive to achieve complete control over the Rift to
                        enforce a monopoly on any form of Ilyrium commerce.
                      </div>
                      <div className="ff-list">
                        <div className="ff-list-row">
                          <div className="ff-list-title">Headquarters</div>
                          <div className="ff-list-detail">
                            UNIX - Las Vegas Underground/Flood Control Tunnels
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Political Structure{" "}
                          </div>
                          <div className="ff-list-detail">
                            Oligarchy. In time of war, the Council of Five, the
                            heads of the five most influential merchant houses,
                            appoints a supreme war commander.
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Head of the Organization
                          </div>
                          <div className="ff-list-detail">
                            General Ron "Falco" Simonsen, Council of Five
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">Major Festivals</div>
                          <div className="ff-list-detail">
                            Midwinter, Solar Eclipses
                          </div>
                        </div>

                        <div className="ff-list-row">
                          <div className="ff-list-title">Mechanized Units</div>
                          <div className="ff-list-detail">
                            Plenty. Limited number of aerial units. Large amount
                            of land units
                          </div>
                        </div>
                      </div>

                      <div className="ff-tagline-wrap">
                        <div className="ff-tagline">
                          "Benevolence is just a weak man's pity"
                        </div>
                      </div>
                      <div className="ff-commander-info">
                        General Ron "Falco" Simonsen, War Commander of the HumnX
                        Armies
                      </div>
                    </div>
                  </div>
                  <div className="ff-content-right">
                    <img
                      src={FFHeaderCenterRightIcon}
                      alt="FFHeaderCenterRightIcon"
                    />
                  </div>
                </div>
                <div className="ff-content-footer">
                  <div className="ff-footer-spacing left"></div>
                  <div className="footer-btn-wrap">
                    <button
                      type="button"
                      className="btn btn-fluid"
                      onClick={() => {
                        props.setFactionSelector(true);

                        props.setHumanx(false);
                      }}
                    >
                      Back
                    </button>
                    <button type="button" className="btn btn-fluid error">
                      RestricteD – coming 204
                    </button>
                  </div>
                  <div className="ff-footer-spacing right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FactionHumanx;
