import React from "react";
import "./sidebarLeft.css";
import ArrowDown from "../../images/icons/down-arrow.svg";
import CoreIcon from "../../images/icons/core-icon-m.svg";
import ArrowLeft from "../../images/icons/arrow-left-sidebar.svg";
import { useDispatch, useSelector } from "react-redux";

import ArrowRight from "../../images/icons/arrow-right-sidebar.svg";
// import BackIcon from '../../images/icons/back-arrow.svg'
const SidebarLeft = (props) => {
  const { tooltipInspecting } = useSelector((state) => state.app);
  const handelOpen = () => {
    props.setLeftOpen(!props.isLeftOpen);
  };
  const handelNavigate = (pageName) => {
    if (pageName == "mining") {
      window.location = "/mining/" + props.id;
    } else if (pageName == "inspect") {
      window.location = "/inspect/" + props.id;
    } else {
      window.location = "/coring/" + props.id;
    }
  };

  return (
    <>
      {/* <div className='back-btn' onClick={()=>back()}><img src={BackIcon} alt='back' />Back</div> */}
      <div className="sidebar left">
        <ul className={`${tooltipInspecting === 5 && "global-border fade-in"}`}>
          <li className="down-arrow-wrap">
            <span>
              <img src={ArrowDown} alt="Open" />
            </span>
          </li>

          <li
            className={`${props.pageOpen == "inspect" ? "active" : ""}`}
            onClick={() => handelNavigate("inspect")}
          >
            <i className="icon-inspect"></i>
            Inspect
          </li>
          <li
            className={`${props.pageOpen == "coring" ? "active" : ""}`}
            onClick={() => handelNavigate("coring")}
          >
            <i className="icon-core"></i>
            Core
          </li>
          <li
            className={`${props.pageOpen == "mining" ? "active" : ""}`}
            onClick={() => handelNavigate("mining")}
          >
            <i className="icon-mine"></i>
            Mine
          </li>
        </ul>
      </div>
      <div className={`sidebar-left-nav ${props.isLeftOpen ? "open" : ""}`}>
        <div className="sr-icon">
          <img
            src={props.isLeftOpen ? ArrowRight : ArrowLeft}
            alt="ArrowRight"
          />
        </div>
        <div className="sr-icon core-icon" onClick={() => handelOpen()}>
          <img src={CoreIcon} alt="EnergyIcon" />
        </div>
        <div className="sr-icon">
          <img
            src={props.isLeftOpen ? ArrowRight : ArrowLeft}
            alt="ArrowRight"
          />
        </div>
      </div>
    </>
  );
};

export default SidebarLeft;
