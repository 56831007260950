import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import WatchIcon from "../../images/icons/watch.svg";
import EnergyIcon from "../../images/icons/energy.svg";
import Progress from "../progress/Progress";
import "./Monitor.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserBalance,
  setUserTempBalance,
  setUserToBeBalance,
  setEnergyLevel,
  setShowError,
} from "../../appSlice";
import bolt from "../../images/monitor/bolt.svg";
import healthIcon from "../../images/monitor/health.svg";
import wrench from "../../images/monitor/wrench.svg";
import position from "../../images/monitor/position.svg";
import energyIcon from "../../images/monitor/energyIcon.svg";
import CoreIndicatorIcon from '../../images/icons/core-indicator-icon.svg'
import MineIndicatorIcon from '../../images/icons/mine-indicator-icon.svg'
import CoreService from "../helper/Coreservice";
const Monitor = (props) => {
  const {
    mapData,
    userBalance,
    selectedGrid,
    userTempBalance,
    isReady,
    pathSelectTemp,
    tooltipMining,
    showError,
    energyLevel,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [progressIndicator, setProgressIndicator] = useState();
  const [progressText, setProgressText] = useState();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
    var isGameRunning = false;
    const coreservices = {
        coreService: new CoreService(),
    };
  //(mapData != null && mapData.userBalance != null) ? mapData.userBalance.balance : 0;
  //
  var remainBal = 0;
  var bal = 0;
  if (isReady && localStorage.getItem("isCoreStart") != "true") {
    isGameRunning = true;
  }
  const maxBalance =
    mapData != null && mapData.userBalance != null
      ? mapData.userBalance.maxBalance
      : 0;
  if (selectedGrid != null && selectedGrid.length > 0 && isGameRunning) {
    if (props.isMiningOpen) {
      selectedGrid.forEach((grid) => {
        bal =
          bal +
          (grid.depth == 1
            ? (grid.totalNode - grid.restrictedGrid) *
              mapData.userBalance.firstDepthCharge
            : grid.depth == 2
            ? (grid.totalNode - grid.restrictedGrid) *
              mapData.userBalance.secondndDepthCharge
            : (grid.totalNode - grid.restrictedGrid) *
              mapData.userBalance.thirdDepthCharge);
      });
      bal =
        bal +
        (pathSelectTemp == null ? 0 : pathSelectTemp) *
          mapData.userBalance.nodeCost;
    } else {
      selectedGrid.forEach((grid) => {
        bal =
          bal +
          (grid.depthTo == 1
            ? mapData.userBalance.firstDepthCharge
            : grid.depthTo == 2
            ? mapData.userBalance.secondndDepthCharge
            : mapData.userBalance.thirdDepthCharge);
      });
      bal =
        bal +
        (pathSelectTemp == null ? 0 : pathSelectTemp) *
          mapData.userBalance.nodeCost;
    }
    remainBal = mapData.userBalance.balance - bal;
    //var bal = 100;// grid.depthTo == 1 ? mapData.userBalance.firstDepthCharge : grid.depthTo == 2 ? mapData.userBalance.secondndDepthCharge : thirdDepthCharge
    //if (userTempBalance != remainBal) {
    dispatch(setUserToBeBalance(remainBal));
    //}
  } else {
    remainBal = mapData != null ? mapData.userBalance.balance : 0;
    //dispatch(setUserTempBalance(mapData.userBalance.balance));
  }
  dispatch(setUserTempBalance(mapData.userBalance.balance));
  const balance = userTempBalance;
  var perc = (balance * 100) / maxBalance;
    perc = perc < 0 ? 0 : perc > 100 ? 100 : perc;
    perc = Math.floor(perc)
    var percString = Math.floor(perc).toString()
  var perc2 = (remainBal * 100) / maxBalance;
  perc2 = perc2 < 0 ? 0 : perc2 > 100 ? 100 : perc2;
  var perc3 = (bal * 100) / mapData.userBalance.balance;
  perc3 = perc3 < 0 ? 0 : perc3 > 100 ? 100 : perc3;
  //perc3 = 100 - perc3;
  dispatch(
    setEnergyLevel(perc2 <= 0 ? "red" : perc2 > 0 && perc2 < 40 ? "yellow" : "")
  );
  if (perc2 <= 0) {
    dispatch(setShowError(true));
  } else {
    if (showError) {
      dispatch(setShowError(false));
    }
  }
  var d = new Date();
  var midNight = new Date();
  function diffMinutes(x, y) {
    return (y.getTime() - x.getTime()) / 1000 / 60;
  }
  function hmsToSeconds(t) {
    const [hours, minutes, seconds] = t.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  function secondsToHMS(secs) {
    return new Date(secs * 1000).toISOString().substr(11, 8);
  }
  var a = new Date();
  var b = new Date();
  b.setDate(b.getDate());
  b.setHours(23);
  b.setMinutes(59);
  b.setSeconds(59);
  var h = a.getHours();
  var m = a.getMinutes();
  var s = a.getSeconds();
  var h1 = b.getHours();
  var m1 = b.getMinutes();
  var s1 = b.getSeconds();
  var startTime = h + ":" + m + ":" + s;
  var endTime = h1 + ":" + m1 + ":" + s1;
  var tt = secondsToHMS(hmsToSeconds(endTime) - hmsToSeconds(startTime));

  //timeRemain = moment.utc(moment(this.d, "DD/MM/YYYY HH:mm:ss").diff(moment(this.midNight, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
  useEffect(() => {
    progressValue(perc);

    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);
  const progressValue = (value) => {
    switch (true) {
      case value >= 0 && value <= 25:
        setProgressIndicator("critical");
        setProgressText("Critical energy");
        break;
      case value >= 26 && value <= 50:
        setProgressIndicator("low");
        setProgressText("Low Energy");
        break;
      case value >= 51 && value <= 75:
        setProgressIndicator("mid");
        setProgressText("Medium energy");
        break;
      case value >= 76 && value <= 100:
        setProgressIndicator("high");
        setProgressText("High energy");
        break;
      default:
        break;
    }
  };
    const UpdateMechEnergy = () => {
        coreservices.coreService.updateMechEnergy();
    };

  return (
    <>
          <div className="monitor-container">
              {tooltipMining == 3 && (
                  <div className="monitor-border-mine fade-in"></div>
              )}
        <p>Monitor</p>
        <div className="monitor-wrap">
          <div className="energy-monitor-wrap">
            <div className="em-header">
              <div className="em-header-content">
                <div className="em-hc-title">
                  <img src={energyIcon}></img>
                  <p>Energy</p>
                  <div className="em-percent medium">
                                      <p>{perc}%</p>
                  </div>
                </div>
              </div>
              <div className="em-button">
                <img src={bolt}></img>
                <p>Recharge</p>
              </div>
            </div>
            <div className="energy-bar-container">
                          <img className="bar-position" style={{ left: percString+"%" }} src={position}></img>
                          <div style={{ width: percString + "%" }} className="energy-bar">
                              {(!isGameRunning || props.pageOpen == "inspect") ? "" : (
                                  <div className='energy-indicator2' style={{ left: ((perc3) > 12 ? (perc3) > (perc) ? (100 - perc3) : (perc - perc3) : (perc - 12)) + '%', width: (perc3 < 12 ? 12 : (perc3) > (perc) ? (perc - (100 - perc3)) : perc3) + '%' }}>
                                      <img src={props.pageOpen == "mining" ? MineIndicatorIcon : CoreIndicatorIcon} alt="Energy" />
                                  </div>)}
                          </div>
            </div>
          </div>
          <div className="monitor-separator"></div>
          <div className="energy-monitor-wrap">
            <div className="em-header">
              <div className="em-header-content">
                <div className="em-hc-title">
                  <img src={healthIcon}></img>
                  <p>Health</p>
                  <div className="em-percent low">
                                      <p>{mapData.mechDetail.mechCharge}%</p>
                  </div>
                </div>
              </div>
                          <div className="hm-button" onClick={() => UpdateMechEnergy()}>
                <img src={wrench}></img>
                <p>repair</p>
              </div>
            </div>
            <div className="energy-bar-container">
              <img
                className="bar-position"
                              style={{ left: mapData.mechDetail.mechCharge+"%" }}
                src={position}
              ></img>
                          <div style={{ width: mapData.mechDetail.mechCharge+"%" }} className="health-bar"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Monitor;
