import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import WatchIcon from "../../images/icons/watch.svg";
import EnergyIcon from "../../images/icons/energy.svg";
import Progress from "../progress/Progress";
import "./EnergyMonitor.css";

const EnergyMonitor = ({ value, time, energyRemaining }) => {
  const [progressIndicator, setProgressIndicator] = useState("");
  const [progressText, setProgressText] = useState("");

  useEffect(() => {
    progressValue(value);
  }, [value]);

  const progressValue = (value) => {
    switch (true) {
      case value >= 1 && value <= 25:
        setProgressIndicator("high");
        setProgressText("High Energy");
        break;
      case value >= 26 && value <= 50:
        setProgressIndicator("mid");
        setProgressText("1630KW will remain");
        break;
      case value >= 51 && value <= 75:
        setProgressIndicator("low");
        setProgressText("Low Energy");
        break;
      case value >= 76 && value <= 100:
        setProgressIndicator("critical");
        setProgressText("no energy left");
        break;
      default:
        break;
    }
  };

  return (
    <div className={`monitor-container ${progressIndicator}`}>
      <div className="monitor-title">
        <h4>Monitor</h4>
      </div>
      <div className="m-detail-wrap">
        <div className={`monitor-time  ${progressIndicator}`}>
          <ReactSVG src={WatchIcon} />
          <div className="time-wrap">{time}</div>
        </div>
        <div className={`monitor-hours ${progressIndicator}`}>
          <div className="unit-number ">{energyRemaining}</div>
          <div className="unit">kwh remaining</div>
        </div>
      </div>
      <Progress />
      <div className="monitor-energy-wrap">
        <div className="e-indicator">
          <ReactSVG src={EnergyIcon} />
          <span> {progressText}</span>
        </div>
        <div className="e-utilize">2000</div>
      </div>
    </div>
  );
};

export default EnergyMonitor;
