import React, { useState } from "react";
import logo from "../../images/avatars/logo.svg";
import mechImage from "../../images/avatars/Layer-29-2.png";
import shareIcon from "../../images/avatars/share-icon1.png";
import closeIcon from "../../images/avatars/close-button.svg";
import darkGradient from "../../images/avatars/dark-gradient-11.png";
import vector3 from "../../images/avatars/vector3.png";
import vector2 from "../../images/avatars/vector2.png";
import LogoMobile from "../../images/logo-mobile.svg";
import Ilyrium from "../../images/icons/discovery.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";
import SocialOverlay from "./SocialOverlay";

const DiscoveryInspect = (props) => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [socialOverlay, setsocialOverlay] = useState(false);

  const menuopen = () => {
    setNavOpen(!isNavOpen);
  };
  return (
    <>
      {!socialOverlay ? (
        <div
          className={`modal-overlay is-desktop-view ${
            props.animateOut ? "animate-out" : "animate-in"
          }`}
        >
                  <div className="modal-content justify-space-between">
            <div className="discover-inspect-top-section">
              <img src={logo} alt="logo"></img>
            </div>
            <div className="asset-title-wrap">
              <h2>ilyrium adventurite</h2>
            </div>
                      <div className="top-section justify-center flexnone">
              {/*<div className="mech-title">*/}
              {/*  <p>p-48 mining mech</p>*/}
              {/*</div>*/}

              <div className="mech-picture discovery-picture">
                <img
                  src={
                    props.sharetype == "gold"
                      ? Ilyrium
                      : props.sharetype == "silver"
                      ? silverThumb
                      : bronzeThumb
                  }
                  alt="Ilyrium"
                />
                {/*<img src={mechImage} alt="mech"></img>*/}
              </div>
            </div>

            <div
              className="mech-inspect-close-button"
              onClick={() => {
                props.handleClose();
              }}
            >
              <img src={closeIcon} alt="close"></img>
            </div>

                      <div className="bottom-section width100">
              <div className="bottom-left">
                <img src={darkGradient} alt="dark gradient"></img>
                <div className="info">
                  <div className="info-labels">
                    <p>location</p>
                    <p>lattitude</p>
                    <p>longitude</p>
                    <p>depth</p>
                  </div>
                  <div className="info-values">
                    <p>Eastern Rift E-08</p>
                    <p>-10.883° N</p>
                    <p>328.219° E</p>
                    <p>16m</p>
                  </div>
                </div>
                <div className="info">
                  <div className="info-labels">
                    <p>YIELD</p>
                    <p>value</p>
                    <p>Energy</p>
                    <p>charged</p>
                  </div>
                  <div className="info-values">
                    <p>2.57kg</p>
                    <p>68,000</p>
                    <p>80,846KW</p>
                    <p>103,846KW</p>
                  </div>
                </div>
              </div>
              <div className="share-button ">
                <p>
                  Charge crystal by
                  <span style={{ color: " var(--status-green, #00ff85)" }}>
                    23,000kW
                  </span>
                  extra by sharing
                </p>
                <button onClick={() => setsocialOverlay(true)}>
                  <h5>Share</h5>
                  <img src={shareIcon} alt="share-icon"></img>
                </button>
              </div>
              <div className="bottom-right">
                <h5>E08</h5>
                <h5>E0819h64</h5>
              </div>
            </div>
          </div>
        </div>
      ) : //<div
      //  className={`modal-overlay is-mobile-view ${
      //    props.animateOut ? "animate-out" : "animate-in"
      //  }`}
      //>
      //  <div className="modal-content">
      //    <div className="top-section">
      //      <header>
      //        <div>
      //          <ul className="left-nav"></ul>
      //        </div>
      //        <div className="logo">
      //          <img src={logo} alt="Archangel" className="l-desktop" />
      //          <img src={LogoMobile} alt="Archangel" className="l-mobile" />
      //          <div className="logo-bottom"></div>
      //        </div>
      //        <div>
      //          <ul className="mobile-header-menu">
      //            <li>
      //              <div
      //                className={`hamburger ${isNavOpen ? "is-active" : ""}`}
      //                onClick={() => menuopen()}
      //              >
      //                <span className="ham-icon"></span>
      //                <span className="ham-icon"></span>
      //                <span className="ham-icon"></span>
      //              </div>
      //            </li>
      //          </ul>
      //        </div>
      //        {isNavOpen ? (
      //          <>
      //            <div className="mobile-menu">
      //              <ul>
      //                <li
      //                  className="close-button"
      //                  onClick={() => {
      //                    props.handleClose();
      //                  }}
      //                ></li>
      //              </ul>
      //            </div>
      //          </>
      //        ) : (
      //          ""
      //        )}
      //      </header>
      //      <div className="mech-title">
      //        <p>p-48</p>
      //      </div>
      //      <div className="mech-picture">
      //        <img src={vector2} alt="mech"></img>
      //      </div>
      //    </div>

      //    <div
      //      className="close-button"
      //      onClick={() => {
      //        props.handleClose();
      //      }}
      //    >
      //      <img src={closeIcon} alt="close"></img>
      //    </div>
      //  </div>
      //    </div>
      socialOverlay ? (
        <SocialOverlay
          handleYesButtom={props.handleYesButtom}
          setsocialOverlay={setsocialOverlay}
          sharetype={props.sharetype}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DiscoveryInspect;
