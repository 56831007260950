import React, { useState } from "react";
import GridLines from "./GridLines";
import Rullers from "./Rullers";
import LandscapeGrid from "./LandscapeGrid";

import { setTooltipLanding, setIsReady, setSelectedGrid } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";

import "./landscape.css";

const MapLand = (props) => {
  return (
    <>
      <div className="scroll-container">
        <div className="landscape-container disable-origin" id="landscapeOuter">
          <GridLines />
          <Rullers />
                  <LandscapeGrid updateToolTipComplete={props.updateToolTipComplete}/>
        </div>
      </div>
    </>
  );
};

export default MapLand;
