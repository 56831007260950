import "../mine/mine.css";
import emMedia from "../../images/em-media.svg";
import closeButton from "../../images/icons/Close-Button.png";
const MineErrorModal = ({ setShowModal, setIsClosed }) => {
  return (
    <div className="error-modal">
      <div className="error-modal-content">
        <div className="em-media">
          <img src={emMedia} alt="mining components"></img>
        </div>
        <h2>Ilyrium Depletion Warning</h2>
        <div className="em-summary">
          <p>
            Your mech's Ilyrium reserves have been depleted due to the selected
            mine depth and size. To proceed, consider deleting a mine or
            adjusting parameters for efficient mining.
          </p>

          <p>
            Click the <img src={closeButton}></img> to remove a mine.
          </p>
        </div>
              <div className="white-button" onClick={() => { setIsClosed(true); setShowModal(false); }}>
          <p>close</p>
        </div>
      </div>
    </div>
  );
};

export default MineErrorModal;
