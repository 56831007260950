import crossIcon from "../../images/icons/cross-icon.svg";

const RestrictedGrid = () => {
  return (
    <div className="restricted-grid">
      <img src={crossIcon} alt="restricted"></img>
    </div>
  );
};

export default RestrictedGrid;
