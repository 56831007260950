import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import Training2img from "../../images/training_energy.png";
import Trainingcontrols from "../../images/training_controls.png";
import union from "../../images/Union.png";
import high from "../../images/hightext.png";
import medium from "../../images/mediumtext.png";
import low from "../../images/lowtext.png";
import critical from "../../images/criticaltext.png";
import arrow from "../../images/Arrow.png";
import arrowlong from "../../images/icons/Arrowlong.png";
import arrowgroup from "../../images/icons/Arrowgroup.png";
import arrowdown from "../../images/Arrowdown.png";
import "./training.css";
import Progress from "../progress/Progress";
import EnergyLedger from "../energyLedgerTraining/EnergyLedger";
import Monitor from "../monitor/Monitor";
import EnergyMonitor from "../energyMonitor/EnergyMonitor";
import AuthService, { AdminUserModel } from "../helper/Authservice";

const TrainingEnergy = () => {
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    console.log(viewportWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls"></div>
        <div className="training_container energy-container">
          <div className="trnng_accrdn">
            <div className="trng_accrdn_slf">
              <div
                className={`trng_acrdn_tpttl status ${
                  user.isTrainingComplete ? "complete" : "progress"
                }`}
              >
                {user.isTrainingComplete
                  ? "Training complete"
                  : "training in progress"}
              </div>
              <ul>
                <li className="active">
                  <div
                    className={`trnng_prcs_sngl status first-render animated-fadeIn ${
                      user.isTrainingComplete
                        ? "complete"
                        : "progress inprogress"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. energy</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "complete" : "in progress"}
                      </span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Energy Use: It’s important to use your Ilyrium supply
                          wisely. Each move requires different amounts of
                          energy. Keep an eye on your energy meter to give you
                          an indication on how you’re tracking and how to play
                          your next move.
                        </li>
                        <li>
                          Time Restrictions: Your Ilyrium is only charged for
                          24h once activated. It is activated following your
                          first action – by either dropping a Core or Opening a
                          mine.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                {viewportWidth <= 1350 && (
                  <div className="right-wrapper-inlist">
                    {" "}
                    <div className="right-wrapper">
                      <div className="tutorial-wrapper">
                        <div className="monitor-parent">
                          <div className="monitor-wrapper">
                            <div className="monitor">
                              <EnergyMonitor
                                value={20}
                                time={"23:12:50"}
                                energyRemaining={1500}
                              />
                            </div>
                            <div className="monitor">
                              <EnergyMonitor
                                value={50}
                                time={"14:12:50"}
                                energyRemaining={1000}
                              />
                            </div>
                            <div className="monitor">
                              <EnergyMonitor
                                value={80}
                                time={"08:12:50"}
                                energyRemaining={200}
                              />
                            </div>
                            <div className="legend-wrapper">
                              <div className="legend-content-wrapper">
                                <img src={high} alt="high" />
                                <img src={medium} alt="high" />
                                <img src={low} alt="high" />
                                <img src={critical} alt="high" />
                              </div>
                              <img src={arrow} alt="arrow" />
                              <p className="arrow-text-top">
                                When your energy reaches “Critical” you’ll no
                                longer be able to mine.
                              </p>
                            </div>
                            <div className="arrow-text-header">
                              <p className="tutorial-heading">
                                Total session time.
                              </p>
                              <img
                                className="arrow-down"
                                src={arrowdown}
                                alt="arrow-down"
                              />
                            </div>
                            <div id="energy-watcher-text">
                              <img src={arrow} alt="arrow"></img>
                              <p className="arrow-text">
                                Keep an eye on your energy!
                              </p>
                            </div>

                            <div className="energy-watcher-text-2">
                              <img src={arrowlong} alt="arrow"></img>
                              <p className="arrow-text-2">
                                When in mining mode, look out for this
                                measurement as a estimate on how much energy
                                your mine area is going to mine.
                              </p>
                            </div>
                            <div className="energy-watcher-text-3">
                              <p className="arrow-text">
                                When in mining mode, look out for this
                                measurement as a estimate on how much energy
                                your mine area is going to mine.
                              </p>
                              <img src={arrowgroup} alt="arrow"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <EnergyLedger />
                      <div className="union">
                        <img src={union} alt="union"></img>
                      </div>
                    </div>
                  </div>
                )}
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">2. Exploration</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">3. Coring</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">4. Mining</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">
                        5. Your Discoveries
                      </span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <button
                className="btn"
                onClick={() => navigate("/training-exploration")}
              >
                Next
              </button>
            </div>
          </div>
          {viewportWidth > 1350 && (
            <div className="right-wrapper animated-fadeIn">
              <div className="tutorial-wrapper">
                <div className="monitor-parent">
                  <div className="monitor-wrapper">
                    <div className="monitor">
                      <EnergyMonitor
                        value={20}
                        time={"23:12:50"}
                        energyRemaining={1500}
                      />
                    </div>
                    <div className="monitor">
                      <EnergyMonitor
                        value={50}
                        time={"14:12:50"}
                        energyRemaining={1000}
                      />
                    </div>
                    <div className="monitor">
                      <EnergyMonitor
                        value={80}
                        time={"08:12:50"}
                        energyRemaining={200}
                      />
                    </div>
                    <div className="legend-wrapper">
                      <div className="legend-content-wrapper">
                        <img src={high} alt="high" />
                        <img src={medium} alt="high" />
                        <img src={low} alt="high" />
                        <img src={critical} alt="high" />
                      </div>
                      <img src={arrow} alt="arrow" />
                      <p className="arrow-text-top">
                        When your energy reaches “Critical” you’ll no longer be
                        able to mine.
                      </p>
                    </div>
                    <div className="arrow-text-header">
                      <p className="tutorial-heading">Total session time.</p>
                      <img
                        className="arrow-down"
                        src={arrowdown}
                        alt="arrow-down"
                      />
                    </div>
                    <div id="energy-watcher-text">
                      <img src={arrow} alt="arrow"></img>
                      <p className="arrow-text">Keep an eye on your energy!</p>
                    </div>

                    <div className="energy-watcher-text-2">
                      <img src={arrowlong} alt="arrow"></img>
                      <p className="arrow-text-2">
                        When in mining mode, look out for this measurement as a
                        estimate on how much energy your mine area is going to
                        mine.
                      </p>
                    </div>
                    <div className="energy-watcher-text-3">
                      <p className="arrow-text">
                        When in mining mode, look out for this measurement as a
                        estimate on how much energy your mine area is going to
                        mine.
                      </p>
                      <img src={arrowgroup} alt="arrow"></img>
                    </div>
                  </div>
                </div>
              </div>
              <EnergyLedger />
              <div className="union">
                <img src={union} alt="union"></img>
              </div>
            </div>
          )}
        </div>
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingEnergy;
