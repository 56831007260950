import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Landscapemap from "../../images/landscape.jpg";
import CoreService from "../helper/Coreservice";
import AuthService from "../helper/Authservice";
import Loader from "../../Loader";
import tooltipLanding1 from "../../images/icons/landing1.png";
import tooltipArrow from "../../images/icons/tooltipArrow.svg";
import tooltipClose from "../../images/icons/tooltipClose.svg";
import { setTooltipLanding, setIsReady, setSelectedGrid } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";
import cursorGrab from "../../images/icons/cursorGrab.svg";
import cursorPointer from "../../images/icons/cursorPointer.svg";
const LandscapeGrid = (props) => {
  const coreservices = {
    coreService: new CoreService(),
  };
    const services = {
        authService: new AuthService(),
    };
    const [user, setUser] = useState(services.authService._CurrentUser());
  const { tooltipLanding } = useSelector((state) => state.app);
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const [isOpenMap, setOpenMap] = useState(false);
  const [touchStart, setTouchStart] = useState();
  const [mapData, setMapData] = useState(null);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
    const [showPopUp, setShowPopUp] = useState(
        user != null && user.isGetSignupBonus == true ? false : true
    );
  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        dispatch(setTooltipLanding(2));
      }, 1000);
    }
  }, [fadeOut]);

  useEffect(() => {
    setShowLoader(true);
    const img = new Image();
    img.src = Landscapemap;
      img.onload = () => {
          setShowLoader(false)          
          if (user?.isLandingTripComplete == false && !showPopUp) {
              if (tooltipLanding == 0) {
                  dispatch(setTooltipLanding(1));
              }
          } else {
              if (tooltipLanding != 0) {
                  dispatch(setTooltipLanding(0));
              }
          }
      };
  }, []);

  useEffect(() => {
      coreservices.coreService.getDataById(1).then((data) => {
          debugger;
      if (
        data != null &&
        data.isSuccess &&
        data.data.mapList &&
        data.data.mapList.length > 0
      ) {
          debugger;
        var maplist = [];
        for (var j = 1; j <= 10; j++) {
          for (var i = 1; i <= 16; i++) {
            var mm = data.data.mapList.find(
              (x) => x.rowNo == j && x.colNo == i
            );
            if (mm != null) {
              maplist.push(mm);
            }
          }
        }
        setMapData(maplist);
      }
      //setMapData(data == null ? null : data.data.isSuccess ? data.data.mapList : null)
      setOpenMap(true);
    });
  }, []);
  var touchtime = 0;
  const gridClicked = (event) => {
    if (touchtime == 0) {
      // set first click
      touchtime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - touchtime < 800) {
        // double click occurred
        alert("double clicked");
        touchtime = 0;
      } else {
        // not a double click so set as a new first click
        touchtime = new Date().getTime();
      }
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      {isOpenMap ? (
        <div className="landscape-grid-container">
          <img src={Landscapemap} alt="Landscape" id="imageDiv" />
          <div className="landscape" id="landscap_outer">
            {mapData?.length > 0 && (
              <div className="landscape" id="landscap_outer">
                {mapData.map((item, index) =>
                  item.isRestricted == true ? (
                    <div className="grid-item grid">
                      <div className="rejected">
                        <span>RESTRICTED</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="grid-item"
                      onTouchStart={(event) => {
                        setTouchStart(event.timeStamp);
                      }}
                      onTouchEnd={(event) => {
                        if (Math.abs(event.timeStamp - touchStart) < 150) {
                          navigate("/inspect/" + item.id);
                        }
                      }}
                      onDoubleClick={(event) => {
                        if (window.innerWidth >= 1024) {
                          navigate("/inspect/" + item.id);
                        }
                      }}
                    >
                      <div
                        className={`enter ${
                          tooltipLanding == 1 && index == 71 && "dummy-enter"
                        }`}
                      >
                        {tooltipLanding == 1 && index == 71 && (
                          <div className="dummy-enter-border fade-in"></div>
                        )}
                        <span>ENTER {item.mapImageNo}</span>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          {tooltipLanding == 1 && (
            <div
              className={`tooltip-container tt-landing fade-in ${
                fadeOut && "fade-out-custom"
              }`}
            >
              <div className="tooltip-media column">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip arrow"></img>
                </div>

                <div className="tooltip-close-button-container">
                                  <div className="tt-close-button" onClick={() => { props.updateToolTipComplete(); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <div className="landing-1-text">
                  <img src={cursorPointer}></img>
                  <p>Double click to Enter</p>
                </div>
                <div className="landing-1-text">
                  <img src={cursorGrab}></img>
                  <p>Drag and Explore</p>
                </div>
                <img className="mt-5" src={tooltipLanding1} alt="landing"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Explore the Eastern Rift</h3>
                  <p>
                    Hover over the map to view labeled grid coordinates.
                    <br></br>
                    Double-click to explore locations, but steer clear of
                    restricted zones we've marked for safety.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => setFadeOut(true)}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>1 of 2</h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LandscapeGrid;
