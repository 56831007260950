import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./landscape.css";
import SignIn from "../auth/SignIn";
import Register from "../auth/Register";
import FactionSelector from "./FactionSelector";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import FactionBadge from "./FactionBadge";
import MechNFT from "./MechNFT";
import FactionFile from "./FactionFile";
import FactionHumanx from "./FactionHumanx";
import FactionBrotherHood from "./FactionBrotherHood";
import FactionApostles from "./FactionApostles";
import FactionConfirmation from "./FactionConfirmation";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import AvatarSelection from "./AvatarSelection";
import Loader from "./Loader";
import MinePreparation from "../mine/MinePreparation";
import MineBase from "../mine/MineBase";
import { useDispatch, useSelector } from "react-redux";
import { setPageName } from "../../appSlice";
var isOpenSigIn = false;
var isOpenRegister = false;
var isOpenFaction = false;
var isOpenBadge = false;
var isShowMinePrep = false;
var isShowBaseMine = false;
export const handleRemoveSignIn = () => {
    isOpenSigIn = false;
};
const LandscapeMap = () => {
    const services = {
        authService: new AuthService(),
    };
    const { pageName } = useSelector((state) => state.app);
    const dispatch = useDispatch()
    const [sign, setSignIn] = useState(false);
    const [signInAuth, setSignInAuth] = useState(false);
    const [register, setRegister] = useState(false);
    const [humanx, setHumanx] = useState();
    const [fallenBrotherhood, setFallenBrotherhood] = useState();
    const [apostles, setApostles] = useState();
    const [isMech, setIsMech] = useState(false);
    const [isFaction, setIsFaction] = useState(false);
    const [isFactionBadge, setIsFactionBadge] = useState(false);
    const [confirmFaction, setConfirmFaction] = useState(false);
    const [factionBadge, setFactionBadge] = useState();
    const [avatarSelection, setAvatarSelection] = useState();
    const [mineBase, setMineBase] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [loggedIn, setLoggedIn] = useState();
    const [factionSelector, setFactionSelector] = useState(true);
    const [showMinePrep, setShowMinePrep] = useState(false);
    const [user, setUser] = useState(services.authService._CurrentUser());
    const handleFactionConfirmation = (faction) => {
        //setConfirmedFaction(faction);
        setFactionBadge("active");
    };

    const handleBadgeProceed = () => {
        setFactionBadge();
        setAvatarSelection("active");
    };
    const handleMech = () => {
        setIsMech(true);
        setIsFaction(false);
    };
    const handleMINEPREP = () => {
        isShowMinePrep = true;
    };
    const handleMechFalse = () => {
        setIsMech(false);
        setIsFaction(false);
    };
    if (services.authService.getSignIn()) {
        isOpenSigIn = true;
    }
    if (services.authService.getWelcome()) {
        isOpenBadge = true;
    }
    if (services.authService.getShowMinePrep()) {
        if (mineBase == 'active') {
            setMineBase('');
        }
        isShowMinePrep = true;
        isShowBaseMine = false;
    }
    if (services.authService.getBaseMine()) {
        if (showMinePrep == true) {
            setShowMinePrep(false);
        }
        isShowMinePrep = false;
        isShowBaseMine = true;
    }
    if (!isOpenBadge && !isOpenRegister && !isMech && !isShowBaseMine) {
        if (!services.authService.isLoggednIn()) {
            isOpenSigIn = true;
        } else {
            if (user != undefined && user != null && user.isProcceed) {
                //setRegister(false);
                //setIsFactionBadge(false)
                isOpenBadge = false;
                isShowBaseMine = false;
                isOpenFaction = false;
                isOpenRegister = false;
                isOpenSigIn = false;
                //handleMech();
                if (mineBase != "active") {
                    if (factionSelector == true) {
                        setFactionSelector(false)
                    }
                    //isShowBaseMine = true;
                    handleMINEPREP();
                }
                else {
                    isShowMinePrep = false;
                }
            } else {
                if (
                    avatarSelection != "active" &&
                    showLoader != true &&
                    showMinePrep != true &&
                    mineBase != "active"
                ) {
                    isOpenFaction = true;
                }
            }
        }
    }
    const handleFaction = () => {
        setIsMech(false);
        setIsFaction(true);
    };
    const handleFactionBadge = () => {
        setIsMech(false);
        setIsFaction(false);
        setSignIn(false);
        setRegister(false);
        setIsFactionBadge(true);
        isOpenBadge = false;
        isOpenFaction = false;
        isOpenRegister = false;
        isShowBaseMine = false;
        isOpenBadge = true;
        isShowMinePrep = false;
    };
    const handleSignIn = () => {
        isOpenSigIn = true;
        isOpenRegister = false;
        setSignIn(true);
        setSignInAuth(false);
        setRegister(false);
    };

    const handleRegister = () => {
        setRegister(true);
        setSignIn(false);
    };
    const handleHumanx = () => {
        setHumanx("active");
    };
    const handleFallenBrotherHood = () => {
        setFallenBrotherhood("active");
    };
    const handleApostles = () => {
        setApostles("active");
    };
    const handleSignInAuth = () => {
        setSignInAuth(false);
    };
    const handleClickOnButtonByUser = (pagename) => {
        if (services.authService.isLoggednIn()) {
            setSignIn(false);
            setRegister(false);
            if (
                pagename == "handleMech" ||
                pagename == "handleMech2" ||
                pagename == "active" ||
                pagename == "active2" ||
                pagename == "active3"
            ) {
                setConfirmFaction();
                setFallenBrotherhood(false);
                if (pagename == "handleMech") {
                    setIsFactionBadge(false);
                    isOpenBadge = false;
                    isOpenFaction = false;
                    isOpenRegister = false;
                    isShowBaseMine = false;
                    isShowMinePrep = true;
                    isOpenRegister = false;
                    setMineBase('');
                    setShowMinePrep(true);

                    //handleMech();
                } else if (pagename == "active2") {
                    setIsFactionBadge(false);
                    isOpenBadge = false;
                    isOpenFaction = false;
                    isOpenRegister = false;
                    isShowBaseMine = false;
                    isShowBaseMine = false;
                    handleMechFalse();
                    setMineBase('');
                    handleBadgeProceed();
                } else if (pagename == "active") {
                    setIsFactionBadge(false);
                    isOpenBadge = false;
                    isOpenFaction = false;
                    isOpenRegister = false;
                    isShowBaseMine = false;
                    setIsMech(false);
                    setMineBase('');
                    setAvatarSelection("active");
                } else if (pagename == "active3") {
                    setIsFactionBadge(false);
                    isOpenBadge = false;
                    isOpenFaction = false;
                    isOpenRegister = false;
                    isShowMinePrep = false;
                    isShowBaseMine = false;
                    setShowMinePrep(false);
                    handleMechFalse();
                    setMineBase("active");
                } else {
                    handleFactionBadge();
                }
            } else if (user.isProcceed) {
                setIsFactionBadge(false);
                isOpenBadge = false;
                isOpenFaction = false;
                isOpenRegister = false;
                isShowBaseMine = false;
                handleMech();
            } else {
                handleFaction();
            }
        } else {
            setSignIn(true);
            setRegister(false);
        }
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const handleClick = (index) => {
        setActiveIndex(index);
    };
    if (isOpenRegister || register) {
        if (pageName != "Regsiter") {
            dispatch(setPageName("Register"))
        }
    }
    else if (isShowMinePrep || showMinePrep) {
        if (pageName != "MinePrep") {
            dispatch(setPageName("MinePrep"))
        }
    }
    else if (isShowBaseMine || mineBase === 'active') {
        if (pageName != "BaseMine") {
            dispatch(setPageName("BaseMine"))
        }
    }
    else {
        if (pageName != "") {
            dispatch(setPageName(""))
        }
    }
    useEffect(() => {
        console.log("confirm faction", confirmFaction);
    }, [confirmFaction]);

    useEffect(() => {
        console.log("User:", user);
        console.log("Sign:", sign);
        console.log("Register or isOpenRegister:", register || isOpenRegister);
    }, [user, sign, register, isOpenRegister]);
    return (
        <>
            <LeftNavBar
                handleMech={handleMech}
                handleClickOnButtonByUser={handleClickOnButtonByUser}
            />
            <RightNavBar
                handleSignIn={handleSignIn}
                handleClickOnButtonByUser={handleClickOnButtonByUser}
            />
            <CSSTransition
                in={!user && (sign || isOpenSigIn)}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <SignIn
                    handleRegister={handleRegister}
                    handleSignIn={handleSignIn}
                    setSignIn={setSignIn}
                    handleFactionBadge={handleFactionBadge}
                    setLoggedIn={setLoggedIn}
                    setRegister={setRegister}
                    signInAuth={signInAuth}
                />
            </CSSTransition>
            <CSSTransition
                in={!user && (register || isOpenRegister)}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <Register
                    handleSignIn={handleSignIn}
                    setRegister={setRegister}
                    handleFactionBadge={handleFactionBadge}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                />
            </CSSTransition>
            {user && factionSelector && !isShowBaseMine && (
                <FactionSelector
                    handleHumanx={handleHumanx}
                    handleFallenBrotherHood={handleFallenBrotherHood}
                    handleApostles={handleApostles}
                    setConfirmFaction={setConfirmFaction}
                />
            )}

            {user && !isShowBaseMine && (
                <FactionHumanx
                    humanx={humanx}
                    setHumanx={setHumanx}
                    fallenBrotherhood={fallenBrotherhood}
                    setFallenBrotherhood={setFallenBrotherhood}
                    setFactionSelector={setFactionSelector}
                />
            )}
            {user && !isShowBaseMine && (
                <FactionBrotherHood
                    fallenBrotherhood={fallenBrotherhood}
                    setFallenBrotherhood={setFallenBrotherhood}
                    handleClickOnButtonByUser={handleClickOnButtonByUser}
                    confirmFaction={confirmFaction}
                    setConfirmFaction={setConfirmFaction}
                    setFactionSelector={setFactionSelector}
                    setFactionBadge={setFactionBadge}
                    humanx={humanx}
                    setHumanx={setHumanx}
                    setApostles={setApostles}
                    apostles={apostles}
                />
            )}
            {user && !isShowBaseMine && (
                <FactionApostles
                    setApostles={setApostles}
                    apostles={apostles}
                    fallenBrotherhood={fallenBrotherhood}
                    setFallenBrotherhood={setFallenBrotherhood}
                    setFactionSelector={setFactionSelector}
                />
            )}
            {user && !isShowBaseMine && isMech && (
                <MechNFT handleFaction={handleFaction} />
            )}
            <CSSTransition
                in={user && !isShowBaseMine && factionBadge === "active"}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <FactionBadge
                    handleClickOnButtonByUser={handleClickOnButtonByUser}
                    handleBadgeProceed={handleBadgeProceed}
                    factionBadge={factionBadge}
                />
            </CSSTransition>

            {/* AvatarSelection Component */}
            <CSSTransition
                in={user && !isShowBaseMine && avatarSelection === "active"}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <AvatarSelection
                    setGenerateLicence={setShowLoader}
                    setAvatarSelection={setAvatarSelection}
                />
            </CSSTransition>

            {/* Loader Component */}
            <CSSTransition
                in={user && !isShowBaseMine && showLoader}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <Loader
                    setShowMinePrep={setShowMinePrep}
                    setShowLoader={setShowLoader}
                />
            </CSSTransition>

            {/* MinePreparation Component */}
            <CSSTransition
                in={(showMinePrep || isShowMinePrep) && !isShowBaseMine}
                timeout={1000} // Adjust the timeout as needed
                classNames="fade"
                unmountOnExit
            >
                <MinePreparation
                    isShowMinePrep={isShowMinePrep}
                    handleClickOnButtonByUser={handleClickOnButtonByUser}
                />
            </CSSTransition>
            {/* <FactionFile /> */}
            {(mineBase === "active" || isShowBaseMine) && <MineBase />}
        </>
    );
};

export default LandscapeMap;
