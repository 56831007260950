import { useMatch, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setPageName,
    setUserBalance,
    setUserTempBalance,
} from "../../appSlice";

export class AdminUserModel {
    userId = "";
    email = "";
    password = "";
    avatarUrl = "";
    location = "";
    refferCode = "";
    registerDate = "";
    refferCode = "";
    firstName = "";
    lastName = "";
    location = "";
    userName = "";
    fullName = "";
    panImgPath = "";
    balance = 0;
    isProcceed = false;
    isTrainingComplete = false;
    isGetSignupBonus = false;
    isOpenReportPopup = false;
    isCoreTripComplete = false;
    isLandingTripComplete = false;
    isInspectTripComplete = false;
    isMineTripComplete = false;
    user_role_Key_code = "";
    registerType = "";
    socialMediaId = "";
    constructor(obj) {
        this.userId = obj.id;
        this.email = obj.email;
        this.password = obj.password;
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.location = obj.location;
        this.userName = obj.userName;
        this.fullName = obj.fullName;
        this.panImgPath = obj.panImgPath;
        this.balance = obj.balance;
        this.registerDate = obj.registerDate;
        this.avatarUrl = obj.avatarUrl;
        this.refferCode = obj.refferCode;
        this.user_role_Key_code = "";
        this.isProcceed = obj.isPlaying;
        this.isTrainingComplete = obj.isAutoMuck;
        this.isGetSignupBonus = obj.isGetSignupBonus;
        this.isOpenReportPopup = obj.isOpenReportPopup;
        this.isCoreTripComplete = obj.isCoreTripComplete;
        this.isLandingTripComplete = obj.isLandingTripComplete;
        this.isInspectTripComplete = obj.isInspectTripComplete;
        this.isMineTripComplete = obj.isMineTripComplete;
        this.registerType = obj.registerType == null ? obj.registerType: obj.registerType.trim();
        this.socialMediaId = obj.socialMediaId;
    }
}
const _CurrentUser = () => {    
    this.CurrentUser = this.getUser();
    return this.CurrentUser;
};
export const BASE_URL = 'https://artemalabs1.com/api'/*'http://metaversegamingservice.com:4000/api'*/ /*'http://api.metaversegamingservice.com/api'*/ /*'http://localhost:55383/api'*/;/*//'http://65.0.32.115:83/api'  */;
export default class AuthService {
    constructor() { }
    dispatch = useDispatch();
    isRememberMe = true;
    isGoToSignIn = false;
    navigate = useNavigate();
    location = useLocation();
    CurrentUser = AdminUserModel;
    _CurrentUser() {
        //this.signOut()
        var obj = this.getUser();
        if (obj != null) {
            this.CurrentUser = new AdminUserModel(obj);
            this.dispatch(setUserBalance(obj.balance));
        } else {
            this.CurrentUser = obj;
        }
        return this.CurrentUser;
    }
    isAuthenticated() { }
    getUserInfo() { }
    signIn() { }
    signOut() {
        localStorage.removeItem("user-token");
        localStorage.removeItem("userDuplicate-token");
        localStorage.removeItem("user");
        localStorage.removeItem("user-role");
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_data");
        this.DeleteCookie("_userLoggedIn");
        this.CurrentUser = null;
    }
    DeleteCookie(name) {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    getUser() {
        //return JSON.parse(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem("user_data"));
    }
    setUser(data) {
        localStorage.setItem("user_data", data);
    }
    sendToken(data, rememberMe = false) {
        this.isRememberMe = rememberMe;
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("user-token", data["token"]);
        localStorage.setItem("userDuplicate-token", data["token"]);
        localStorage.setItem("user-role", data["user_role_Key_code"]);
        localStorage.setItem("access_token", data["access_token"]);
        localStorage.setItem("user_data", data["user"]);
        var userData = JSON.parse(localStorage.getItem("user_data"));
        var balance = userData.balance;
        //this.dispatch(setUserBalance(balance))
        //this.dispatch(setUserTempBalance(balance))
    }
    isLoggednIn() {
        return this.getToken() !== null;
    }
    getToken() {
        return localStorage.getItem("user-token");
    }
    getBearerToken() {
        if (localStorage.getItem("access_token")) {
            return localStorage.getItem("access_token");
        }
        return "";
    }
    getUserBalance() {
        return this._CurrentUser() != null ? _CurrentUser().balance : 0;
    }
    setUserBalance(balance) {
        var data = this.getUser();
        data.balance = balance;
        this.dispatch(setUserBalance(balance));
        this.dispatch(setUserTempBalance(balance));
        this.setUser(JSON.stringify(data));
    }
    setUserIsGetSignupBonus(isGetSignupBonus) {
        var data = this.getUser();
        data.isGetSignupBonus = isGetSignupBonus;
        this.setUser(JSON.stringify(data));
    }
    isUserHasBalance() {
        return _CurrentUser() != null && _CurrentUser().balance > 0 ? true : false;
    }
    getUserName() {
        return _CurrentUser() != null ? _CurrentUser().userName : null;
    }
    handleClickOnButton = (tagName) => {
        if (tagName == "logout") {
            this.signOut();
            tagName = "/";
        }
        if (this.isLoggednIn()) {
            this.dispatch(setPageName(""));
            if (this._CurrentUser().isProcceed) {
                if (tagName == "welcome") {
                    localStorage.setItem("iswelcome", true);
                } else if (tagName == "prospecting") {
                    this.navigate("/prospecting");
                } else if (tagName == "training") {
                    if (this._CurrentUser().isTrainingComplete) {
                        this.navigate("/training-complete");
                    } else {
                        this.navigate("/training");
                    }
                } else if (tagName == "training-complete") {
                    if (this._CurrentUser().isTrainingComplete) {
                        this.navigate("/training-complete");
                    } else {
                        this.navigate("/training");
                    }
                } else if (tagName == "training-mining") {
                    if (this._CurrentUser().isTrainingComplete) {
                        this.navigate("/training-mining");
                    } else {
                        this.navigate("/training");
                    }
                } else if (tagName == "training-coring") {
                    if (this._CurrentUser().isTrainingComplete) {
                        this.navigate("/training-coring");
                    } else {
                        this.navigate("/training");
                    }
                } else if (tagName == "basemine") {
                    localStorage.setItem("isbasemine", true);
                    this.navigate("/");
                } else if (tagName == "mineprep") {
                    localStorage.setItem("ismineprep", true);
                    this.navigate("/");
                } else if (tagName == "profile") {
                    this.navigate("/profile");
                } else if (tagName == "mylocker") {
                    this.navigate("/mylocker");
                } else if (tagName == "ilyrium") {
                    this.navigate("/ilyrium");
                }
                else if (tagName == 'reports') {
                    this.navigate('/reports');
                }
                else if (tagName == "resetpassword") {
                    this.navigate("/assetviewer");
                }
                else if (tagName == "forgotpassword") {
                    this.navigate("/forgot-password");
                }
                else if (tagName == "assetviewer") {
                    this.navigate("/assetviewer");
                }
                else {
                    this.navigate("/");
                }
            } else {
                window.location.reload();
                //this.navigate('/');
            }
        } else {
            if (tagName == "/") {
                this.removeSignIn();
                window.location.href = "/";
            } else if (tagName == "resetpassword") {
                this.navigate("/assetviewer");
            }
            else if (tagName == "forgotpassword") {
                this.navigate("/forgot-password");
            }
            else {
                localStorage.setItem("isUserLogIn", true);
                window.location.href = "/";
            }

            //var pp = this.location.pathname;
            /*if(location.pathname.startsWith("/"))*/
            //this.navigate('/');
        }
    };
    getSignIn() {
        return localStorage.getItem("isUserLogIn");
    }
    removeSignIn() {
        localStorage.removeItem("isUserLogIn");
    }
    getWelcome() {
        return localStorage.getItem("iswelcome");
    }
    removeWelcome() {
        localStorage.removeItem("iswelcome");
    }
    getBaseMine() {
        return localStorage.getItem("isbasemine");
    }
    removeBaseMine() {
        localStorage.removeItem("isbasemine");
    }
    getShowMinePrep() {
        return localStorage.getItem("ismineprep");
    }
    removeMinePrep() {
        localStorage.removeItem("ismineprep");
    }
}
