import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./map.css";
import Draggable from "react-draggable";
import MainGrid from "../mainGrid/MainGridCoring";
import MainGridMining from "../mainGrid/MainGridMining";
import MainGridInspect from "../mainGrid/MainGridInspect";
import DepthSelector from "./DepthSelector";
import EnergyLedger from "../energyLedger/EnergyLedger";
import { ReactSVG } from "react-svg";
import MoveUp from "../../../src/images/icons/move-up.svg";
import MoveDown from "../../../src/images/icons/move-down.svg";
import MoveLeft from "../../../src/images/icons/move-left.svg";
import MoveRight from "../../../src/images/icons/move-right.svg";
import RadioModule from "../../../src/images/radio-module.png";
import CoringCompleteModal from "../coring/CoringCompleteModal";
import CoreMap from "../coring/CoreMap";
import CoringSectionInfo from "../coring/CoringSectionInfo";
import DepthIcon from "../../../src/images/icons/depth-white-icon.svg";
import FXIcon from "../../../src/images/icons/fx-icon.svg";
import MineCalulator from "../mine/MineCalculator";
import MiningCompleteModal from "../mine/MiningCompleteModal";
import Congratulation from "../mine/Congratulation";
import Welcome from "../coring/Welcome";
import { Discoveries } from "../mine/Discoveries";
import CoreService from "../helper/Coreservice";
import { setShowWarning, setSnackbarQueue } from "../../appSlice";

import { useDispatch, useSelector } from "react-redux";
import WarningPopup from "../warning/WarningPopup";
import Snackbar from "../snackbars/Snackbar";
const demo = [
    ["f1", "high"],
    ["f2", "low"],
    ["f3", "bonus"],
    ["f2", "high"],
    ["f4", "medium"],
    ["f1", "high"],
];

const Map = (props) => {
    const {
        mapData,
        energyLevel,
        isCoreReportModal,
        tooltipCoring,
        showWarning,
        risk,
        factors,
        remainMechEnergy,
        snackbarQueue
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coreservices = {
        coreService: new CoreService(),
    };
    const [ccModelOpen, setCCModelOpen] = useState(true);
    const [mcModelOpen, setMCModelOpen] = useState(true);
    const [showDepthSelector, setShowDepthSelector] = useState(true);
    const [showDepthIcon, setShowDepthIcon] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(true);
    const [snacbarQ, setSnackbarQ] = useState([]);
    const [activeSnackbars, setActiveSnackbars] = useState([]);
    //const [snackbarQueue, setSnackbarQueue] = useState([
    //  { id: 1, factor: ["f1", "high"] },
    //  { id: 2, factor: ["f1", "medium"] },
    //  { id: 3, factor: ["f1", "low"] },
    //  { id: 4, factor: ["f1", "bonus"] },
    //  { id: 5, factor: ["f2", "high"] },
    //  { id: 6, factor: ["f3", "high"] },
    //  { id: 7, factor: ["f4", "high"] },
    //  { id: 8, factor: ["f4", "low"] },
    //  { id: 9, factor: ["f2", "high"] },
    //  { id: 10, factor: ["f3", "medium"] },
    //  { id: 11, factor: ["f3", "bonus"] },
    //]);
    const [showCoreCalculator, setShowCoreCalculator] = useState(true);
    const [showCoreCalculatorIcon, setCoreCalculatorIcon] = useState(true);
    const [showMineCalculator, setShowMineCalculator] = useState(true);
    const [ind, setInd] = useState(0);
    const [showMineCalculatorIcon, setMineCalculatorIcon] = useState(false);
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    const handelCCModal = () => {
        setCCModelOpen(true);
    };
    const handelSelectedGrid = () => {
        props.handelSelectedGrid1();
    };
    const handelMCModal = () => {
        setMCModelOpen(true);
    };
    const handelDepthSelector = () => {
        setShowDepthIcon(true);
        setShowDepthSelector(false);
    };

    const handelDepthIcon = () => {
        setShowDepthIcon(false);
        setShowDepthSelector(true);
    };

    const handelCoreCalculator = () => {
        setCoreCalculatorIcon(true);
        setShowCoreCalculator(true);
    };

    const handelCoreCalculatorIcon = () => {
        setCoreCalculatorIcon(false);
        setShowCoreCalculator(false);
    };

    const handelMineCalculator = () => {
        setMineCalculatorIcon(true);
        setShowMineCalculator(false);
    };

    const handelMineCalculatorIcon = () => {
        setMineCalculatorIcon(false);
        setShowMineCalculator(true);
    };
    const moveToOtherMap = (id) => {
        if (id > 0) {
            if (props.pageOpen == "mining") {
                window.location = "/mining/" + id;
            } else if (props.pageOpen == "inspect") {
                window.location = "/inspect/" + id;
            } else {
                window.location = "/coring/" + id;
            }
        } else {
            alert("Restricetd Area");
        }
        //coreservices.coreService.getData(id);
    };
     useEffect(() => {
    console.log(activeSnackbars);
  }, [activeSnackbars]);

    useEffect(() => {
        console.log(
            new Date() +
            " activeSnackbars=" + JSON.stringify(activeSnackbars) + " snackbarQueue=" + JSON.stringify(snackbarQueue)
        );
        if (activeSnackbars.length < 6 && snackbarQueue.length > 0) {
            const interval = setInterval(() => {
                setActiveSnackbars((prevSnackbars) => [
                    ...prevSnackbars,
                    snackbarQueue[0],
                ]);
                let snacks = JSON.parse(JSON.stringify(snackbarQueue));
                snacks = snacks.slice(1);
                dispatch(setSnackbarQueue(snacks));
                //dispatch(setSnackbarQueue((prevQueue) => prevQueue.slice(1)));
            }, 1000);
            // Clear the interval when the component unmounts
            return () => clearInterval(interval);
        }
    }, [snackbarQueue, activeSnackbars]);

    useEffect(() => {
        const docWidth = () => setWinWidth(window.innerWidth);
        if (window.innerWidth <= 767) {
            setMineCalculatorIcon(true);
            setShowMineCalculator(false);
        }
        window.addEventListener("resize", docWidth);

        return () => {
            window.removeEventListener("resize", docWidth);
        };
    }, []);
    return (
        <>
            <div className="map-container-outer">
                <div className="map-content-wrap">
                    {energyLevel == "red" ? <div id="overlay"></div> : ""}
                    {showWarning && (
                        <>
                            {remainMechEnergy < 0 ? (
                                <div className="reporting-modal-backdrop"></div>
                            ) : (
                                ""
                            )}
                            <WarningPopup risk={risk} factors={factors} />
                        </>
                    )}
                    {activeSnackbars.length > 0 &&
                        activeSnackbars.map((snackbar, index) => (
                            <Snackbar
                                key={index}
                                factorArray={snackbar.factor}
                                id={snackbar.id}
                                top={index * 80 + 10} // Assuming 80 as height including margin
                                onRemove={(removedSnackbar) => {
                  setActiveSnackbars((prevSnackbars) =>
                                        prevSnackbars.filter(
                                            (snackbar) => snackbar.id !== removedSnackbar
                                        )
                                    );
                                }}
                            />
                        ))}

                    <div className="map-area">
                        <div className="map-spacing-wrap map-side-area">
                            <div className="map-nav"></div>
                            <div className="map-center-wrap top">
                                <div
                                    className="move-icon"
                                    onClick={() =>
                                        moveToOtherMap(mapData === null ? 0 : mapData.upperMapId.id)
                                    }
                                >
                                    <ReactSVG src={MoveUp} />
                                    <div className="text-wrapper">
                                        <div className="text">
                                            Move to{" "}
                                            {mapData === null ? 0 : mapData.upperMapId.mapImageNo}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="map-nav"></div>
                        </div>
                        <div className="map-spacing-wrap">
                            <div className="map-nav-center  left map-side-area">
                                <div
                                    className="move-icon"
                                    onClick={() =>
                                        moveToOtherMap(mapData === null ? 0 : mapData.leftMapId.id)
                                    }
                                >
                                    <ReactSVG src={MoveLeft} />
                                    <div className="text-wrapper">
                                        <div className="text">
                                            Move to{" "}
                                            {mapData === null ? 0 : mapData.leftMapId.mapImageNo}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.pageOpen == "mining" && !isCoreReportModal ? (
                                <MainGridMining />
                            ) : props.pageOpen == "inspect" && !isCoreReportModal ? (
                                <MainGridInspect />
                            ) : !isCoreReportModal ? (
                                <MainGrid />
                            ) : (
                                ""
                            )}

                            <div className="map-nav-center right map-side-area">
                                <div
                                    className="move-icon "
                                    onClick={() =>
                                        moveToOtherMap(mapData === null ? 0 : mapData.rightMapId.id)
                                    }
                                >
                                    <ReactSVG src={MoveRight} />
                                    <div className="text-wrapper">
                                        <div className="text">
                                            Move to{" "}
                                            {mapData === null ? 0 : mapData.rightMapId.mapImageNo}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="map-spacing-wrap map-side-area">
                            <div className="map-nav"></div>
                            <div className="map-center-wrap bottom">
                                <div
                                    className="move-icon "
                                    onClick={() =>
                                        moveToOtherMap(mapData === null ? 0 : mapData.lowerMapId.id)
                                    }
                                >
                                    <ReactSVG src={MoveDown} />
                                    <div className="text-wrapper">
                                        <div className="text">
                                            Move to{" "}
                                            {mapData === null ? 0 : mapData.lowerMapId.mapImageNo}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="map-nav"></div>
                        </div>
                        <Draggable
                            defaultPosition={{ x: 0, y: 0 }}
                            position={null}
                            bounds="parent"
                        >
                            <div className="radio-module">
                                <img src={RadioModule} alt="" />
                            </div>
                        </Draggable>
                    </div>
                </div>
                {winWidth >= 1024 ? (
                    <>
                        {props.pageOpen == "mining" ? (
                            <DepthSelector
                                handelDepthSelector={handelDepthSelector}
                                showDepthSelector={showDepthSelector}
                                pageOpen={props.pageOpen}
                            />
                        ) : props.pageOpen == "inspect" ? (
                            ""
                        ) : (
                            <DepthSelector
                                handelDepthSelector={handelDepthSelector}
                                showDepthSelector={showDepthSelector}
                                pageOpen={props.pageOpen}
                            />
                        )}
                        {/* <CoreMap handelCCModal={handelCCModal} /> */}
                        {props.pageOpen == "mining" ? (
                            <MineCalulator
                                handelMCModal={handelMCModal}
                                deployCores={props.deployCores}
                                showMineCalculator={showMineCalculator}
                            />
                        ) : props.pageOpen == "inspect" ? (
                            ""
                        ) : (
                            <CoreMap
                                handelCCModal={handelCCModal}
                                deployCores={props.deployCores}
                                showCoreCalculator={showMineCalculator}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <div className="mobile-handle-energy-core-calculator-wrap">
                            <div className="mh-depth-icon-wrap">
                                <div
                                    className={`mh-icon ${showDepthIcon ? "" : "hide"}`}
                                    onClick={() => handelDepthIcon()}
                                >
                                    <div className="circle-icon-wrap">
                                        <img src={DepthIcon} alt="Depth" />
                                    </div>
                                </div>
                                {props.pageOpen == "mining" ? (
                                    ""
                                ) : props.pageOpen == "inspect" ? (
                                    ""
                                ) : (
                                    <DepthSelector
                                        handelDepthSelector={handelDepthSelector}
                                        showDepthSelector={showDepthSelector}
                                        pageOpen={props.pageOpen}
                                    />
                                )}
                            </div>

                            {/* For Core Calculator */}

                            {/* <div className='mh-core-calculator-icon-wrap'>
              <div className={`mh-icon ${showCoreCalculatorIcon ? '' : 'hide'}`} onClick={() => handelCoreCalculatorIcon()}>
                  <div className='circle-icon-wrap'>
                    <img src={FXIcon} alt="Core Calculator" />
                  </div>
                </div>
               
                <CoreMap handelCCModal={handelCCModal} handelCoreCalculator={handelCoreCalculator} showCoreCalculator={showCoreCalculator} />
              </div> */}

                            {/* For Mine Calculator */}
                            <div className="mh-core-calculator-icon-wrap">
                                <div
                                    className={`mh-icon mine ${showMineCalculatorIcon ? "" : "hide"
                                        }`}
                                    onClick={() => handelMineCalculatorIcon()}
                                >
                                    <div className="circle-icon-wrap">
                                        <img src={FXIcon} alt="Core Calculator" />
                                    </div>
                                </div>
                                {props.pageOpen == "mining" ? (
                                    <MineCalulator
                                        handelMCModal={handelMCModal}
                                        deployCores={props.deployCores}
                                        handelMineCalculator={handelMineCalculator}
                                        showMineCalculator={showMineCalculator}
                                    />
                                ) : props.pageOpen == "inspect" ? (
                                    ""
                                ) : (
                                    <CoreMap
                                        handelCCModal={handelCCModal}
                                        deployCores={props.deployCores}
                                        handelCoreCalculator={handelMineCalculator}
                                        showCoreCalculator={showMineCalculator}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
                <EnergyLedger />
                {props.pageOpen == "mining" ? (
                    <MiningCompleteModal
                        setMCModelOpen={setMCModelOpen}
                        mcModelOpen={mcModelOpen}
                    />
                ) : (
                    <CoringCompleteModal />
                )}
                <CoringSectionInfo mapData={mapData} />
                {/* <Welcome /> */}
                {/*{props.pageOpen == "mining" ? <Congratulation /> : ""}*/}
                {/* <Discoveries /> */}
            </div>
        </>
    );
};

export default Map;
