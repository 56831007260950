import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authLogo from "../../images/icons/auth-logo.svg";
import close from "../../images/icons/close.svg";
import AuthService, { BASE_URL } from "../helper/Authservice";
const ResetPassword = () => {
    const services = {
        authService: new AuthService(),
    };
    const [isPasswordValid, setPasswordValid] = useState(0);
    const [invalidPassword, setPasswordError] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setCOnfirmPassword] = useState("");
    const [isCoPasswordValid, setCoPasswordValid] = useState(0);
    const [invalidCoPassword, setCoPasswordError] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const mapId = useParams();
    const handleClick = (variable, value) => {
        var index = 0;
        if (variable == "password") {
            setPassword(value);
            const emailRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{8,}$/i;
            if (value === "") {
                setPasswordValid(0);
                setPasswordError("");
            } else if (value.length < 8) {
                setPasswordValid(1);
                setPasswordError("Minimum 8 character need");
            } else if (!emailRegex.test(value)) {
                setPasswordValid(2);
                setPasswordError(
                    "Password must be include one uppercase,one lowercase,one number abd one special character"
                );
            } else {
                setPasswordValid(-1);
                setPasswordError("Secure");
            }
        }
        if (variable == "copassword") {
            setCOnfirmPassword(value);
            const emailRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{8,}$/i;
            if (value === "") {
                setCoPasswordValid(0);
                setCoPasswordError("");
            } else if (value != password) {
                setCoPasswordValid(1);
                setCoPasswordError("Confirm password and password should same");
            } else {
                setCoPasswordValid(-1);
                setCoPasswordError("Secure");
            }
        }
    };
    if (isPasswordValid < 0 && isCoPasswordValid < 0) {
        if (!canRegister) {
            setCanRegister(true);
        }
    }
    else {
        if (canRegister) {
            setCanRegister(false);
        }
    }
    const CLosePopUp = () => {
        services.authService.handleClickOnButton("/");
    };

    const userLogin = async (title, body) => {
        try {
            if (password == "" || password == null || password == undefined || password!=confirmPassword) {
                setCoPasswordValid(1);
                setCoPasswordError("Confirm password and password shoul same");
                return;
            }
            let response = await fetch(BASE_URL + "/Account/updatepassword", {
                method: "POST",
                body: JSON.stringify({
                    userid: mapId.id,
                    password: password,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response.json();

            if (response.status === 200) {
                if (data.isSuccess) {
                    CLosePopUp();
                }
                else {
                    setCoPasswordValid(1);
                    setCoPasswordError(data.errorMessage);
                }                
            } else {
                //setEmailError(data.errorMessage);
                setCoPasswordValid(1);
                setCoPasswordError("Link Expired.");
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            //services.authService.handleClickOnButton("logout");
        }
    };
    return (
        <div className="authwrapper">
            <div className="fp-container fade-in">
                <img src={authLogo} alt="logo" />

                <div className="fp-header">
                    <h4>Forgotten your password?</h4>
                </div>
                <div className={`auth-form-row reset margin-0 ${isPasswordValid === 0
                    ? ""
                    : isPasswordValid < 0
                        ? "active"
                        : "error"
                    }`}>
                    <div className="af-title reset">Password</div>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="•••••••••"
                        value={password}
                        onChange={(e) => handleClick("password", e.target.value)}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "•••••••••")}
                    />
                </div>
                <div className={`${isPasswordValid < 0 ? "success" : "error"}`}>
                    {invalidPassword}
                </div>
                <div id="cPassword" className={`auth-form-row reset margin-0 ${isCoPasswordValid === 0
                    ? ""
                    : isCoPasswordValid < 0
                        ? "active"
                        : "error"
                    }`}>
                    <div className="af-title reset">Confirm Password</div>
                    <input
                        type="password"
                        name="password"
                        id="confirm-password"
                        placeholder="•••••••••"
                        value={confirmPassword}
                        onChange={(e) => handleClick("copassword", e.target.value)}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "•••••••••")}
                    />
                </div>
                <div className={`${isCoPasswordValid < 0 ? "success" : "error"}`}>
                    {invalidCoPassword}
                </div>
                <div className="auth-button-wrap">
                    <button type="submit" className={`btn btn-fluid ${canRegister && "auth-btn fade-in"}`} onClick={() => userLogin()}>
                        RESET PASSWORD
                    </button>
                </div>
                <div className="fp-footer-wrap">
                    <div className="fp-footer">
                        <p>remember your password?</p>
                        <p className="href" onClick={() => CLosePopUp()}>Log in Here</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
