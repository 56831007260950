import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import Trainingcontrols from "../../images/training_controls.png";
import pilotUploaded from "../../images/training_pilot.png";
import ImageUploading from "react-images-uploading";
import AuthService, { BASE_URL } from "../helper/Authservice";
import AvatarImg from "../../images/avtar.png";
import DeleteIcon from "../../images/icons/delete.svg";

import "./training.css";

const TrainingCoring = () => {
  const services = {
    authService: new AuthService(),
  };
  const [images, setImages] = React.useState([]);
  const [user, setUser] = useState(services.authService._CurrentUser());
  const avUrl = user.avatarUrl.includes("uploadimg")
      ? user.avatarUrl : (user != null && user.registerType != "Email") ? user.avatarUrl
    : pilotUploaded;
  const maxNumber = 1;
  const onChange = async (imageList) => {
    var action = "";
    var imageBase64 = "";
    var imageName = "";
    if (imageList.length > 0) {
      action = "uploadImage";
      imageBase64 = imageList[0].data_url;
      imageName = imageList[0].file.name;
    } else if (imageList.length === 0) {
      action = "removeImage";
    }
    updateImage(imageBase64, imageName, action);
    //setImages(imageList);
  };
  const updateImage = async (imageBase64, imageName, action) => {
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            base64Image: imageBase64,
            userId: user.userId,
            imgaeName: imageName,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          //toast.success(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
          services.authService.setUser(data.data);
          setUser(services.authService._CurrentUser());
        } else {
          //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
        }
      } else {
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    }
  };
  const onProceed = async () => {
    var action = "updateTraining";
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          //setUser(services.authService._CurrentUser());
        }
      }
      //props.handleClickOnButtonByUser('handleMech');
    }
    //setImages(imageList);
  };
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    onProceed();
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    console.log(viewportWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls"></div>
        {viewportWidth > 1350 ? (
          <div id="complete-container" class="training_container">
            <div class="trnng_accrdn trnng_complete_accrdn">
              <div className="trng_accrdn_slf">
                <div className="trng_acrdn_tpttl status complete ">
                  training complete
                </div>
                <ul>
                  <li>
                    <div className="trnng_prcs_sngl status complete">
                      <div className="trnng_acrdn_hdr">
                        <span className="trnng_acrdn_tll">1. Energy</span>
                        <span className="trnng_acrdn_status">Complete</span>
                      </div>
                      <div className="trnng_acrdn_con">
                        <ol>
                          <li>
                            Energy Use: It’s important to use your Ilyrium
                            supply wisely. Each move requires different amounts
                            of energy. Keep an eye on your energy meter to give
                            you an indication on how you’re tracking and how to
                            play your next move.
                          </li>
                          <li>
                            Time Restrictions: Your Ilyrium is only charged for
                            24h once activated. It is activated following your
                            first action – by either dropping a Core or Opening
                            a mine.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="trnng_prcs_sngl status complete">
                      <div className="trnng_acrdn_hdr">
                        <span className="trnng_acrdn_tll">2. Exploration</span>
                        <span className="trnng_acrdn_status">Complete</span>
                      </div>
                      <div className="trnng_acrdn_con">
                        <ol>
                          <li>
                            Once inside a section, Ilyrium can be discovered at
                            multiple depths (16m, 64m, 192m). Hover or click on
                            any one grid to either Core or Mine that coordinate.{" "}
                          </li>
                          <li>
                            Coring allows you to gather samples at different
                            depths and is faster than mining. Use cores to
                            explore the section faster and gather information
                            about the location of Ilyrium.{" "}
                          </li>
                          <li>
                            Mining is a more laborious process as the Mech is
                            required to dig through an entire region at a
                            specific depth, rather than simply drop test tubes.
                            In the spirit of effeciency, we recommend you
                            explore a section by dropping a wide variety of
                            cores first.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="trnng_prcs_sngl status complete">
                      <div className="trnng_acrdn_hdr">
                        <span className="trnng_acrdn_tll">3. Coring</span>
                        <span className="trnng_acrdn_status">Complete</span>
                      </div>
                      <div className="trnng_acrdn_con">
                        <ol>
                          <li>
                            With Ilyrium hidden at 3 different depths, we
                            believe it’s best to core multiple different depths
                            inside any given section to gather the best
                            information as to where and at what depth Ilyrium
                            might be hidden.
                          </li>
                          <li>
                            Conserving energy is critical, so it’s best to plan
                            a path from the outline in, or inside out in order
                            to minimise your energy expended on travel.
                          </li>
                          <li>
                            If you are feeling more confident and looking to
                            rapidly drop a large amount of cores at a set depth,
                            you can use the depth locker module, located in the
                            top left of your console. To unlock, simply click
                            the locked depth again to reactive your dropdown on
                            click within the map.
                          </li>
                          <li>
                            Checking the Core Calculator prior to deploying your
                            core will also help you optimise your energy.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="trnng_prcs_sngl status complete">
                      <div className="trnng_acrdn_hdr">
                        <span className="trnng_acrdn_tll">4. Mining</span>
                        <span className="trnng_acrdn_status">Complete</span>
                      </div>
                      <div className="trnng_acrdn_con">
                        <ol>
                          <li>
                            Opening a mine is always best based on informed
                            research conducted within the Coring phase, however
                            you can also open a mine anywhere you desire.
                          </li>
                          <li>
                            To open mine, click and drag the rectangle over the
                            desired area and watch as each grid returns a
                            result.
                          </li>
                          <li>
                            Remember, a grid coordinate is only exhausted once
                            you have mined at all three grid levels. Rich
                            deposits may even exist across all three.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="trnng_prcs_sngl status complete">
                      <div className="trnng_acrdn_hdr">
                        <span className="trnng_acrdn_tll">
                          5. Your Discoveries
                        </span>
                        <span className="trnng_acrdn_status">Complete</span>
                      </div>
                      <div className="trnng_acrdn_con">
                        <ol>
                          <li>
                            Throughout the Eastern Rift are rare scatters of
                            Ilyrium Crystals – the most prized and powerful of
                            any form of Ilyrium. If you should find any of
                            these, you’ll not only inherit it’s powerful energy
                            source, but be rewarded by the Brotherhood with your
                            own verified discovery card.
                          </li>
                          <li>
                            Your discoveries will be stored within your Profile,
                            sharing them amongst your comrades does XXXXX.{" "}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </li>
                </ul>
                <button
                  class="btn first-render animated-fadeIn"
                  onClick={() =>
                    services.authService.handleClickOnButton("basemine")
                  }
                >
                  deploy to field
                </button>
              </div>
            </div>
            <div class="training-complete first-render animated-fadeIn">
              <div class="trnng_con_slf">
                <div className="trnng_ttl"> TRAINING COMPLETE </div>
                <p>Congratulations, Pilot!</p>
                <p>
                  You’ve successfully completed basic training, you’re now a
                  Certified Pilot!
                </p>
                <p>Have fun out there!</p>
              </div>
              {/* <div class="trnng_img">
              <img src={Training7img} alt="training "/>
            </div> */}
              <div className="trnng_cmplt_crtfct_img">
                <div className="trnng_plt_upld_img">
                  {/*<form action="">*/}
                  <div className="trnng_plt_img_inpt">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        // write your building UI

                        <>
                                                  {(!user.avatarUrl.includes("uploadimg") && user.registerType == "Email") && (
                            <div className="trnng_plt_img_txt">
                              <span className="trnng_plt_txt">
                                                              {user.userName.charAt(0)}
                              </span>
                            </div>
                          )}
                                                  {(user.avatarUrl.includes("uploadimg") || user.registerType != "Email") && (
                            <div key={1} className="trnng_plt_img_txt">
                              <span className="trnng_plt_txt">
                                <img src={user.avatarUrl} alt="" />
                              </span>
                              <div className="remove-btn">
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  onClick={() => onImageRemove(1)}
                                />
                              </div>
                            </div>
                          )}

                          <div
                            onClick={onImageUpload}
                            className="cmn_btn_small"
                          >
                            ADD IMAGE
                          </div>
                        </>
                      )}
                    </ImageUploading>

                    {/*<div className="trnng_plt_img_txt">*/}
                    {/*  */}
                    {/* <img src={pilotUploaded} alt=""/> */}
                    {/*  <span className="trnng_plt_txt">B</span>*/}
                    {/*</div>*/}
                    {/*<label className="cmn_btn_small">*/}
                    {/*  ADD IMAGE*/}
                    {/*  <input className="" type='file'/>*/}
                    {/*</label>*/}
                  </div>
                  {/* </form>*/}
                </div>
                <div class="trnng_cmplt_crtfct">
                  <div class="trnng_crtfct">
                    <div class="trnng_crtfct_img">
                      <img src={avUrl} alt="training " />
                    </div>
                    <div class="trnng_crtfct_txt">
                      <span>SKYHAVEN </span>
                      <span>STRATOSPHERE 6 </span>
                    </div>
                  </div>
                  <div class="trnng_crtfct_rfrshid">
                    <button class="btn">refresh my ID</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="training-parent">
            <div class="training-complete">
              <div class="trnng_con_slf">
                <p>Upload your avatar for your Pilot Certification.</p>
                <p> You can change this at any time.</p>
              </div>
              {/* <div class="trnng_img">
              <img src={Training7img} alt="training "/>
            </div> */}
              <div className="trnng_cmplt_crtfct_img">
                <div className="trnng_plt_upld_img">
                  {/* <form action="">*/}
                  <div className="trnng_plt_img_inpt">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        // write your building UI

                        <>
                          {!user.avatarUrl.includes("uploadimg") && (
                            <div className="trnng_plt_img_txt">
                              <span className="trnng_plt_txt">
                                                                  {user.userName.charAt(0)}
                              </span>
                            </div>
                          )}
                          {user.avatarUrl.includes("uploadimg") && (
                            <div key={1} className="trnng_plt_img_txt">
                              <span className="trnng_plt_txt">
                                <img src={user.avatarUrl} alt="" />
                              </span>
                              <div className="remove-btn">
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  onClick={() => onImageRemove(1)}
                                />
                              </div>
                            </div>
                          )}

                          <div
                            onClick={onImageUpload}
                            className="cmn_btn_small"
                          >
                            ADD IMAGE
                          </div>
                        </>
                      )}
                    </ImageUploading>
                    {/*<div className="trnng_plt_img_txt">*/}
                    {/*  */}
                    {/* <img src={pilotUploaded} alt=""/> */}
                    {/*  <span className="trnng_plt_txt">B</span>*/}
                    {/*</div>*/}
                    {/*<label className="cmn_btn_small">*/}
                    {/*  ADD IMAGE*/}
                    {/*  <input className="" type="file" />*/}
                    {/*</label>*/}
                  </div>
                  {/* </form>*/}
                </div>
                <div class="trnng_cmplt_crtfct">
                  <div class="trnng_crtfct">
                    <div class="trnng_crtfct_img">
                      <img src={avUrl} alt="training " />
                    </div>
                    <div class="trnng_crtfct_txt">
                      <span>SKYHAVEN </span>
                      <span>STRATOSPHERE 6 </span>
                    </div>
                  </div>
                  <div class="trnng_crtfct_rfrshid">
                    <button class="btn">refresh my ID</button>
                  </div>
                  <button
                    class="btn deploy-btn"
                    onClick={() =>
                      services.authService.handleClickOnButton("basemine")
                    }
                  >
                    <p>skip & Deploy to field</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingCoring;
