import React from "react";
import mineIcon from "../../images/icons/mine-icon-small.png";
import depth1 from "../../images/icons/Depth-1-big.png";
import depth2 from "../../images/icons/Depth-2-big.png";
import depth3 from "../../images/icons/Depth-3-big.png";
import closeButton from "../../images/icons/Close-Button.png";

const GridSelector = ({
  depth = 1,
  isRestricted = false,
  startCoords,
  endCoords,
  onRemove,
  setCloseHovered,
}) => {
  const depthIcons = [depth1, depth2, depth3];
  const gridSize = 32;
  const overlayBoxStyles = {
    top: Math.min(startCoords.y, endCoords.y) * gridSize + "px",
    left: Math.min(startCoords.x, endCoords.x) * gridSize + "px",
    width: (Math.abs(endCoords.x - startCoords.x) + 1) * gridSize + "px",
    height: (Math.abs(endCoords.y - startCoords.y) + 1) * gridSize + "px",
    position: "absolute",
    pointerEvents: "none",
    background: "rgba(0, 0, 0, 0.6)",
  };

  const externalBorderStyle = {
    position: "absolute",
    top: "-2px",
    left: "-2px",
    width: "calc(100% + 4px)",
    height: "calc(100% + 4px)",
    boxSizing: "border-box",
    pointerEvents: "none",
    border: isRestricted
      ? "2.5px dashed var(--status-red, #64BBEB)"
      : depth === 1
      ? "2.5px dashed var(--accents-32, #64BBEB)"
      : depth === 2
      ? "2.5px dashed var(--accents-64, #F2EF4C)"
      : "2.5px dashed var(--accents-192, #AA5AFA)",
  };

  return (
    <div className="grid-selector" style={overlayBoxStyles}>
      <div style={externalBorderStyle}></div>
      <div className="gs-content-wrap">
        <div className="gs-text-content-wrap">
          <img src={mineIcon} alt="mine-icon"></img>
          <p>E8-04</p>
        </div>
        <img src={depthIcons[depth - 1]} alt="depth"></img>
      </div>
      <div
        style={{ userSelect: "auto", pointerEvents: "auto" }}
        className="close-button"
        onClick={(e) => {
          setCloseHovered(false);
          onRemove();
        }}
        onMouseEnter={() => setCloseHovered(true)}
        onMouseLeave={() => setCloseHovered(false)}
      >
        <img src={closeButton} alt="close"></img>
      </div>
    </div>
  );
};

export default GridSelector;
