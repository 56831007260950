import React from 'react'

const TopRuller = () => {
  return (
    <div className='top ruler '>
    <div className="ruler-item"></div>
    <div className="ruler-item">1</div>
    <div className="ruler-item">2</div>
    <div className="ruler-item">3</div>
    <div className="ruler-item">4</div>
    <div className="ruler-item">5</div>
    <div className="ruler-item">6</div>
    <div className="ruler-item">7</div>
    <div className="ruler-item">8</div>
    <div className="ruler-item">9</div>
    <div className="ruler-item">10</div>
    <div className="ruler-item">11</div>
    <div className="ruler-item">12</div>
    <div className="ruler-item">13</div>
    <div className="ruler-item">14</div>
    <div className="ruler-item">15</div>
    <div className="ruler-item">16</div>
    <div className="ruler-item"></div>
  </div>
  )
}

export default TopRuller