import React, { useState } from 'react'
import RestrictedIcon from '../../images/icons/restricted.svg'
import ReportIcon from '../../images/icons/report.svg'
import UnexploredIcon from '../../images/icons/unexplored.svg'
const DiscoveryModal = (props) => {
    const [openDiscoveryModal, setOpenDiscoveryModal] = useState(true);
    const handleDiscoveryModal = () => {
        setOpenDiscoveryModal(false)
    }

    return (
        <>
            {openDiscoveryModal ? (
                <>
                    <div className='discoveries-modal-wrapper'>
                        <div className='discoveries-modal'>
                            <div className='discoveries-options'>
                                <div className='d-box restricted'>
                                    <img src={RestrictedIcon} alt='Restricted' />
                                    <div className='d-tag'>Restricted</div>
                                </div>
                                <div className='d-box unexplored'>
                                    <img src={UnexploredIcon} alt='Unexplored' />
                                    <div className='d-tag'>unexplored</div>
                                </div>
                                <div className='d-box nofind'>
                                    <img src={ReportIcon} alt='Report' />
                                    <div className='d-tag'>No Find</div>
                                </div>
                                <div className='d-box find'>
                                    <img src={ReportIcon} alt='Report' />
                                    <div className='d-tag'>Find</div>
                                </div>
                            </div>
                            <div className='dm-title'>Discovery Reports</div>
                            <div className='dm-info'>Your Mining Data Hub! <br />Dive into a world of consolidated mining experience data. Whether you're a seasoned pro or new to mining, this is your research hotspot. Gain insights to inform your journey and make data-driven decisions.</div>
                            <div className='dm-footer'>
                                <button className='btn btn-secondary' onClick={() => handleDiscoveryModal()}>Close</button>
                                <button className='btn' onClick={() => { props.onProceed(); handleDiscoveryModal() }}>Close, don't show again</button>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

        </>
    )
}

export default DiscoveryModal