import React, { useState, useEffect }  from "react";
import Ilyrium from "../../images/icons/discovery.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";
import AuthService, { AdminUserModel } from '../helper/Authservice'
import CoreService from '../helper/Coreservice'
const Congratulation = ({ ilyriumWeight = "1,247", megaCrystals = "4", handleClick = null }) => {
    const services = {
        authService: new AuthService()
    };
    const coreservices = {
        coreService: new CoreService(),
    };
    const [user, setUser] = useState(services.authService._CurrentUser());
    var totalEarn = 0
    //const style = (winWidth > 1024) ? (active === 1 ? { height: "0px" } : { height: coreResultHeight }) : (active === 1 ? { height: coreResultHeight } : { height: "0px" });
    //const coreCoordinatesList = props.mapData == null ? null : props.mapData.mapDetailList.filter(x => x.result < 3).sort((b, a) => a.digId - b.digId);
    const coreCoordinatesList = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel.filter(x => x.result <= 1).sort((b, a) => a.energyEarn - b.energyEarn);
    var findCoordinate = coreCoordinatesList.filter(x => x.energyEarn > 0);
    var totalEarn = 0
    var totalMile = 0
    var totalBonus = 0
    var mechCharge = 0
    var maxDepth = 1;
    var bestEnergyData = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel;
    var str = [];
    bestEnergyData.forEach((line, index) => {
        debugger;
        if (line.energyEarn > 0) {
            str.push(bestEnergyData[index]);
        }
        totalMile = totalMile + line.totalNode
        maxDepth = maxDepth >= line.depthTo ? maxDepth : line.depthTo
        totalBonus = totalBonus + line.bonus;
    });
    var lastMine = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel.sort((b, a) => a.id - b.id)[0];
    var allLatMine = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mapGroupModel == null ? null : coreservices.coreService.getMapData().mapGroupModel.filter(x => x.sessionId === lastMine.sessionId)
    allLatMine.forEach((line, index) => {        
        mechCharge = mechCharge + line.mechCharge;
    });
    var bestEnergyData1 = coreservices.coreService.getMapData() == null ? null : coreservices.coreService.getMapData().mineList == null ? null : coreservices.coreService.getMapData().mineList.filter(x => x.result < 3).sort((b, a) => a.energyEarn - b.energyEarn);
    bestEnergyData1.forEach((line, index) => {
        totalEarn = totalEarn + (line.energyEarn != null ? line.energyEarn : 0)
    });

  return (
    <div className="congratulation-wrap">
      <div className="congratulation-content-wrap">
        <h2>congratulations!</h2>
              <div className="cc-gallery-element-img">
                  <img src={maxDepth == 1 ? bronzeThumb : maxDepth == 2 ? silverThumb:Ilyrium} alt="Ilyrium" />
        </div>
      </div>
      <div className="congratulation-footer">
        <div className="message">
                  <p>Nice work, Captain '{user.userName}'!</p>
          <p className="message-grey">
                      mines have yielded
            <span className="message-green">{` ${totalEarn}kg of Ilyrium,`}</span>
                      <br></br>including
                      <span className="message-green">{` ${str.length} mega crystals.`}</span>
                      {totalBonus > 0 ? <><br></br>and
                          <span className="message-green">{` ${totalBonus} Bonus.`}</span></> : ""}
          </p>
        </div>
        <div className="btn-wrap">
                  <button type="button" className="btn btn-secondary" onClick={() => handleClick("discoveries")}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
