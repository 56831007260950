import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthLogo from "../../images/icons/auth-logo.svg";
import AuthCloseIcon from "../../images/icons/auth-close-icon.svg";
import "./auth.css";
import AuthService, { BASE_URL } from "../helper/Authservice";
import { ToastContainer, toast } from "react-toastify";
import { handleRemoveSignIn } from "../landscape/LandscapeMap";
import "react-toastify/dist/ReactToastify.css";
import LeftNavBar from "../landscape/LeftNavBar";
import RightNavBar from "../landscape/RightNavBar";
import Welcome from "./Welcome";
import SignInAuth from "./SignInAuth";
import Email from "./Email";

const SignIn = (props) => {
    const services = {
        authService: new AuthService(),
    };

    window.Issignin = false;
    services.authService.removeSignIn();
    const [welcomePage, setWelcomePage] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const [isEmailValid, setEmailValid] = useState(0);
    const [isPasswordValid, setPasswordValid] = useState(0);
    const [invalidEmailError, setEmailError] = useState("");
    const [invalidPassword, setPasswordError] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const handleClick = (variable, value) => {
        var index = 0;
        if (variable == "email") {
            setEmail(value);
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(value)) {
                setEmailValid(-1);
                setEmailError("EMAIL NOT VAILD");
            } else {
                setEmailValid(1);
                setEmailError("");
            }
        }
        if (variable == "password") {
            setPasswordValid(-1);
            setPasswordError("");
            setPassword(value);
        }
        if (variable == "name") {
            setEmailValid(1);
            setEmailError("");
            setName(value);
        }
    };
    const [email, setEmail] = useState(randomStr(20, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') + '@' + randomStr(7, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')+'.com');
    const [password, setPassword] = useState("Abc@1234");
    const [name, setName] = useState("DEMO MODE");
    if (isEmailValid > 0 && isPasswordValid < 0) {
        if (!canRegister) {
            setCanRegister(true);
        }
    }
    function randomStr(len, arr) {
        let ans = '';
        for (let i = len; i > 0; i--) {
            ans +=
                arr[(Math.floor(Math.random() * arr.length))];
        }
        //console.log(ans);
        return ans;
    }
    const userRegister = async (title, body) => {
        try {
            //var preEmail = randomStr(15, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
            //setEmail(preEmail + '@gmail.com');
            //if (name == "" || name == null || name == undefined) {
            //    setFullNameValid(-1);
            //    setFullNameError("Name required");
            //    return;
            //}
            //if (username == "" || username == null || username == undefined) {
            //    setNickNameValid(-1);
            //    setNickNameError("Nick name required");
            //    return;
            //}
            //if (email == "" || email == null || email == undefined) {
            //    setEmailValid(-1);
            //    setEmailError("Email required");
            //    return;
            //}
            //if (password == "" || password == null || password == undefined) {
            //    setPasswordValid(1);
            //    setPasswordError("Password required");
            //    return;
            //}
            debugger;
            let response = await fetch(BASE_URL + "/Account/register", {
                method: "POST",
                body: JSON.stringify({
                    name: 'Demo',
                    userName: 'Demo User',
                    password: password,
                    email: email,
                    registerBy: "Email"
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response.json();
            if (response.status === 200) {
                if (data.isSuccess) {
                    let response2 = await fetch(BASE_URL + "/Account/login", {
                        method: "POST",
                        body: JSON.stringify({
                            userName: email,
                            password: password,
                            registerBy: "Email"
                        }),
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    }
                    );
                    let data2 = await response2.json();
                    if (response2.status === 200) {
                        services.authService.sendToken(data2);
                        setWelcomePage(true);
                        const timer = setTimeout(() => {
                            clearTimeout(timer);
                            window.location.reload();
                        }, 2000);
                        //services.authService.handleClickOnButton("welcome");
                        //props.handleFactionBadge();
                        //window.location.reload();
                        //props.handleFactionBadge();
                        //window.location.reload();
                        //props.setLoggedIn(true);
                    } else {
                        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    }
                    //toast.success("Register Successfully.", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                } else {
                    setEmailValid(-1);
                    setEmailError(data.errorMessage);
                    //toast.error(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    //alert(response.errorMessage);
                }
            } else {
                setEmailValid(-1);
                setEmailError("Some error occured");
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            debugger;
            services.authService.handleClickOnButton("logout");
        }
    };
    const userLogin = async (title, body) => {
        try {
            if (email == "" || email == null || email == undefined) {
                setEmailValid(-1);
                setEmailError("Email required");
                return;
            }
            if (password == "" || password == null || password == undefined) {
                setPasswordValid(1);
                setPasswordError("Password required");
                return;
            }
            let response = await fetch(BASE_URL + "/Account/login", {
                method: "POST",
                body: JSON.stringify({
                    password: password,
                    userName: email,
                    registerBy: "Email"
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response.json();
            debugger;
            if (response.status === 200) {
                services.authService.sendToken(data);
                setWelcomePage(true);
                const timer = setTimeout(() => {
                    clearTimeout(timer);
                    props.setLoggedIn(true);
                    props.setSignIn(false);
                    props.setRegister(false);
                    console.log(props.loggedIn);
                    window.location.reload();
                }, 2000);

                //services.authService.handleClickOnButton("welcome");
            } else {
                //setEmailError(data.errorMessage);
                setEmailError("Invalid Email or Password");
                setEmailValid(-1);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            services.authService.handleClickOnButton("logout");
        }
    };
    const CLosePopUp = () => {
        services.authService.handleClickOnButton("/");
    };

    return (
        <>
            {!welcomePage && !props.signInAuth ? (
                <div className="authwrapper">
                    <div
                        className="auth-close hide"
                        onClick={() => CLosePopUp()}
                        onTouchStart={() => CLosePopUp()}
                    >
                        <div className="authclose-icon">
                            <ReactSVG src={AuthCloseIcon} />
                        </div>
                        <div className="auth-close-text">EXIT</div>
                    </div>
                    <div className="auth-container">
                        <div className="auth-logo">
                            <img src={AuthLogo} alt="Auth Logo" />
                        </div>
                        <div className="auth-title">Sign in</div>
                        <div className="auth-form-wrapper">
                            <div
                                className={`auth-form-row ${isEmailValid === 0
                                    ? ""
                                    : isEmailValid > 0
                                        ? "active"
                                        : "error"
                                    }`}
                            >
                                <div className="af-title">Email</div>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="name@email.com"
                                    value={name}
                                    onChange={(e) => handleClick("name", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "name@email.com")}
                                />
                            </div>
                            <div className={`${isEmailValid === 0 ? "success" : "error"}`}>
                                {invalidEmailError}
                            </div>

                            <div
                                className={`auth-form-row ${isPasswordValid === 0
                                    ? ""
                                    : isPasswordValid < 0
                                        ? "active"
                                        : "error"
                                    }`}
                            >
                                <div className="af-title">Password</div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="•••••••••"
                                    value={password}
                                    onChange={(e) => handleClick("password", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "•••••••••")}
                                />
                            </div>
                            <div className={`${isPasswordValid < 0 ? "success" : "error"}`}>
                                {invalidPassword}
                            </div>
                        </div>
                        <div className="auth-button-wrap">
                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className={`btn btn-fluid ${canRegister && "auth-btn fade-in"}`}*/}
                            {/*    onClick={() => userLogin()}>*/}
                            {/*    Sign In*/}
                            {/*</button>*/}
                            <button
                                type="submit"
                                className={`btn btn-fluid ${canRegister && "auth-btn fade-in"}`}
                                onClick={() => userRegister()}>
                                Sign In
                            </button>
                        </div>
                        <div className="auth-footer hide">
                            <div
                                className="auth-footer-link"
                            >
                                FORGOT PASSWORD?
                            </div>
                            <div className="auth-footer-text">Don’t have an account?</div>
                            <div
                                className="auth-footer-link"
                            >
                                Register Here
                            </div>
                        </div>
                    </div>
                </div>
            ) :
                props.signInAuth ? (
                    /*<GoogleOAuthProvider clientId="1099327091640-g4vgfbv187gtr0ueoh6tu3gc53d7bmlq.apps.googleusercontent.com">*/
                    <GoogleOAuthProvider clientId="347813789361-072uf4plg177l1846ufae3efl0fg4s48.apps.googleusercontent.com">                    
                        <SignInAuth handleRegister={props.handleRegister} handleSignIn={props.handleSignIn} />
                    </GoogleOAuthProvider>
                ) :
                    (
                        <Welcome />
                    )}
        </>
    );
};

export default SignIn;
