import React, { useState } from 'react'
import LeftCol from './components/leftcol/LeftCol'
import Map from './components/map/Map'

import SidebarRight from './components/infonav/Infonav';




const Coring = () => {
  const [isOpenMech, setOpenMech] = useState(false);


  // useEffect(() => {
  //   let mainContainer = document.querySelector('.main-wrapper')
  //   mainContainer.classList.add('main-bg');
  //   return () => { mainContainer.classList.remove('main-bg'); }
  // }, [])

  return (
    <>
      <LeftCol />
      <div className='coring-container'>
        <Map isOpenMech={isOpenMech} setOpenMech={setOpenMech} />
      </div>
     
      <SidebarRight setOpenMech={setOpenMech} isOpenMech={isOpenMech} />
     


    </>
  )
}

export default Coring