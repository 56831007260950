import React, { useState, useEffect } from "react";
import Rullers from "../landscape/Rullers";
import ReportGrid from "./ReportGrid";
import "./reports.css";
import ProfileNav from "./ProfileNav";
import LeftNavBar from "../landscape/LeftNavBar";
import RightNavBar from "../landscape/RightNavBar";
import Loader from "../../Loader";
import DiscoveryModal from "./DiscoveryModal";
import SelectModal from "./SelectModal";
import CoreService from "../helper/Coreservice";
import AuthService, { BASE_URL } from "../helper/Authservice";
const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showContent, setShowContent] = useState();
  const [isActive, setIsActive] = useState();
  const [isSelectModal, setIsSelectModal] = useState(false);
  const [userLocker, setUserLocker] = useState(null);
  const [mapSelectedData, setMapSelectedData] = useState(null);
  const services = {
    authService: new AuthService(),
  };
  const handleSelectModal = (id) => {
    setMapSelectedData(userLocker.mapDataList.find((z) => z.id == id));
    setIsSelectModal(true);
  };
  const coreservices = {
    coreService: new CoreService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());
  const onProceed = async () => {
    var action = "updateReportPopup";
    if (action != "") {
      let response2 = await fetch(
                BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          //setUser(services.authService._CurrentUser());
        }
      }
      //props.handleClickOnButtonByUser('handleMech');
    }
    //setImages(imageList);
  };

  //useEffect(() => {
  //    setTimeout(() => {
  //        setIsLoading(false);
  //        setShowContent(true);
  //    }, 1000);
  //    setTimeout(() => {
  //        setIsActive(true)
  //    }, 1050)
  //}, []);
  useEffect(() => {
    coreservices.coreService.getUserProfile("getreports").then((data) => {
      setUserLocker(data);
      setIsLoading(false);
      setShowContent(true);
      setTimeout(() => {
        setIsActive(true);
      }, 1050);
    });
  }, []);
  return (
    <>
      <LeftNavBar />
      <RightNavBar />
      <div className="report-container">
        {isLoading ? (
          <Loader />
        ) : showContent ? (
          <>
            <div
              className={`report-content-wrap ${
                isActive ? "active fade" : "fade"
              }`}
            >
              {user == null || !user.isOpenReportPopup ? (
                <DiscoveryModal onProceed={onProceed} />
              ) : (
                ""
              )}
              <div className="landscape-container">
                <Rullers />
                <ReportGrid
                  handleSelectModal={handleSelectModal}
                  userLocker={userLocker}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ProfileNav />
      {isSelectModal ? (
        <SelectModal
          setIsSelectModal={setIsSelectModal}
          mapSelectedData={mapSelectedData}
          userLocker={userLocker}
        />
      ) : null}
    </>
  );
};

export default Reports;
