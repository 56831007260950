import goldThumb from "../../images/aventurite.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";
import logo from "../../images/logo.svg";
import goldBadge from "../../images/goldBadge.png";
import silverBadge from "../../images/silverBadge.png";
import bronzeBadge from "../../images/bronzeBadge.png";

const ShareableCard = ({ badge = "silver", setDiscovery, hover = true }) => {
  const badges = {
    badge: {
      gold: goldBadge,
      silver: silverBadge,
      bronze: bronzeBadge,
    },
    thumb: {
      gold: goldThumb,
      silver: silverThumb,
      bronze: bronzeThumb,
    },
  };

  const footerClassName =
    badge === "gold"
      ? "thumb-footer-2 gold"
      : badge === "silver"
      ? "thumb-footer-2 silver"
      : "thumb-footer-2 bronze";

  const goldTextClassName =
    badge === "gold"
      ? "gold-text"
      : badge === "silver"
      ? "silver-text"
      : "bronze-text";

  return (
    <div className="thumbnail-wrap-2">
      <div className="thumb-header-2">
        <img src={logo} alt="logo"></img>
      </div>
      <div className="thumb-img-no-hover">
        <img src={badges.thumb[badge]} alt="thumbnail"></img>
      </div>
      <div className={footerClassName}>
        <img
          className="badge"
          src={badges.badge[badge]}
          alt={`${badge} badge`}
        ></img>
        <p className={goldTextClassName}>ilyrium Aventurite</p>
      </div>
    </div>
  );
};

export default ShareableCard;
