import React from "react";
import Draggable from "react-draggable";
import MapLand from "./MapLand";

const DraggableMap = ({ blur = false, updateToolTipComplete = updateToolTipComplete }) => {
  return (
    <Draggable>
      <div
        id="react-draggable"
        style={{
          width: "100%", // Set the map width
          height: "auto", // Set the map height
          position: "relative",
          cursor: "grab",
          filter: blur && "blur(5px)",
        }}
      >
        {/* Your map content goes here */}
        {/* This can be an actual map component or an image */}

              <MapLand updateToolTipComplete={updateToolTipComplete} />


      </div>
    </Draggable>
  );
};

export default DraggableMap;
