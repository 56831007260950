import React from 'react'
import { useMatch, useNavigate, useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import GalleryIcon from '../../images/icons/landscape-gallery.svg'
import IlyriumIcon from '../../images/icons/landscape-ilyrium.svg'
import ProfileIcon from '../../images/icons/landscape-profile.svg'
import AuthService, { AdminUserModel } from "../helper/Authservice";
const RightNavBar = (props) => {
    const services = {
        authService: new AuthService(),
    };
  return (
    <div className='landscapenav-wrap right'>
          <ul className='landscapenav'>
              <li className={useMatch('/profile') ? 'active' : ''} onClick={() => services.authService.handleClickOnButton('profile')} >
            <ReactSVG src={ProfileIcon} className='landscapenav-icon' />
            <span>Profile</span>
          </li>
              <li className={useMatch('/mylocker') ? 'active' : ''} onClick={() => services.authService.handleClickOnButton('mylocker')} >
            <ReactSVG src={GalleryIcon} className='landscapenav-icon' />
            <span>Gallery</span>
          </li>
              <li className={useMatch('/ilyrium') ? 'active' : ''} onClick={() => services.authService.handleClickOnButton('ilyrium')} >
            <ReactSVG src={IlyriumIcon} className='landscapenav-icon' />
            <span>Ilyrium</span>
          </li>
        </ul>
      </div>
  )
}

export default RightNavBar