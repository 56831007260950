import goldThumb from "../../images/aventurite.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";
import logo from "../../images/logo.svg";
import goldBadge from "../../images/goldBadge.png";
import silverBadge from "../../images/silverBadge.png";
import bronzeBadge from "../../images/bronzeBadge.png";

const Thumbnail = ({ badge = "silver", setDiscovery }) => {
  const badges = {
    badge: {
      gold: goldBadge,
      silver: silverBadge,
      bronze: bronzeBadge,
    },
    thumb: {
      gold: goldThumb,
      silver: silverThumb,
      bronze: bronzeThumb,
    },
  };

  const footerClassName =
    badge === "gold"
      ? "thumb-footer gold"
      : badge === "silver"
      ? "thumb-footer silver"
      : "thumb-footer bronze";

  const goldTextClassName =
    badge === "gold"
      ? "gold-text"
      : badge === "silver"
      ? "silver-text"
      : "bronze-text";

  return (
      <div className="thumbnail-wrap" >
      <div className="thumb-header">
        <img src={logo} alt="logo"></img>
      </div>
      <div className="thumb-img">
        <p className="hover-text top-text">e-08</p>
        <p className="hover-text mid-text">open</p>
        <p className="hover-text bottom-text">A0619201</p>
        <img src={badges.thumb[badge]} alt="thumbnail"></img>
      </div>
      <div className={footerClassName}>
        <img
          className="badge"
          src={badges.badge[badge]}
          alt={`${badge} badge`}
        ></img>
        <p className={goldTextClassName}>ilyrium Aventurite</p>
      </div>
    </div>
  );
};

export default Thumbnail;
