import React, { useEffect, useState } from "react";
import LeftRuller from "./LeftRuller";
import TopRuller from "./TopRuller";
import BottomRuller from "./BottomRuller";
import RightRuller from "./RightRuller";
import Grid from "../../images/768-Grid-bg.svg";
import Core from "./Core";
import map from "../../images/map.png";
import RestrictedGrid from "./RestrictedGrid";
import GridPathway from "./GridPathway";
import CoringComponent from "./CoringComponent";
import PathwayComplete from "./PathwayComplete";
import Loader from "../../Loader";
import CoreService from "../helper/Coreservice";
import AuthService from "../helper/Authservice";
import { useDispatch, useSelector } from "react-redux";
import {
    addToSelectedGrid,
    setIsReady,
    setPathSelect,
    setPathSelectTemp,
    setSelectedGrid,
    setSessionTime,
    setTooltipCoring
} from "../../appSlice";
var lastSelectedNode;

const MainGrid = () => {
    const gridSize = 32;
    const {
        selectedGrid,
        mapData,
        pathSelect,
        pathSelectTemp,
        sessionTime,
        tooltipCoring,
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const coreservices = {
        coreService: new CoreService(),
    };
    const services = {
        authService: new AuthService(),
    };
    const [user, setUser] = useState(services.authService._CurrentUser());
    const [gridItems, setGridItems] = useState([]);
    const [completedTill, setCompletedTill] = useState(0);
    const [pathCoordinate, setPathCoordinate] = useState([]);
    const [pathCoordinateComplete, setPathCoordinateComplete] = useState([]);
    const [cursorLocation, setCursorLocation] = useState(null);
    const [dropLocation, setDropLocation] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setShowLoader(true);
        const img = new Image();
        img.src = map;
        img.onload = () => {
            setShowLoader(false);
            /*if (location.pathname.startsWith("/coring")) {*/
            if (user?.isCoreTripComplete == false) {
                if (tooltipCoring == 0) {
                    dispatch(setTooltipCoring(1));
                }
            } else {
                if (tooltipCoring != 0) {
                    dispatch(setTooltipCoring(0));
                }
            }
            /*}*/
        };
    }, []);
    let pathCoordinates = [];

    const handleMouseMove = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        let x = Math.floor((event.clientX - containerRect.x) / gridSize);
        let y = Math.floor((event.clientY - containerRect.y) / gridSize);

        // Constrain x and y to be between 0 and 24
        x = Math.min(Math.max(0, x), 23);
        y = Math.min(Math.max(0, y), 23);

        setCursorLocation({ x, y });
    };

    const handleMouseDown = (event, index) => {
        console.log(event);
        const depth = Number(coreservices.coreService.getDepth());
        if (depth >= 0) {
            if (
                cursorLocation &&
                !mapData.mapDetailList[cursorLocation.y * 24 + cursorLocation.x]
                    .isRestricted
            ) {
                selectGrid(cursorLocation.y * 24 + cursorLocation.x, depth + 1);
            }
        } else {
            if (
                !expanded &&
                cursorLocation &&
                !mapData.mapDetailList[cursorLocation.y * 24 + cursorLocation.x]
                    .isRestricted
            ) {
                setExpanded(true);
                setDropLocation({ x: cursorLocation.x, y: cursorLocation.y });
            } else {
                setExpanded(false);

                setDropLocation(null);
            }
        }
    };

    const handleTouchStart = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        const touch = event.touches[0]; // Get the first touch point
        let x = Math.floor((touch.clientX - containerRect.x) / gridSize);
        let y = Math.floor((touch.clientY - containerRect.y) / gridSize);

        // Constrain x and y to be between 0 and 24
        x = Math.min(Math.max(0, x), 23);
        y = Math.min(Math.max(0, y), 23);

        setCursorLocation({ x, y }); // This might not be necessary if you don't need to track cursor on touch

        const depth = Number(coreservices.coreService.getDepth());

        if (depth >= 0) {
            if (
                !mapData.mapDetailList[cursorLocation.y * 24 + cursorLocation.x]
                    .isRestricted
            ) {
                selectGrid(cursorLocation.y * 24 + cursorLocation.x, depth + 1);
            }
        } else {
            if (
                !expanded &&
                cursorLocation &&
                !mapData.mapDetailList[cursorLocation.y * 24 + cursorLocation.x]
                    .isRestricted
            ) {
                setExpanded(true);
                setDropLocation({ x, y });
            } else {
                setExpanded(false);
                setDropLocation(null);
            }
        }
    };

    const filterUserDigReal = (myArray) => {
        var abc = myArray
            .filter((x) => x.result < 3)
            .sort((a, b) => Number(a.digId) - Number(b.digId));
        return abc;
    };

    const UpdatePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var nodeArray = nodePathList
                        .map((x) => Number(x.id))
                        .sort((a, b) => Number(a) - Number(b));

                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodeArray[abc];
                    var pathCharge = 1;
                    //mapData != null && mapData.userBalance != null
                    //    ? mapData.userBalance.nodeCost
                    //    : totalpath1;
                    var str =
                        "<span className='energyclass' style='background-color: white;font-size: 10px;position: absolute;width: 40px;opacity: 0.9;top:25%;left:-13%;text-align:center'>" +
                        (nodePathList.length * pathCharge).toString() +
                        "KW" +
                        "</span>";
                    const box = document.getElementById("id_" + middleNode);
                    if (box != null) {
                        box.insertAdjacentHTML("beforeend", str);
                    }
                }
            }
        }
    };
    const RemovePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var nodeArray = nodePathList
                        .map((x) => Number(x.id))
                        .sort((a, b) => Number(a) - Number(b));
                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodeArray[abc];
                    const box = document.getElementById("id_" + middleNode);
                    if (box != null) {
                        var paras = box.getElementsByTagName("span");
                        while (paras[0]) paras[0].parentNode.removeChild(paras[0]);
                    }
                }
            }
        }
    };

    useEffect(() => {
        let coreCoordinatesListTemp =
            mapData == null
                ? null
                : mapData.mapDetailList.filter((x) => x.result >= 0 && x.result < 3);
        //setPathCoordinate([]);
        coreCoordinatesListTemp = filterUserDigReal(coreCoordinatesListTemp);
        pathCoordinates = coreservices.coreService.FindAllNodes(
            coreCoordinatesListTemp
        );
        const totalLength = pathCoordinates.reduce(
            (total, subArray) => total + subArray.length,
            0
        );
        setCompletedTill(totalLength);
    }, []);
    useEffect(() => {
        let coreCoordinatesListTemp =
            mapData == null
                ? null
                : mapData.mapDetailList.filter((x) => x.result >= 0 && x.result < 3);
        //setPathCoordinate([]);

        coreCoordinatesListTemp = filterUserDigReal(coreCoordinatesListTemp);

        pathCoordinates = coreservices.coreService.FindAllNodes(
            coreCoordinatesListTemp
        );
        setPathCoordinateComplete(pathCoordinates);

        RemovePathEnergy(pathCoordinates);
        if (
            coreservices.coreService.getMapDataGrid() != null &&
            coreservices.coreService.getMapDataGrid().length > 0
        ) {
            pathCoordinates = coreservices.coreService.FindAllNodes(
                coreservices.coreService.getMapDataGrid(),
                true
            );
            setPathCoordinate(pathCoordinates);
            UpdatePathEnergy(pathCoordinates);
        }
        var totalPath = 0;

        //pathCoordinates.forEach((line) => {
        //    totalPath = totalPath + line.length;
        //});
        totalPath = pathCoordinates.reduce(
            (total, subArray) => total + subArray.length,
            0
        );
        if (pathSelectTemp != totalPath) {
            dispatch(setPathSelectTemp(totalPath));
        }
        //pathCoordinateComplete.forEach((line) => {
        //    totalPath = totalPath + line.length;
        //});
        totalPath = pathCoordinateComplete.reduce(
            (total, subArray) => total + subArray.length,
            0
        );
        if (pathSelect != totalPath) {
            dispatch(setPathSelect(totalPath));
        }
        mapData.mapDetailList.forEach((x, index) => {
            if (x.result <= 3) {
                const gridItem = (
                    <div className="grid-item" key={index} onClick={null}>
                        <Core
                            depth={x.depthTo}
                            find={x.result}
                            activateFill={x.depth1 < 0 ? true : false}
                            id={x.id}
                            isComplete={true}
                            core={x.coreNo}
                        />
                    </div>
                );
                setGridItems((prev) => {
                    return {
                        ...prev,
                        [index]: gridItem,
                    };
                });
            }
        });
    }, [mapData]);

    useEffect(() => {
        const emptyObject = {};

        if (mapData && mapData.mapDetailList && mapData.mapDetailList.length > 0) {
            mapData.mapDetailList.forEach((item, index) => {
                const row = item.rowNo - 1;
                const col = item.colNo - 1;

                if (item.isRestricted === true) {
                    emptyObject[row * 24 + col] = (
                        <div className="grid-item" id={"id_" + item.id} key={index}>
                            <RestrictedGrid />
                        </div>
                    );
                } else if (item.result !== 3) {
                    emptyObject[row * 24 + col] = (
                        <div className="grid-item" id={"id_" + item.id} key={index}>
                            <Core
                                depth={item.depthTo}
                                find={item.result}
                                activateFill={item.depth1 < 0 ? true : false}
                                id={item.id}
                                core={item.coreNo}
                            />
                        </div>
                    );
                } else {
                    emptyObject[row * 24 + col] = (
                        <div className="grid-item" key={index} id={"id_" + item.id}></div>
                    );
                }
            });
        }

        setGridItems(emptyObject);
    }, [mapData]);

    useEffect(() => {
        mapData.mapDetailList.forEach((x, index) => {
            if (!x.isRestricted && x.result >= 3) {
                var gridItem = (
                    <div className="grid-item" key={index} id={"id_" + x.id}></div>
                );
                var pp = selectedGrid.find((p) => p.id == x.id);
                if (pp != null) {
                    gridItem = (
                        <div className="grid-item" key={index} onClick={null}>
                            <Core
                                depth={pp.depthTo}
                                find={pp.result}
                                activateFill={pp.depth1 < 0 ? true : false}
                                id={pp.id}
                                isComplete={true}
                                core={pp.coreNo}
                            />
                        </div>
                    );
                }
                setGridItems((prev) => {
                    return {
                        ...prev,
                        [index]: gridItem,
                    };
                });
            }
        });
        var abc = pathCoordinate;
        RemovePathEnergy(abc);

        pathCoordinates = coreservices.coreService.FindAllNodes(selectedGrid, true);
        setPathCoordinate(pathCoordinates);
        UpdatePathEnergy(pathCoordinates);

        var totalPath = 0;

        //pathCoordinates.forEach((line) => {
        //    totalPath = totalPath + line.length;
        //});
        totalPath = pathCoordinates.reduce(
            (total, subArray) => total + subArray.length,
            0
        );
        if (pathSelectTemp != totalPath) {
            dispatch(setPathSelectTemp(totalPath));
        }
        //pathCoordinateComplete.forEach((line) => {
        //    totalPath = totalPath + line.length;
        //});
        totalPath = pathCoordinateComplete.reduce(
            (total, subArray) => total + subArray.length,
            0
        );
        if (pathSelect != totalPath) {
            dispatch(setPathSelect(totalPath));
        }
    }, [selectedGrid]);
    if (tooltipCoring == 3) {
        if (selectedGrid.length == 0) {
            var gridList = [];
            let grid = JSON.parse(JSON.stringify(mapData.mapDetailList[55]));
            grid.depthTo = grid.depth = 1;
            grid.digId = 1;
            gridList.push(grid);
            //dispatch(addToSelectedGrid(grid));
            //coreservices.coreService.saveDataGrid(grid);
            grid = JSON.parse(JSON.stringify(mapData.mapDetailList[182]));
            grid.depthTo = grid.depth = 2;
            grid.digId = 2;
            gridList.push(grid);
            //dispatch(addToSelectedGrid(grid));
            //coreservices.coreService.saveDataGrid(grid);
            grid = JSON.parse(JSON.stringify(mapData.mapDetailList[344]));
            grid.depthTo = grid.depth = 3;
            grid.digId = 3;
            gridList.push(grid);
            //dispatch(addToSelectedGrid(grid));
            //coreservices.coreService.saveDataGrid(grid);
            grid = JSON.parse(JSON.stringify(mapData.mapDetailList[405]));
            grid.depthTo = grid.depth = 1;
            grid.digId = 4;
            gridList.push(grid);

            dispatch(setIsReady(true));
            dispatch(setSelectedGrid(gridList));
            coreservices.coreService.saveDataEntireGrid(gridList);
        }
    }

    const selectGrid = (gridIndex, depth) => {
        if (localStorage.getItem("isCoreStart") == "true") {
            alert("Deploy core uder process. Try after deploy all core");
        } else {
            if (
                mapData.mapDetailList[gridIndex] != null &&
                mapData.mapDetailList[gridIndex] != undefined
            ) {
                let grid = JSON.parse(JSON.stringify(mapData.mapDetailList[gridIndex]));
                if (
                    mapData.mapDetailList
                        .filter((x) => x.result < 3)
                        .find((x) => x.id == grid.id) == null &&
                    (selectedGrid == null ||
                        selectedGrid.length == 0 ||
                        selectedGrid.find((x) => x.id == grid.id) == null)
                ) {
                    if (sessionTime == null) {
                        dispatch(setSessionTime(new Date().toISOString()));
                    }
                    lastSelectedNode = grid;
                    // grid.depthTo = grid.depth =
                    //   Number(coreservices.coreService.getDepth()) + 1;
                    grid.depthTo = grid.depth = depth;
                    grid.digId = selectedGrid.length + 1;

                    if (grid != null) {
                        dispatch(setIsReady(true));
                    }

                    dispatch(addToSelectedGrid(grid));

                    coreservices.coreService.saveDataGrid(grid);

                    const gridItem = (
                        <div className="grid-item" key={gridIndex} onClick={null}>
                            <Core
                                depth={grid.depthTo}
                                find={grid.result}
                                activateFill={false}
                                id={grid.id}
                                core={grid.coreNo}
                            />
                        </div>
                    );

                    setGridItems((prevValue) => {
                        return {
                            ...prevValue,
                            [gridIndex]: gridItem,
                        };
                    });

                    pathCoordinates = coreservices.coreService.FindAllNodes(
                        selectedGrid,
                        true
                    );
                    console.log("pathCoordinates", JSON.stringify(pathCoordinates));
                    setPathCoordinate(pathCoordinates);
                    UpdatePathEnergy(pathCoordinates);
                    var totalPath = 0;
                    //pathCoordinates.forEach((line) => {
                    //    totalPath = totalPath + line.length;
                    //});
                    totalPath = pathCoordinates.reduce(
                        (total, subArray) => total + subArray.length,
                        0
                    );
                    if (pathSelectTemp != totalPath) {
                        dispatch(setPathSelectTemp(totalPath));
                    }
                    //pathCoordinateComplete.forEach((line) => {
                    //    totalPath = totalPath + line.length;
                    //});
                    totalPath = pathCoordinateComplete.reduce(
                        (total, subArray) => total + subArray.length,
                        0
                    );
                    if (pathSelect != totalPath) {
                        dispatch(setPathSelect(totalPath));
                    }
                }
            }
        }
    };

    return (
        <div className="map-container">
            {showLoader && <Loader />}
            <PathwayComplete
                path={pathCoordinateComplete}
                completedTill={completedTill}
                setCompletedTill={setCompletedTill}
            />
            <div className="grid-outer">
                <LeftRuller />
                <TopRuller />
                <BottomRuller />
                <RightRuller />
                <img className="map-image" src={map}></img>
                <img className="grid" src={Grid} alt="Grid" />
                {expanded && dropLocation && (
                    <CoringComponent
                        y={dropLocation.y}
                        x={dropLocation.x}
                        expanded={expanded}
                        selectGrid={selectGrid}
                        setExpanded={setExpanded}
                    />
                )}
                <div
                    className="grid-wrap"
                    onMouseMove={windowWidth >= 450 ? handleMouseMove : null}
                    onMouseDown={windowWidth >= 450 ? handleMouseDown : null}
                    onTouchStart={windowWidth < 450 ? handleTouchStart : null}
                    style={{
                        userSelect: "none",
                        cursor: expanded ? "pointer" : "none",
                        zIndex: 1,
                    }}
                >
                    {tooltipCoring == 3 && (
                        <div className="grid-wrap-border fade-in"></div>
                    )}
                    {cursorLocation && !expanded && tooltipCoring !== 2 && (
                        <CoringComponent
                            y={cursorLocation.y}
                            x={cursorLocation.x}
                            expanded={expanded}
                            selectGrid={selectGrid}
                            setExpanded={setExpanded}
                            coreNo={
                                mapData.mapDetailList[cursorLocation.y * 24 + cursorLocation.x]
                            }
                        />
                    )}

                    {tooltipCoring == 2 && (
                        <CoringComponent
                            y={7}
                            x={4}
                            expanded={false}
                            selectGrid={selectGrid}
                            setExpanded={setExpanded}
                            coreNo="H-05"
                        />
                    )}

                    <GridPathway path={pathCoordinate} />
                    {Object.values(gridItems)}
                </div>
            </div>
        </div>
    );
};

export default MainGrid;
