import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Close from "../../images/close-icon.svg";
import MineFindIcon from "../../images/icons/select-modal-mine-find.svg";
import MineNoFindIcon from "../../images/icons/select-modal-mine-nofind.svg";
import CoreFindIcon from "../../images/icons/select-modal-core-find.svg";
import CoreNoFindIcon from "../../images/icons/select-modal-mine-nofind.svg";
import Reports from "./Reports";
const SelectModal = (props) => {
  const handleSelectModal = () => {
    props.setIsSelectModal(false);
  };
  const handleOpenReport = (imageId, page) => {
    if (page == "Core") {
      localStorage.setItem("isSetReport", "active");
      window.location = "/coring/" + imageId;
    } else if (page == "Mine") {
      localStorage.setItem("isSetReport", "active");
      window.location = "/mining/" + imageId;
    }
    props.setIsSelectModal(false);
  };
  var allReportList = props.userLocker.reportList.filter(
    (x) => x.imageId == props.mapSelectedData.id
  );

  return (
    <>
      <div className="select-modal-wrap">
        <div className="select-close-modal" onClick={() => handleSelectModal()}>
          <ReactSVG src={Close} className="close-icon" />
        </div>
        <div className="select-modal-content">
          <div className="select-modal-title">
            SECTION {props.mapSelectedData.mapImageNo}{" "}
          </div>
          <div className="select-report-wrap">
            {/* Content Start Here */}
            {allReportList != null &&
              allReportList.map((item, index) =>
                item.reportType == "Core" ? (
                  <div
                    className={`select-report ${
                      item.result === 1 ? "find" : "nofind"
                    }`}
                    onClick={() => handleOpenReport(item.imageId, "Core")}
                  >
                    <div className="sr-icon-wrap">
                      <img
                        src={item.result === 1 ? CoreFindIcon : CoreNoFindIcon}
                        alt="Core"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div className="sr-content">
                      <div className="sr-content-title">
                        Core report_{item.sessionId}
                      </div>
                      <div className="sr-content-date">{item.createDate}</div>
                    </div>
                  </div>
                ) : item.reportType == "Mine" ? (
                  <div
                    className={`select-report ${
                      item.result === 1 ? "find" : "nofind"
                    }`}
                    onClick={() => handleOpenReport(item.imageId, "Mine")}
                  >
                    <div className="sr-icon-wrap">
                      <img
                        src={item.result === 1 ? MineFindIcon : MineNoFindIcon}
                        alt="Mine"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div className="sr-content">
                      <div className="sr-content-title">
                        Mine report_{item.sessionId}
                      </div>
                      <div className="sr-content-date">{item.createDate}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            {/*<div className='select-report find'>*/}
            {/*    <div className='sr-icon-wrap'><img src={MineFindIcon} alt='Mine' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Mine report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='select-report nofind'>*/}
            {/*    <div className='sr-icon-wrap'><img src={CoreNoFindIcon} alt='Core' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Mine report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='select-report nofind'>*/}
            {/*    <div className='sr-icon-wrap'><img src={MineNoFindIcon} alt='Mine' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Core report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className='select-report find'>*/}
            {/*    <div className='sr-icon-wrap'><img src={MineFindIcon} alt='Mine' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Mine report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='select-report nofind'>*/}
            {/*    <div className='sr-icon-wrap'><img src={CoreNoFindIcon} alt='Core' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Mine report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='select-report nofind'>*/}
            {/*    <div className='sr-icon-wrap'><img src={MineNoFindIcon} alt='Mine' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Core report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='select-report find'>*/}
            {/*    <div className='sr-icon-wrap'><img src={CoreFindIcon} alt='Core' width="40" height="40" /></div>*/}
            {/*    <div className='sr-content'>*/}
            {/*        <div className='sr-content-title'>Core report_6847</div>*/}
            {/*        <div className='sr-content-date'>June 06, 2023</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* Content Ends Here */}
          </div>
        </div>
      </div>
      <div className="select-modal-backdrop"></div>
    </>
  );
};

export default SelectModal;
