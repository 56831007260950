import React, { useState, useEffect } from "react";
import logo from "../../images/avatars/logo.svg";
import mechImage from "../../images/avatars/Layer-29-2.png";
import shareIcon from "../../images/avatars/share-icon1.png";
import closeIcon from "../../images/avatars/close-button.svg";
import darkGradient from "../../images/avatars/dark-gradient-11.png";
import vector3 from "../../images/avatars/vector3.png";
import vector2 from "../../images/avatars/vector2.png";
import LogoMobile from "../../images/logo-mobile.svg";
import Ilyrium from "../../images/icons/discovery.png";
import silverThumb from "../../images/onyphyllite.png";
import bronzeThumb from "../../images/thorianite.png";
import SocialOverlay from "./SocialOverlay";

import icon1 from "../../images/icons/Group.svg";
import icon9 from "../../images/icons/Group2.svg";
import icon2 from "../../images/icons/Social1.svg";
import icon4 from "../../images/icons/Social2.svg";
import icon7 from "../../images/icons/Social3.svg";
import icon5 from "../../images/icons/Group3.svg";
import icon3 from "../../images/icons/Social5.svg";
import icon6 from "../../images/icons/Social7.svg";
import icon8 from "../../images/icons/Social8.svg";

import gicon1 from "../../images/icons/instagramg.svg";
import gicon2 from "../../images/icons/tiktokg.svg";
import gicon3 from "../../images/icons/twitterg.svg";
import gicon4 from "../../images/icons/snapchat.svg";
import gicon5 from "../../images/icons/pinterest.svg";
import gicon6 from "../../images/icons/discordg.svg";
import gicon7 from "../../images/icons/twitch.svg";
import gicon8 from "../../images/icons/copy.svg";

import CoreService from "../helper/Coreservice";
import AuthService, { BASE_URL } from "../helper/Authservice";

const ShareComplete = (props) => {
  const [hovered, setHovered] = useState(-1);
  const handleHover = (icon) => {
    setHovered(icon);
  };
  const services = {
    authService: new AuthService(),
  };

  const [user, setUser] = useState(services.authService._CurrentUser());

  useEffect(() => {
    //onProceed();
  }, []);
  const onProceed = async () => {
    var action = "updatesharecrystal";
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          services.authService.setUserBalance(
            services.authService.getUser().balance
          );
          //setUser(services.authService._CurrentUser());
        }
      }
      //props.handleClickOnButtonByUser('handleMech');
    }
    //setImages(imageList);
  };
  const shareOnSocialMedia = (platform) => {};
  return (
    <div
      className={`modal-overlay is-desktop-view ${
        props.animateOut ? "animate-out" : "animate-in"
      }`}
    >
      <div className="modal-content">
        <div className="logo-top-center">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="picture-holder">
          <img
            src={
              props.sharetype == "gold"
                ? Ilyrium
                : props.sharetype == "silver"
                ? silverThumb
                : bronzeThumb
            }
            alt="Ilyrium"
          />
        </div>

        <div
          className="mech-inspect-close-button"
          onClick={() => {
            props.handleYesButtom();
          }}
        >
          <img src={closeIcon} alt="close"></img>
        </div>

        <div className="bottom-section-share-complete">
          <p className="share-complete-text">
            NICE! YOU HAVE EARNED{" "}
            <span style={{ color: " var(--status-green, #00ff85)" }}>
              23,000kW
            </span>
          </p>
          <div className="share-button ">
            <p>
              Keep up the good work.Sharing your knowledgehelp others
              <br />
              dicover these raer crystals.Follow us online to keep up with
              <br />
              the latest news and see what others have found.
            </p>
            <div className="share-icons-wrap">
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(0)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("instragram");
                }}
              >
                {hovered === 0 ? (
                  <img src={gicon1} alt="glow" />
                ) : (
                  <img src={icon1} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("tiktok");
                }}
              >
                {hovered === 1 ? (
                  <img src={gicon2} alt="glow" />
                ) : (
                  <img src={icon2} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(2)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("twitter");
                }}
              >
                {hovered === 2 ? (
                  <img src={gicon3} alt="glow" />
                ) : (
                  <img src={icon3} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={() => handleHover(-1)}
              >
                {hovered === 3 ? (
                  <img src={gicon4} alt="glow" />
                ) : (
                  <img src={icon4} alt="social icon" />
                )}
              </div>

              <div
                className="social-icon"
                onMouseEnter={() => handleHover(4)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("pintrest");
                }}
              >
                {hovered === 4 ? (
                  <img src={gicon5} alt="glow" />
                ) : (
                  <img src={icon5} alt="social icon" />
                )}
              </div>

              <div
                className="social-icon"
                onMouseEnter={() => handleHover(5)}
                onMouseLeave={() => handleHover(-1)}
              >
                {hovered === 5 ? (
                  <img src={gicon6} alt="glow" />
                ) : (
                  <img src={icon6} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(6)}
                onMouseLeave={() => handleHover(-1)}
              >
                {hovered === 6 ? (
                  <img src={gicon7} alt="glow" />
                ) : (
                  <img src={icon7} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(7)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("copy");
                }}
              >
                {hovered === 7 ? (
                  <img src={gicon8} alt="glow" />
                ) : (
                  <img src={icon8} alt="social icon" />
                )}
              </div>
              <div
                className="social-icon"
                onMouseEnter={() => handleHover(8)}
                onMouseLeave={() => handleHover(-1)}
                onClick={() => {
                  shareOnSocialMedia("download");
                }}
              >
                {hovered === 8 ? (
                  <img src={icon9} alt="glow" />
                ) : (
                  <img src={icon9} alt="social icon" />
                )}
              </div>
            </div>

            <button
              onClick={() => {
                props.setShareComplete(false);
                props.handleYesButtom();
              }}
            >
              <h5>Close</h5>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareComplete;
