import React from "react";
import "./sidebarRight.css";
import MechIcon from "../../images/icons/mech.svg";
import IlyriumIcon from "../../images/icons/ilyrium.svg";
import EnergyIcon from "../../images/icons/energy-icon-m.svg";
import ArrowLeft from "../../images/icons/arrow-left-sidebar.svg";
import ArrowRight from "../../images/icons/arrow-right-sidebar.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenMech } from "../../appSlice";
const SidebarRight = (props) => {
  const dispatch = useDispatch();
  const { isOpenMech, tooltipInspecting } = useSelector((state) => state.app);
  const handelOpen = () => {
    dispatch(setIsOpenMech(true));
    //props.setOpenMech(true)
  };
  return (
    <div className={`sidebar-right ${isOpenMech ? "open" : ""}`}>
      <div className="sidebar-right-nav d">
        <div className="sr-icon" onClick={() => handelOpen()}>
          {tooltipInspecting == 6 && (
            <div className={`ledger-border fade-in`}></div>
          )}
          <img src={MechIcon} alt="Mining Mech" />
        </div>
        <div className="sr-icon">
          <img src={IlyriumIcon} alt="Ilyrium" />
        </div>
      </div>
      <div className="sidebar-right-nav m ">
        <div className="sr-icon">
          <img src={isOpenMech ? ArrowLeft : ArrowRight} alt="ArrowRight" />
        </div>
        <div className="sr-icon energy-icon" onClick={() => handelOpen()}>
          <img src={MechIcon} alt="Mining Mech" />
        </div>
        <div className="sr-icon">
          <img src={isOpenMech ? ArrowLeft : ArrowRight} alt="ArrowRight" />
        </div>
      </div>
    </div>
  );
};

export default SidebarRight;
