import { useEffect } from "react";
import EnvironmentIcon from "../../images/warning/environment";
import FactionIcon from "../../images/warning/faction";
import TerrainIcon from "../../images/warning/terrain";
import WeatherIcon from "../../images/warning/weather";
import "./Snackbar.css";
import { useState } from "react";
import close from "../../images/warning/close.svg";
const Snackbar = ({ factorArray, top, onRemove, id }) => {
  const [factor, risk] = factorArray;
  const [topValue, setTopValue] = useState(null);
  const [show, setShow] = useState(false);
  const [remove, setRemove] = useState(false);
  const damageInfo = {
    f1: {
      high: {
        heading: "Critical Acid Rain Damage",
        content: "A heavy acid rain storm has significantly damaged your mech.",
      },
      medium: {
        heading: "Moderate Acid Rain Damage",
        content: "An acid rain shower has moderately damaged your mech.",
      },
      low: {
        heading: "Light Acid Rain Damage",
        content: "An acid rain spray has lightly damaged your mech.",
      },
      bonus: {
        heading: "Boost Activated: Widow's Peak",
        content:
          "You have discovered rare and valuable crystals at Widow's Peak.",
      },
    },
    f2: {
      high: {
        heading: "Critical Mech Damage",
        content:
          "Your mech has suffered critical damage after falling in a sinkhole.",
      },
      medium: {
        heading: "Moderate Quicksand Damage",
        content:
          "Quicksand has caused your mech to get stuck and damaged some parts.",
      },
      low: {
        heading: "Low Terrain Damage",
        content: "Unstable terrain has lightly damaged your mech.",
      },
      bonus: {
        heading: "Unearthing Ilyrium Bonus",
        content:
          "Bonus activated: Unearth Ilyrium crystals in unstable terrains.",
      },
    },
    f3: {
      high: {
        heading: "Critical Mech Damage",
        content: "Apostles have sprayed your mech with toxic plasma.",
      },
      low: {
        heading: "Apostles' Patrol Damage",
        content: "A passing apostle patrol have vandalised your mech.",
      },
      bonus: {
        heading: "Apostle Ilyrium Recovered",
        content: "Apostles dropped Ilyrium; additional discoveries made.",
      },
      medium: {
        heading: "Hybrid Warband Attack",
        content: "Moderate damage sustained after a hybrid warband attack.",
      },
    },
    f4: {
      high: {
        heading: "Critical HumnX Assault Damage",
        content:
          "A passing HumnX assault team have critically damaged your mech.",
      },
      low: {
        heading: "HumnX Patrol Damage",
        content: "A passing HumnX patrol have vandalised your mech.",
      },
      medium: {
        heading: "HumnX Warband Raid",
        content: "Warband raid encountered; moderate damage incurred.",
      },
      bonus: {
        heading: "HumnX Ilyrium Recovered",
        content: "HumnX dropped Ilyrium; additional discoveries made.",
      },
    },
  };

  useEffect(() => {
    console.log("add", id);
    const showTimeout = setTimeout(() => {
      setShow(true);
      // Nested the removal timeout inside the show timeout to ensure correct sequencing
      const removeTimeout = setTimeout(() => {
        console.log("remove", id);
        onRemove(id);
      }, 10000); // Adjusted this to 1 second, so total time is still 1.5 seconds

      // Clear the removal timeout when the component is unmounted
      return () => clearTimeout(removeTimeout);
    }, 300);

    // Clear the show timeout when the component is unmounted
    return () => clearTimeout(showTimeout);
  }, [id, onRemove]);

  return (
    <div
      className={`snackbar-container ${show && "snackbar-entered"} ${
        remove && "snackbar-removed"
      }`}
      style={{ top: `${top}px` }}
    >
      <div
        className="snackbar-close-button"
        onClick={() => {
          onRemove(id);
        }}
      >
        <div className="scb-button">
          <img src={close} />
        </div>
      </div>
      <div className="snackbar-icon">
        {factor == "f1" ? (
          <WeatherIcon risk={risk} />
        ) : factor == "f2" ? (
          <TerrainIcon risk={risk} />
        ) : factor == "f3" ? (
          <EnvironmentIcon risk={risk} />
        ) : (
          <FactionIcon risk={risk} />
        )}
      </div>
      <div className={`snackbar-text ${risk}`}>
        <h3>{damageInfo[factor][risk].heading}</h3>
        <p>{damageInfo[factor][risk].content}</p>
      </div>
    </div>
  );
};

export default Snackbar;
