import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Prospecting from "./Prospecting";
import Layout from "../src/layout/Publiclayout";
import MechNFT from "./components/landscape/MechNFT";
import TrainingHome from "./components/training/TrainingHome";
import TrainingEnergy from "./components/training/TrainingEnergy";
import TrainingExploration from "./components/training/TrainingExploration";
import TrainingCoring from "./components/training/TrainingCoring";
import TrainingMining from "./components/training/TrainingMining";
import TrainingDiscoveries from "./components/training/TrainingDiscoveries";
import TrainingComplete from "./components/training/TrainingComplete";
import CoreLanding from "./components/coring/CoreLanding";
import InspectLanding from "./components/inspect/InspectLanding";
import MineLanding from "./components/mine/MineLanding";
import Profile from "./components/profile/Profile";
import MyLocker from "./components/profile/Mylocker";
import Ilyrium from "./components/profile/Ilyrium";
import Reports from "./components/profile/Reports";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import AssetViewer from "./components/profile/AssetViewer";
function App() {
    const [visible, setVisible] = useState("home");
    localStorage.removeItem('mapDataGrid');
    localStorage.removeItem('isCoreStart');
    localStorage.removeItem('isNeedStart');
    localStorage.removeItem('mineTempData');
    localStorage.removeItem('updateMineData')
    localStorage.removeItem("updateTempMineData");
    localStorage.removeItem("mapDataGridTemp")
    localStorage.setItem("depthSelect", -1);
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <Layout>
                    <Home />
                </Layout>
            )
        },
        {
            path: "/mechnft",
            element: (
                <Layout>
                    <MechNFT />
                </Layout>
            )
        },
        {
            path: "/prospecting",
            element: (
                <Layout>
                    <Prospecting />
                </Layout>
            )
        },
        {
            path: "/profile",
            element: (
                <Layout>
                    <Profile />
                </Layout>
            )
        },
        {
            path: "/mylocker",
            element: (
                <Layout>
                    <MyLocker />
                </Layout>
            )
        },
        {
            path: "/ilyrium",
            element: (
                <Layout>
                    <Ilyrium />
                </Layout>
            )
        },
        {
            path: "/reports",
            element: (
                <Layout>
                    <Reports />
                </Layout>
            )
        },
        {
            path: "/assetviewer",
            element: (
                <Layout>
                    <AssetViewer />
                </Layout>
            )
        },
        {
            path: "/inspect/:id",
            element: (
                <Layout>
                    <InspectLanding />
                </Layout>
            )
        },
        {
            path: "/coring/:id",
            element: (
                <Layout>
                    <CoreLanding />
                </Layout>
            )
        },
        {
            path: "/mining/:id",
            element: (
                <Layout>
                    <MineLanding />
                </Layout>
            )
        },
        {
            path: "/training",
            element: (
                <Layout>
                    <TrainingHome setVisible={setVisible} visible={visible} />
                </Layout>
            )
        },
        {
            path: "/training-energy",
            element: (
                <Layout>
                    <TrainingEnergy setVisible={setVisible} visible={visible} />
                </Layout>
            )
        },
        {
            path: "/training-exploration",
            element: (
                <Layout>
                    <TrainingExploration setVisible={setVisible} visible={visible} />
                </Layout>
            )
        },
        {
            path: "/training-coring",
            element: (
                <Layout>
                    <TrainingCoring setVisible={setVisible} visible={visible} />
                </Layout>
            )
        },
        {
            path: "/training-mining",
            element: (
                <Layout>
                    <TrainingMining setVisible={setVisible} visible={visible} />
                </Layout>
            ),
        },
        {
            path: "/training-your-discoveries",
            element: (
                <Layout>
                    <TrainingDiscoveries setVisible={setVisible} visible={visible} />
                </Layout>
            ),
        },
        {
            path: "/training-complete",
            element: (
                <Layout>
                    <TrainingComplete setVisible={setVisible} visible={visible} />
                </Layout>
            ),
        },
        {
            path: "/forgot-password",
            element: (
                <Layout>
                    <ForgetPassword />
                </Layout>
            ),
        },
        {
            path: "/reset-password/:id",
            element: (
                <Layout>
                    <ResetPassword />
                </Layout>
            ),
        },
    ]);
    return <RouterProvider router={router} />;
}

export default App;

