import React from 'react'
import RightRullerImg from '../../images/right-ruller.svg'
const RightRuller = () => {
    return (
        <div className="ruler right">
             <img src={RightRullerImg} alt='top Ruller' />
            {/* <div className="ruler-item">A</div>
            <div className="ruler-item">B</div>
            <div className="ruler-item">C</div>
            <div className="ruler-item">D</div>
            <div className="ruler-item">E</div>
            <div className="ruler-item">F</div>
            <div className="ruler-item">G</div>
            <div className="ruler-item">H</div>
            <div className="ruler-item">I</div>
            <div className="ruler-item">J</div>
            <div className="ruler-item">K</div>
            <div className="ruler-item">L</div>
            <div className="ruler-item">M</div>
            <div className="ruler-item">N</div>
            <div className="ruler-item">O</div>
            <div className="ruler-item">P</div>
            <div className="ruler-item">Q</div>
            <div className="ruler-item">R</div>
            <div className="ruler-item">S</div>
            <div className="ruler-item">T</div>
            <div className="ruler-item">U</div>
            <div className="ruler-item">V</div>
            <div className="ruler-item">W</div>
            <div className="ruler-item">X</div> */}
        </div>
    )
}

export default RightRuller