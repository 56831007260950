import React, { useState, useEffect } from "react";
import Location from "../location/Location";
import CoringSampleImg from "../../images/coring-sample.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import "./mine.css";
import MineReport from "../mine/MineReport";
import Monitor from "../monitor/Monitor";
import { ReactSVG } from "react-svg";
import GridLines from "../landscape/GridLines";
import Rullers from "../landscape/Rullers";
import LandscapeGrid from "../landscape/LandscapeGrid";
import ZoomController from "../landscape/ZoomController";
import LandscapeLocation from "../landscape/LandscapeLocation";
import DraggableMap from "../landscape/DraggableMap";
import ScrollContainer from "react-indiana-drag-scroll";
import AuthService, { BASE_URL } from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import popupMonitor from "../../images/popup-monitor.svg";
import AnimatedButton from "../animatedButton/AnimatedButton";
import arrow from "./arrow.svg";
import tooltipArrow from "../../images/icons/tooltipArrow.svg";
import landingZoom from "../../images/icons/landingZoom.svg";
import tooltipClose from "../../images/icons/tooltipClose.svg";
import { setTooltipLanding, setIsReady, setSelectedGrid } from "../../appSlice";
import { useDispatch, useSelector } from "react-redux";

const Mine = (props) => {
  const services = {
    authService: new AuthService(),
  };
  const coreservices = {
    coreService: new CoreService(),
  };
  const [isMineReportModal, setMineReportModal] = useState(false);
  const { tooltipLanding } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState("#00FF85");
  const [fadeOut, setFadeOut] = useState(false);
  const [user, setUser] = useState(services.authService._CurrentUser());
  const updateToolTipComplete = () => {
    updateImage("updateLandingTripComplete");
    dispatch(setTooltipLanding(0));
  };
  const updateImage = async (action) => {
    if (action != "") {
      let response2 = await fetch(
                BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          setUser(services.authService._CurrentUser());
        } else {
          //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
        }
      } else {
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    }
  };

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        updateToolTipComplete();
      }, 1000);
    }
  }, [fadeOut]);
  const handleMineReportModal = () => {
    setMineReportModal(true);
    props.setLeftOpen(false);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index);
  };

  services.authService.removeBaseMine();
  //var user = services.authService._CurrentUser();
  const [showPopUp, setShowPopUp] = useState(
    user != null && user.isGetSignupBonus == true ? false : true
  );

  const [isOpenMap, setOpenMap] = useState(true);
  const [mapData, setMapData] = useState(null);
  const handleAnimationButton = () => {
    setShowPopUp(false);
    if (user?.isLandingTripComplete == false) {
      if (tooltipLanding == 0 && fadeOut == false) {
        dispatch(setTooltipLanding(1));
      }
    } else {
      if (tooltipLanding != 0) {
        dispatch(setTooltipLanding(0));
      }
    }
  };

  return (
    <>
      {isOpenMap ? (
        <div
          className="landscape-main-container zoomlevel1 animate-in"
          id="zoom_scroll"
        >
          {showPopUp && (
            <div className="popup-container">
              <div className="popup-frame">
                <h1>
                  Congratulations !<br></br>you've been energised.
                </h1>
                <img src={popupMonitor} alt="popup monitor"></img>

                <p>
                  Click below to pre-load your mech with<br></br>
                  <span className="green-text-popup">
                    20,000kWH of Ilyrium.
                  </span>
                  <br></br>Use it wisely and keep an eye on your energy monitor.
                  <br></br>Your next collection will be in 24:00 hours.
                </p>
                <AnimatedButton
                  handleAnimationButton={handleAnimationButton}
                  buttonText={"COLLECT ENERGY"}
                />
                {/*<div*/}
                {/*  className="animated-button-container"*/}
                {/*  onMouseEnter={() => setHovered("black ")}*/}
                {/*  onMouseLeave={() => setHovered("#00FF85")}*/}
                {/*  onClick={() => setShowPopUp(false)}*/}
                {/*>*/}
                {/*  <div className="animated-button-buttons">*/}
                {/*    <div className="arrow-animations">*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*          transform="rotate(180 5.5 13)"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*    </div>*/}
                {/*    <h5>collect energy</h5>*/}
                {/*    <div className="arrow-animations">*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*      <svg*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        width="11"*/}
                {/*        height="26"*/}
                {/*        viewBox="0 0 11 26"*/}
                {/*        fill="none"*/}
                {/*      >*/}
                {/*        <path*/}
                {/*          d="M2.5 1L8 13L2.49999 25"*/}
                {/*          stroke={hovered}*/}
                {/*          stroke-opacity="0.1"*/}
                {/*          stroke-width="4"*/}
                {/*        />*/}
                {/*      </svg>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          )}
          <DraggableMap
            blur={showPopUp}
            updateToolTipComplete={updateToolTipComplete}
          />
        </div>
      ) : (
        ""
      )}
      {isOpenMap ? (
        <ZoomController
          handleClick={handleClick}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ) : (
        ""
      )}
      {tooltipLanding == 2 && (
        <div className="zoom-controller-border fade-in"></div>
      )}
      {tooltipLanding == 2 && (
        <div
          className={`tooltip-container tt-landing-2 fade-in  ${
            fadeOut && "fade-out-custom"
          }`}
        >
          <div className="tooltip-media column">
            <div className="tooltip-arrow">
              <img src={tooltipArrow} alt="tooltip arrow"></img>
            </div>
            <div className="tooltip-close-button-container">
              <div className="tt-close-button">
                <img src={tooltipClose} alt="close button"></img>
              </div>
            </div>

            <img className="mt-5" src={landingZoom} alt="landing"></img>
          </div>
          <div className="tooltip-info-wrap">
            <div className="tooltip-info">
              <h3>Explore the Eastern Rift</h3>
              <p>
                Hover over the map to view labeled grid coordinates.
                <br></br>
                Double-click to explore locations, but steer clear of restricted
                zones we've marked for safety.
              </p>
            </div>
            <div className="tooltip-info-footer">
              <div
                className="tooltip-next-button"
                onClick={() => setFadeOut(true)}
              >
                <h5>Close</h5>
              </div>
              <div className="tooltip-pagination">
                <h5>2 of 2</h5>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenMap ? <LandscapeLocation /> : ""}
    </>
  );
};

export default Mine;
