import React, { useState, useEffect, useRef, useCallback } from "react";
import avatar1 from "../../images/avatars/avatar1.png";
import avatar2 from "../../images/avatars/avatar2.png";
import avatar3 from "../../images/avatars/avatar3.png";
import avatar4 from "../../images/avatars/avatar4.png";
import avatar5 from "../../images/avatars/avatar5.png";
import avatar6 from "../../images/avatars/55.png";
import ImageUploading from "react-images-uploading";
import AuthService, { AdminUserModel, BASE_URL } from "../helper/Authservice";
import { ToastContainer, toast } from "react-toastify";
import AvatarImg from "../../images/avtar.png";
import Avatar from "../../images/icons/avartar-thumb.svg";
import DeleteIcon from "../../images/icons/delete.svg";

const AvatarSelection = (props) => {
    const services = {
        authService: new AuthService(),
    };
    const [winHeight, setWinHeight] = useState(window.innerHeight);
    const [images, setImages] = React.useState([]);
    const [btnActive, setBtnActive] = useState(false);
    const [user, setUser] = useState(services.authService._CurrentUser());
    const avUrl = user.avatarUrl.includes("uploadimg")
        ? user.avatarUrl
        : AvatarImg;
    const maxNumber = 1;
    const onChange = async (imageList) => {
        var action = "";
        var imageBase64 = "";
        var imageName = "";
        if (imageList.length > 0) {
            action = "uploadImage";
            imageBase64 = imageList[0].data_url;
            imageName = imageList[0].file.name;
        } else if (imageList.length === 0) {
            action = "removeImage";
        }
        updateImage(imageBase64, imageName, action);
        //setImages(imageList);
    };
    const updateImage = async (imageBase64, imageName, action) => {
        if (action != "") {
            setBtnActive(true);
            let response2 = await fetch(
                BASE_URL + "/Account/updateUserImage",
                {
                    method: "POST",
                    body: JSON.stringify({
                        base64Image: imageBase64,
                        userId: user.userId,
                        imgaeName: imageName,
                        action: action,
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                }
            );
            let data = await response2.json();
            if (response2.status === 200) {
                if (data.isSuccess) {
                    //toast.success(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    services.authService.setUser(data.data);
                    setUser(services.authService._CurrentUser());
                } else {
                    //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                }
            } else {
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        }
    };
    return (
        <div className="avatar-selection-background animate-in">
            <div className="avatar-selection-wrapper">
                <div className="as-content-wrapper">
                    <h5>Time to generate your licence</h5>
                    <div className="as-content">
                        <p>Please upload your avatar</p>
                    </div>
                    <div className="as-badge-content-wrap">
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                        >
                            {({ imageList, onImageUpload, onImageRemove }) => (
                                // write your building UI

                                <>
                                    {(!user.avatarUrl.includes("uploadimg") && user.registerType == "Email") && (
                                        <div className="as-badge">
                                            <div className="badge-text">
                                                <h1>{user.userName.charAt(0)}</h1>
                                            </div>
                                        </div>
                                    )}
                                    {(user.avatarUrl.includes("uploadimg") || user.registerType != "Email") && (
                                        <div key={1} className="mnft-img-wrap">
                                            {/* <div className='user-name'>B</div> */}
                                            <img src={user.avatarUrl} alt="" />
                                            <div className="remove-btn">
                                                <img
                                                    src={DeleteIcon}
                                                    alt="Delete"
                                                    onClick={() => onImageRemove(1)}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div onClick={onImageUpload} className="badge-btn-wrap">
                                        <h5>ADD IMAGE</h5>
                                    </div>
                                </>
                            )}
                        </ImageUploading>
                    </div>
                    <div className="as-separator">
                        <div className="separator-line"></div>
                        <h5>or choose from the options below</h5>
                        <div className="separator-line"></div>
                    </div>
                    <div className="avatar-group-wrapper">
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "avatar1.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar1}
                            ></img>
                        </div>
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "avatar2.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar2}
                            ></img>
                        </div>
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "avatar3.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar3}
                            ></img>
                        </div>
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "avatar4.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar4}
                            ></img>
                        </div>
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "avatar5.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar5}
                            ></img>
                        </div>
                        <div
                            className="avatar-wrapper"
                            onClick={() => {
                                updateImage("", "55.png", "uploadImage");
                            }}
                        >
                            <img
                                className="avatar-icon"
                                alt="avatar-male"
                                src={avatar6}
                            ></img>
                        </div>
                    </div>
                    <div className="long-btn-wrapper">
                        <div className="btn-wrap">
                            <button
                                type="button"
                                className={`long-btn ${btnActive && "long-btn-active"}`}
                                onClick={() => {
                                    props.setGenerateLicence(true);
                                    props.setAvatarSelection();
                                }}
                            >
                                <h5>generate my licence</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvatarSelection;
