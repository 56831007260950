import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import EarningIcon from '../../images/icons/earning.svg'
import DiscoveyIcon from '../../images/icons/discovery.svg'
import CoveredIcon from '../../images/icons/covered.svg'
import TravelIcon from '../../images/icons/travel-el-icon.svg'
import CoreIcon from '../../images/icons/core-el-icon.svg'
import MineIcon from '../../images/icons/mine-el-icon.svg'
import WatchIcon from '../../images/icons/watch.svg'
import TotalAreaIcon from '../../images/icons/total-area.svg'
import ProfileNav from './ProfileNav'
import Depth1 from '../../images/icons/depth-1.svg'
import Depth2 from '../../images/icons/depth-2.svg'
import Depth3 from '../../images/icons/depth-3.svg'
import LeftNavBar from '../landscape/LeftNavBar'
import RightNavBar from '../landscape/RightNavBar'
import './ilyrium.css'
import Loader from '../../Loader'
import CoreService from "../helper/Coreservice";

const Ilyrium = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showContent, setShowContent] = useState();
    const [isActive, setIsActive] = useState();
    const [winHeight, setWinHeight] = useState(window.innerHeight);
    const [userLocker, setUserLocker] = useState(null);
    const coreservices = {
        coreService: new CoreService(),
    };
    var energySpent=0
    var userData = userLocker != null ? userLocker.userStatistics : null;
    var imageList = userLocker != null ? userLocker.imageIdList : null;
    var coreList = userLocker != null ? userLocker.coreReportList : null;
    var corePathList = userLocker != null ? userLocker.corePathResult : null;
    var mineList = userLocker != null ? userLocker.mineReportList : null;
    var allMineList = [];
    if (mineList != null) {
        mineList.forEach((item) => {
            var firstDepth = mineList.find(z => z.imageId == item.imageId && z.depthTo == 1)
            var secondDepth = mineList.find(z => z.imageId == item.imageId && z.depthTo == 2)
            var thirdDepth = mineList.find(z => z.imageId == item.imageId && z.depthTo == 3)
            var allList = [];
            if (firstDepth != null) {
                allList.push(firstDepth);
            }
            if (secondDepth != null) {
                allList.push(secondDepth);
            }
            if (thirdDepth != null) {
                allList.push(thirdDepth);
            }
            if (allMineList == null || allMineList.length == 0 || allMineList.find(n => n.imageId == item.imageId) == null) {
                var obj = { imageId: item.imageId, mapImageNo: item.mapImageNo, allList: allList }
                allMineList.push(obj);
            }
            energySpent = energySpent + item.energySpent;
        })
    }
    if (mineList != null) {
        mineList.forEach((item) => {
            energySpent = energySpent + item.energySpent;
        })
    }
    var minePathList = userLocker != null ? userLocker.minePathResult : null;
    function secondsToHHMMSS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    useEffect(() => {
        const updateHeight = () => {
            setWinHeight(window.innerHeight - 70);
        }
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, []);

    //useEffect(() => {
    //  setTimeout(() => {
    //    setIsLoading(false);
    //    setShowContent(true);
    //  }, 1000);
    //  setTimeout(() => {
    //    setIsActive(true)
    //  }, 1050)
    //}, []);
    useEffect(() => {
        coreservices.coreService.getUserProfile("getilyrium").then((data) => {
            setUserLocker(data);
            setIsLoading(false);
            setShowContent(true);
            setTimeout(() => {
                setIsActive(true)
            }, 1050)
        });
    }, []);
    return (
        <>
            <LeftNavBar />
            <RightNavBar />
            <div className='inner-main-container' >
                {isLoading ? (
                    <Loader />
                ) : showContent ? (

                    <>
                        <div className={`inner-container-wrap ${isActive ? 'active fade' : 'fade'}`} style={{ height: winHeight }}>
                            <div className='inner-page-heading'>Ilyrium</div>
                            <div className='inner-content-container'>
                                <div className='ilyrium-content-wrap'>
                                    <div className='ilyrium-discoveries'>
                                        <div className='ilyd-info-wrap'>
                                            <div className='ilyd-title'>Net discoveries</div>
                                            <div className='ily-detail-wrap'>
                                                <div className='ily-icon-wrap'><ReactSVG src={DiscoveyIcon} /></div>
                                                <div className='ily-detail'>{userData.totalDiscoveries}kg</div>
                                            </div>
                                        </div>
                                        <div className='ilyd-info-wrap'>
                                            <div className='ilyd-title'>Net earnings</div>
                                            <div className='ily-detail-wrap'>
                                                <div className='ily-icon-wrap'><ReactSVG src={EarningIcon} /></div>
                                                <div className='ily-detail'>{userData.totalEarning}kg</div>
                                            </div>
                                        </div>
                                        <div className='ilyd-info-wrap'>
                                            <div className='ilyd-title'>Ground covered</div>
                                            <div className='ily-detail-wrap'>
                                                <div className='ily-icon-wrap'><ReactSVG src={CoveredIcon} /></div>
                                                <div className='ily-detail'>{userData.totalGrounCovered}m</div>
                                            </div>
                                        </div>
                                        <div className='ilyd-info-wrap'>
                                            <div className='ilyd-title'>Total session time</div>
                                            <div className='ily-detail-wrap'>
                                                    <div className='ily-icon-wrap'><ReactSVG src={WatchIcon} /></div>
                                                    <div className='ily-detail'>{secondsToHHMMSS(userData.totalSessionTime)}</div>
                                            </div>
                                        </div>

                                        <div className='ilyd-info-wrap'>
                                            <div className='ilyd-title'>Total area mined</div>
                                            <div className='ily-detail-wrap'>
                                                <div className='ily-icon-wrap'><ReactSVG src={TotalAreaIcon} /></div>
                                                <div className='ily-detail'>{userData.totalAreaMined}m</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='ilyrium-mine'>
                                        <div className='ily-mine-title'>Mines</div>
                                        <div className='ily-mine-wrap'>
                                            <div className='status find'>{mineList.filter(x => x.result == 1).length} successful mines</div>
                                            <div className='ily-mine-main-container'>
                                                <div className='ily-mine-wrapper'>
                                                    <div className='ily-mine-row ily-mine-header'>
                                                        <div className='ily-mine-number'>NO#.</div>
                                                        <div className='ily-mine-status'>STATUS</div>
                                                        <div className='ily-mine-depth'>Depth</div>
                                                        <div className='ily-mine-result'>RESULT</div>
                                                    </div>
                                                </div>
                                                <div className='ily-mine-wrapper'>
                                                    {allMineList && allMineList.length > 0 && allMineList.map((item, index) => (
                                                        item.allList != null && item.allList.length > 0 && item.allList.map((item2, index) => (
                                                            <div className='ily-mine-row'>
                                                                <div className='ily-mine-number'>{item.mapImageNo}-{item.allList.length}</div>
                                                                <div className='ily-mine-status'>COMPLETE</div>
                                                                <div className='ily-mine-depth'><img src={item2.depthTo == 1 ? Depth1 : item2.depthTo == 2 ? Depth2 : Depth3} alt='Depth' /></div>
                                                                <div className='ily-mine-result'><span className={`result ${1 === item2.result ? 'find' : 'none'}`}>{item2.result == 1 ? "Find" : "Not-Find"}</span></div>
                                                            </div>
                                                        ))
                                                    ))}
                                                    {/* Content Start Here */}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-04</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-03</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth3} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-02</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth2} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-01</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-04</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-03</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth3} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-02</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth2} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className='ily-mine-row'>*/}
                                                    {/*    <div className='ily-mine-number'>E8-01</div>*/}
                                                    {/*    <div className='ily-mine-status'>COMPLETE</div>*/}
                                                    {/*    <div className='ily-mine-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                                    {/*    <div className='ily-mine-result'><span className='result find'>Find</span></div>*/}
                                                    {/*</div>*/}
                                                    {/* Content Ends Here */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='ilyrium-energy-ledger'>
                                        <div className='ily-el-title'>Energy ledger</div>
                                        <div className='ily-energy-used'>
                                                {energySpent}kWh <span>USED</span>
                                        </div>
                                        <div className='ilyrium-energy-ledger-content'>
                                            <div className='ily-el-row ily-el-header'>
                                                <div className='ily-el-process'>NO.</div>
                                                <div className='ily-el-number'>STATUS</div>
                                                <div className='ily-el-detail'>Depth</div>
                                                <div className='ily-el-usage'>USAGE</div>
                                            </div>
                                            <div className='ily-el-content-wrap '>
                                                {/* Content End Here */}
                                                {imageList && imageList.length > 0 && imageList.map((item, index) => (
                                                    <>
                                                        {(coreList != null && coreList.find(x => x.imageId == item && x.depthTo == 1) != null) ?
                                                            (<div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>
                                                                    <span>Core</span></div>
                                                                <div className='ily-el-number'>#{coreList.find(x => x.imageId == item && x.depthTo == 1).mapImageNo}-{(coreList.find(x => x.imageId == item && x.depthTo == 1).totalCore < 10 ? "0" : "") + coreList.find(x => x.imageId == item && x.depthTo == 1).totalCore}</div>
                                                                <div className='ily-el-detail'>16m</div>
                                                                <div className='ily-el-usage'>{(coreList.find(x => x.imageId == item && x.depthTo == 1)).energySpent}kw</div>
                                                            </div>) : ""}
                                                        {(coreList != null && coreList.find(x => x.imageId == item && x.depthTo == 2) != null) ?
                                                            (
                                                                <div className='ily-el-row '>
                                                                    <div className='ily-el-process'>
                                                                        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>
                                                                        <span>Core</span></div>
                                                                    <div className='ily-el-number'>#{coreList.find(x => x.imageId == item && x.depthTo == 2).mapImageNo}-{(coreList.find(x => x.imageId == item && x.depthTo == 2).totalCore < 10 ? "0" : "") + coreList.find(x => x.imageId == item && x.depthTo == 2).totalCore}</div>
                                                                    <div className='ily-el-detail'>64m</div>
                                                                    <div className='ily-el-usage'>{(coreList.find(x => x.imageId == item && x.depthTo == 2)).energySpent}kw</div>
                                                                </div>
                                                            ) : ""}
                                                        {(coreList != null && coreList.find(x => x.imageId == item && x.depthTo == 3) != null) ? (
                                                            <div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>
                                                                    <span>Core</span></div>
                                                                <div className='ily-el-number'>#{(coreList.find(x => x.imageId == item && x.depthTo == 3)).mapImageNo}-{(coreList.find(x => x.imageId == item && x.depthTo == 3).totalCore < 10 ? "0" : "") + coreList.find(x => x.imageId == item && x.depthTo == 3).totalCore}</div>
                                                                <div className='ily-el-detail'>128m</div>
                                                                <div className='ily-el-usage'>{(coreList.find(x => x.imageId == item && x.depthTo == 3)).energySpent}kw</div>
                                                            </div>
                                                        ) : ""}
                                                        {(corePathList != null && corePathList.find(x => x.imageId == item) != null) ? (
                                                            <div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>
                                                                    <span>TRAVEL</span></div>
                                                                <div className='ily-el-number'>-</div>
                                                                <div className='ily-el-detail'>{(corePathList.find(x => x.imageId == item)).totalPathCovered}m</div>
                                                                <div className='ily-el-usage'>{(corePathList.find(x => x.imageId == item)).balanceDeduct}kw</div>
                                                            </div>
                                                        ) : ""}
                                                        {(mineList != null && mineList.find(x => x.imageId == item && x.depthTo == 1) != null) ?
                                                            (<div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>
                                                                    <span>MINING</span></div>
                                                                <div className='ily-el-number'>#{(mineList.find(x => x.imageId == item && x.depthTo == 1)).mapImageNo}-{(mineList.find(x => x.imageId == item && x.depthTo == 1).totalCore < 10 ? "0" : "") + mineList.find(x => x.imageId == item && x.depthTo == 1).totalCore}</div>
                                                                <div className='ily-el-detail'>{(mineList.find(x => x.imageId == item && x.depthTo == 1)).totalNode}m/16m</div>
                                                                <div className='ily-el-usage'>{(mineList.find(x => x.imageId == item && x.depthTo == 1)).energySpent}kw</div>
                                                            </div>) : ""}
                                                        {(mineList != null && mineList.find(x => x.imageId == item && x.depthTo == 2) != null) ?
                                                            (
                                                                <div className='ily-el-row '>
                                                                    <div className='ily-el-process'>
                                                                        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>
                                                                        <span>MINING</span></div>
                                                                    <div className='ily-el-number'>#{(mineList.find(x => x.imageId == item && x.depthTo == 2)).mapImageNo}-{(mineList.find(x => x.imageId == item && x.depthTo == 2).totalCore < 10 ? "0" : "") + mineList.find(x => x.imageId == item && x.depthTo == 2).totalCore}</div>
                                                                    <div className='ily-el-detail'>{(mineList.find(x => x.imageId == item && x.depthTo == 2)).totalNode}m/64m</div>
                                                                    <div className='ily-el-usage'>{(mineList.find(x => x.imageId == item && x.depthTo == 2)).energySpent}kw</div>
                                                                </div>
                                                            ) : ""}
                                                        {(mineList != null && mineList.find(x => x.imageId == item && x.depthTo == 3) != null) ? (
                                                            <div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>
                                                                    <span>MINING</span></div>
                                                                <div className='ily-el-number'>#{(mineList.find(x => x.imageId == item && x.depthTo == 3)).mapImageNo}-{(mineList.find(x => x.imageId == item && x.depthTo == 3).totalCore < 10 ? "0" : "") + mineList.find(x => x.imageId == item && x.depthTo == 3).totalCore}</div>
                                                                <div className='ily-el-detail'>{(mineList.find(x => x.imageId == item && x.depthTo == 3)).totalNode}m/128m</div>
                                                                <div className='ily-el-usage'>{(mineList.find(x => x.imageId == item && x.depthTo == 3)).energySpent}kw</div>
                                                            </div>
                                                        ) : ""}
                                                        {(minePathList != null && minePathList.find(x => x.imageId == item) != null) ? (
                                                            <div className='ily-el-row '>
                                                                <div className='ily-el-process'>
                                                                    <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>
                                                                    <span>TRAVEL</span></div>
                                                                <div className='ily-el-number'>-</div>
                                                                <div className='ily-el-detail'>{(minePathList.find(x => x.imageId == item)).totalPathCovered}m</div>
                                                                <div className='ily-el-usage'>{(minePathList.find(x => x.imageId == item)).balanceDeduct}kw</div>
                                                            </div>

                                                        ) : ""}
                                                    </>

                                                ))}


                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={CoreIcon} /></div>*/}
                                                {/*        <span>Core</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={TravelIcon} /></div>*/}
                                                {/*        <span>TRAVEL</span></div>*/}
                                                {/*    <div className='ily-el-number'>-</div>*/}
                                                {/*    <div className='ily-el-detail'>16m</div>*/}
                                                {/*    <div className='ily-el-usage'>1000kw</div>*/}
                                                {/*</div>*/}
                                                {/*<div className='ily-el-row '>*/}
                                                {/*    <div className='ily-el-process'>*/}
                                                {/*        <div className='ily-el-icon'><ReactSVG src={MineIcon} /></div>*/}
                                                {/*        <span>MINING</span></div>*/}
                                                {/*    <div className='ily-el-number'>#E10-10</div>*/}
                                                {/*    <div className='ily-el-detail'>300m/196m</div>*/}
                                                {/*    <div className='ily-el-usage'>24kw</div>*/}
                                                {/*</div>*/}
                                                {/* Content End Here */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
            <div className='ily-gradient-bottom'></div>
            <ProfileNav />

        </>
    )
}

export default Ilyrium