import React, { useState, useEffect, useRef, useCallback } from "react";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "../../images/icons/delete.svg";
import Avatar from "../../images/icons/avartar-thumb.svg";
import FactionImg from "../../images/faction.jpg";
import BrotherHoodLogo from "../../images/icons/brotherhood-logo.svg";
import IDCard from "../../images/id-card.png";
import Vector from "../../images/Vector-icon.png";
import Country from "../../images/Country-Flag.png";
import "./profile.css";
import ProfileNav from "./ProfileNav";
import LeftNavBar from "../landscape/LeftNavBar";
import RightNavBar from "../landscape/RightNavBar";
import Loader from "../../Loader";
import FactionBrotherHood from "../profile/FactionBrotherHood";
import AvatarImg from "../../images/avtar.png";
import AuthService, { BASE_URL } from "../helper/Authservice";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import "react-toastify/dist/ReactToastify.css";
const Profile = () => {
  const services = {
    authService: new AuthService(),
  };
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenForEdit, setOpenForEdit] = useState(false);
  const [isChangeButton, setChangeButton] = useState(false);
  const [fallenBrotherhood, setFallenBrotherhood] = useState();
  const [showContent, setShowContent] = useState();
  const [isActive, setIsActive] = useState();
  const [isEmailValid, setEmailValid] = useState(0);
  const [isFullNameValid, setFullNameValid] = useState(0);
  const [isNickNameValid, setNickNameValid] = useState(0);
  const [isPasswordValid, setPasswordValid] = useState(0);
  const [invalidEmailError, setEmailError] = useState("");
  const [invalidFullName, setFullNameError] = useState("");
  const [invalidNickName, setNickNameError] = useState("");
  const [invalidPassword, setPasswordError] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [images, setImages] = React.useState([]);
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const [user, setUser] = useState(services.authService._CurrentUser());
  const maxNumber = 1;
  var userN = ""; //user.userName;
  var fullName = ""; //user.fullName;
  var uPassword = ""; // user.password;
  const handleFallenBrotherHood = () => {
    setFallenBrotherhood("active");
  };

  const avUrl =
    user != null && user.avatarUrl.includes("uploadimg")
          ? user.avatarUrl : (user != null && user.registerType != "Email") ? user.avatarUrl
      : AvatarImg;
  useEffect(() => {
    const updateHeight = () => {
      setWinHeight(window.innerHeight - 70);
    };
    updateHeight();
    onButtonClick();
    window.addEventListener("resize", updateHeight);
    if (
      user.userName != username ||
      user.fullName != name ||
      user.password != password
    ) {
      setUserName(user.userName);
      setName(user.fullName);
      setPassword(user.password);
    }
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setShowContent(true);
    }, 1000);
    setTimeout(() => {
      setIsActive(true);
    }, 1050);
  }, []);
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    } else {
      document.getElementById("domEl").classList.remove("hide");
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        document.getElementById("domEl").classList.add("hide");
        document.getElementById("IdCard").src = dataUrl;
        //
        //const link = document.createElement('a')
        //link.download = 'my-image-name.png'
        //link.href = dataUrl
        //link.click()
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  const handleClick = (variable, value) => {
    var index = 0;
    if (variable == "email") {
      setEmail(value);
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        setEmailValid(-1);
        setEmailError("EMAIL NOT VAILD");
      } else {
        setEmailValid(1);
        setEmailError("");
      }
    }
    if (variable == "name") {
      fullName = value;
      setName(value);
      if (value.length < 3) {
        setFullNameValid(-1);
        setFullNameError("Name must be minimum 3 letters.");
      } else {
        setFullNameValid(1);
        setFullNameError("");
      }
    }
    if (variable == "username") {
      userN = value;
      setUserName(value);
      if (value.length < 3) {
        setNickNameValid(-1);
        setNickNameError("User Name must be minimum 3 letters.");
      } else {
        setNickNameValid(1);
        setNickNameError("");
      }
    }
    if (variable == "password") {
      uPassword = value;
      setPassword(value);
      const emailRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{8,}$/i;
      if (value === "") {
        setPasswordValid(0);
        setPasswordError("");
      } else if (value.length < 8) {
        setPasswordValid(1);
        setPasswordError("Minimum 8 character need");
      } else if (!emailRegex.test(value)) {
        setPasswordValid(2);
        setPasswordError(
          "Password must be include one uppercase,one lowercase,one number abd one special character"
        );
      } else {
        setPasswordValid(-1);
        setPasswordError("Secure");
      }
    }
    setChangeButton(true);
  };
  const updateUser = async (title, body) => {
    try {
      let response = await fetch(
          BASE_URL +"/Account/updateuser",
        {
          method: "POST",
          body: JSON.stringify({
            name: name,
            userName: username,
            password: password,
            email: email,
            userId: user.userId,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(JSON.stringify(data.data));
          setUser(services.authService._CurrentUser());
          setOpenForEdit(false);
          // location.reload();
        } else {
          setEmailValid(-1);
          setEmailError(data.errorMessage);
          //toast.error(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
          //alert(response.errorMessage);
        }
      } else {
        setEmailValid(-1);
        setEmailError("Some error occured");
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    } catch (ex) {
      services.authService.handleClickOnButton("logout");
    }
  };
  const onChange = async (imageList) => {
    var action = "";
    var imageBase64 = "";
    var imageName = "";
    if (imageList.length > 0) {
      action = "uploadImage";
      imageBase64 = imageList[0].data_url;
      imageName = imageList[0].file.name;
    } else if (imageList.length === 0) {
      action = "removeImage";
    }
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            base64Image: imageBase64,
            userId: user.userId,
            imgaeName: imageName,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          setUser(services.authService._CurrentUser());
        } else {
          //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
        }
      } else {
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    }
    //setImages(imageList);
  };
  return (
    <>
      <LeftNavBar />
      <RightNavBar />
      <div className="inner-main-container">
        {isLoading ? (
          <Loader />
        ) : showContent ? (
          <>
            <div
              className={`inner-container-wrap ${
                isActive ? "active fade" : "fade"
              }`}
              style={{ height: winHeight }}
            >
              <div className="inner-page-heading">Profile</div>
              <div className="inner-content-container">
                <div className="profile-content-wrap">
                  <div className="p-content p-details">
                    <div className="inner-section-heading">Details</div>
                    <div className="detail-form-wrapper ">
                      <div className="image-upload-wrap">
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({ imageList, onImageUpload, onImageRemove }) => (
                            // write your building UI
                            <>
                              <div className="mnft-user-profile-pic-wrap">
                                                                  {(user.avatarUrl.includes("uploadimg") || user.registerType != "Email") && (
                                  <div
                                    key={1}
                                    className="mnft-img-wrap-profile"
                                  >
                                    {/* <div className='user-name'>B</div> */}
                                    <img
                                      className="licence-image"
                                      src={user.avatarUrl}
                                      alt=""
                                    />
                                    {/*<div className="remove-btn">*/}
                                    {/*  <img*/}
                                    {/*    className="licence-image"*/}
                                    {/*    src={DeleteIcon}*/}
                                    {/*    alt="Delete"*/}
                                    {/*    onClick={() => onImageRemove(1)}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                  </div>
                                )}
                              </div>
                              <button onClick={onImageUpload} className="btn ">
                                Add Image
                              </button>
                            </>
                          )}
                        </ImageUploading>
                      </div>
                      <div
                        className={`auth-form-row ${
                          isFullNameValid === 0
                            ? ""
                            : isFullNameValid > 0
                            ? "active"
                            : "error"
                        }`}
                      >
                        <div className="af-title">Name</div>
                        <input
                          type="text"
                          tabIndex="1"
                          name="name"
                          id="name"
                          placeholder="YOUR NAME"
                          readOnly={!isOpenForEdit}
                          value={name}
                          onChange={(e) => handleClick("name", e.target.value)}
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) => (e.target.placeholder = "YOUR NAME")}
                        />
                        {/*<input type='text' name='name' id='name' placeholder='Name' value={ user?.fullName} />*/}
                      </div>
                      <div
                        className={`${
                          isFullNameValid === 0 ? "success" : "error"
                        }`}
                      >
                        {invalidFullName}
                      </div>
                      <div className="auth-form-row ">
                        <div className="af-title">Email</div>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="name@email.com"
                          readOnly="readonly"
                          value={user?.email}
                        />
                      </div>
                      <div
                        className={`auth-form-row ${
                          isNickNameValid === 0
                            ? ""
                            : isNickNameValid > 0
                            ? "active"
                            : "error"
                        }`}
                      >
                        <div className="af-title">Callsign</div>
                        <input
                          type="text"
                          tabIndex="3"
                          name="callsign"
                          id="callsign"
                          readOnly={!isOpenForEdit}
                          placeholder="YOUR CALLSIGN OR NICKNAME"
                          value={username}
                          onChange={(e) =>
                            handleClick("username", e.target.value)
                          }
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder = "YOUR CALLSIGN OR NICKNAME")
                          }
                        />
                        {/*<input type='text' name='callsign' id='callsign' placeholder='Type Callsign' value={user.userName} />*/}
                      </div>
                      <div
                        className={`${
                          isNickNameValid === 0 ? "success" : "error"
                        }`}
                      >
                        {invalidNickName}
                      </div>
                      <div
                        className={`auth-form-row ${
                          isPasswordValid === 0
                            ? ""
                            : isPasswordValid < 0
                            ? "active"
                            : "error"
                        }`}
                      >
                        <div className="af-title">Password</div>
                        <input
                          type="password"
                          tabIndex="4"
                          name="password"
                          id="password"
                          readOnly={!isOpenForEdit}
                          placeholder="•••••••••"
                          value={password}
                          onChange={(e) =>
                            handleClick("password", e.target.value)
                          }
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) => (e.target.placeholder = "•••••••••")}
                        />
                        {/*<input type='password' name='password' id='password' placeholder='•••••••••' />*/}
                      </div>
                      <div
                        className={`${
                          isPasswordValid < 0 ? "success" : "error"
                        }`}
                      >
                        {invalidPassword}
                      </div>
                    </div>
                    <div className="auth-button-wrap">
                      {!isOpenForEdit ? (
                        <button
                          type="submit"
                          className="btn btn-fluid"
                        >
                          Open / edit my file
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-fluid"
                          className={`btn btn-fluid ${
                            isChangeButton === true ? "btn-focus" : ""
                          }`}
                          onClick={() => updateUser()}
                        >
                          Update Profile
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="p-content p-faction">
                    <div className="inner-section-heading">Faction</div>
                    <div className="p-faction-content">
                      <img src={FactionImg} alt="Faction" />
                      <div className="p-faction-info-wrap">
                        <div className="p-faction-logo">
                          <img src={BrotherHoodLogo} alt="Brother Hood" />
                        </div>
                        <div className="p-faction-title">Apostles</div>
                        <div className="p-faction-btn-wrap">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => handleFallenBrotherHood()}
                          >
                            OPEN FILE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-content p-id-card">
                    <div className="inner-section-heading">ID</div>
                    <div className="p-id-card-content">
                      <div class="provisional-card hide" id="domEl" ref={ref}>
                        <div class="card-left-wrap">
                          <div class="avtar-outer">
                            <img
                              className="licence-image"
                              src={avUrl}
                              alt="Avatar"
                              onLoad={onButtonClick}
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                          <div class="vactor-outer">
                            <img src={Vector} alt="Vector" />
                          </div>
                          <div class="plot-no">
                            <p>Pilot No.</p>
                            <h5>{user.refferCode}</h5>
                          </div>
                          <div class="flag-outer">
                            <img src={Country} alt="Country" />
                          </div>
                        </div>
                        <div class="detail-info">
                          <div class="top-title">provisional</div>
                          <div class="user-info-group">
                            <div class="user-name">{user.userName} </div>
                            <div class="user-tagline">{user.fullName}</div>
                            <div class="user-address">
                              {" "}
                              Las Vegas, New Mexico, USA
                            </div>
                          </div>
                          <div class="info-group-wrap">
                            <div class="info-group-row">
                              <div class="info-group-title">Role</div>
                              <div class="info-group-detail">
                                Provisional
                                <br />
                                Pilot
                              </div>
                            </div>
                            <div class="info-group-row">
                              <div class="info-group-title">Faction</div>
                              <div class="info-group-detail">
                                The fallen
                                <br />
                                Brotherhood
                              </div>
                            </div>
                            <div class="info-group-row">
                              <div class="info-group-title">Date of issue</div>
                              <div class="info-group-detail">
                                {user.registerDate}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="id-car-wrap">
                        <img id="IdCard" src={IDCard} alt="Id Card" />
                      </div>
                      <div className="id-card-content">
                        In order to Pilot the P-48 successfully, utilise your
                        energy supply, and navigate the controls we strongly
                        encourage you to complete your Pilot Licence
                        Certification program.
                      </div>
                      <div className="id-card-btn-wrap">
                        <button
                          className="btn btn-fluid btn-secondary"
                          type="button"
                          onClick={() =>
                            services.authService.handleClickOnButton(
                              "training-complete"
                            )
                          }
                        >
                          gain pilot certification{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ProfileNav />
      {fallenBrotherhood == "active" ? (
        <FactionBrotherHood
          fallenBrotherhood={fallenBrotherhood}
          setFallenBrotherhood={setFallenBrotherhood}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Profile;
