import React from "react";
import logo from "../../images/icons/Logo.png";
const LandscapeLocation = () => {
  return (
    <div>
      <div className="landscape-footer-wrap">
        <footer className="landscapefooter">
          <img className="logo-bird" src={logo}></img>
          <h2>The Eastern Rift</h2>
          <p>105.03° N, 42.79° E</p>
        </footer>
      </div>
    </div>
  );
};

export default LandscapeLocation;
