import React from 'react'

const VerticalGridLines = () => {
    return (
        <div className='vertical-grid-line'>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
            <div className='grid-line'></div>
        </div>
    )
}

export default VerticalGridLines