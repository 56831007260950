import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import AuthService, { BASE_URL } from "../helper/Authservice";
import AuthLogo from "../../images/icons/auth-logo.svg";
import AuthCloseIcon from "../../images/icons/auth-close-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import Welcome from "./Welcome";
import "react-toastify/dist/ReactToastify.css";
const Register = (props) => {
    const [activeIndex, setActiveIndex] = useState();
    const [welcomePage, setWelcomePage] = useState(false);
    const [isEmailValid, setEmailValid] = useState(0);
    const [isFullNameValid, setFullNameValid] = useState(0);
    const [isNickNameValid, setNickNameValid] = useState(0);
    const [isPasswordValid, setPasswordValid] = useState(0);
    const [invalidEmailError, setEmailError] = useState("");
    const [invalidFullName, setFullNameError] = useState("");
    const [invalidNickName, setNickNameError] = useState("");
    const [invalidPassword, setPasswordError] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const [sliderChecked, setSliderChecked] = useState(false);
    const [name, setName] = useState("");
    const [username, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const services = {
        authService: new AuthService(),
    };
    const user = services.authService._CurrentUser();
    //const handleClick = (index) => {
    //    setActiveIndex(index);
    //};
    const handleClick = (variable, value) => {
        var index = 0;
        if (variable == "email") {
            setEmail(value);
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(value)) {
                setEmailValid(-1);
                setEmailError("EMAIL NOT VAILD");
            } else {
                setEmailValid(1);
                setEmailError("");
            }
        }
        if (variable == "name") {
            setName(value);
            if (value.length < 3) {
                setFullNameValid(-1);
                setFullNameError("Name must be minimum 3 letters.");
            } else {
                setFullNameValid(1);
                setFullNameError("");
            }
        }
        if (variable == "username") {
            setUserName(value);
            if (value.length < 3) {
                setNickNameValid(-1);
                setNickNameError("User Name must be minimum 3 letters.");
            } else {
                setNickNameValid(1);
                setNickNameError("");
            }
        }
        if (variable == "password") {
            setPassword(value);
            const emailRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{8,}$/i;
            if (value === "") {
                setPasswordValid(0);
                setPasswordError("");
            } else if (value.length < 8) {
                setPasswordValid(1);
                setPasswordError("Minimum 8 character need");
            } else if (!emailRegex.test(value)) {
                setPasswordValid(2);
                setPasswordError(
                    "Password must be include one uppercase,one lowercase,one number abd one special character"
                );
            } else {
                setPasswordValid(-1);
                setPasswordError("Secure");
            }
        }
    };
    if (isFullNameValid > 0 && isEmailValid > 0 && isNickNameValid > 0 && isPasswordValid < 0 && sliderChecked) {
        if (!canRegister) {
            setCanRegister(true);
        }
    }

        // Post with fetchAPI
        const userRegister = async (title, body) => {
            try {
                if (name == "" || name == null || name == undefined) {
                    setFullNameValid(-1);
                    setFullNameError("Name required");
                    return;
                }
                if (username == "" || username == null || username == undefined) {
                    setNickNameValid(-1);
                    setNickNameError("Nick name required");
                    return;
                }
                if (email == "" || email == null || email == undefined) {
                    setEmailValid(-1);
                    setEmailError("Email required");
                    return;
                }
                if (password == "" || password == null || password == undefined) {
                    setPasswordValid(1);
                    setPasswordError("Password required");
                    return;
                }
                debugger;
                let response = await fetch(BASE_URL + "/Account/register", {
                    method: "POST",
                    body: JSON.stringify({
                        name: name,
                        userName: username,
                        password: password,
                        email: email,
                        registerBy: "Email"
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                });
                let data = await response.json();
                if (response.status === 200) {
                    if (data.isSuccess) {
                        let response2 = await fetch(BASE_URL + "/Account/login", {
                            method: "POST",
                            body: JSON.stringify({
                                userName: email,
                                password: password,
                                registerBy: "Email"
                            }),
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                            },
                        }
                        );
                        let data2 = await response2.json();
                        if (response2.status === 200) {
                            services.authService.sendToken(data2);
                            setWelcomePage(true);
                            const timer = setTimeout(() => {
                                clearTimeout(timer);
                                window.location.reload();
                            }, 2000);
                            //services.authService.handleClickOnButton("welcome");
                            //props.handleFactionBadge();
                            //window.location.reload();
                            //props.handleFactionBadge();
                            //window.location.reload();
                            //props.setLoggedIn(true);
                        } else {
                            //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                        }
                        //toast.success("Register Successfully.", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                    } else {
                        setEmailValid(-1);
                        setEmailError(data.errorMessage);
                        //toast.error(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                        //alert(response.errorMessage);
                    }
                } else {
                    setEmailValid(-1);
                    setEmailError("Some error occured");
                    //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
                }
            } catch (ex) {
                debugger;
                services.authService.handleClickOnButton("logout");
            }
        };


    const CLosePopUp = () => {
        services.authService.handleClickOnButton("/");
    };
    return (
        <>
            {!welcomePage ? (
                <div className="authwrapper">
                    <div
                        className="auth-close"
                        onClick={() => CLosePopUp()}
                        onTouchStart={() => CLosePopUp()}
                    >
                        <div className="authclose-icon">
                            <ReactSVG src={AuthCloseIcon} />
                        </div>
                        <div className="auth-close-text">EXIT</div>
                    </div>
                    <div className="auth-container">
                        <div className="auth-logo">
                            <img src={AuthLogo} alt="Auth Logo" />
                        </div>
                        <div className="auth-title">REGISTRATION</div>
                        <div className="auth-form-wrapper">
                            <div
                                className={`auth-form-row ${isFullNameValid === 0
                                        ? ""
                                        : isFullNameValid > 0
                                            ? "active"
                                            : "error"
                                    }`}
                            >
                                <div className="af-title">Name</div>
                                <input
                                    type="text"
                                    tabIndex="1"
                                    name="name"
                                    id="name"
                                    placeholder="YOUR NAME"
                                    value={name}
                                    onChange={(e) => handleClick("name", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "YOUR NAME")}
                                />
                            </div>
                            <div className={`${isFullNameValid === 0 ? "success" : "error"}`}>
                                {invalidFullName}
                            </div>
                            <div
                                className={`auth-form-row ${isEmailValid === 0
                                        ? ""
                                        : isEmailValid > 0
                                            ? "active"
                                            : "error"
                                    }`}
                            >
                                <div className="af-title">Email</div>
                                <input
                                    type="text"
                                    tabIndex="2"
                                    name="email"
                                    id="email"
                                    placeholder="NAME@EMAIL.COM"
                                    value={email}
                                    onChange={(e) => handleClick("email", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "NAME@EMAIL.COM")}
                                />
                                {/*<div className={`${isEmailValid === 0 ? 'success' : 'error'}`}>{invalidEmailError}</div>*/}
                            </div>
                            <div className={`${isEmailValid === 0 ? "success" : "error"}`}>
                                {invalidEmailError}
                            </div>
                            <div
                                className={`auth-form-row ${isNickNameValid === 0
                                        ? ""
                                        : isNickNameValid > 0
                                            ? "active"
                                            : "error"
                                    }`}
                            >
                                <div className="af-title">Callsign</div>
                                <input
                                    type="text"
                                    tabIndex="3"
                                    name="callsign"
                                    id="callsign"
                                    placeholder="YOUR CALLSIGN OR NICKNAME"
                                    value={username}
                                    onChange={(e) => handleClick("username", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                        (e.target.placeholder = "YOUR CALLSIGN OR NICKNAME")
                                    }
                                />
                            </div>
                            <div className={`${isNickNameValid === 0 ? "success" : "error"}`}>
                                {invalidNickName}
                            </div>

                            <div
                                className={`auth-form-row ${isPasswordValid === 0
                                        ? ""
                                        : isPasswordValid < 0
                                            ? "active"
                                            : "error"
                                    }`}
                            >
                                <div className="af-title">Password</div>
                                <input
                                    type="password"
                                    tabIndex="4"
                                    name="password"
                                    id="password"
                                    placeholder="•••••••••"
                                    value={password}
                                    onChange={(e) => handleClick("password", e.target.value)}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "•••••••••")}
                                />
                            </div>
                            <div className={`${isPasswordValid < 0 ? "success" : "error"}`}>
                                {invalidPassword}
                            </div>
                            <div className="toggle-section">
                                <div className="toggle-btn">
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            onClick={() => setSliderChecked((prev) => !prev)}
                                        />
                                        <span
                                            class={`slider round ${!sliderChecked && "slider-checked"
                                                }`}
                                        ></span>
                                    </label>
                                </div>
                                <p>
                                    I accept the <span>Privacy Statement</span> and{" "}
                                    <span>Terms of Service</span>.
                                </p>
                            </div>
                        </div>
                        <div className="auth-button-wrap">
                            <button
                                tabIndex="5"
                                type="submit"
                                className={`btn btn-fluid ${canRegister && "auth-btn fade-in"}`}
                                onClick={() => userRegister()}
                            >
                                CONTINUE
                            </button>
                        </div>
                        <div className="auth-footer">
                            <div className="auth-footer-text">Already have an account?</div>
                            <div
                                tabIndex="6"
                                className="auth-footer-link"
                                onClick={() => props.handleSignIn()}
                            >
                                Log in Here
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Welcome />
            )}
        </>
    );
};

export default Register;
