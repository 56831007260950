import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import Training1img from "../../images/training_robo.png";
import Trainingcontrols from "../../images/training_controls.png";
import AuthService, { AdminUserModel } from "../helper/Authservice";

import "./training.css";

const TrainingHome = () => {
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  console.log(user);
  const navigate = useNavigate();
  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls "></div>
        <div className="training_container ">
          <div className="trnng_accrdn first-render animated-fadeIn ">
            <div className="trng_accrdn_slf">
              <div
                className={`trng_acrdn_tpttl status ${
                  user.isTrainingComplete && "complete"
                }`}
              >
                {user.isTrainingComplete
                  ? "Training complete"
                  : "READY TO Train"}
              </div>
              <ul>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. Energy</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">2. Exploration</span>
                      <span className="trnng_acrdn_status">
                        {" "}
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">3. Coring</span>
                      <span className="trnng_acrdn_status">
                        {" "}
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">4. Mining</span>
                      <span className="trnng_acrdn_status">
                        {" "}
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">
                        5. Your Discoveries
                      </span>
                      <span className="trnng_acrdn_status">
                        {" "}
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                {/* <li className="active">
                  <div className="trnng_prcs_sngl status incomplete">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. Energy</span>
                      <span className="trnng_acrdn_status">Incomplete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>asbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasa</li>
                        <li>asbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasaasbabsasas ajs ajsjas s asasasa</li>
                      </ol>
                    </div>
                  </div>
                </li> */}
              </ul>
              <button
                className="btn"
                onClick={() => navigate("/training-energy")}
              >
                Begin training
              </button>
            </div>
          </div>
          <div className="trnng_img_con animated-fadeIn">
            <div className="trnng_con_slf home_trnng_con_slf animated-fadeIn">
              <div className="trnng_ttl"> TRAINING DAY </div>
              <p>Welcome to training, Captain.</p>
              <p>
                To prepare you for the field, you are required to complete basic
                training to successfully pilot your P-48 Mining Mech.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingHome;
