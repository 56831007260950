import React from 'react'

const LocationInfo = (props) => {
    return (
        <>
            <div className='info-wrap'>
                <div className='info-wrap-row'>
                    <div className='info'>
                        <div className='info-title'>Location</div>
                        <div className='info-detail'>EASTERN RIFT</div>
                    </div>
                    <div className='info'>
                        <div className='info-title'>Latitude [C]</div>
                        <div className='info-detail'>-10.883°</div>
                    </div>
                </div>
                <div className='info-wrap-row'>
                    <div className='info'>
                        <div className='info-title'>SECTOR</div>
                        <div className='info-detail'>{props.mapName}</div>
                    </div>
                    <div className='info'>
                        <div className='info-title'>Longitude (E)</div>
                        <div className='info-detail'>328.219°</div>
                    </div>
                </div>
                
               
               
            </div>
        </>
    )
}

export default LocationInfo