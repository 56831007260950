import React, { useState, useEffect } from 'react'
import CoreSample from '../../images/core-sample.png'
import Star from '../../images/icons/star.svg'
import Depth1 from '../../images/icons/depth.svg'
import Depth2 from '../../images/icons/depth-2.svg'
import Depth3 from '../../images/icons/depth-3.svg'
import './coring.css'
const CoreDetails = (props) => {
    const depthIcons = [Depth1, Depth2, Depth3];
    const [count, setCount] = useState(0);
    useEffect(() => {
        //Implementing the setInterval method
       
    }, []);
    return (
        <>
            {props.coreCoordinatesList && props.coreCoordinatesList.length > 0 && props.coreCoordinatesList.filter(x=>x.result==1).map((item, index) => (
                 <div className='cd-wrap find' className={`cd-wrap ${(item.result === 1 && item.digStatus == 1) ? 'find' : (item.digStatus == 1 && item.result == 0) ? 'no-find' : 'inprogress'}`}>
                <div className='cd-header' style={{ backgroundImage: `url(${CoreSample})` }}>
                        CORE {item.coreNo}
                </div>
                <div className='cd-content'>
                    <div className='cd-distance cd-list'>
                        <div className='cd-row'>
                            <div className='cd-title'>Latitude</div>
                            <div className='cd-description'>-10.883° N</div>
                        </div>
                        <div className='cd-row'>
                            <div className='cd-title'>Longitude</div>
                            <div className='cd-description'>328.219° E</div>
                        </div>
                    </div>
                    <div className='cd-depth cd-list'>
                        <div className='cd-row'>
                            <div className='cd-title'>depth</div>
                            <div className='cd-description'><img src={depthIcons[item.depthTo - 1]} alt="Depth" />{item.depthTo == 1 ? "16m" : item.depthTo == 2 ? "64m" : "192m"}</div>
                        </div>
                        <div className='cd-row'>
                            <div className='cd-title'>yield</div>
                            <div className='cd-description'>1,362g</div>
                        </div>
                    </div>
                    <div className='cd-quality cd-list'>
                        <div className='cd-row'>
                            <div className='cd-title'>quality</div>
                            <div className='cd-description'>5 <img src={Star} alt='Star' /></div>
                        </div>
                    </div>
                </div>

            </div>

            ))}
        </>
    )
}

export default CoreDetails