import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import AuthLogo from '../../images/icons/auth-logo.svg'
import AuthCloseIcon from '../../images/icons/auth-close-icon.svg'

import AuthService, { AdminUserModel } from "../helper/Authservice";

const Welcome = () => {
    const services = {
        authService: new AuthService(),
    };
    var user = services.authService._CurrentUser();
    return (
        <div className='authwrapper'>
            <div className='auth-close' >
                <div className='authclose-icon'><ReactSVG src={AuthCloseIcon} /></div>
                <div className='auth-close-text'>EXIT</div>
            </div>
            <div className='welcome-container'>
                <div className='welcome-logo'><img src={AuthLogo} alt='Auth Logo' /></div>
                <div className='welcome-title'>Welcome, {user.userName}!</div>
                <div className='welcome-footer-text'>Let’s get started!</div>
               
               
            
            </div>
            
        </div>
    )
}

export default Welcome