const findUniqueShortestPath = (boxes) => {
  const distance = (point1, point2) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    return Math.abs(x1 - x2) + Math.abs(y1 - y2);
  };

  const getBorderPoints = ([x1, y1, x2, y2]) => {
    const points = [];
    for (let x = x1; x <= x2; x++) {
      points.push([x, y1], [x, y2]);
    }
    for (let y = y1; y <= y2; y++) {
      points.push([x1, y], [x2, y]);
    }
    return points;
  };

  const drawLine = (x1, y1, x2, y2) => {
    const points = [];
    for (let y = Math.min(y1, y2); y <= Math.max(y1, y2); y++) {
      points.push([x1, y]);
    }
    points.pop();
    for (let x = Math.min(x1, x2); x <= Math.max(x1, x2); x++) {
      points.push([x, y2]);
    }
    return points;
  };

  let paths = [];

  for (let i = 0; i < boxes.length - 1; i++) {
    const box1 = boxes[i].flat();
    const box2 = boxes[i + 1].flat();

    let shortestDistance = Infinity;
    let closestPoints = null;

    for (const point1 of getBorderPoints(box1)) {
      for (const point2 of getBorderPoints(box2)) {
        const dist = distance(point1, point2);
        if (dist < shortestDistance) {
          shortestDistance = dist;
          closestPoints = [point1, point2];
        }
      }
    }

    const linePoints = drawLine(...closestPoints[0], ...closestPoints[1]);
    paths.push(...linePoints);
  }

  const uniqueArray = [];
  const seen = new Map();

  for (const [x, y] of paths) {
    const key = `${x}-${y}`;
    if (!seen.has(key)) {
      seen.set(key, true);
      uniqueArray.push([x, y]);
    }
  }

  return uniqueArray;
};

export default findUniqueShortestPath;
