import React, { useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import Frameimg from "../../../images/frame.png";
import ArrowRight from "../../../images/icons/arrow-white.svg";
import ArrowRightRed from "../../../images/icons/arrow-right-red.svg";
import MapIcon from "../../../images/icons/map-icon.svg";
import MapIconFill from "../../../images/icons/map-icon-fill.svg";
import MoveIcon from "../../../images/icons/move.svg";
import FrameIcon from "../../../images/icons/frame-icon.svg";
import MapSelection from "../../../images/map-selection.png";
import "./frame.css";
import MoveSelectionModal from "./MoveSelectionModal";
import AuthService, { AdminUserModel } from "../../helper/Authservice";
import LocationGrid from "../LocationGrid";
const Frame = (props) => {
  const services = {
    authService: new AuthService(),
  };
  const [mapName, setMapName] = useState(props.mapName);
  const [isRestricted, setIsRestricted] = useState(false);
  const [direction, setDirection] = useState(props.mapName);
  const handleMapDetail = (mapName, isRestricted, direction) => {
    if (isRestricted) {
      direction = direction;
    }
    setMapName(mapName);
    setIsRestricted(isRestricted);
    setDirection("rotate(" + direction + "deg)");
  };
  return (
    <>
      <div className="frame-wrapper">
        <div className="frame">
          <div className={`fl-area-wrap ${isRestricted ? "not-find" : ""}`}>
            <div>Location</div>
            <img
              style={{ transform: direction }}
              src={isRestricted ? ArrowRightRed : ArrowRight}
            />
            <div>
              {mapName} {isRestricted ? " Restrcted" : ""}
            </div>
          </div>

          {/* <div className='location-cordinate'>
            <div className='lc-box'>
              <img src={FrameIcon} alt='Frame' />
              <div className='location-img-wrap'>
                <img src={MapSelection} alt='Map Selection' />
              </div>
            </div>
          </div> */}
          <LocationGrid
            mapName={props.mapName}
            reportMode={props.reportMode}
            handleMapDetail={handleMapDetail}
          />
          <img src={Frameimg} alt="Frame" />
          <div
            className="full-screen-wrap"
            onClick={() => services.authService.handleClickOnButton("basemine")}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Frame;
