import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import posterGroup from "../../images/mine-poster-group.png";
import samsungTab from "../../images/samsung-tab-8.png";
import iphone from "../../images/iPhone-14-Pro.png";
import iconGroup from "../../images/iconGroup.png";
import Training6img from "../../images/training_discoveries.png";
import Trainingcontrols from "../../images/training_controls.png";
import AuthService, { AdminUserModel } from "../helper/Authservice";

import "./training.css";
import Loader from "../../Loader";

const TrainingCoring = () => {
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [loadedImages, setLoadedImages] = useState(0);
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  console.log(user);

  useEffect(() => {
    setLoadedImages(0);
    const img = new Image();
    const img2 = new Image();
    const img3 = new Image();
    const img4 = new Image();
    img.src = posterGroup;
    img2.src = samsungTab;
    img3.src = iphone;
    img4.src = iconGroup;
    img.onload = () => setLoadedImages((prev) => prev + 1);
    img2.onload = () => setLoadedImages((prev) => prev + 1);
    img3.onload = () => setLoadedImages((prev) => prev + 1);
    img4.onload = () => setLoadedImages((prev) => prev + 1);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    console.log(viewportWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls"></div>
        <div class="training_container">
          <div class="trnng_accrdn">
            <div className="trng_accrdn_slf">
              <div
                className={`trng_acrdn_tpttl status ${
                  user.isTrainingComplete ? "complete" : "progress"
                }`}
              >
                {user.isTrainingComplete
                  ? "Training complete"
                  : "training in progress"}
              </div>
              <ul>
                <li>
                  <div className="trnng_prcs_sngl status complete">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. Energy</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Energy Use: It’s important to use your Ilyrium supply
                          wisely. Each move requires different amounts of
                          energy. Keep an eye on your energy meter to give you
                          an indication on how you’re tracking and how to play
                          your next move.
                        </li>
                        <li>
                          Time Restrictions: Your Ilyrium is only charged for
                          24h once activated. It is activated following your
                          first action – by either dropping a Core or Opening a
                          mine.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="trnng_prcs_sngl status complete">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">2. Exploration</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Once inside a section, Ilyrium can be discovered at
                          multiple depths (16m, 64m, 192m). Hover or click on
                          any one grid to either Core or Mine that coordinate.{" "}
                        </li>
                        <li>
                          Coring allows you to gather samples at different
                          depths and is faster than mining. Use cores to explore
                          the section faster and gather information about the
                          location of Ilyrium.{" "}
                        </li>
                        <li>
                          Mining is a more laborious process as the Mech is
                          required to dig through an entire region at a specific
                          depth, rather than simply drop test tubes. In the
                          spirit of effeciency, we recommend you explore a
                          section by dropping a wide variety of cores first.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="trnng_prcs_sngl status complete">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">3. Coring</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          With Ilyrium hidden at 3 different depths, we believe
                          it’s best to core multiple different depths inside any
                          given section to gather the best information as to
                          where and at what depth Ilyrium might be hidden.
                        </li>
                        <li>
                          Conserving energy is critical, so it’s best to plan a
                          path from the outline in, or inside out in order to
                          minimise your energy expended on travel.
                        </li>
                        <li>
                          If you are feeling more confident and looking to
                          rapidly drop a large amount of cores at a set depth,
                          you can use the depth locker module, located in the
                          top left of your console. To unlock, simply click the
                          locked depth again to reactive your dropdown on click
                          within the map.
                        </li>
                        <li>
                          Checking the Core Calculator prior to deploying your
                          core will also help you optimise your energy.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="trnng_prcs_sngl status complete  first-render animated-fadeIn">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">4. Mining</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Opening a mine is always best based on informed
                          research conducted within the Coring phase, however
                          you can also open a mine anywhere you desire.
                        </li>
                        <li>
                          To open mine, click and drag the rectangle over the
                          desired area and watch as each grid returns a result.
                        </li>
                        <li>
                          Remember, a grid coordinate is only exhausted once you
                          have mined at all three grid levels. Rich deposits may
                          even exist across all three.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="active">
                  <div
                    className={`trnng_prcs_sngl status first-render animated-fadeIn ${
                      user.isTrainingComplete
                        ? "complete"
                        : "progress inprogress"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">
                        5. your discoveries
                      </span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "complete" : "in progress"}
                      </span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Throughout the Eastern Rift are rare scatters of
                          Ilyrium Crystals – the most prized and powerful of any
                          form of Ilyrium. If you should find any of these,
                          you’ll not only inherit it’s powerful energy source,
                          but be rewarded by the Brotherhood with your own
                          verified discovery card.
                        </li>
                        <li>
                          Your discoveries will be stored within your Profile,
                          sharing them amongst your comrades does XXXXX.{" "}
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                {viewportWidth <= 1350 && (
                  <div
                    id="discovery-right-col"
                    className="right-col-wrap animated-fadeIn"
                  >
                    <div className="poster-group">
                      <img src={posterGroup} alt="poster group"></img>
                    </div>
                    <div className="samsung-tab">
                      <img
                        className="samsung-tab-img"
                        src={samsungTab}
                        alt="samsung tab"
                      ></img>
                    </div>
                    <div className="iphone">
                      <img src={iphone} alt="iphone"></img>
                    </div>
                    <div className="right-frame">
                      <div className="right-frame-top">
                        <img src={iconGroup} alt="icon group"></img>
                      </div>
                      <p className="right-frame-text">
                        Rare crystals are graded according to rarity, they can
                        also be charged, dramatically increase their power, by
                        sharing them to your favourite social platforms. Be sure
                        to look out for the energy gains you can get on your
                        rare crystal discoveries!
                      </p>
                    </div>
                  </div>
                )}
              </ul>
              <button
                class="btn"
                onClick={() => navigate("/training-complete")}
              >
                Next
              </button>
            </div>
          </div>

          {viewportWidth > 1350 &&
            (loadedImages < 4 ? (
              <Loader />
            ) : (
              <div id="discovery-right-col" className="right-col-wrap">
                <div className="poster-group">
                  <img src={posterGroup} alt="poster group"></img>
                </div>
                <div className="samsung-tab">
                  <img
                    className="samsung-tab-img"
                    src={samsungTab}
                    alt="samsung tab"
                  ></img>
                </div>
                <div className="iphone">
                  <img src={iphone} alt="iphone"></img>
                </div>
                <div className="right-frame">
                  <div className="right-frame-top">
                    <img src={iconGroup} alt="icon group"></img>
                  </div>
                  <p className="right-frame-text">
                    Rare crystals are graded according to rarity, they can also
                    be charged, dramatically increase their power, by sharing
                    them to your favourite social platforms. Be sure to look out
                    for the energy gains you can get on your rare crystal
                    discoveries!
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingCoring;
