import avatar from "../../images/avatars/55.png";
import { ReactSVG } from "react-svg";
import loaderLogo from "../../images/loader-logo.svg";
import vector from "../../images/icons/badge1.png";
import grain from "../../images/avatars/GRAIN.png";
import vector3 from "../../images/avatars/vector3.png";
import { useState, useRef, useEffect } from "react";
import MechInspect from "./MechInspect";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import ArrowDown from "../../images/icons/arrow-down.svg";
import p48 from "../../images/avatars/P48.png";
import Loader from "../../Loader";
import AnimatedButton from '../animatedButton/AnimatedButton'
function useScrollHeight() {
  const ref = useRef(null);
  const [scrollHeight, setScrollHeight] = useState();
  useEffect(() => {
    if (ref.current) {
      setScrollHeight(ref.current.scrollHeight + 10);
    }
  }, []);

  return [ref, scrollHeight];
}
const MinePreparation = (props) => {
  const [showInspect, setShowInspect] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [isHeightExceeded, setIsHeightExceeded] = useState(false);
  const [divRef1, scrollHeight1] = useScrollHeight();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    const img = new Image();
    img.src = vector3;
    img.onload = () => setShowLoader(false);
  }, []);

  const handleShow = () => {
    setShowInspect(true);
  };
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
      setIsHeightExceeded("");
    } else {
      setActive(index);
      setIsHeightExceeded("");
    }
  };
  const handleToggle1 = (index) => {
    if (active1 === index) {
      setActive1(null);
      setIsHeightExceeded("");
    } else {
      setActive1(index);
      setIsHeightExceeded("");
    }
  };

    const handleAnimationButton = () => {
        props.handleClickOnButtonByUser("active3");
    };
    const handleClose = () => {
        setShowInspect(false);
    };
    const services = {
        authService: new AuthService(),
    };
    services.authService.removeMinePrep();
    const [user, setUser] = useState(services.authService._CurrentUser());
    const avUrl = user.avatarUrl.includes("uploadimg") ? user.avatarUrl : avatar;

    return (
        <>
            <div className="mine-prep-background animate-in">
                {showLoader && <Loader />}
                <div className="mine-prep-wrapper">
                    <div className="mp-left-wrap">
                        <h1>prepare to mine</h1>
                        <p>
                            You have been authorised to pilot the P-48 under a Provisional
                            Licence. Further your understanding of the game will make you a
                            better Pilot and whilst it is recommended you complete your
                            Certification, you can choose to do so at any time.
                        </p>
                        <div className="mp-btn-wrap">
                            <AnimatedButton handleAnimationButton={handleAnimationButton} buttonText={"Deploy to field"} />
                            {/*<button className="mp-light-btn">*/}
                            {/*  <h5*/}
                            {/*    className="mp-lb-text"*/}
                            {/*    onClick={() => {*/}
                            {/*      props.handleClickOnButtonByUser("active3");*/}
                            {/*    }}*/}
                            {/*  >*/}
                            {/*    Start mining without certification*/}
                            {/*  </h5>*/}
                            {/*</button>*/}
                            {/*<button*/}
                            {/*  className="mp-dark-btn"*/}
                            {/*  onClick={() =>*/}
                            {/*    services.authService.handleClickOnButton("training")*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  <h5 className="mp-db-text">*/}
                            {/*    enter pilot certification program*/}
                            {/*  </h5>*/}
                            {/*</button>*/}
                        </div>
                        <div
                            className={`accordion-toggle-new section-title ${active === 1 ? "active" : ""
                                }`}
                            onClick={() => handleToggle(1)}
                        >
                            <h4>Your Licence </h4>
                            <ReactSVG src={ArrowDown} className="accordion-icon" />
                        </div>
                        <div className="mob-wrapper">
                            <div
                                className="mp-licence-wrap"
                                style={{ display: active === 1 ? "none" : "block" }}
                            >
                                <div className="mp-licence-card-wrap">
                                    <div className="mp-lc-top">
                                        <div className="np-lc-top-group">
                                            <img src={vector} alt="vector"></img>
                                        </div>
                                    </div>
                                    <div className="mp-lc-front">
                                        <div className="mp-lc-front-wrap">
                                            <img
                                                className="grain-texture"
                                                src={grain}
                                                alt="grain"
                                            ></img>
                                            <div className="mp-lc-fw-content">
                                                <div className="subtract">
                                                    <div className="subtract-in">
                                                        <h1>provisional</h1>
                                                        <div className="pfp-wrap">
                                                            <div className="pfp-background">
                                                                <img
                                                                    src={avUrl}
                                                                    alt="avatar"
                                                                    className="pfp"
                                                                ></img>
                                                            </div>
                                                        </div>
                                                        <h1>pilot</h1>
                                                        <div className="logo-wrap">
                                                            <div className="loader-badge">
                                                                <img src={loaderLogo} alt="loader-logo" />
                                                                <div className="badge-background"></div>
                                                            </div>
                                                        </div>

                                                        <div className="text-bottom">
                                                            <p className="text-bottom-left">SKYHAVEN</p>
                                                            <p className="text-bottom-right">
                                                                STRATOSPHERE 6
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="licence-plate-wrap ">
                                    <h1>YOUR LICENCE</h1>
                                    <p>
                                        In order to Pilot the P-48 successfully, utilise your energy
                                        supply, and navigate the controls we strongly encourage you
                                        to complete your Pilot Licence Certification program.
                                    </p>
                                    <button
                                        onClick={() =>
                                            services.authService.handleClickOnButton("training")
                                        }
                                    >
                                        <h5>enter pilot certification program</h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`accordion-toggle-new section-title ${active1 === 1 ? "active" : ""
                                }`}
                            onClick={() => handleToggle1(1)}
                        >
                            <h4>YOUR MINING MECH </h4>
                            <ReactSVG src={ArrowDown} className="accordion-icon" />
                        </div>
                        <div className="mob-wrapper">
                            <div
                                className="mp-licence-wrap"
                                style={{ display: active1 === 1 ? "none" : "block" }}
                            >
                                <div className="mp-mech-card-wrap">
                                    <div className="mp-lc-top">
                                        <div className="np-lc-top-group">
                                            <img src={vector} alt="vector"></img>
                                        </div>
                                    </div>
                                    <div className="mech-card-front">
                                        <img
                                            className="vector-img"
                                            src={vector3}
                                            alt="vector2"
                                        ></img>
                                    </div>
                                </div>
                                <div className="licence-plate-wrap mrb">
                                    <h1>YOUR MINING MECH</h1>
                                    <p>
                                        In order to Pilot the P-48 successfully, utilise your energy
                                        supply, and navigate the controls we strongly encourage you
                                        to complete your Pilot Licence Certification program.
                                    </p>
                                    <button
                                        onClick={() => {
                                            handleShow();
                                        }}
                                    >
                                        <h5>INSPECT MECH</h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

          <div className="mp-middle-wrap">
            <div className="mp-top-content">
              <div className="mp-badge-wrap">
                <img src={vector} alt="vector"></img>
              </div>
              <div className="mp-tc-content-wrap">
                <div className="card-front">
                  <div className="mp-id-content">
                    <img
                      className="grain-texture"
                      src={grain}
                      alt="grain"
                    ></img>
                    <h1 className="red-big-text">provisional</h1>
                    <div className="mp-id-image-wrap">
                      <img src={avUrl} alt="avatar" className="pfp"></img>
                    </div>
                    <h1 className="red-big-text">pilot</h1>
                    <div className="logo-wrap">
                      <div className="loader-badge">
                        <div className="badge-background">
                          <div style={{ position: "relative" }}>
                            <img
                              className="mine-prep-logo"
                              src={loaderLogo}
                              alt="loader-logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-bottom">
                      <p className="text-bottom-left">SKYHAVEN</p>
                      <p className="text-bottom-right">STRATOSPHERE 6</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="licence-plate-wrap">
              <h1>YOUR LICENCE</h1>
              <p>
                In order to Pilot the P-48 successfully, utilise your energy
                supply, and navigate the controls we strongly encourage you to
                complete your Pilot Licence Certification program.
              </p>
              <button
                onClick={() =>
                  services.authService.handleClickOnButton("training")
                }
              >
                <h5>enter pilot certification program</h5>
              </button>
            </div>
          </div>

          <div className="mp-right-wrap">
            <div className="mp-top-content">
              <div className="mp-badge-wrap">
                <img src={vector} alt="vector"></img>
              </div>
              <div className="mp-image-holder">
                <img className="vector-top-img" src={p48} />
                <img className="vector-img" src={vector3} alt="vector2"></img>
              </div>
            </div>
            <div className="mp-licence-wrap">
              <div className="licence-plate-wrap">
                <h1>YOUR MINING MECH</h1>
                <p>
                  In order to Pilot the P-48 successfully, utilise your energy
                  supply, and navigate the controls we strongly encourage you to
                  complete your Pilot Licence Certification program.
                </p>
                <button
                  onClick={() => {
                    handleShow();
                  }}
                >
                  <h5>INSPECT MECH</h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInspect && (
        <MechInspect
          handleClose={handleClose}
          handleClickOnButtonByUser={props.handleClickOnButtonByUser}
          animateOut={animateOut}
          setAnimateOut={setAnimateOut}
        />
      )}
    </>
  );
};

export default MinePreparation;
