const BonusIcon = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_718_4441)">
        <path
          d="M13.9609 19.9219C12.6002 19.9219 11.3178 19.6614 10.1133 19.1406C8.91536 18.6198 7.85742 17.9004 6.93945 16.9824C6.02148 16.0644 5.30208 15.0065 4.78125 13.8086C4.26041 12.6041 4 11.3216 4 9.96092C4 8.60026 4.26041 7.32096 4.78125 6.12305C5.30208 4.91862 6.01823 3.85742 6.92968 2.93945C7.84765 2.02149 8.9056 1.30208 10.1035 0.781249C11.3079 0.260419 12.5904 0 13.9511 0C15.3118 0 16.5943 0.260419 17.7988 0.781249C19.0032 1.30208 20.0644 2.02149 20.9824 2.93945C21.9003 3.85742 22.6197 4.91862 23.1406 6.12305C23.6614 7.32096 23.9219 8.60026 23.9219 9.96092C23.9219 11.3216 23.6614 12.6041 23.1406 13.8086C22.6197 15.0065 21.9003 16.0644 20.9824 16.9824C20.0644 17.9004 19.0032 18.6198 17.7988 19.1406C16.6009 19.6614 15.3216 19.9219 13.9609 19.9219ZM11.0704 15.166L13.9609 13.0566L16.8515 15.166C17.0533 15.3157 17.2421 15.3743 17.4179 15.3418C17.6002 15.3092 17.7337 15.2116 17.8183 15.0488C17.9095 14.886 17.916 14.6907 17.8378 14.4629L16.6953 11.0547L19.6152 8.96484C19.7975 8.84115 19.9016 8.68815 19.9277 8.50586C19.9537 8.31706 19.9081 8.15104 19.791 8.00781C19.6738 7.86458 19.4883 7.79623 19.2344 7.80274L15.6503 7.83203L14.5567 4.4043C14.4851 4.17643 14.3678 4.02669 14.205 3.95508C14.0423 3.87695 13.8795 3.87695 13.7167 3.95508C13.554 4.02669 13.4368 4.17643 13.3652 4.4043L12.2812 7.83203L8.69726 7.80274C8.44336 7.79623 8.25781 7.86458 8.14062 8.00781C8.02343 8.14453 7.97786 8.30729 8.0039 8.4961C8.02994 8.67839 8.13411 8.83789 8.3164 8.97461L11.2363 11.0547L10.0937 14.4629C10.0156 14.6907 10.0188 14.886 10.1035 15.0488C10.1946 15.2116 10.3281 15.3092 10.5039 15.3418C10.6861 15.3743 10.875 15.3157 11.0704 15.166Z"
          fill="#00FF85"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_718_4441"
          x="0"
          y="0"
          width="27.9219"
          height="27.9219"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.913725 0 0 0 0 0.0509804 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_718_4441"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_718_4441"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default BonusIcon;
