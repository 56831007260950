import React, { useEffect, useState } from "react";

import LeftRuller from "./LeftRuller";
import TopRuller from "./TopRuller";
import BottomRuller from "./BottomRuller";
import RightRuller from "./RightRuller";
import Grid from "../../images/768-Grid-bg.svg";
import Core from "./Core";
import map from "../../images/map.png";
import RestrictedGrid from "./RestrictedGrid";

import GridPathway from "./GridPathway";
import Loader from "../../Loader";
import CoreService from "../helper/Coreservice";
import AuthService from "../helper/Authservice";
import InspectingComponent from "../mainGrid/InspectingComponent";
import { useDispatch, useSelector } from "react-redux";
import { setPathSelect, setTooltipInspecting } from "../../appSlice";

const MainGrid = (props) => {
  const gridSize = 32;
  const { selectedGrid, mapData, pathSelect, tooltipInspecting } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const coreservices = {
    coreService: new CoreService(),
  };
    const services = {
        authService: new AuthService(),
    };
    const [user, setUser] = useState(services.authService._CurrentUser());
  //const [mapDetailList, setMapDetailList] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [pathCoordinate, setPathCoordinate] = useState([]);
  const [pathCoordinateComplete, setPathCoordinateComplete] = useState([]);
  const [cursorLocation, setCursorLocation] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowLoader(true);
    const img = new Image();
    img.src = map;
      img.onload = () => {
          setShowLoader(false);
          /*if (location.pathname.startsWith("/inspect")) {*/
              if (user?.isInspectTripComplete == false) {
                  if (tooltipInspecting == 0) {
                      dispatch(setTooltipInspecting(1));
                  }
              } else {
                  if (tooltipInspecting != 0) {
                      dispatch(setTooltipInspecting(0));
                  }
              }
          /*}*/
      };
  }, []);

  const handleMouseMove = (event) => {
    const containerRect = event.currentTarget.getBoundingClientRect();
    let x = Math.floor((event.clientX - containerRect.x) / gridSize);
    let y = Math.floor((event.clientY - containerRect.y) / gridSize);

    // Constrain x and y to be between 0 and 24
    x = Math.min(Math.max(0, x), 23);
    y = Math.min(Math.max(0, y), 23);

    setCursorLocation({ x, y });
  };

  const handleMouseDown = (event) => {
    console.log(event);
    if (!expanded && cursorLocation) {
      setExpanded(true);

      setDropLocation({ x: cursorLocation.x, y: cursorLocation.y });
    } else {
      setExpanded(false);
      setDropLocation(null);
    }
  };
  const handleTouchStart = (event) => {
    const containerRect = event.currentTarget.getBoundingClientRect();
    const touch = event.touches[0]; // Get the first touch point
    let x = Math.floor((touch.clientX - containerRect.x) / gridSize);
    let y = Math.floor((touch.clientY - containerRect.y) / gridSize);

    // Constrain x and y to be between 0 and 24
    x = Math.min(Math.max(0, x), 23);
    y = Math.min(Math.max(0, y), 23);

    if (!expanded) {
      setExpanded(true);
      setDropLocation({ x, y });
    } else {
      setExpanded(false);
      setDropLocation(null);
    }
  };

  let pathCoordinatesLatest = [];
  let pathCoordinates = [];

  const filterUserDigRealDecrease = (myArray) => {
    var abc = myArray
      .filter((x) => x.result < 3)
      .sort((b, a) => a.digId - b.digId);
    return abc;
  };
  const filterUserDigReal = (myArray) => {
    var abc = myArray
      .filter((x) => x.result < 3)
      .sort((a, b) => Number(a.digId) - Number(b.digId));
    return abc;
  };
  let userNodesAll = [];
  //let userDigTemp = coreCoordinatesList;
  const UpdatePathEnergy = (coreCoordinatesList) => {
    if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
      for (var i = 0; i < coreCoordinatesList.length; i++) {
        var nodePathList = coreCoordinatesList[i];
        if (nodePathList != undefined && nodePathList.length > 0) {
          var nodeArray = nodePathList
            .map((x) => Number(x.id))
            .sort((a, b) => Number(a) - Number(b));

          var abc = Math.floor(nodePathList.length / 2);
          var middleNode = nodeArray[abc];
          var pathCharge =
            mapData != null && mapData.userBalance != null
              ? mapData.userBalance.nodeCost
              : 1;
          var str =
            "<span className='energyclass' style='background-color: white;font-size: 10px;position: absolute;width: 40px;opacity: 0.9;top:25%;left:-13%;text-align:center'>" +
            (nodePathList.length * pathCharge).toString() +
            "KW" +
            "</span>";
          const box = document.getElementById("id_" + middleNode);
          console.log(box);
          if (box != null) {
            box.insertAdjacentHTML("beforeend", str);
          }
        }
      }
    }
  };
  const RemovePathEnergy = (coreCoordinatesList) => {
    if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
      for (var i = 0; i < coreCoordinatesList.length; i++) {
        var nodePathList = coreCoordinatesList[i];
        if (nodePathList != undefined && nodePathList.length > 0) {
          var nodeArray = nodePathList
            .map((x) => Number(x.id))
            .sort((a, b) => Number(a) - Number(b));
          var abc = Math.floor(nodePathList.length / 2);
          var middleNode = nodeArray[abc];
          const box = document.getElementById("id_" + middleNode);
          if (box != null) {
            var paras = box.getElementsByTagName("span");
            while (paras[0]) paras[0].parentNode.removeChild(paras[0]);
          }
        }
      }
    }
  };

  useEffect(() => {
    let coreCoordinatesListTemp =
      mapData == null
        ? null
        : mapData.mapDetailList.filter((x) => x.result >= 0 && x.result < 3);
    console.log(coreCoordinatesListTemp);
    setPathCoordinate([]);
    coreCoordinatesListTemp = filterUserDigReal(coreCoordinatesListTemp);
    pathCoordinates = coreservices.coreService.FindAllNodes(
      coreCoordinatesListTemp
    );
    setPathCoordinateComplete(pathCoordinates);
    RemovePathEnergy(pathCoordinates);
    if (
      coreservices.coreService.getMapDataGrid() != null &&
      coreservices.coreService.getMapDataGrid().length > 0
    ) {
      pathCoordinates = coreservices.coreService.FindAllNodes(
        coreservices.coreService.getMapDataGrid(),
        true
      );
      setPathCoordinate(pathCoordinates);
      UpdatePathEnergy(pathCoordinates);
    }
    var totalPath = 0;
    pathCoordinates.forEach((line) => {
      totalPath = totalPath + line.length;
    });
    pathCoordinateComplete.forEach((line) => {
      totalPath = totalPath + line.length;
    });
    if (pathSelect != totalPath) {
      dispatch(setPathSelect(totalPath));
    }
    mapData.mapDetailList.forEach((x, index) => {
      if (x.result <= 3) {
        const gridItem = (
          <div className="grid-item" key={index} onClick={null}>
            <Core
              depth={x.depthTo}
              find={x.result}
              activateFill={x.depth1 < 0 ? true : false}
              id={x.id}
              isComplete={true}
              core={x.coreNo}
            />
          </div>
        );
        setGridItems((prev) => {
          return {
            ...prev,
            [index]: gridItem,
          };
        });
      }
    });
    console.log(pathCoordinate);
  }, [mapData]);
  useEffect(() => {
    mapData.mapDetailList.forEach((x, index) => {
      if (!x.isRestricted && x.result >= 3) {
        var gridItem = (
          <div className="grid-item" key={index} id={"id_" + x.id}></div>
        );
        var pp = selectedGrid.find((p) => p.id == x.id);
        if (pp != null) {
          gridItem = (
            <div className="grid-item" key={index} onClick={null}>
              <Core
                depth={pp.depthTo}
                find={pp.result}
                activateFill={pp.depth1 < 0 ? true : false}
                id={pp.id}
                isComplete={true}
                core={pp.coreNo}
              />
            </div>
          );
        }
        setGridItems((prev) => {
          return {
            ...prev,
            [index]: gridItem,
          };
        });
      }
    });
    var abc = pathCoordinate;
    RemovePathEnergy(abc);
    pathCoordinates = coreservices.coreService.FindAllNodes(selectedGrid, true);
    setPathCoordinate(pathCoordinates);
    UpdatePathEnergy(pathCoordinates);
    var totalPath = 0;
    pathCoordinates.forEach((line) => {
      totalPath = totalPath + line.length;
    });
    pathCoordinateComplete.forEach((line) => {
      totalPath = totalPath + line.length;
    });
    if (pathSelect != totalPath) {
      dispatch(setPathSelect(totalPath));
    }
    console.log(pathCoordinate);
  }, [selectedGrid]);

  useEffect(() => {
    const emptyObject = {};

    if (mapData && mapData.mapDetailList && mapData.mapDetailList.length > 0) {
      mapData.mapDetailList.forEach((item, index) => {
        const row = item.rowNo - 1;
        const col = item.colNo - 1;

        if (item.isRestricted === true) {
          emptyObject[row * gridSize + col] = (
            <div className="grid-item" id={"id_" + item.id} key={index}>
              <RestrictedGrid />
            </div>
          );
        } else {
          emptyObject[row * gridSize + col] = (
            <div className="grid-item" key={index} id={"id_" + item.id}>
              {/*<CoringComponent core={item.coreNo}/>*/}
            </div>
          );
        }
      });
    }

    setGridItems(emptyObject);
  }, [mapData]);
  return (
    <div className="map-container">
      {showLoader && <Loader />}
      <div className="grid-outer">
        <LeftRuller />
        <TopRuller />
        <BottomRuller />
        <RightRuller />
        <img className="map-image" src={map}></img>
        <img className="grid" src={Grid} alt="Grid" />
        {expanded && dropLocation && (
          <InspectingComponent
            y={dropLocation.y}
            x={dropLocation.x}
            inspect={
              mapData.mapDetailList[dropLocation.y * 24 + dropLocation.x]
            }
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )}
        <div
          className="grid-wrap"
          onMouseMove={windowWidth >= 450 ? handleMouseMove : null}
          onMouseDown={windowWidth >= 450 ? handleMouseDown : null}
          onTouchStart={windowWidth < 450 ? handleTouchStart : null}
          style={{
            userSelect: "none",
            cursor: expanded ? "pointer" : "none",
            zIndex: 1,
          }}
        >
          {tooltipInspecting == 7 && (
            <div className="grid-wrap-border-inspect fade-in"></div>
          )}
          {!expanded && cursorLocation && (
            <InspectingComponent y={cursorLocation.y} x={cursorLocation.x} />
          )}
          {}
          {/* <PathwayComplete path={pathCoordinateComplete} />*/}
          <GridPathway path={pathCoordinate} />
          {Object.values(gridItems)}
        </div>
      </div>
      {/* {showCursor && <CoringComponent />} */}
    </div>
  );
};

export default MainGrid;
