import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Draggable from "react-draggable";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import Travel from "../../images/icons/travel-orange.svg";
import TravelWhite from "../../images/travel-white.svg";
import TravelRed from "../../images/travel-red.svg";
import TotalEngrgy from "../../images/icons/total-energy.svg";
import TotalEnergyYellow from "../../images/total-energy-yellow.svg";
import TotalEnergyRed from "../../images/total-energy-red.svg";

/*import CoringProcess from '../../images/coring-process.svg'*/
import CoringProcess from "../../images/animation.gif";
import EnergyProcess from "../../images/icons/energy-process.svg";
import FXIcon from "../../images/icons/fx-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    setCoreReportModal,
    setSelectedGrid,
    setEnergyLevel,
} from "../../appSlice";
import AnimatedButton from "../animatedButton/AnimatedButton";
var depth1RealSelector = 0;
var depth2RealSelector = 0;
var depth3RealSelector = 0;
var depth1Selector = 0;
var depth2Selector = 0;
var depth3Selector = 0;
var totalPath = 0;

const CoreMap = (props) => {
    const {
        isCoreReportModal,
        selectedGrid,
        mapData,
        showLoading,
        pathSelect,
        pathSelectTemp,
        userToBeBalance,
        energyLevel,
        tooltipCoring,
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    const [count, setCount] = useState(0);
    //const [energyLevel, setEnergyLevel] = useState("");

    const handleLoading = () => {
        props.deployCores();
    };
    const handleOpenCalculator = () => {
        props.deployCores();
    };
    var depth1Charge = 0;
    var depth2Charge = 0;
    var depth3Charge = 0;
    var pathCharge = 0;
    depth1Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.firstDepthCharge
            : 0;
    depth2Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.secondndDepthCharge
            : 0;
    depth3Charge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.thirdDepthCharge
            : 0;
    pathCharge =
        mapData != null && mapData.userBalance != null
            ? mapData.userBalance.nodeCost
            : 0;
    const coreCoordinatesList =
        mapData == null
            ? null
            : mapData.mapDetailList.filter((x) => {
                return x.result < 3;
            });

    if (selectedGrid != null && selectedGrid.length > 0) {
        var depth1Grid = selectedGrid.filter((x) => {
            return x.depthTo == 1;
        });
        if (depth1Selector != depth1Grid.length) {
            depth1Selector = depth1Grid.length;
        }
        var depth2Grid = selectedGrid.filter((x) => {
            return x.depthTo == 2;
        });
        if (depth2Selector != depth2Grid.length) {
            depth2Selector = depth2Grid.length;
        }
        var depth3Grid = selectedGrid.filter((x) => {
            return x.depthTo == 3;
        });
        if (depth3Selector != depth3Grid.length) {
            depth3Selector = depth3Grid.length;
        }
    } else {
        depth1Selector = depth2Selector = depth3Selector = totalPath = 0;
    }
    if (coreCoordinatesList.length > 0) {
        var depth1Grid = coreCoordinatesList.filter((x) => {
            return x.depthTo == 1;
        });
        if (depth1RealSelector != depth1Grid.length) {
            depth1RealSelector = depth1Grid.length;
        }
        var depth2Grid = coreCoordinatesList.filter((x) => {
            return x.depthTo == 2;
        });
        if (depth2RealSelector != depth2Grid.length) {
            depth2RealSelector = depth2Grid.length;
        }
        var depth3Grid = coreCoordinatesList.filter((x) => {
            return x.depthTo == 3;
        });
        if (depth3RealSelector != depth3Grid.length) {
            depth3RealSelector = depth3Grid.length;
        }
    }

    if (pathSelect + pathSelectTemp != totalPath) {
        totalPath = pathSelect + pathSelectTemp;
    }
    useEffect(() => {
        //Implementing the setInterval method
        //const interval = setInterval(() => {
        //    setCount(count + 1);
        //}, 1000);
    }, []);

    return (
        <Draggable
            defaultPosition={{ x: 0, y: 0 }}
            disabled={winWidth > 1023 ? false : true}
            position={null}
            bounds="parent"
        >
            <div
                className={`core-map-wrap ${props.showCoreCalculator ? "" : "hide"}`}
            >
                {tooltipCoring == 5 && (
                    <div className="calculator-border fade-in"></div>
                )}
                {showLoading ? (
                    <div className="core-map">
                        <div className="cm-title-wrap">
                            {winWidth >= 1024 ? (
                                <div className="fx-circle-wrap">
                                    <div className="fx-circle">
                                        <img src={FXIcon} alt="FX" />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="fx-circle-wrap"
                                    onClick={() => props.handelCoreCalculator()}
                                >
                                    <div className="fx-circle">
                                        <img src={FXIcon} alt="FX" />
                                    </div>
                                </div>
                            )}
                            <div className="cm-title">
                                Core journey <br /> energy calculator
                            </div>
                        </div>

                        <div className="cm-depth-wrap">
                            <div className="cm-depth-row">
                                <div className="cm-depth-icon">
                                    <img src={Depth1} alt="Depth 1" />
                                </div>
                                <div className="cm-depth-value">
                                    {depth1Selector + depth1RealSelector}
                                </div>
                                <div className="cm-depth-divider"></div>
                                <div className="cm-energy-ultlize">
                                    {(depth1Selector + depth1RealSelector) * depth1Charge}kW
                                </div>
                            </div>
                            <div className="cm-depth-row">
                                <div className="cm-depth-icon">
                                    <img src={Depth2} alt="Depth 2" />
                                </div>
                                <div className="cm-depth-value">
                                    {depth2Selector + depth2RealSelector}
                                </div>
                                <div className="cm-depth-divider"></div>
                                <div className="cm-energy-ultlize">
                                    {(depth2Selector + depth2RealSelector) * depth2Charge}kW
                                </div>
                            </div>
                            <div className="cm-depth-row">
                                <div className="cm-depth-icon">
                                    <img src={Depth3} alt="Depth 3" />
                                </div>
                                <div className="cm-depth-value">
                                    {depth3Selector + depth3RealSelector}
                                </div>
                                <div className="cm-depth-divider"></div>
                                <div className="cm-energy-ultlize">
                                    {(depth3Selector + depth3RealSelector) * depth3Charge}kW
                                </div>
                            </div>
                        </div>
                        <div className="cm-divider"></div>
                        <div className="cm-travel-wrap">
                            <div className="cm-travel-icon">
                                <img
                                    src={
                                        energyLevel === "red"
                                            ? TravelRed
                                            : energyLevel === "yellow"
                                                ? Travel
                                                : TravelWhite
                                    }
                                    alt="Travel"
                                />
                            </div>
                            <div className="cm-travel-title">Travel</div>
                            <div className="cm-travel-energy">{totalPath * pathCharge}</div>
                        </div>
                        <div className="cm-divider"></div>
                        <div className="cm-total-wrap">
                            <div className="cm-total-icon">
                                <img
                                    src={
                                        energyLevel === "red"
                                            ? TotalEnergyRed
                                            : energyLevel === "yellow"
                                                ? TotalEnergyYellow
                                                : TotalEngrgy
                                    }
                                    alt="Travel"
                                />
                            </div>
                            <div className={"cm-total-title " + energyLevel}>Total</div>
                            <div className={"cm-total-energy " + energyLevel}>
                                {(depth1Selector + depth1RealSelector) * depth1Charge +
                                    (depth2Selector + depth2RealSelector) * depth2Charge +
                                    (depth3Selector + depth3RealSelector) * depth3Charge +
                                    totalPath * pathCharge}
                                kw
                            </div>
                        </div>
                        {energyLevel === "yellow" ? (
                            <div>
                                <div className="cm-divider"></div>
                                <div className={"cm-extra-title-yellow"}>
                                    Warning! Ilyrium low
                                </div>
                            </div>
                        ) : (
                            energyLevel === "red" && (
                                <>
                                    <div className="cm-divider"></div>
                                    <div className={"cm-extra-title-red"}>
                                        error! not enough Ilyrium
                                    </div>
                                </>
                            )
                        )}

                        <div className="cm-footer">
                            {selectedGrid != null && selectedGrid.length > 0 && (
                                <div className="mm-footer report-btn">
                                    <AnimatedButton
                                        buttonText={energyLevel == "red" ? "Cannot Core" : "Deploy Cores"}
                                        color={energyLevel}
                                        type="inward"
                                        arrows={1}
                                        disabled={userToBeBalance < 0}
                                        handleAnimationButton={() => handleLoading()}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="core-map-process">
                        <div className="cm-divider"></div>
                        <div className="coring-process-anim">
                            <img src={CoringProcess} alt="Coring Process" />
                        </div>
                        <div className="cm-divider"></div>
                        <div className="cm-process-wrap">
                            <div className="cm-process-icon">
                                <img src={EnergyProcess} alt="Energy Process" />
                            </div>
                            <div className="cm-process-title">CORING IN PROGRESS</div>
                        </div>
                        <div className="cm-divider"></div>
                    </div>
                )}
            </div>
        </Draggable>
    );
};

export default CoreMap;
