import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import Location from "../location/Location";
import CoringSampleImg from "../../images/coring-sample.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
/*import Wave from '../../images/wave.svg'*/
import "../coring/coring.css";
import Monitor from "../monitor/MonitorNew";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import { useDispatch, useSelector } from "react-redux";
import { setShowManual} from "../../appSlice";
import CoreIcon from "../../images/icons/CoreIcon";
var isOpenOppup = false;
const Inspecting = (props) => {
  const {
    isCoreReportModal,
    selectedGrid,
    mapData,
    isReady,
    isShowButton,
    userToBeBalance,
    tooltipInspecting,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const depthIcons = [Depth1, Depth2, Depth3];
  const services = {
    authService: new AuthService(),
  };
  const [count, setCount] = useState(0);
  var coreCoordinatesList =
    mapData == null
      ? null
      : mapData.mapDetailList
          .filter((x) => x.result < 3)
          .sort((b, a) => a.digId - b.digId);
  if (localStorage.getItem("isCoreStart") === "true") {
    coreCoordinatesList = coreCoordinatesList.sort(
      (a, b) => Number(a.digId) - Number(b.digId)
    );
    console.log(
      new Date() +
        " end timer=" +
        " data2=" +
        JSON.stringify(coreCoordinatesList)
    );
  }
  if (coreCoordinatesList.length > 0) {
    isOpenOppup = true;
  }
  const mapName =
    mapData != null && mapData.imageData != null
      ? mapData.imageData.mapImageNo
      : "";

  useEffect(() => {}, []);
  const handleToPage = (pageName) => {
    if (pageName == "mining") {
      window.location = "/mining/" + props.id;
    } else {
      window.location = "/coring/" + props.id;
    }
  };

  return (
    <>
      <div className="left-bar-content">
        <Monitor pageOpen={props.pageOpen} />
        {tooltipInspecting == 1 && (
          <div className="monitor-border fade-in"></div>
        )}
        <div id="left-bar-inner" className="col-left-inner">
          {tooltipInspecting == 2 && (
            <div className="location-border fade-in"></div>
          )}
          {tooltipInspecting == 3 && (
            <div className="navigator-border fade-in"></div>
          )}
          {tooltipInspecting == 4 && (
            <div className="control-border fade-in"></div>
          )}

          <Location mapName={mapName} />
          <div className="ready-wrapper">
            <div className="status ready">
              <CoreIcon color={"ready"} />
              <CoreIcon color={"ready"} />
              <CoreIcon color={"ready"} />
              READY TO EXPLORE
              <CoreIcon color={"ready"} />
              <CoreIcon color={"ready"} />
              <CoreIcon color={"ready"} />
            </div>
            <div className="explore-text">
              ilyirium has been detected, drop cores at different depths to
              chart the territory prior to mining.
            </div>
            <button className="btn" onClick={() => handleToPage("coring")}>
              START CORING
            </button>
            <button className="btn" onClick={() => handleToPage("mining")}>
              START MINING
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspecting;
