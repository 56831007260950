import React, { useEffect, useState } from "react";
import authLogo from "../../images/icons/auth-logo.svg";
import AuthCloseIcon from "../../images/icons/auth-close-icon.svg";
import { ReactSVG } from "react-svg";
import close from "../../images/icons/close.svg";
import AuthService, { BASE_URL } from "../helper/Authservice";
const ForgetPassword = () => {
    const services = {
        authService: new AuthService(),
    };
    const [isEmailValid, setEmailValid] = useState(0);
    const [email, setEmail] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [invalidEmailError, setEmailError] = useState("");
    const [canRegister, setCanRegister] = useState(false);
    const handleClick = (variable, value) => {
        var index = 0;
        if (variable == "email") {
            setEmail(value);
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(value)) {
                setEmailValid(-1);
                setEmailError("EMAIL NOT VAILD");
            } else {
                setEmailValid(1);
                setEmailError("");
            }
        }
    };
    if (isEmailValid > 0) {
        if (!canRegister) {
            setCanRegister(true);
        }
    }
    const userLogin = async (title, body) => {
        try {
            if (email == "" || email == null || email == undefined) {
                setEmailValid(-1);
                setEmailError("Email required");
                return;
            }
            let response = await fetch(BASE_URL + "/Account/forgotpassword", {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            let data = await response.json();

            if (response.status === 200) {
                if (data.isSuccess) {
                    setShowModal(true);
                }
                else {
                    setEmailError(data.errorMessage);
                    setEmailValid(-1);
                }
            } else {
                //setEmailError(data.errorMessage);
                setEmailError("Invalid Email or Password");
                setEmailValid(-1);
                //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
            }
        } catch (ex) {
            //services.authService.handleClickOnButton("logout");
        }
    };
    const CLosePopUp = () => {
        services.authService.handleClickOnButton("/");
    };
    return (
        <div className="authwrapper">
            <div
                className="auth-close"
                onClick={() => CLosePopUp()}
            // onTouchStart={() => CLosePopUp()}
            >
                <div className="authclose-icon">
                    <ReactSVG src={AuthCloseIcon} />
                </div>
                <div className="auth-close-text">EXIT</div>
            </div>
            {showModal && (
                <div
                    className={`fp-modal-container fade-in ${fadeOut && "fade-out-custom"
                        }`}
                >
                    <div className="fp-modal-wrap">
                        <div
                            className="modal-close-button"
                            onClick={() => {
                                setFadeOut(true);
                                setTimeout(() => {
                                    setShowModal(false);
                                }, 500);
                            }}
                        >
                            <img src={close} alt="close" />
                        </div>
                        <div className="fp-modal">
                            <div className="fp-modal-header">
                                <p>CHECK YOUR INBOX</p>
                            </div>
                            <p>AN EMAIL HAS TO SENT TO YOU.</p>
                            <div className="modal-button" onClick={() => CLosePopUp()}>
                                <p>BACK TO HOMEPAGE</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="fp-container fade-in">
                <img src={authLogo} alt="logo" />
                <div className="fp-header">
                    <h4>Forgotten your password?</h4>
                </div>
                <div className="fp-subtitle">
                    <p>
                        Enter your email address to reset your password. <br></br>You may
                        need to check your spam folder or unblock <br></br>
                        no-reply@archangelressurection.com.
                    </p>
                </div>
                <div
                    className={`auth-form-row ${isEmailValid === 0 ? "" : isEmailValid > 0 ? "active" : "error"
                        }`}
                >
                    <div className="af-title">Email</div>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="name@email.com"
                        value={email}
                        onChange={(e) => handleClick("email", e.target.value)}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "name@email.com")}
                    />
                </div>
                <div className={`${isEmailValid === 0 ? "success" : "error"}`}>
                    {invalidEmailError}
                </div>
                <div className="auth-button-wrap">
                    <button type="submit" className={`btn btn-fluid ${canRegister && "auth-btn fade-in"}`} onClick={() => userLogin()}>
                        RESET PASSWORD
                    </button>
                </div>
                <div className="fp-footer-wrap">
                    <div className="fp-footer">
                        <p>remember your password?</p>
                        <p className="href" onClick={() => CLosePopUp()}>Log in Here</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
