import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LeftCol from "../leftcol/LeftCol";
import Map from "../map/Map";
import CoringComplete from "../coring/CoringCompleteModal";
import CoreService from "../helper/Coreservice";
import AuthService from "../helper/Authservice";
import SidebarRight from "../infonav/Infonav";
import { useDispatch, useSelector } from "react-redux";
import {
    setSelectedGrid,
    setMapData,
    setIsReady,
    setShowButton,
    setShowLoading,
    setCoringComplete,
    setGridSelectors,
    setSessionTime,
    setCoreReportModal,
    setUserBalance,
    setUserTempBalance,
    setMechEnergy,
    setSnackbarQueue
} from "../../appSlice";

var timeOut = 0;
var timeOutInterval = 0;
var timeOutInterval1 = 0;
var isCallOver = false;
var tempData = null;
var iddd = 0;
const Coring = () => {
    const { selectedGrid, sessionTime, isCoreReportModal, userTempBalance, snackbarQueue } =
        useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [isOpenMap, setOpenMap] = useState(false);
    //const [isOpenMech, setOpenMech] = useState(false);
    //const [isCoringComplete, setCoringComplete] = useState(false);
    //const [userBalance, setUserBalance] = useState(0);
    //const [mapData, setMapData] = useState(null);
    //const [mapMineData, setMapMineData] = useState(null);
    //const [depthSelect, setDepthSelect] = useState(0);
    //const [selectedGrid, setselectedGrid] = useState([]);
    //const [gridSelectors, setGridSelectors] = useState([]);
    //const [selectedPath, setMapPath] = useState([]);
    //const [isReady, isSetReady] = useState(false);
    //const [pathSelect, setPathSelect] = useState(0);
    //const [isShowButton, setShowButton] = useState(true);
    //const [showLoading, setShowLoading] = useState(true);
    //const [isCoreReportModal, setCoreReportModal] = useState(false);
    const [pageOpen, setPageOpen] = useState("mining");
    const coreservices = {
        coreService: new CoreService(),
    };
    const authServices = {
        service: new AuthService(),
    };
    const mapId = useParams();
    const navigate = useNavigate();
    if (localStorage.getItem("isSetReport") == "active") {
        if (!isCoreReportModal) {
            dispatch(setCoreReportModal(true));
        }
    }
    //const handelSelectedGrid1 = () => {
    //    setDepthSelect(depthSelect);
    //    if (selectedGrid != null && selectedGrid.length > 0) {
    //        isSetReady(true);
    //    }
    //};
    //const handleCoreReportModel = () => {
    //    localStorage.removeItem('updateMineData')
    //    setCoreReportModal(!isCoreReportModal)
    //}
    const deployCores = async () => {
        //var depth1Grid = props.selectedGrid.filter(x => { return x.depthTo == 1 });
        //var depth1Price = depth1Grid * 50;
        //var depth2Grid = props.selectedGrid.filter(x => { return x.depthTo == 2 });
        //var depth2Price = depth2Grid * 100;
        //var depth3Grid = props.selectedGrid.filter(x => { return x.depthTo == 3 });
        //var depth3Price = depth3Grid * 150;
        //if (selectedGrid.length > 0) {
        dispatch(setShowButton(false));
        dispatch(setShowLoading(false));
        localStorage.removeItem("updateMineData");
        localStorage.setItem("isCoreStart", true);
        console.log(new Date() + " Start timer=");
        var stGrid = coreservices.coreService.getMineTempData();
        var stGridDig = stGrid;
        //if (stGridDig.length > 0) {
        //    localStorage.setItem("setId", stGridDig[0].id);
        //}
        //var pathCoordinates = coreservices.coreService.FindAllNodes(stGridDig, false, true);
        var imageIdListTemo = [];
        var nn = [];
        stGridDig.forEach((line) => {
            var iiList = [];
            line.arr.forEach((line2) => {
                iiList.push(line2.id);
            });
            var abc = selectedGrid.find((n) => n.id === line.id);
            var mapGroupObj = {
                start: JSON.stringify(abc.start),
                end: JSON.stringify(abc.end),
                depth: abc.depth.toString(),
                startNodeName: abc.nodeName,
                totalNode: abc.totalNode.toString(),
                result: abc.result.toString(),
                isRestricted: abc.isRestricted == false ? "0" : "1",
                restrictedGrid: abc.restrictedGrid.toString(),
                bonusPercentage: abc.bonusPercentage.toString(),
                bonus: abc.bonus.toString(),
                mechCharge: abc.mechCharge.toString(),
            };
            imageIdListTemo.push({
                depth: line.depth.toString(),
                imageIdList: iiList,
                imageId: mapId.id,
                mineGroup: mapGroupObj,
            });
            nn.push(abc);
        });

        var response = coreservices.coreService.FinAllNodesInMineAll(
            nn,
            false,
            true
        );
        const pathCoordinates = response.AllNodesReal;
        var totalTime = (new Date() - new Date(sessionTime)) / 1000;
        const sessionModel = {
            startFrom: sessionTime,
            endTo: new Date().toISOString(),
            sessionKey: authServices.service.getBearerToken(),
            totalTime: totalTime.toString(),
        };
        timeOut = 0;
        var tii = 0;


        //coreservices.coreService
        //  .mineGridTime(imageIdListTemo, pathCoordinates, sessionModel)
        //  .then((data) => {
        //    tempData = data;
        //    var tt = 0;
        //    var data1 = coreservices.coreService.getMapTempData();
        //    var ball =
        //      coreservices.coreService.getMapTempData().userBalance.balance;
        //    if (userTempBalance !== ball) {
        //      dispatch(setUserTempBalance(ball));
        //      dispatch(setUserBalance(ball));
        //      authServices.service.setUserBalance(ball);
        //    }
        //    for (var i = 0; i < selectedGrid.length; i++) {
        //      var ggGrid = coreservices.coreService.getMapDataGrid();
        //      var line = ggGrid[0];
        //      data1.mapGroupModel.find(
        //        (x) => x.nodeName == line.nodeName
        //      ).result = 2;
        //      data1.mapGroupModel.find((x) => x.nodeName == line.nodeName).depthTo =
        //        -1;
        //      timeOut = timeOut >= line.totalNode ? timeOut : line.totalNode;
        //      timeOut = timeOut == null || timeOut === undefined ? 10 : timeOut;
        //      var sList = ggGrid.filter((n) => n.id != line.id);
        //      coreservices.coreService.saveDataEntireGrid(sList);
        //    }
        //    console.log("timeoutt==" + timeOut.toString());
        //    dispatch(setGridSelectors([]));
        //    dispatch(setSelectedGrid([]));
        //    dispatch(setMapData(data1));
        //    coreservices.coreService.saveData(data1);
        //    timeOutInterval = setTimeout(() => {
        //      clearTimeout(timeOutInterval);
        //      updateSelectedGrid([]);
        //    }, timeOut * 600 + 1000);
        //  });

        const data = await coreservices.coreService.mineGridTime(imageIdListTemo, pathCoordinates, sessionModel);
        for (let i = 0; i < selectedGrid.length; i++) {
            const data1 = coreservices.coreService.getMapTempData();
            const ggGrid = coreservices.coreService.getMapDataGrid();
            const line = ggGrid.sort(
                (a, b) => Number(a.digId) - Number(b.digId)
            )[0];
            //const bal =line.depthTo === 1? coreservices.coreService.getMapTempData().userBalance.firstDepthCharge
            //        : line.depthTo === 2? coreservices.coreService.getMapTempData().userBalance.secondndDepthCharge
            //            : coreservices.coreService.getMapTempData().userBalance.thirdDepthCharge;
            //const remainBal = userBalance - bal;
            var ball = coreservices.coreService.getMapTempData().userBalance.balance;
            if (userTempBalance !== ball) {
                dispatch(setUserTempBalance(ball));
                dispatch(setUserBalance(ball));
                authServices.service.setUserBalance(ball);
            }
            //data1.mapGroupModel.find((x) => x.nodeName === line.nodeName).result = 2;
            data1.mapGroupModel.find((x) => x.nodeName === line.nodeName).depth1 = -1;
            data1.mapGroupModel.find((x) => x.nodeName == line.nodeName).depthTo = -1;
            var currentMine = data1.mapGroupModel.find((x) => x.nodeName === line.nodeName);
            var allNode = data1.mineList.filter((x) => x.mineGridId == currentMine.id);
            var weather = 0; var terrain = 0; var faction = 0; var bonus = 0;
            debugger;
            allNode.forEach((item1, index) => {
                debugger;
                var node = data1.mapDetailList.find(x => x.id == item1.id);
                if (node != null) {
                    weather = weather > node.weather ? weather : node.weather;
                    terrain = terrain > node.terrain ? terrain : node.terrain;
                    faction = faction > node.faction ? faction : node.faction;
                    bonus = bonus > node.bonus ? bonus : node.bonus;
                }
            });
            coreservices.coreService.getMapDataGrid().filter((n) => n.nodeName !== line.nodeName).forEach((item, inex) => {
                data1.mapGroupModel.find((x) => x.nodeName === item.nodeName).result = 2;
            });
            //var totalPath = coreservices.coreService.getMapTempData().mapDetailList.filter((x) => x.result < 2)
            //    .find((x) => x.id === line.id);
            //if (totalPath != null) {
            //    var pathData =
            //        coreservices.coreService.getMapTempData() != null
            //            ? coreservices.coreService
            //                .getMapTempData()
            //                .mapPathList.find((x) => x.idTo == totalPath.id)
            //            : null;
            //    if (pathData != null) {
            //        timerTime = Number(pathData.totalPathCovered);
            //    }
            //}
            timeOut = line.totalNode;
            timeOut = timeOut == null || timeOut === undefined ? 10 : timeOut;
            const sList = ggGrid.filter((n) => n.id !== line.id);
            updateSelectedGrid(sList, timeOut, weather, terrain, faction, bonus);
            coreservices.coreService.saveData(data1);
            dispatch(setMapData(data1));

            timeOut = (timeOut * 600) + 1000;
            //timeOut =timeOut < 4000 ? 4500 : timeOut > 8000 ? 8000 : timeOut;
            await new Promise((resolve) => setTimeout(resolve, timeOut));
        }


    };
    const updateSelectedGrid = (sList, timeout, weather, terrain, faction, bonus) => {
        timeOutInterval1 = setTimeout(() => {
            clearTimeout(timeOutInterval1);
            let snacks = JSON.parse(JSON.stringify(snackbarQueue));
            if (snacks == null) {
                snacks = [];
            }
            console.log(new Date() +" stGrid coreCoordinatesList="+ " weather:" + weather + " terrain:" + terrain
                + " faction:" + faction + " bonus:" + bonus
            );
            weather = weather >= 3 ? "high" : weather >= 2 ? "medium" : "low";
            terrain = terrain >= 3 ? "high" : terrain >= 2 ? "medium" : "low";
            faction = faction >= 3 ? "high" : faction >= 2 ? "medium" : "low";
            //bonus = bonus == 1 ? "high" : "low";
            iddd++;
            snacks.push({ id: iddd, factor: ["f1", weather] })
            iddd++;
            snacks.push({ id: iddd, factor: ["f2", terrain] })
            iddd++;
            snacks.push({ id: iddd, factor: ["f3", faction] })            
            if (bonus == 1) {
                iddd++;
                snacks.push({ id: iddd, factor: ["f1", "bonus"] })
            }
            console.log(
                new Date() +
                " stGrid coreCoordinatesList=" + JSON.stringify(snacks) + " weather:" + weather + " terrain:" + terrain
                + " faction:" + faction + " bonus:" + bonus
            );
            dispatch(setSnackbarQueue(snacks));
        }, (200));
        if (sList == null || sList.length == 0) {
            coreservices.coreService.saveDataEntireGridTemp(null);
            if (localStorage.getItem("isCoreStart") == "true") {
                timeOutInterval = setTimeout(() => {
                    clearTimeout(timeOutInterval);
                    clearData();
                }, (timeout * 600) + 4000);

            }
            coreservices.coreService.saveData(coreservices.coreService.getMapData());
        }
        //else {
        //    isSetReady(true);
        //}
        dispatch(setGridSelectors(sList));
        dispatch(setSelectedGrid(sList));
        coreservices.coreService.saveDataEntireGrid(sList);
    };
    const clearData = () => {
        localStorage.removeItem("isCoreStart");
        console.log(new Date() + " end timer=");
        //clearInterval(intervalId);
        dispatch(setCoringComplete(true));
        dispatch(setSnackbarQueue([]));
        dispatch(setShowButton(true));
        dispatch(setShowLoading(true));
        dispatch(setSelectedGrid([]));
        dispatch(setIsReady(false));
        dispatch(setMechEnergy(0));
        localStorage.setItem("isNeedStart", true);
        coreservices.coreService.saveData(
            coreservices.coreService.getMapTempData()
        );
        dispatch(setSessionTime(null));
        dispatch(setMapData(coreservices.coreService.getMapTempData()));
        //setMapData(coreservices.coreService.getMapTempData());
    };

    useEffect(() => {
        coreservices.coreService.getMineData(mapId.id).then((data) => {
            dispatch(
                setMapData(
                    coreservices.coreService.getMapData() == null
                        ? null
                        : coreservices.coreService.getMapData()
                )
            );
            setOpenMap(true);
            //coreservices.coreService.getMineData(mapId.id).then((data1) => {

            //    //setMapData(
            //    //    coreservices.coreService.getMapData() == null
            //    //        ? null
            //    //        : coreservices.coreService.getMapData()
            //    //);
            //    setOpenMap(true);
            //});
            //setOpenMap(true);
        });
    }, []);

    return (
        <>
            {isOpenMap ? (
                <LeftCol deployCores={deployCores} pageOpen={pageOpen} id={mapId.id} />
            ) : (
                ""
            )}
            {isOpenMap ? (
                <div className="coring-container">
                    <Map deployCores={deployCores} pageOpen={pageOpen} />
                </div>
            ) : (
                ""
            )}

            {isOpenMap ? <SidebarRight /> : <div></div>}

            {isOpenMap ? <SidebarRight /> : <div></div>}
        </>
    );
};

export default Coring;
