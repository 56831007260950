import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HumanxImageBg from "../../images/humanx.png";
import FallenImageBg from "../../images/fallen-brotherhood.png";
import ApostlesImageBg from "../../images/apostles.png";
import Humanx from "../../images/humanx-2.png";
import Fallen from "../../images/brotherhood-1.png";
import Apostles from "../../images/apostle-1.png";
import HumanxLogo from "../../images/icons/humax-logo.svg";
import BrotherHoodLogo from "../../images/icons/brotherhood-logo.svg";
import ApostlesLogo from "../../images/icons/apostels-logo.svg";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
const FactionSelector = (props) => {
  const [activeIndex, setActiveIndex] = useState(2);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };
  const handleMouseClick = (e, index) => {
    if (index == 1) {
      e.stopPropagation();
      props.handleFallenBrotherHood();
    } else {
      props.setConfirmFaction("Active");
    }
  };

  return (
    <div className="faction-selector-wrap">
      <div className="faction-content-wrap">
        <div className="faction-title-wrap">Choose Your Faction</div>
        <div className="faction-slider">
          <Swiper
            spaceBetween={"5%"}
            slidesPerView={3}
            centeredSlidesBounds={true}
            centeredSlides={true}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              1199: {
                slidesPerView: 3,
                centeredSlides: true,
              },
              768: {
                slidesPerView: 1,
                centeredSlides: false,
              },
              320: {
                slidesPerView: 1,
                centeredSlides: false,
              },
            }}
            style={{ overflow: "visible" }}
          >
            <SwiperSlide
              className={`swiper-item humanx ${
                1 === activeIndex ? "active" : ""
              } custom-order-1`}
              onMouseEnter={() => handleMouseEnter(1)}
            >
              {/* <div className='coming-wrap'>COMING <br />2024</div> */}
              <div className="availability none">RestricteD – coming 2024</div>
              <div
                className="slide-box"
                style={{ backgroundImage: `url(${HumanxImageBg})` }}
              ></div>

              <div className="game-character humanx">
                <img src={Humanx} alt="Humanx" />
              </div>
              <div className="game-info-wrap">
                <div className="game-logo">
                  <img src={HumanxLogo} alt="Humanx" />
                </div>
                <div className="game-title">Humanx</div>
                <div className="game-btn-wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => props.handleHumanx()}
                  >
                    OPEN FILE
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className={`swiper-item fallen  ${
                2 === activeIndex ? "active" : ""
              } custom-order-2`}
              onMouseEnter={() => handleMouseEnter(2)}
              onClick={(e) => handleMouseClick(e, 2)}
            >
              <div className="availability available">Available</div>
              <div
                className="slide-box"
                style={{ backgroundImage: `url(${FallenImageBg})` }}
              ></div>

              <div className="game-character">
                <img src={Fallen} alt="Fallen" />
              </div>
              <div className="game-info-wrap">
                <div className="game-logo">
                  <img src={BrotherHoodLogo} alt="Brother Hood" />
                </div>
                <div className="game-title">USFF</div>
                <div className="game-btn-wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={(e) => handleMouseClick(e, 1)}
                  >
                    OPEN FILE
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className={`swiper-item apostles ${
                3 === activeIndex ? "active" : ""
              } custom-order-3`}
              onMouseEnter={() => handleMouseEnter(3)}
            >
              {/* <div className='coming-wrap'>COMING <br />2024</div> */}
              <div className="availability none">RestricteD – coming 2024</div>
              <div
                className="slide-box"
                style={{ backgroundImage: `url(${ApostlesImageBg})` }}
              ></div>

              <div className="game-character apostles">
                <img src={Apostles} alt="Apostles" />
              </div>
              <div className="game-info-wrap">
                <div className="game-logo">
                  <img src={ApostlesLogo} alt="Apostles" />
                </div>
                <div className="game-title">Apostles</div>
                <div className="game-btn-wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => props.handleApostles()}
                  >
                    OPEN FILE
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FactionSelector;
