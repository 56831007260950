import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import LogoDesktop from "../../images/logo.svg";
import { ReactSVG } from "react-svg";
import LogoMobile from "../../images/logo-mobile.svg";
import Avatar from "../../images/avatar.jpg";
import UserIcon from "../../images/icons/user-icon.svg";
import BlanceIcon from "../../images/icons/balance-icon.svg";
import "./header.css";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import LandscapeMap from "../landscape/LandscapeMap";
import { useDispatch, useSelector } from "react-redux";
import { setUserBalance } from "../../appSlice";
const Header = (props) => {
    const { userBalance, pageName } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const services = {
        authService: new AuthService(),
    };
    const [isNavOpen, setNavOpen] = useState(false);
    const [isLogIn, setLogin] = useState(services.authService.isLoggednIn());
    const [profileModal, setProfileModal] = useState(false);
    
    var user = services.authService._CurrentUser();
    const [haveBalance, setHaveBlance] = useState(
        userBalance > 0 ? true : false
        //user != null && user.balance > 0 ? true : false
    );
    const certified = (user != null && user.isTrainingComplete == true) ? true : false;
    //dispatch(setUserBalance(user?.balance))
    const location = useLocation();
    const menuopen = () => {
        setNavOpen(!isNavOpen);
    };
    const navigate = useNavigate();
    const avUrl = (user != null && user.avatarUrl.includes("uploadimg"))
        ? user.avatarUrl : (user != null && user.registerType != "Email") ? user.avatarUrl
        : "";
    return (
        <header>
            <div>
                <ul className="left-nav">
                    {/* <li onClick={()=>navigate('/')} className={useMatch('/') ? 'active' : ''}><span>Home</span></li> */}
                    {/*<li onClick={() => navigate('/prospecting')} className={location.pathname.startsWith("/prospecting") ? "active" : ""}><span>Prospecting</span></li>*/}
                    <li
                        className={
                            location.pathname.startsWith("/prospecting") ||
                                location.pathname.startsWith("/coring") ||
                                location.pathname.startsWith("/mining")
                                ? "active"
                                : ""
                        } onClick={() =>
                            services.authService.handleClickOnButton("basemine")
                        }
                    >
                        <span>Prospecting</span>
                    </li>
                    <li>
                        <span>x33</span>
                    </li>
                    <li className={pageName == "Register" ? "active" : ""}>
                        <span>recruitment</span>
                    </li>
                </ul>
            </div>
            <div className="logo">
                <img src={LogoDesktop} alt="Archangel" className="l-desktop" />
                <img src={LogoMobile} alt="Archangel" className="l-mobile" />
                <div className="logo-bottom"></div>
            </div>
            <div>
                <ul className="right-nav">
                    {/*                    <li onClick={() => services.authService.handleClickOnButton('/')} className={location.pathname === "/" ? "active" : ""}><span>Home</span></li>*/}
                    <li onClick={() => services.authService.handleClickOnButton("/")}>
                        <span>Home</span>
                    </li>
                    <li>
                        <span>About</span>
                    </li>
                    <li>
                        <span>News</span>
                    </li>
                    {/*{isLogIn ? (*/}
                    {/*    <li*/}
                    {/*        onClick={() => services.authService.handleClickOnButton("logout")}*/}
                    {/*    >*/}
                    {/*        <span>Logout</span>*/}
                    {/*    </li>*/}
                    {/*) : (*/}
                    {/*    ""*/}
                    {/*)}*/}
                    {profileModal ? (
                        <li
                            className="auth-wrap"
                            onClick={() => {
                                setProfileModal(!profileModal);
                            }}
                        >
                            {/* Header States  Below */}
                            {/*{isLogIn && user != null && avUrl != "" && (*/}
                            {/*    <div className="avatar">*/}
                            {/*        <img src={avUrl} alt="Avatar" />*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {isLogIn && user != null && (
                                <div className="avatar have-name">
                                    {avUrl != "" ? (<div className="avatar">
                                        <img src={avUrl} alt="Avatar" />
                                    </div>) : (user.userName.charAt(0))}
                                    {/*{user.userName.charAt(0)}*/}
                                    <div className="profile-dropdown-container">
                                        <div
                                            className={`dropdown-top-badge ${certified && "dropdown-top-badge-certified"
                                                }`}
                                        >
                                            <p>{user.userName}</p>
                                            <div
                                                className={`licence-chip ${certified && "licence-chip-certified"
                                                    }`}
                                            >
                                                <p>{certified ? "certified" : "provisional"}</p>
                                            </div>
                                        </div>
                                        <div className="dropdown-nav-item" onClick={() => services.authService.handleClickOnButton("profile")}>
                                            <p>Profile</p>
                                        </div>
                                        <div className="dropdown-nav-item" onClick={() => services.authService.handleClickOnButton("mylocker")}>
                                            <p>My locker</p>
                                        </div>
                                        <div className="dropdown-nav-item" onClick={() =>services.authService.handleClickOnButton("ilyrium")}>
                                            <p>ilyrium</p>
                                        </div>
                                        <div className="dropdown-nav-item" onClick={() =>services.authService.handleClickOnButton("reports")}>
                                            <p>reports</p>
                                        </div>
                                        <div className="dropdown-nav-item" onClick={() => services.authService.handleClickOnButton("logout")}>
                                            <p>logout</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isLogIn && (
                                <div className="avatar not-register">
                                    !<div className="profile-dropdown">Register</div>
                                </div>
                            )}
                        </li>
                    ) : (
                        <li
                            className="auth-wrap"
                            onClick={() => {
                                setProfileModal(true);
                            }}
                        >
                            {/* Header States  Below */}
                            {/*{isLogIn && user != null && user.userName == "" && (*/}
                            {/*    <div className="avatar">*/}
                            {/*        <img src={Avatar} alt="Avatar" />*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {isLogIn && user != null  && (
                                    <div className="avatar have-name">
                                        {avUrl != "" ? (<div className="avatar">
                                            <img src={avUrl} alt="Avatar" />
                                        </div>) : (user.userName.charAt(0))}
                                    {/*{user.userName.charAt(0)}*/}
                                    <div className="profile-dropdown">Profile</div>
                                </div>
                            )}
                            {!isLogIn && (
                                <div className="avatar not-register">
                                    !<div className="profile-dropdown">Register</div>
                                </div>
                            )}
                        </li>
                    )}
                    <li className={userBalance>0 ? "balance" : "balance nill"}>
                        <div className="balance-icon-wrap">
                            {" "}
                            <ReactSVG src={BlanceIcon} className="balance-icon" />
                        </div>
                        <div className="balance-wrap">
                            <div className="balance-title">Balance</div>
                            {userBalance > 0 ? (
                                <div className="balance-amount">{userBalance}</div>
                            ) : (
                                <div className="no-blance">
                                    <span>*</span>
                                    <span>*</span>
                                    <span>*</span>
                                    <span>*</span>
                                    <span>*</span>
                                </div>
                            )}
                        </div>
                    </li>
                </ul>
                <ul className="mobile-header-menu">
                    <li className="user-icon">
                        <img src={UserIcon} alt="User" />
                    </li>
                    <li>
                        <div
                            className={`hamburger ${isNavOpen ? "is-active" : ""}`}
                            onClick={() => menuopen()}
                        >
                            <span className="ham-icon"></span>
                            <span className="ham-icon"></span>
                            <span className="ham-icon"></span>
                        </div>
                    </li>
                </ul>
            </div>

            {isNavOpen ? (
                <>
                    <div className="mobile-menu">
                        <ul>
                            <li
                                onClick={() => services.authService.handleClickOnButton("/")}
                                className={location.pathname === "/" ? "active" : ""}
                            >
                                <span>Home</span>
                            </li>
                            <li>
                                <span>Prospecting</span>
                            </li>
                            <li>
                                <span>News</span>
                            </li>
                            <li>
                                <span>Information</span>
                            </li>
                            {isLogIn ? (
                                <li
                                    onClick={() =>
                                        services.authService.handleClickOnButton("logout")
                                    }
                                >
                                    <span>Logout</span>
                                </li>
                            ) : (
                                ""
                            )}
                            <li className="header-user-wrap">
                                <ul className="header-user-info">
                                    <li
                                        className="auth-wrap"
                                        onClick={() =>
                                            services.authService.handleClickOnButton("/login")
                                        }
                                    >
                                        {/* Header States  Below */}
                                        {isLogIn && user != null && user.userName == "" ? (
                                            <div className="avatar">
                                                <img src={Avatar} alt="Avatar" />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {isLogIn && user != null && user.userName != "" ? (
                                            <div className="avatar have-name">
                                                {user.userName}
                                                <div className="profile-dropdown">Profile</div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {!isLogIn ? (
                                            <div className="avatar not-register">
                                                !<div className="profile-dropdown">Register</div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </li>
                                    <li className={userBalance > 0 ? "balance" : "balance nill"}>
                                        <div className="balance-icon-wrap">
                                            {" "}
                                            <ReactSVG src={BlanceIcon} className="balance-icon" />
                                        </div>
                                        <div className="balance-wrap">
                                            <div className="balance-title">Balance</div>
                                            {userBalance > 0 ? (
                                                <div className="balance-amount">{userBalance}</div>
                                            ) : (
                                                <div className="no-blance">
                                                    <span>*</span>
                                                    <span>*</span>
                                                    <span>*</span>
                                                    <span>*</span>
                                                    <span>*</span>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </>
            ) : (
                ""
            )}
        </header>
    );
};

export default Header;
