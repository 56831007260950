import React from "react";
import FactionConfirmationLogo from "../../images/icons/faction-confirmation-logo.svg";
const FactionConfirmation = (props) => {
  return (
    <div
      className={`faction-confirmation-wrap ${
        props.confirmFaction ? "active" : ""
      }`}
    >
      <div className={`fc-modal `}>
        <div className="fc-modal-logo">
          <img src={FactionConfirmationLogo} alt="BrotherHood" />
        </div>
        <div className="fc-modal-title">YOU HAVE SELECTED USFF</div>
        <div className="fc-modal-info">DO YOU WANT TO CONFIRM?</div>
        <div className="fc-modal-footer">
          <button
            type="button"
            className="btn btn-secondary "
            onClick={() => {
              props.handleComfirmClose("handleMech2");
              props.setFactionSelector(false);
              props.setFactionBadge("active");
            }}
          >
            CONFIRM
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => props.handleComfirmClose("")}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default FactionConfirmation;
