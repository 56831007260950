import $ from 'jquery';
import {astar, Graph} from 'javascript-astar/astar.js';
var WALL = 0,
    performance = window.performance;

var css = { start: "start", finish: "finish", wall: "wall", active: "active" };

/* var demo = {
  GraphSearch1: function ($graph1, options1, implementation1) {
    return GraphSearch($graph1, options1, implementation1)
  }
}

var search;
   function GraphSearch($graph, options, implementation) {
    this.$graph = $graph;
    search = this.astar.search;// implementation;
  this.opts = $.extend({ wallFrequency: 0.1, debug: true, gridSize: 10 }, options);
  return this.const initialize();
} */

var graph = null;
var search = astar.search;
var opts = [];
var grid = [];
var ggrid = [];
var nodes = [];
var walllist = [{ rowNo: 3, colNo: 16 }, { rowNo: 4, colNo: 16 }, { rowNo: 5, colNo: 16 }, { rowNo: 6, colNo: 15 }, { rowNo: 6, colNo: 16 }, { rowNo: 7, colNo: 15 }, { rowNo: 8, colNo: 22 }, { rowNo: 9, colNo: 22 }
    , { rowNo: 10, colNo: 21 }, { rowNo: 10, colNo: 22 }, { rowNo: 11, colNo: 20 }, { rowNo: 12, colNo: 21 }, { rowNo: 13, colNo: 21 }, { rowNo: 14, colNo: 20 }];    

var cellWidth = 32;
var cellHeight = 32;
var $cellTemplate = $("<span />").addClass("grid_item").width(cellWidth).height(cellHeight);
var startSet = false;
var $cells = [];
var abc = "";

const GraphSearch = ($grid, opts, search = astar.search) => {
    for(var x = 0; x < 24; x++) {
        var $row = $("<div class='clear' />"),
            nodeRow = [],
            gridRow = [];

        for(var y = 0; y < 24; y++) {
            var id = "cell_"+x+"_"+y;
            var $cell = $cellTemplate.clone();
            $cell.attr("id", id).attr("x", x).attr("y", y);
            $row.append($cell);
            gridRow.push($cell);

            var isWall = Math.floor(Math.random() * (1 / 0.1));
            
            if (walllist.find(o => o.rowNo === x && o.colNo === y) != null) {
                isWall = 0
            }
            else {
                isWall = 1;
            }
            if(isWall === 0) {
                nodeRow.push(WALL);
                $cell.addClass(css.wall);
            }
            else  {
                var cell_weight = ($("#generateWeights").prop("checked") ? (Math.floor(Math.random() * 3)) * 2 + 1 : 1);
                nodeRow.push(cell_weight);
                $cell.addClass('weight' + cell_weight);
                if ($("#displayWeights").prop("checked")) {
                    $cell.html(cell_weight);
                }
                if (!startSet) {
                    $cell.addClass(css.start);
                    startSet = true;
                }
            }
        }
        //$graph.append($row);
        //ggrid.push($row);
        grid.push(gridRow);
        nodes.push(nodeRow);
    }

    graph = new Graph(nodes);
    $cells = $.find(".grid_item");
    /* $cells.click(function() {
        cellClicked($(this));
    }); */
    return {
        cellClickedRow: cellClickedRow
    };
}    
        

    /* const setOption = function (opt) {
        opts = $.extend(opts, opt);
        drawDebugInfo();
    }; */
    const cellClicked = function ($end) {
        var end = nodeFromElement($end);

        if($end.hasClass(css.wall) || $end.hasClass(css.start)) {
            return;
        }

        $cells.removeClass(css.finish);
        $end.addClass("finish");
        var $start = this.$cells.filter("." + css.start),
            start = this.nodeFromElement($start);

        var sTime = performance ? performance.now() : new Date().getTime();

        var path = this.search(graph, start, end, {
            closest: this.opts.closest
        });
        var fTime = performance ? performance.now() : new Date().getTime(),
            duration = (fTime-sTime).toFixed(2);

        if(path.length === 0) {
            $("#message").text("couldn't find a path (" + duration + "ms)");
            animateNoPath();
        }
        else {
            $("#message").text("search took " + duration + "ms.");
            drawDebugInfo();
            animatePath(path);
        }
    };
    const cellClickedRow = function (sr, sc, rr, cc) {
        
        var end =graph.grid[parseInt(rr)-1][parseInt(cc)-1];
            //this.$cells =this.$graph.find(".grid_item");
            //var $start = this.$cells.filter("." + css.start),
        var start = graph.grid[parseInt(sr) - 1][parseInt(sc) - 1];
        var path = search(graph, start, end, {
            closest: false
        });
        return path;
    }

    /* if (x === 24 && y === 24){
        cellClickedRow(1,1,8, 9);
    } */

    
    const drawDebugInfo = function() {
        $cells.html(" ");
        if(this.opts.debug) {
            $cells.each(function() {
                var node = nodeFromElement($(this)),
                    debug = false;
                if (node.visited) {
                    debug = "F: " + node.f + "<br />G: " + node.g + "<br />H: " + node.h;
                }

                if (debug) {
                    $(this).html(debug);
                }
            });
        }
    };
    const nodeFromElement = function($cell) {
        return graph.grid[parseInt($cell.attr("x"))][parseInt($cell.attr("y"))];
    };
    const animateNoPath = function() {
        var $graph = this.$graph;
        var jiggle = function(lim, i) {
            if(i>=lim) { $graph.css("top", 0).css("left", 0); return; }
            if(!i) i=0;
            i++;
            $graph.css("top", Math.random()*6).css("left", Math.random()*6);
            setTimeout(function() {
                jiggle(lim, i);
            }, 5);
        };
        jiggle(15);
    };
    const animatePath = function(path) {
        var grid = this.grid,
            timeout = 1000 / grid.length,
            elementFromNode = function(node) {
            return grid[node.x][node.y];
        };

        var self = this;
        // will add start class if final
        var removeClass = function(path, i) {
            if(i >= path.length) { // finished removing path, set start positions
                return setStartClass(path, i);
            }
            elementFromNode(path[i]).removeClass(css.active);
            //setTimeout(function() {
            //    removeClass(path, i+1);
            //}, timeout*path[i].getCost());
        };
        var setStartClass = function(path, i) {
            if(i === path.length) {
                self.$graph.find("." + css.start).removeClass(css.start);
                elementFromNode(path[i-1]).addClass(css.start);
            }
        };
        var addClass = function(path, i) {
            if(i >= path.length) { // Finished showing path, now remove
                return removeClass(path, 0);
            }
            elementFromNode(path[i]).addClass(css.active);
            setTimeout(function() {
                addClass(path, i+1);
            }, timeout*path[i].getCost());
        };
        addClass(path, 0);
        this.$graph.find("." + css.start).removeClass(css.start);
        this.$graph.find("." + css.finish).removeClass(css.finish).addClass(css.start);
    };

export default GraphSearch