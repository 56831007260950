import React from "react";
import CoringSampleImg from "../../images/coring-sample.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";

import "./coring.css";
const CoreResult = () => {
  return (
    <>
      <div className="c-wrapper-2">
        <div id="report-list" className="pl-main-container results">
          <div className="pl-wrapper">
            <div className="pl-row pl-header">
              <div className="pl-number">NO#.</div>
              <div className="pl-status">STATUS</div>
              <div className="pl-sample">SAMPLE</div>
              <div className="pl-depth">Depth</div>
              <div className="pl-result">RESULT</div>
            </div>
          </div>
          <div className="pl-wrapper pl-content-wrap">
            <div className="pl-row inprogress">
              <div className="pl-number">I-05</div>
              <div className="pl-status">IN PROGRESS</div>
              <div className="pl-sample">
                <div
                  className="sample inProgress"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth1} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result inProgress">•••</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row find">
              <div className="pl-number">U-04</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth3} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result find">Find</span>
              </div>
            </div>
            <div className="pl-row find">
              <div className="pl-number">U-04</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth1} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result find">Find</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row find">
              <div className="pl-number">U-04</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result find">Find</span>
              </div>
            </div>
            <div className="pl-row find">
              <div className="pl-number">U-04</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth3} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result find">Find</span>
              </div>
            </div>
            <div className="pl-row find">
              <div className="pl-number">U-04</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth3} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result find">Find</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
            <div className="pl-row no-find">
              <div className="pl-number">N-09</div>
              <div className="pl-status">COMPLETE</div>
              <div className="pl-sample">
                <div
                  className="sample"
                  style={{ backgroundImage: `url(${CoringSampleImg})` }}
                ></div>
              </div>
              <div className="pl-depth">
                <img src={Depth2} alt="Depth" />
              </div>
              <div className="pl-result">
                <span className="result none">NO-FIND</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoreResult;
