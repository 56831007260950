import React, { useState, useEffect } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/header/Header";

import tooltipCoring5 from "../images/tooltipCoring5.svg";
import tooltipCoring4 from "../images/tooltipCoring4.svg";
import tooltipClose from "../images/icons/tooltipClose.svg";
import tooltipCoring2 from "../images/tooltipCoring2.svg";
import tooltipCoring3 from "../images/tooltipCoring3.svg";
import tooltipArrow from "../images/icons/tooltipArrow.svg";
import tooltipDepth from "../images/tooltipDepthSelector.svg";
import landingMonitor from "../images/icons/tooltipMonitor.svg";
import tooltipLocation from "../images/icons/tooltipLocation.svg";
import tooltipNavigator from "../images/tooltipNavigator.svg";
import tooltipLedger from "../images/tooltipLedger.svg";
import tooltipIcons from "../images/tooltipIcons.svg";
import tooltipArea from "../images/tooltipAreas.svg";
import {
  setTooltipCoring,
  setTooltipInspecting,
  setIsReady,
  setSelectedGrid,
  setTooltipMining,
} from "../appSlice";
import { useDispatch, useSelector } from "react-redux";
import CoreService from "../components/helper/Coreservice";
import AuthService, { BASE_URL } from "../components/helper/Authservice";
import Manual from "../components/manual/Manual";
const Publiclayout = ({ children }) => {
    const { tooltipCoring, tooltipInspecting, tooltipMining, showManual } = useSelector(
    (state) => state.app
  );
  const [mapCoords, setMapCoords] = useState({ x: 0, y: 0 });
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeOut2, setFadeOut2] = useState(false);
  //const [showManual, setShowManual] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        updateCoringToolTipComplete();
      }, 1000);
    }
  }, [fadeOut]);

  useEffect(() => {
    if (fadeOut2) {
      setTimeout(() => {
        updateInspectingToolTipComplete();
      }, 1000);
    }
  }, [fadeOut2]);

  const coreservices = {
    coreService: new CoreService(),
  };
  const services = {
    authService: new AuthService(),
  };
  const location = useLocation();
  const [user, setUser] = useState(services.authService._CurrentUser());
    if (!location.pathname.startsWith("/inspect")) {
        if (tooltipInspecting != 0) {
            dispatch(setTooltipInspecting(0));
        }
    }
    else if (!location.pathname.startsWith("/coring")) {
        if (tooltipCoring != 0) {
            dispatch(setTooltipCoring(0));
        }
    }
    else if (!location.pathname.startsWith("/mining")) {
        if (tooltipMining != 0) {
            dispatch(setTooltipMining(0));
        }
    }
    

  const updateCoringToolTipComplete = () => {
    updateImage("updateCoreTripComplete");
    dispatch(setTooltipCoring(0));
    dispatch(setIsReady(false));
    dispatch(setSelectedGrid([]));
    coreservices.coreService.saveDataEntireGrid(null);
  };

  const updateMiningToolTipComplete = () => {
    // updateImage("updateCoreTripComplete");
    dispatch(setTooltipMining(0));
    // dispatch(setIsReady(false));
    // dispatch(setSelectedGrid([]));
    // coreservices.coreService.saveDataEntireGrid(null);
  };

  const updateInspectingToolTipComplete = () => {
      updateImage("updateInspectTripComplete");
    dispatch(setTooltipInspecting(0));
  };
  const updateImage = async (action) => {
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          //toast.success(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
          services.authService.setUser(data.data);
          //window.location.reload();
          setUser(services.authService._CurrentUser());
        } else {
          //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
        }
      } else {
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    }
  };

  useEffect(() => {
    const publicLayout = document.querySelector(".main-wrapper");
    const mapContainer = document.querySelector(".grid-wrap-border");
    const coringComponent = document.querySelector(".coring-component-border");
    let relativeX;
    let relativeY;
    let coringX;
    let coringY;
    if (publicLayout && mapContainer) {
      const publicLayoutRect = publicLayout.getBoundingClientRect();
      const mapContainerRect = mapContainer.getBoundingClientRect();

      relativeX = mapContainerRect.left;
      relativeY = mapContainerRect.top;
    }

    if (coringComponent) {
      const coringComponentRect = coringComponent.getBoundingClientRect();
      coringX = coringComponentRect.left;
      coringY = coringComponentRect.top;
    }
    let tooltipContainer = document.querySelector(".tooltip-coring");
    if (!tooltipContainer) return;

    const parentContainer = tooltipContainer.parentElement;
    if (!parentContainer) return;

    const parentHeight = parentContainer.offsetHeight;
    const parentWidth = parentContainer.offsetWidth;

    const tooltipHeight = tooltipContainer.offsetHeight;
    const tooltipWidth = tooltipContainer.offsetWidth;

    if (tooltipCoring === 1) {
      tooltipContainer.style.top = "54px";
      tooltipContainer.style.left = "556px";
    } else if (tooltipCoring === 2) {
      const left = coringX;
      const top = coringY;
      tooltipContainer.style.top = `${top - 100}px`;
      tooltipContainer.style.left = `${left - 475}px`;
    } else if (tooltipCoring === 3) {
      const top = relativeY - tooltipHeight + 440;
      const left = relativeX - tooltipWidth - 35;
      tooltipContainer.style.top = `${top}px`;
      tooltipContainer.style.left = `${left}px`;
    } else if (tooltipCoring === 4) {
      tooltipContainer.style.top = "290px";
      tooltipContainer.style.left = "397px";
    } else if (tooltipCoring === 5) {
      const top = parentHeight - tooltipHeight - 40; // 50px from the bottom
      const left = parentWidth - tooltipWidth - 265; // 229px from the right
      tooltipContainer.style.top = `${top}px`;
      tooltipContainer.style.left = `${left}px`;
    }
    console.log(tooltipContainer.style.top);
  }, [tooltipCoring]);

  useEffect(() => {
    const publicLayout = document.querySelector(".main-wrapper");
    const mapContainer = document.querySelector(".grid-wrap-border");
    const globalBorder = document.querySelector(".global-border");
    const ledgerBorder = document.querySelector(".ledger-border");
    const areaBorder = document.querySelector(".grid-wrap-border-inspect");

    let relativeX;
    let relativeY;
    let locationX;
    let locationY;
    let ledgerX;
    let ledgerY;
    let areaX;
    let areaY;
    if (publicLayout && mapContainer) {
      const mapContainerRect = mapContainer.getBoundingClientRect();
      relativeX = mapContainerRect.left;
      relativeY = mapContainerRect.top;
    }

    if (globalBorder) {
      const globalBorderRect = globalBorder.getBoundingClientRect();
      locationX = globalBorderRect.left;
      locationY = globalBorderRect.top;
    }

    if (ledgerBorder) {
      const ledgerBorderRect = ledgerBorder.getBoundingClientRect();
      ledgerX = ledgerBorderRect.left;
      ledgerY = ledgerBorderRect.top;
    }

    if (areaBorder) {
      const areaBorderRect = areaBorder.getBoundingClientRect();
      areaX = areaBorderRect.left;
      areaY = areaBorderRect.top;
    }

    let tooltipContainer = document.querySelector(".tooltip-inspecting");

    if (!tooltipContainer) return;

    const parentContainer = tooltipContainer.parentElement;
    if (!parentContainer) return;

    const parentHeight = parentContainer.offsetHeight;
    const parentWidth = parentContainer.offsetWidth;

    const tooltipHeight = tooltipContainer.offsetHeight;
    const tooltipWidth = tooltipContainer.offsetWidth;

    if (tooltipInspecting === 1) {
      tooltipContainer.style.top = "62px";
      tooltipContainer.style.left = "389px";
    } else if (tooltipInspecting === 2) {
      tooltipContainer.style.top = `145px`;
      tooltipContainer.style.left = `389px`;
    } else if (tooltipInspecting === 3) {
      tooltipContainer.style.top = `187px`;
      tooltipContainer.style.left = `389px`;
    } else if (tooltipInspecting === 4) {
      tooltipContainer.style.top = "383px";
      tooltipContainer.style.left = "389px";
    } else if (tooltipInspecting === 5) {
      tooltipContainer.style.top = `${locationY - 63}px`;
      tooltipContainer.style.left = `${locationX + 95}px`;
    } else if (tooltipInspecting === 6) {
      tooltipContainer.style.top = `${ledgerY - tooltipHeight + 245}px`;
      tooltipContainer.style.left = `${ledgerX - tooltipWidth - 40}px`;
    } else if (tooltipInspecting === 7) {
      console.log(areaY, areaX);
      tooltipContainer.style.top = `${areaY - tooltipHeight + 345}px`;
      tooltipContainer.style.left = `${areaX - tooltipWidth - 40}px`;
    }
  }, [tooltipInspecting]);

  return (
    <>
      <Header />
      <div className="App">
        <div className="main-wrapper">
          {tooltipCoring == 1 ? (
            <div
              className={`tooltip-container tooltip-coring tt-depth-selector fade-in`}
            >
              <div className="tooltip-media">
                {/* <div className="depth-selector-border"></div> */}
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip arrow"></img>
                </div>
                <div className="tooltip-close-button-container">
                                    <div className="tt-close-button" onClick={() => { setFadeOut(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipDepth} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Effortless Coring: Simplified Depth Selection</h3>
                  <p>
                    For speedy coring, lock your desired depth to drop a
                    simultaneous array of cores. You have the flexibility to
                    unlock it at any time and select multiple depths within a
                    single session.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipCoring(2))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>1 of 5</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipCoring == 2 ? (
            <div
              className={`tooltip-container tooltip-coring tt-core-component `}
            >
              <div className="tooltip-media">
                {/* <div className="depth-selector-border"></div> */}
                <div className="tooltip-arrow-2">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                    <div className="tt-close-button" onClick={() => { setFadeOut(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipCoring2} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Precision Coring: Versatile Depth Control</h3>
                  <p>
                    Master the art of versatile and precise coring. Use the
                    dropdown to select different depths for each core, trading
                    speed for pinpoint accuracy as you explore the depths of the
                    game world.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipCoring(3))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>2 of 5</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipCoring == 3 ? (
            <div className={`tooltip-container tooltip-coring tt-pathway`}>
              <div className="tooltip-media align-item-end">
                {/* <div className="depth-selector-border"></div> */}
                <div className="tooltip-arrow-2">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                    <div className="tt-close-button" onClick={() => { setFadeOut(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipCoring3} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Navigate with Clarity: Mech Path Visualization</h3>
                  <p>
                    Visualize your mech's path and illyrium consumption with a
                    helpful dotted line as you plan your cores across the map.
                    Navigate your journey wisely and optimize your resources.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipCoring(4))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>3 of 5</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipCoring == 4 ? (
            <div className={`tooltip-container tooltip-coring tt-monitor `}>
              <div className="tooltip-media align-item-end">
                {/* <div className="depth-selector-border"></div> */}
                <div className="tooltip-arrow-4">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                    <div className="tt-close-button" onClick={() => { setFadeOut(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipCoring4} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Core Control Center</h3>
                  <p>
                    Easily track your core plan's status within the status bar..
                    Check core status, remove cores, and deploy your mech with a
                    single click when your plan is set.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipCoring(5))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>4 of 5</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            tooltipCoring == 5 && (
              <div
                className={`tooltip-container tooltip-coring tt-calculator ${
                  fadeOut && "fade-out-custom"
                }`}
              >
                <div className="tooltip-media align-item-end">
                  {/* <div className="depth-selector-border"></div> */}
                  <div className="tooltip-arrow-5">
                    <img src={tooltipArrow} alt="tooltip "></img>
                  </div>
                  <div className="tooltip-close-button-container">
                                        <div className="tt-close-button" onClick={() => { setFadeOut(true); }}>
                      <img src={tooltipClose} alt="close button"></img>
                    </div>
                  </div>
                  <img src={tooltipCoring5} alt="depth selector"></img>
                </div>
                <div className="tooltip-info-wrap">
                  <div className="tooltip-info">
                    <h3>Maximize Core Potential with the Calculator</h3>
                    <p>
                      Enhance your Ilyrium efficiency by optimizing your path
                      for the highest core yield with our bottom-right core
                      calculator. Travel wisely and make the most of your coring
                      journey.
                    </p>
                  </div>
                  <div className="tooltip-info-footer">
                    <div
                      className="tooltip-next-button"
                      onClick={() => {
                        setFadeOut(true);
                      }}
                    >
                      <h5>Close</h5>
                    </div>
                    <div className="tooltip-pagination">
                      <h5>5 of 5</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          {tooltipInspecting == 1 ? (
            <div
              className={`tooltip-container tooltip-inspecting tt-energy fade-in`}
            >
              <div className="tooltip-media">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip arrow"></img>
                </div>
                <div className="tooltip-close-button-container">
                                  <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={landingMonitor} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Keep an eye on your energy!</h3>
                  <p>
                    The energy monitor allows you to monitor time elapsed and
                    energy remaining.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(2))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>1 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipInspecting == 2 ? (
            <div
              className={`tooltip-container tooltip-inspecting tt-location `}
            >
              <div className="tooltip-media">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                      <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipLocation} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Location Details</h3>
                  <p>Stay up to date with your location in this accordian.</p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(3))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>2 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipInspecting == 3 ? (
            <div className={`tooltip-container tooltip-inspecting tt-pathway`}>
              <div className="tooltip-media align-item-end">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                          <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipNavigator} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>The Navigator</h3>
                  <p>
                    The Quick Navigator allows you to rapidly jump to anywhere
                    on the map. Just watch out for restricted areas.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(4))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>3 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipInspecting == 4 ? (
            <div className={`tooltip-container tooltip-inspecting tt-monitor `}>
              <div className="tooltip-media align-item-end">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                              <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Control Centre</h3>
                  <p>
                    Whilst it is suggested you should drop cores to help map any
                    given grid first, you can go straight to mining if you
                    desire.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(5))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>4 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipInspecting == 5 ? (
            <div className={`tooltip-container tooltip-inspecting tt-monitor `}>
              <div className="tooltip-media ">
                <div className="tooltip-arrow-1">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                                  <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipIcons} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Global Navigation</h3>
                  <p>
                    Get where you want, when you want using this global
                    navigation side bar that remains in place throughout the
                    experience.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(6))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>5 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : tooltipInspecting == 6 ? (
            <div className={`tooltip-container tooltip-inspecting tt-ledger `}>
              <div className="tooltip-media align-item-end">
                <div className="tooltip-arrow-2">
                  <img src={tooltipArrow} alt="tooltip "></img>
                </div>
                <div className="tooltip-close-button-container">
                                                      <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                    <img src={tooltipClose} alt="close button"></img>
                  </div>
                </div>
                <img src={tooltipLedger} alt="depth selector"></img>
              </div>
              <div className="tooltip-info-wrap">
                <div className="tooltip-info">
                  <h3>Energy Ledger</h3>
                  <p>
                    To analyze your energy consumption, check the energy ledger
                    for your drilling, mining, and travel history.
                  </p>
                </div>
                <div className="tooltip-info-footer">
                  <div
                    className="tooltip-next-button"
                    onClick={() => dispatch(setTooltipInspecting(7))}
                  >
                    <h5>Next</h5>
                  </div>
                  <div className="tooltip-pagination">
                    <h5>6 of 7</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            tooltipInspecting == 7 && (
              <div
                className={`tooltip-container tooltip-inspecting tt-area ${
                  fadeOut2 && "fade-out-custom"
                }`}
              >
                <div className="tooltip-media align-item-end">
                  <div className="tooltip-arrow-2">
                    <img src={tooltipArrow} alt="tooltip "></img>
                  </div>
                  <div className="tooltip-close-button-container">
                                                              <div className="tt-close-button" onClick={() => { setFadeOut2(true); }}>
                      <img src={tooltipClose} alt="close button"></img>
                    </div>
                  </div>
                  <img src={tooltipArea} alt="depth selector"></img>
                </div>
                <div className="tooltip-info-wrap">
                  <div className="tooltip-info">
                    <h3>Restricted Areas & Landmarks</h3>
                    <p>
                      In the map, you'll find two types of marked areas: Red
                      Squares with Cross Icon: These are restricted zones for
                      safety or scenic preservation. Purple Squares with Camera
                      Icon: Click to view beautiful landscapes and download them
                      as wallpapers.
                    </p>
                  </div>
                  <div className="tooltip-info-footer">
                    <div
                      className="tooltip-next-button"
                      onClick={() => {
                        setFadeOut2(true);
                      }}
                    >
                      <h5>Close</h5>
                    </div>
                    <div className="tooltip-pagination">
                      <h5>7 of 7</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
                  )}
                  {showManual >= 0 && showManual<4? <Manual />:""}
          <div className="grid-container">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Publiclayout;
