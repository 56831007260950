import React from 'react'
import HorizontalGridLines from './HorizontalGridLines'
import VerticalGridLines from './VerticalGridLines'


const GridLines = () => {
    return (
        <div className='grid-lines'>
            <HorizontalGridLines />
            <VerticalGridLines />
        </div>
    )
}

export default GridLines