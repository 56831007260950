import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import map from "../../images/Map2.png";
import mapLow from "../../images/MapExporationLow.png";
import yellowxhair from "../../images/yellowcrosshair.png";
import core from "../../images/core.png";
import mine from "../../images/mine.png";
import Trainingcontrols from "../../images/training_controls.png";
import hovergroup from "../../images/group22.png";
import lowergroup from "../../images/icons/lowergroup.png";
import "./training.css";
import Loader from "../../Loader";
import AuthService, { AdminUserModel } from "../helper/Authservice";

const TrainingExploration = () => {
  const navigate = useNavigate();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [showLoader, setShowLoader] = useState(false);
  const services = {
    authService: new AuthService(),
  };
  const [user, setUser] = useState(services.authService._CurrentUser());

  console.log(user);
  useEffect(() => {
    setShowLoader(true);

    const img = new Image();
    img.src = map;
    img.onload = () => {
      setShowLoader(false);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    console.log(viewportWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div className="training_wrapper">
        <div className="training_controls"></div>
        <div className="training_container">
          <div className="trnng_accrdn">
            <div className="trng_accrdn_slf">
              <div
                className={`trng_acrdn_tpttl status ${
                  user.isTrainingComplete ? "complete" : "progress"
                }`}
              >
                {user.isTrainingComplete
                  ? "Training complete"
                  : "training in progress"}
              </div>

              <ul>
                <li>
                  <div className="trnng_prcs_sngl status complete first-render animated-fadeIn">
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">1. Energy</span>
                      <span className="trnng_acrdn_status">Complete</span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Energy Use: It’s important to use your Ilyrium supply
                          wisely. Each move requires different amounts of
                          energy. Keep an eye on your energy meter to give you
                          an indication on how you’re tracking and how to play
                          your next move.
                        </li>
                        <li>
                          Time Restrictions: Your Ilyrium is only charged for
                          24h once activated. It is activated following your
                          first action – by either dropping a Core or Opening a
                          mine.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li className="active">
                  <div
                    className={`trnng_prcs_sngl status first-render animated-fadeIn ${
                      user.isTrainingComplete
                        ? "complete"
                        : "progress inprogress"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">2. Exploration</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "complete" : "in progress"}
                      </span>
                    </div>
                    <div className="trnng_acrdn_con">
                      <ol>
                        <li>
                          Once inside a section, Ilyrium can be discovered at
                          multiple depths (16m, 64m, 192m). Hover or click on
                          any one grid to either Core or Mine that coordinate.{" "}
                        </li>
                        <li>
                          Coring allows you to gather samples at different
                          depths and is faster than mining. Use cores to explore
                          the section faster and gather information about the
                          location of Ilyrium.{" "}
                        </li>
                        <li>
                          Mining is a more laborious process as the Mech is
                          required to dig through an entire region at a specific
                          depth, rather than simply drop test tubes. In the
                          spirit of effeciency, we recommend you explore a
                          section by dropping a wide variety of cores first.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                {viewportWidth <= 1350 && (
                  <div className="tutorial-2-wrapper">
                    <div className="fixed-legend">
                      <div className="hoverables">
                        <p>depths</p>
                        <img
                          className="upper-group-img"
                          src={hovergroup}
                          alt="legend"
                        ></img>
                        <img
                          className="lower-group-img"
                          src={lowergroup}
                          alt="lower"
                        ></img>
                      </div>
                    </div>
                    <div className="training-map">
                      <div className="hover-legend">
                        <div className="yellow-crosshair">
                          <img src={yellowxhair} alt="crosshair"></img>
                        </div>
                        <div className="legend-row-2">
                          <div className="core-legend">
                            <img src={core} alt="core"></img>
                            <div className="text-box-legend">
                              <p>core</p>
                            </div>
                          </div>
                          <div className="core-legend">
                            <img src={mine} alt="mine"></img>
                            <div className="text-box-legend">
                              <p>Mine</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {showLoader ? (
                        <Loader />
                      ) : (
                        <img className="exploration-map" src={map} alt="map" />
                      )}
                    </div>
                  </div>
                )}

                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">3. Coring</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">4. Mining</span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className={`trnng_prcs_sngl status ${
                      user.isTrainingComplete ? "complete" : "incomplete"
                    }`}
                  >
                    <div className="trnng_acrdn_hdr">
                      <span className="trnng_acrdn_tll">
                        5. Your Discoveries
                      </span>
                      <span className="trnng_acrdn_status">
                        {user.isTrainingComplete ? "Complete" : "Incomplete"}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <button
                className="btn"
                onClick={() => navigate("/training-coring")}
              >
                Next
              </button>
            </div>
          </div>
          {viewportWidth > 1350 && (
            <div className="tutorial-2-wrapper animated-fadeIn">
              <div className="fixed-legend">
                <div className="hoverables">
                  <p>depths</p>
                  <img
                    className="upper-group-img"
                    src={hovergroup}
                    alt="legend"
                  ></img>
                  <img
                    className="lower-group-img"
                    src={lowergroup}
                    alt="lower"
                  ></img>
                </div>
              </div>
              <div className="training-map">
                <div className="hover-legend">
                  <div className="yellow-crosshair">
                    <img src={yellowxhair} alt="crosshair"></img>
                  </div>
                  <div className="legend-row-2">
                    <div className="core-legend">
                      <img src={core} alt="core"></img>
                      <div className="text-box-legend">
                        <p>core</p>
                      </div>
                    </div>
                    <div className="core-legend">
                      <img src={mine} alt="mine"></img>
                      <div className="text-box-legend">
                        <p>Mine</p>
                      </div>
                    </div>
                  </div>
                </div>
                {showLoader ? (
                  <Loader />
                ) : (
                  <img className="exploration-map" src={map} alt="map" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <LeftNavBar />

      <RightNavBar />
    </>
  );
};

export default TrainingExploration;
