import React, { useState, useEffect } from "react";
import loaderLogo from "../../images/loader-logo.svg";
import AuthService, { BASE_URL } from "../helper/Authservice";
import bird from "../../images/bird.png";
const Loader = (props) => {
  const [showText, setShowText] = useState("preparing");

  const services = {
    authService: new AuthService(),
  };

  //services.authService.removeWelcome();
  var user = services.authService._CurrentUser();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText("generating");
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.setShowLoader(false);
      props.setShowMinePrep(true);
      onProceed();
    }, 3000);

    return () => clearTimeout(timer);
  }, [showText]);

  const onProceed = async () => {
    var action = "updateProcced";
      if (action != "") {
          console.log(BASE_URL + " " + action);
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            userId: user.userId,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          services.authService.setUser(data.data);
          //setUser(services.authService._CurrentUser());
        }
      }
      //props.handleClickOnButtonByUser('handleMech');
    }
    //setImages(imageList);
  };

  return (
    <div className="loader-background">
      <div className="loader-content-wrapper">
        <div className="loader-content-wrap">
          <img src={loaderLogo} alt="loader-logo" />
          <div className="loader-badge-wrapper">
            <div className="loader-badge-loader">
              <div className="badge-background">
                <div className="badge"></div>
              </div>
            </div>
          </div>
        </div>
        <h5>
          {showText === "preparing"
            ? "preparing your p-48..."
            : "generating your license..."}
        </h5>
      </div>
    </div>
  );
};

export default Loader;
