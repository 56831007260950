import React from 'react'
import { ReactSVG } from 'react-svg'
import CoringIcon from '../../images/icons/landscape-core.svg'
import MechIcon from '../../images/icons/landscape-mech.svg'
import RecruitIcon from '../../images/icons/landscape-recruit.svg'
import AuthService, { AdminUserModel } from "../helper/Authservice";
import { useSelector } from "react-redux";
const LeftNavBar = (props) => {
    const services = {
        authService: new AuthService(),
    };
    const { pageName } = useSelector((state) => state.app);
  return (
    <div className='landscapenav-wrap left'>
        <ul className='landscapenav'>
              <li className={pageName == "BaseMine" ? "active" : ""} onClick={() =>
                  services.authService.handleClickOnButton("basemine")
              }>
            <ReactSVG src={CoringIcon} className='landscapenav-icon' />
            <span>Core</span>
          </li>
              <li className={pageName == "MinePrep" ? "active" : ""} onClick={() => services.authService.handleClickOnButton("mineprep")}>
            <ReactSVG src={MechIcon} className='landscapenav-icon' />
            <span>X33</span>
          </li>
              <li className={pageName == "Register" ? "active" : ""}>
            <ReactSVG src={RecruitIcon} className='landscapenav-icon' />
            <span>Recruit</span>
          </li>
        </ul>
      </div>
  )
}

export default LeftNavBar