import React from 'react'
import Landscapemap from '../../images/landscape-report.jpg'
import RestrictedIcon from '../../images/icons/restricted.svg'
import ReportIcon from '../../images/icons/report.svg'
import UnexploredIcon from '../../images/icons/unexplored.svg'

import './reports.css'

const ReportGrid = (props) => {
    var maplist = [];
    if (props.userLocker != null) {
        for (var j = 1; j <= 10; j++) {
            for (var i = 1; i <= 16; i++) {
                var mm = props.userLocker.mapDataList.find(x => x.rowNo == j && x.colNo == i)
                if (mm != null) {
                    maplist.push(mm);
                }
            }
        }
    }
    return (
        <>
            <div className='landscape-grid-container'>
                <img src={Landscapemap} alt='Landscape' />
                <div className="landscape">
                    {maplist.map((item, index) =>
                        item.isRestricted == true ? (
                            <div className="grid-item">
                                <div className='grid-restricted grid-box' >
                                    <img src={RestrictedIcon} alt='Restricted' />
                                    <div className='grid-location'>
                                        <div className='gl-area'>{item.mapImageNo}</div>
                                        <div className='gl-report'>Restricted</div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            item.totalReports == 0 ? (
                                <div className="grid-item">
                                    <div className='grid-unexplored grid-box'>
                                        <img src={UnexploredIcon} alt='Unexplored' />
                                        <div className='grid-location'>
                                            <div className='gl-area'>{item.mapImageNo}</div>
                                            <div className='gl-report'>unexplored</div>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                item.totalReports > 0 ?
                                    (
                                        <div className="grid-item">
                                            <div className={`grid-box ${item.result == 1 ? 'grid-find' : 'grid-nofind'}`} onClick={() => props.handleSelectModal(item.id)}>
                                                <img src={ReportIcon} alt='ReportIcon' />
                                                <div className='grid-location'>
                                                    <div className='gl-area'>{item.mapImageNo}</div>
                                                    <div className='gl-report-count'>{item.totalReports}</div>
                                                    <div className='gl-report'>Reports</div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ""


                    )}


                    {/*<div className="grid-item ">*/}

                    {/*    <div className='grid-restricted grid-box'>*/}
                    {/*        <img src={RestrictedIcon} alt='Restricted' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>Restricted</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item "></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"> </div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-find grid-box' onClick={() => props.handleSelectModal()}>*/}
                    {/*        <img src={ReportIcon} alt='ReportIcon' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report-count'>6 </div>*/}
                    {/*            <div className='gl-report'>Reports</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-unexplored grid-box'>*/}
                    {/*        <img src={UnexploredIcon} alt='Unexplored' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>unexplored</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-find grid-box' onClick={() => props.handleSelectModal()}>*/}
                    {/*        <img src={ReportIcon} alt='ReportIcon' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report-count'>6 </div>*/}
                    {/*            <div className='gl-report'>Reports</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-unexplored grid-box' >*/}
                    {/*        <img src={UnexploredIcon} alt='Unexplored' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>unexplored</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-unexplored grid-box'>*/}
                    {/*        <img src={UnexploredIcon} alt='Unexplored' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>unexplored</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-unexplored grid-box'>*/}
                    {/*        <img src={UnexploredIcon} alt='Unexplored' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>unexplored</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-unexplored grid-box'>*/}
                    {/*        <img src={UnexploredIcon} alt='Unexplored' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report'>unexplored</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"> </div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"> </div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-nofind grid-box' onClick={() => props.handleSelectModal()}>*/}
                    {/*        <img src={ReportIcon} alt='Report' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report-count'>6 </div>*/}
                    {/*            <div className='gl-report'>Reports</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-find grid-box' onClick={() => props.handleSelectModal()}>*/}
                    {/*        <img src={ReportIcon} alt='ReportIcon' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report-count'>6 </div>*/}
                    {/*            <div className='gl-report'>Reports</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item">*/}
                    {/*    <div className='grid-find grid-box' onClick={() => props.handleSelectModal()}>*/}
                    {/*        <img src={ReportIcon} alt='ReportIcon' />*/}
                    {/*        <div className='grid-location'>*/}
                    {/*            <div className='gl-area'>E-08</div>*/}
                    {/*            <div className='gl-report-count'>6 </div>*/}
                    {/*            <div className='gl-report'>Reports</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                    {/*<div className="grid-item"></div>*/}
                </div>

            </div>

        </>
    )
}

export default ReportGrid