import React, { useState } from "react";
import BrotherHoodBg from "../../images/brotherhood-bg.jpg";
import ThumbImgBrotherHood from "../../images/brotherhood-profile-1.png";
import FFHeaderLeftIcon from "../../images/icons/ff-icon-left.svg";
import FFHeaderRightIcon from "../../images/icons/ff-icon-right.svg";
import FFHeaderCenterLeftIcon from "../../images/icons/ff-icon-center-left.svg";
import FFHeaderCenterRightIcon from "../../images/icons/ff-icon-center-right.svg";
import FFBrotherHoodLogo from "../../images/icons/ff-brotherhood-logo.svg";
import { ReactSVG } from "react-svg";
import FFCloseIcon from "../../images/icons/auth-close-icon.svg";
import arrow from "../../images/arrow-up.svg";
const FactionBrotherHood = (props) => {
  const handleConfirm = () => {
    props.setConfirmFaction("active");
    props.setFactionSelector(false);
    props.setFactionBadge("active");
  };
  const handleComfirmClose = (pageName) => {
    props.setConfirmFaction();
    //props.setFallenBrotherhood(false)
    if (pageName == "handleMech2") {
      props.handleClickOnButtonByUser("handleMech2");
    }
  };
  return (
    <>
      <div
        className={`faction-file-wrapper ${
          props.fallenBrotherhood ? "active" : ""
        }`}
      >
        <div
          className="ff-close"
          onClick={() => props.setFallenBrotherhood(false)}
        >
          <div className="ff-close-icon">
            <ReactSVG src={FFCloseIcon} />
          </div>
          <div className="ff-close-text">EXIT</div>
        </div>
        <div
          className="ff-bg-container"
          style={{ backgroundImage: `URL(${BrotherHoodBg})` }}
        >
          <div className="ff-slider-wrap brotherhood">
            <img
              className="right-arrow "
              src={arrow}
              onClick={() => {
                props.setFallenBrotherhood();
              }}
            ></img>
            <img
              className="left-arrow"
              src={arrow}
              onClick={() => {
                props.setFallenBrotherhood();
              }}
            ></img>
            <div className="ff-content-container">
              <div className="ff-thumb-img-wrap">
                <img src={ThumbImgBrotherHood} alt="Humanx" />
              </div>
              <div className="ff-content-wrapper brotherhood">
                <div className="ff-content-header">
                  <div className="ff-header-icon left">
                    {" "}
                    <img src={FFHeaderLeftIcon} alt="FFHeaderLeftIcon" />
                  </div>

                  <div className="ff-header-icon right">
                    {" "}
                    <img src={FFHeaderRightIcon} alt="FFHeaderRightIcon" />
                  </div>
                </div>
                <div className="ff-content-wrap">
                  <div className="ff-content-left">
                    <img
                      src={FFHeaderCenterLeftIcon}
                      alt="FFHeaderCenterLeftIcon"
                    />
                  </div>
                  <div className="ff-content-detail-wrap">
                    <div className="ff-content brotherhood">
                      <div className="ff-logo">
                        <img src={FFBrotherHoodLogo} alt="FBrotherHood" />
                      </div>

                      <div className="ff-title">USFF</div>
                      <div className="ff-m-img">
                        {" "}
                        <img src={ThumbImgBrotherHood} alt="Humanx" />
                      </div>
                      <div className="ff-description">
                        A post-Great Mech War techno military order operating in
                        the western US. Successor of the USFF and sworn enemy of
                        the HumnX, the USFF is headquartered on a floating
                        starship, "Skyhaven'', located above the ruins of
                        Denver, Colorado. 
                      </div>
                      <div className="ff-description">
                        Small when compared to the HumnX corporation, the
                        Brotherhood has proved to be one of the most
                        technologically advanced and democratic organizations
                        emerging from the destruction caused by the Cataclysm.
                        Though its exact levels of power remain unclear, it is
                        rumored its sphere of influence extends east past the
                        Rocky Mountains and stretches toward the eastern coast
                        of the North American continent.
                      </div>
                      <div className="ff-list">
                        <div className="ff-list-row">
                          <div className="ff-list-title">Headquarters</div>
                          <div className="ff-list-detail">
                            Skyhaven (Floating Starship)
                          </div>
                        </div>
                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Political Structure{" "}
                          </div>
                          <div className="ff-list-detail">
                            Liberal Democracy
                          </div>
                        </div>
                        <div className="ff-list-row">
                          <div className="ff-list-title">
                            Head of the Organization
                          </div>
                          <div className="ff-list-detail">
                            Air Marshall Rebecca Lawson, Conclave (23 Elders)
                          </div>
                        </div>
                        <div className="ff-list-row">
                          <div className="ff-list-title">Major Festivals</div>
                          <div className="ff-list-detail">
                            Independence Day, Thanksgiving
                          </div>
                        </div>
                        <div className="ff-list-row">
                          <div className="ff-list-title">Mechanized Units</div>
                          <div className="ff-list-detail">
                            Many aerial units
                          </div>
                        </div>
                      </div>
                      <div className="ff-tagline-wrap">
                        <div className="ff-tagline">
                          "Don't engage an enemy in combat too often, or he will
                          learn how you wage war."
                        </div>
                      </div>
                      <div className="ff-commander-info">
                        General Ron "Falco" Simonsen, War Commander of the HumnX
                        Armies
                      </div>
                    </div>
                  </div>
                  <div className="ff-content-right">
                    <img
                      src={FFHeaderCenterRightIcon}
                      alt="FFHeaderCenterRightIcon"
                    />
                  </div>
                </div>
                <div className="ff-content-footer">
                  <div className="ff-footer-spacing left"></div>
                  <div className="footer-btn-wrap">
                    <button
                      type="button"
                      className="btn btn-fluid"
                      onClick={() => props.setFallenBrotherhood(false)}
                    >
                      Back
                    </button>

                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="btn btn-secondary btn-fluid"*/}
                    {/*  onClick={() => handleConfirm()}*/}
                    {/*>*/}
                    {/*  Confirm Selection*/}
                    {/*</button>*/}
                  </div>
                  <div className="ff-footer-spacing right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FactionBrotherHood;
