import React from "react";
import CoringSampleImg from "../../images/coring-sample.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";

// import './coring.css'
const MineResults = (props) => {
    var findCoordinate = props.coreCoordinatesList.filter(x => x.energyEarn > 0);
    const depthIcons = [Depth1, Depth2, Depth3];
  return (
    <>
      <div className="m-wrapper">
        <div className="message-wrap">
                  <div className="status-green message-black">
                      {findCoordinate.length} ILYRIUM CRYSTALS FOUND
          </div>
        </div>

        <div id="report-list" className="pl-main-container results">
          <div className="pl-wrapper">
            <div className="pl-row pl-header">
              <div className="pl-number">NO#.</div>
              <div className="pl-status">STATUS</div>
              <div className="pl-depth">Depth</div>
              <div className="pl-result">RESULT</div>
            </div>
          </div>
                  <div className="pl-wrapper pl-content-wrap">
                      {props.coreCoordinatesList && props.coreCoordinatesList.length > 0 && props.coreCoordinatesList.map((item, index) => (
                          <div className={`pl-row ${(item.energyEarn > 0 && item.digStatus == 1) ? 'find' : (item.digStatus == 1 && item.result == 0) ? 'no-find' : 'inprogress'}`}>
                              <div className="pl-number">{item.nodeName}</div>
              <div className="pl-status">{"COMPLETE"}</div>
              <div className="pl-depth">
                <img src={depthIcons[item.depthTo - 1]} alt="Depth" />
              </div>
                              <div className='pl-result'><span className={`result ${(item.energyEarn > 0) ? 'find' : (item.result == 0) ? 'none' : 'inProgress'}`}>{(item.energyEarn > 0) ? 'Find' : (item.result == 0) ? 'No-Find':""}</span></div>
            </div>
                          
                      ))}


            {/*<div className="pl-row inprogress">*/}
            {/*  <div className="pl-number">I-05</div>*/}
            {/*  <div className="pl-status">IN PROGRESS</div>*/}
            {/*  <div className="pl-depth">*/}
            {/*    <img src={Depth1} alt="Depth" />*/}
            {/*  </div>*/}
            {/*  <div className="pl-result">*/}
            {/*    <span className="result inProgress">•••</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="pl-row no-find">*/}
            {/*  <div className="pl-number">N-09</div>*/}
            {/*  <div className="pl-status">COMPLETE</div>*/}
            {/*  <div className="pl-depth">*/}
            {/*    <img src={Depth2} alt="Depth" />*/}
            {/*  </div>*/}
            {/*  <div className="pl-result">*/}
            {/*    <span className="result none">NO-FIND</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="pl-row find">*/}
            {/*  <div className="pl-number">U-04</div>*/}
            {/*  <div className="pl-status">COMPLETE</div>*/}
            {/*  <div className="pl-depth">*/}
            {/*    <img src={Depth3} alt="Depth" />*/}
            {/*  </div>*/}
            {/*  <div className="pl-result">*/}
            {/*    <span className="result find">Find</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="pl-row find">*/}
            {/*  <div className="pl-number">U-04</div>*/}
            {/*  <div className="pl-status">COMPLETE</div>*/}
            {/*  <div className="pl-depth">*/}
            {/*    <img src={Depth1} alt="Depth" />*/}
            {/*  </div>*/}
            {/*  <div className="pl-result">*/}
            {/*    <span className="result find">Find</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default MineResults;
