import React, { useState } from "react";
import logo from "../../images/avatars/logo.svg";
import mechImage from "../../images/avatars/Layer-29-2.png";
import shareIcon from "../../images/avatars/share-icon1.png";
import closeIcon from "../../images/avatars/close-button.svg";
import darkGradient from "../../images/avatars/dark-gradient-11.png";
import vector3 from "../../images/avatars/vector3.png";
import vector2 from "../../images/avatars/vector2.png";
import LogoMobile from "../../images/logo-mobile.svg";
import SocialOverlay from "../landscape/SocialOverlay";

const MechInspect = (props) => {
  const [animateOut, setAnimateOut] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
    const [isSocialOverlay, setsocialOverlay] = useState(false);
  const handleClose = () => {
    props.setAnimateOut(true);
    setTimeout(() => {
      props.handleClose();
      props.setAnimateOut(false);
    }, 500);
  };
  const menuopen = () => {
    setNavOpen(!isNavOpen);
  };
  return (
      <>
          {!isSocialOverlay ? (
              <>
      <div
        className={`modal-overlay is-desktop-view ${
          props.animateOut ? "animate-out" : "animate-in"
        }`}
      >
        <div className="modal-content justify-space-between">
          <div className="discover-inspect-top-section">
            <img src={logo} alt="logo"></img>
          </div>
          <div className="asset-title-wrap">
            <h2>p-48 mining mech</h2>
          </div>
          <div className="top-section justify-center push-to-left ">
            <img src={mechImage} alt="mech"></img>
          </div>

          <div
            className="mech-inspect-close-button"
            onClick={() => {
              props.handleClose();
            }}
          >
            <img src={closeIcon} alt="close"></img>
          </div>

          <div className="bottom-section">
            <div className="bottom-left">
              <img src={darkGradient} alt="dark gradient"></img>
              <div className="info">
                <div className="info-labels">
                  <p>location</p>
                  <p>lattitude</p>
                  <p>longitude</p>
                  <p>depth</p>
                </div>
                <div className="info-values">
                  <p>Eastern Rift E-08</p>
                  <p>-10.883° N</p>
                  <p>328.219° E</p>
                  <p>16m</p>
                </div>
              </div>
              <div className="info">
                <div className="info-labels">
                  <p>YIELD</p>
                  <p>value</p>
                  <p>Energy</p>
                  <p>charged</p>
                </div>
                <div className="info-values">
                  <p>2.57kg</p>
                  <p>68,000</p>
                  <p>80,846KW</p>
                  <p>103,846KW</p>
                </div>
              </div>
            </div>
                      <div className="share-button " onClick={() => setsocialOverlay(true)}>
              <p>
                Charge crystal by
                <span style={{ color: " var(--status-green, #00ff85)" }}>
                  23,000kW
                </span>
                extra by sharing
              </p>
                          <button>
                <h5>Share</h5>
                <img src={shareIcon} alt="share-icon"></img>
              </button>
            </div>
            <div className="bottom-right">
              <h5>E0819h64</h5>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-overlay is-mobile-view ${
          props.animateOut ? "animate-out" : "animate-in"
        }`}
      >
        <div className="modal-content justify-space-between">
          <div className="discover-inspect-top-section">
            <img src={logo} alt="logo"></img>
          </div>
          <div className="asset-title-wrap">
            <h2>p-48 mining mech</h2>
          </div>
          <div className="top-section justify-center push-to-left">
            <img src={mechImage} alt="mech"></img>
          </div>

          <div
            className="mech-inspect-close-button"
            onClick={() => {
              props.handleClose();
            }}
          >
            <img src={closeIcon} alt="close"></img>
          </div>

          <div className="bottom-section">
            <div className="bottom-left">
              <img src={darkGradient} alt="dark gradient"></img>
              <div className="info">
                <div className="info-labels">
                  <p>location</p>
                  <p>lattitude</p>
                  <p>longitude</p>
                  <p>depth</p>
                </div>
                <div className="info-values">
                  <p>Eastern Rift E-08</p>
                  <p>-10.883° N</p>
                  <p>328.219° E</p>
                  <p>16m</p>
                </div>
              </div>
              <div className="info">
                <div className="info-labels">
                  <p>YIELD</p>
                  <p>value</p>
                  <p>Energy</p>
                  <p>charged</p>
                </div>
                <div className="info-values">
                  <p>2.57kg</p>
                  <p>68,000</p>
                  <p>80,846KW</p>
                  <p>103,846KW</p>
                </div>
              </div>
            </div>
            <div className="share-button ">
              <p>
                Charge crystal by
                <span style={{ color: " var(--status-green, #00ff85)" }}>
                  23,000kW
                </span>
                extra by sharing
              </p>
              <button>
                <h5>Share</h5>
                <img src={shareIcon} alt="share-icon"></img>
              </button>
            </div>
            <div className="bottom-right">
              <h5>E0819h64</h5>
            </div>
          </div>
        </div>
                  </div>
                  </>
          ) : isSocialOverlay ? (
                  <>
                   <div className="reporting-modal-backdrop"></div>
              <SocialOverlay
                  setsocialOverlay={setsocialOverlay}
                      />
                      </>
          ) : ""}
    </>
    );
};


export default MechInspect;
