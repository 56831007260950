import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as astar from "javascript-astar/astar.js";
import GraphSearch from "./CustomHooks";
import LeftRuller from "./LeftRuller";
import TopRuller from "./TopRuller";
import BottomRuller from "./BottomRuller";
import RightRuller from "./RightRuller";
import Grid from "../../images/768-Grid-bg.svg";
import Core from "./Core";
import map from "../../images/map.png";
import RestrictedGrid from "./RestrictedGrid";
import MiningComponent from "./MiningComponent";
import GridHover from "./GridHover";
import GridSelector from "./GridSelector";
import GridPathway from "./GridPathwayMining";
import CoringComponent from "./CoringComponent";
import PathwayComplete from "./PathwayComplete";
import CoreService from "../helper/Coreservice";
import { useSelector } from "react-redux";
var fridAct = [];
const MainGrid = () => {
    const { mapData } = useSelector((state) => state.app);
    const gridSize = 32;
    const coreservices = {
        coreService: new CoreService(),
    };
    const mapId = useParams();
    const [gridItems, setGridItems] = useState([]);
    const [pathCoordinate, setPathCoordinate] = useState([]);
    const [pathCoordinateComplete, setPathCoordinateComplete] = useState([]);
    //var obj = { start: 0, end: 0, depth: 1, arr: [] };
    const [showCursor, setShowCursor] = useState(false);

    
    const [gridActual, setGridActual] = useState([]);
    const [gridSelectorsArray, setGridSelectorsArray] = useState([]);

    const [isSelecting, setIsSelecting] = useState(false);
    const [selectionStart, setSelectionStart] = useState(null);
    const [selectionEnd, setSelectionEnd] = useState(null);

    const handleMouseDown = (event) => {
        setIsSelecting(true);
        const containerRect = event.currentTarget.getBoundingClientRect();
        const x = Math.floor((event.clientX - containerRect.x) / gridSize);
        const y = Math.floor((event.clientY - containerRect.y) / gridSize);

        setSelectionStart({ x, y });
    };
    const handleMouseMove = (event) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        if (isSelecting) {
            const x = Math.floor((event.clientX - containerRect.x) / gridSize);
            const y = Math.floor((event.clientY - containerRect.y) / gridSize);

            setSelectionEnd({ x, y });
        }
    };
    let pathCoordinates = [];
    const filterUserDigReal = (myArray) => {
        var abc = myArray
            .filter((x) => x.result < 3)
            .sort((a, b) => Number(a.digId) - Number(b.digId));
        return abc;
    };
    //let userDigTemp = coreCoordinatesList;
    const UpdatePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodePathList[abc];
                    var pathCharge =
                        mapData != null && mapData.userBalance != null
                            ? mapData.userBalance.nodeCost
                            : 1;
                    var str =
                        "<span className='energyclass' style='background-color: white;font-size: 10px;position: absolute;width: 32px;opacity: 0.9;top:25%'>" +
                        (nodePathList.length * pathCharge).toString() +
                        "KW" +
                        "</span>";
                    const box = document.getElementById("id_" + middleNode.id);
                    console.log(box);
                    if (box != null) {
                        box.insertAdjacentHTML("beforeend", str);
                    }
                }
            }
        }
    };
    const RemovePathEnergy = (coreCoordinatesList) => {
        if (coreCoordinatesList != undefined && coreCoordinatesList.length > 0) {
            for (var i = 0; i < coreCoordinatesList.length; i++) {
                var nodePathList = coreCoordinatesList[i];
                if (nodePathList != undefined && nodePathList.length > 0) {
                    var abc = Math.floor(nodePathList.length / 2);
                    var middleNode = nodePathList[abc];
                    const box = document.getElementById("id_" + middleNode.id);
                    if (box != null) {
                        var paras = box.getElementsByTagName("span");
                        while (paras[0]) paras[0].parentNode.removeChild(paras[0]);
                        //for (index = paras.length - 1; index >= 0; index--) {
                        //    paras[index].parentNode.removeChild(paras[index]);
                        //}
                    }
                }
            }
        }
    };

    //useEffect(() => {        
    //    //let coreCoordinatesListTemp =
    //    //    props.mapData == null
    //    //        ? null
    //    //        : props.mapData.mapDetailList.filter((x) => (x.result >= 0 && x.result < 3));
    //    //console.log(coreCoordinatesListTemp);
    //    //setPathCoordinate([]);
    //    //coreCoordinatesListTemp = filterUserDigReal(coreCoordinatesListTemp);
    //    //pathCoordinates = coreservices.coreService.FindAllNodes(
    //    //    coreCoordinatesListTemp
    //    //);
    //    //setPathCoordinateComplete(pathCoordinates);
    //    //RemovePathEnergy(pathCoordinates);
    //    //if (coreservices.coreService.getMapDataGrid() != null && coreservices.coreService.getMapDataGrid().length > 0) {
    //    //    pathCoordinates = coreservices.coreService.FindAllNodes(
    //    //        coreservices.coreService.getMapDataGrid(),
    //    //        true
    //    //    );
    //    //    setPathCoordinate(pathCoordinates);
    //    //    UpdatePathEnergy(pathCoordinates);
    //    //}
    //    if (coreservices.coreService.getMapData() != null && coreservices.coreService.getMapData().mapGroupModel != null && coreservices.coreService.getMapData().mapGroupModel.length > 0) {
    //        var abc = [];
    //        coreservices.coreService.getMapData().mapGroupModel.forEach((line) => {
    //            var allNode = coreservices.coreService.getMapData().mineList.filter(x => x.mineGridId == line.id)
    //            abc.push({ depth: line.depth, start: JSON.parse(line.start), end: JSON.parse(line.end), totalNode: line.totalNode, nodeName: line.nodeName, result: line.result, depth1: line.depth, id: line.id, energyEarn: line.energyEarn, allNode: allNode })
    //            //abc.push({ start: JSON.parse(line.start), end: JSON.parse(line.end), depth: line.depth });
    //        })
    //        setGridActual(abc);

    //    }
    //    var nn = [];
    //    gridActual.forEach((line) => {
    //        nn.push(line);
    //    })
    //    RemovePathEnergy(pathCoordinateComplete);
    //    var response = coreservices.coreService.FinAllNodesInMineAll(nn);
    //    var rrPath = response.rrPath;
    //    pathCoordinates = response.AllNodesReal;
    //    UpdatePathEnergy(pathCoordinates);
    //    setPathCoordinate(rrPath);
    //    setPathCoordinateComplete(pathCoordinates);
    //    //var totalPath = rrPath.length;
    //    //if (pathSelect != totalPath) {
    //    //    setPathSelect(totalPath);
    //    //}
    //    if (localStorage.getItem('isCoreStart') === 'true') {
    //        setGridSelectors([])
    //        setGridSelectorsArray([])
    //        coreservices.coreService.saveMineTempData([]);
    //        var aa = coreservices.coreService.getMineTempData();
    //    }

    //    //props.mapData.mapDetailList.forEach((x, index) => {
    //    //    if (x.result <= 3) {
    //    //        const gridItem = (
    //    //            <div className="grid-item" key={index} onClick={null}>
    //    //                <Core
    //    //                    depth={x.depthTo}
    //    //                    find={x.result}
    //    //                    activateFill={x.depth1 < 0 ? true : false}
    //    //                    id={x.id}
    //    //                    isComplete={true}
    //    //                    core={x.coreNo}
    //    //                />
    //    //            </div>
    //    //        );
    //    //        setGridItems((prev) => {
    //    //            return {
    //    //                ...prev,
    //    //                [index]: gridItem,
    //    //            };
    //    //        });
    //    //    }
    //    //});
    //    //console.log(pathCoordinate);
    //}, [mapData]);
    //useEffect(() => {
    //    ;
    //    var sList = props.selectedGrid;
    //    var newArr = gridSelectorsArray;
    //    newArr = [];
    //    var newArr2 = sList;
    //    sList.forEach((line) => {
    //        var obs = gridSelectorsArray.find(pp => pp.id == line.id)
    //        if (obs != null) {
    //            newArr.push[obs];
    //        }
    //    })
        
    //    //if (coreservices.coreService.getMapData() != null && coreservices.coreService.getMapData().mapGroupModel != null && coreservices.coreService.getMapData().mapGroupModel.length > 0) {
    //    //    //var abc = [];
    //    //    ;
    //    //    coreservices.coreService.getMapData().mapGroupModel.forEach((line) => {
    //    //        var alredayMine = newArr2.find(x => x.id == line.id)
    //    //        if (alredayMine === null || alredayMine === undefined) {
    //    //            newArr2.push({ depth: line.depth, start: JSON.parse(line.start), end: JSON.parse(line.end), totalNode: line.totalNode, nodeName: line.nodeName, result: line.result, depth1: line.depth, id: line.id, energyEarn: line.energyEarn })
    //    //        }
    //    //        //abc.push({ start: JSON.parse(line.start), end: JSON.parse(line.end), depth: line.depth });
    //    //    })
    //    //    //setGridSelectors(newArr2);

    //    //}
    //    ;
    //    //setGridSelectors(newArr2);
    //    setGridSelectorsArray(newArr);
    //    //coreservices.coreService.saveMineTempData(newArr);
    //}, [props.setselectedGrid]);

    
    //useEffect(() => {
    //    setMapDetailList(mapData.mapDetailList);
    //}, []);
    useEffect(() => {
   
        const emptyObject = {};

        if (
            mapData &&
            mapData.mapDetailList &&
            mapData.mapDetailList.length > 0
        ) {
            mapData.mapDetailList.forEach((item, index) => {
                const row = item.rowNo - 1;
                const col = item.colNo - 1;

                if (item.isRestricted === true) {
                    emptyObject[row * gridSize + col] = (
                        <div className="grid-item restricted-box" id={"id_" + item.id} key={index}>
                            <RestrictedGrid />
                        </div>
                    );
                } else if (item.result !== 3) {
                    emptyObject[row * gridSize + col] = (
                        <div className="grid-item" id={"id_" + item.id} key={index}>
                            <Core
                                depth={item.depthTo}
                                find={item.result}
                                activateFill={item.depth1 < 0 ? true : false}
                                id={item.id}
                                core={item.coreNo}
                            />
                        </div>
                    );
                } else {
                    emptyObject[row * gridSize + col] = (
                        <div
                            className="grid-item"
                            key={index}
                            id={"id_" + item.id}

                        ></div>
                    );
                }
            });
            var timeOutInterval = setTimeout(() => {
                clearTimeout(timeOutInterval)
                var pc = coreservices.coreService.getMapData();
                if (coreservices.coreService.getMapData() != null && coreservices.coreService.getMapData().mapGroupModel != null && coreservices.coreService.getMapData().mapGroupModel.length > 0) {
                    var abc = [];
                    var npq = [];
                    coreservices.coreService.getMapData().mapGroupModel.forEach((line) => {
                        var allNode = coreservices.coreService.getMapData().mineList.filter(x => x.mineGridId == line.id)
                        abc.push({ depth: line.depth, start: JSON.parse(line.start), end: JSON.parse(line.end), totalNode: line.totalNode, nodeName: line.nodeName, result: line.result, depth1: line.depth, id: line.id, energyEarn: line.energyEarn, allNode: allNode, isRestricted: line.isRestricted  })
                        //abc.push({ start: JSON.parse(line.start), end: JSON.parse(line.end),depth:line.depth });
                    })
                    setGridActual(abc);
                    fridAct = abc;
                    var response = coreservices.coreService.FinAllNodesInMineAll(abc);
                    var rrPath = response.rrPath;
                    pathCoordinates = response.AllNodesReal;
                    //UpdatePathEnergy(pathCoordinates);
                    //setPathCoordinate(rrPath);
                    //setPathCoordinateComplete(pathCoordinates);
                }
            }, 200)
            
        }

        setGridItems(emptyObject);
    }, [mapData]);
    console.log(
        new Date() +
        " stGrid1 mining="  + " jgj==" + "=busy="  + "=busy1=" 
    );
    return (
    <div className="map-container mining-report">
            <div className="grid-outer">
                <LeftRuller />
                <TopRuller />
                <BottomRuller />
                <RightRuller />
                <img className="map-image" src={map}></img>
                <img className="grid" src={Grid} alt="Grid" />

                <div
                    className="grid-wrap"                    
                    style={{ userSelect: "none" }}
                >
                    <GridPathway path={pathCoordinate} />
                    {Object.values(gridItems)}
                    {fridAct.length > 0 &&
                        fridAct.filter(x => x.allNode != null && x.allNode.length>0).map((selector, index) => (
                            <GridHover                                
                                startCoords={selector.start}
                                endCoords={selector.end}                               
                                allNode={selector.allNode}
                                isRestricted={selector.isRestricted}
                                depth={selector.depth}
                                nodeName={selector.nodeName}
                            />
                        ))}
                    {/*{coreservices.coreService.getMapData().mapGroupModel.length > 0 &&*/}
                    {/*    coreservices.coreService.getMapData().mapGroupModel.map((selector, index) => (*/}
                    {/*        <GridSelector*/}
                    {/*            depth={selector.depth}*/}
                    {/*            startCoords={JSON.parse(selector.start)}*/}
                    {/*            endCoords={JSON.parse(selector.end)}*/}
                                
                    {/*        />*/}
                    {/*    ))}*/}
                    {/*{selectionStart && selectionEnd && (*/}
                    {/*    <GridSelector*/}
                    {/*        depth={1}*/}
                    {/*        startCoords={selectionStart}*/}
                    {/*        endCoords={selectionEnd}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>
            </div>
            {/* {showCursor && <CoringComponent />} */}
        </div>
    );
};

export default MainGrid;
