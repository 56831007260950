import React, { useState, useEffect } from "react";
import Location from "../location/Location";
import CoringSampleImg from "../../images/coring-sample.png";
import Wave from "../../images/icons/WaveAnimation.png";
import Depth1 from "../../images/icons/depth-1.svg";
import Depth2 from "../../images/icons/depth-2.svg";
import Depth3 from "../../images/icons/depth-3.svg";
import "./mine.css";
import MineReport from "../mine/MineReport";
import Monitor from "../monitor/MonitorNew";
import AuthService, { AdminUserModel } from "../helper/Authservice";
import CoreService from "../helper/Coreservice";
import MineIcon from "../../images/icons/mineIcon";
import heart from "./heart.svg";
import repair from "./repair.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    setCoreReportModal,
    setSelectedGrid,
    setGridSelectors,
    setIsReady,
    setPathSelect,
    setGridSelectorsArray,
    setCongratulation,
    setMechEnergy,
    setRemainMechEnergy,
    setShowManual
} from "../../appSlice";
import AnimatedButton from "../animatedButton/AnimatedButton";
import Manual from "../manual/Manual";

var isOpenOppup = false;
const Mine = (props) => {
    const {
        isCoreReportModal,
        selectedGrid,
        mapData,
        isReady,
        isShowButton,
        gridSelectors,
        gridSelectorsArray,
        userToBeBalance,
        isCongratulation,
        energyLevel,
        tooltipMining,
        isCoringComplete,
    } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    //const [isMineReportModal, setMineReportModal] = useState(false)

    //const handleMineReportModal = () => {
    //    setMineReportModal(true)
    //    props.setLeftOpen(false)
    //}
    const depthIcons = [Depth1, Depth2, Depth3];
    const services = {
        authService: new AuthService(),
    };
    const coreservices = {
        coreService: new CoreService(),
    };
    const [count, setCount] = useState(0);
    const handleCoreReportModel = (obj) => {
        localStorage.removeItem("updateMineData");
        dispatch(setCoreReportModal(obj));
    };
    var coreCoordinatesList =
        coreservices.coreService.getMapData() == null &&
            coreservices.coreService.getMapData().mapGroupModel != null
            ? null
            : coreservices.coreService.getMapData().mapGroupModel.filter(x => x.result < 2);
    //if (localStorage.getItem('isCoreStart') === 'true') {
    //    //coreCoordinatesList = coreCoordinatesList.sort((b, a) => a.digId - b.digId);
    //    console.log(new Date() + " end timer=" + " data2=" + JSON.stringify(coreCoordinatesList));
    //}
    if (coreCoordinatesList?.length > 0) {
        isOpenOppup = true;
    }
    const mapName =
        mapData != null && mapData.imageData != null
            ? mapData.imageData.mapImageNo
            : "";
    const handleCoreDeploy = () => {
        //setShowButton(false)
        props.deployCores();
    };
    const handleRemoveSelectedGrid = (id) => {
        //var sList = selectedGrid;
        //sList = sList.filter(x => x.id != id);
        //dispatch(setSelectedGrid(sList));

        var newGridSelectors = gridSelectors;
        newGridSelectors = newGridSelectors.filter((x) => x.id != id);
        coreservices.coreService.saveDataEntireGrid(newGridSelectors);
        //newGridSelectors.splice(index, 1);
        var tempArr = coreservices.coreService.getMapDataGridTemp();
        tempArr = tempArr.filter((x) => x.id != id);
        coreservices.coreService.saveDataEntireGridTemp(tempArr);
        dispatch(setGridSelectors(newGridSelectors));
        dispatch(setSelectedGrid(newGridSelectors));
        if (newGridSelectors == null || newGridSelectors.length == 0) {
            dispatch(setIsReady(false));
        }
        var newArr = gridSelectorsArray;
        var totalMech = 0
        newGridSelectors.forEach((line) => {
            totalMech = totalMech + line.mechCharge;
        });

        var remainMechCharge = mapData.mechDetail.mechCharge - totalMech;
        dispatch(setRemainMechEnergy(remainMechCharge));
        dispatch(setMechEnergy(totalMech));
        newArr = newArr.filter((x) => x.id != id);
        dispatch(setGridSelectorsArray(newArr));
        coreservices.coreService.saveMineTempData(newArr);
        console.log("ddRemove" + JSON.stringify(gridSelectorsArray));
    };
    useEffect(() => {
        //Implementing the setInterval method
        //const interval = setInterval(() => {
        //    setCount(count + 1);
        //}, 1000);
        //return () => {
        //    clearInterval(interval);
        //};
    }, []);

    

    return (
        <>
            <div className="left-bar-content">
                <Monitor isMiningOpen={true} pageOpen={props.pageOpen} />
                {/*<div className="health-monitor">*/}
                {/*    <div className="health-left">*/}
                {/*        <div className="health-left-title">*/}
                {/*            <img src={heart} />*/}
                {/*            <p>Health</p>*/}
                {/*        </div>*/}
                {/*        <div className="health-percent">*/}
                {/*            <p>{mapData.mechDetail.mechCharge}%</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="repair-button" onClick={() => UpdateMechEnergy()}>*/}
                {/*        <img src={repair} />*/}
                {/*        <p>repair</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div id="left-bar-inner" className="col-left-inner">
                    <Location mapName={mapName} />
                    {isReady || isOpenOppup ? (
                        <div className="mines-wrapper">
                            {tooltipMining == 4 && <div className="tt-rtm fade-in"></div>}
                            {!isReady ? (
                                <div
                                    className={`status ${!isCoringComplete ? "ready" : "warning"
                                        } `}
                                >
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                    <p>
                                        {!isCoringComplete ? "Ready To Mine" : "Mining complete"}
                                    </p>
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                    <MineIcon color={!isCoringComplete ? "ready" : ""} />
                                </div>
                            ) : (
                                ""
                            )}
                            {isReady && isShowButton ? (
                                <div
                                    className={`status ${userToBeBalance < 0 ? "error" : "ready"
                                        } `}
                                >
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />

                                    {userToBeBalance < 0
                                        ? "Ilyrium Depleted"
                                        : "Mines Initialised"}
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                    <MineIcon
                                        color={`${userToBeBalance < 0 ? "error" : "ready"}`}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            {isReady && !isShowButton ? (
                                <div className="progress status">
                                    <MineIcon color="progress" />
                                    <MineIcon color="progress" />
                                    <MineIcon color="progress" />
                                    <p>MINING In Progress</p>
                                    <MineIcon color="progress" />
                                    <MineIcon color="progress" />
                                    <MineIcon color="progress" />
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="pl-main-container">
                                <div className="pl-wrapper">
                                    <div className="pl-row pl-header">
                                        <div className="pl-number">NO#.</div>
                                        <div className="pl-status">STATUS</div>
                                        <div className="pl-depth">Depth</div>
                                        <div className="pl-result">RESULT</div>
                                    </div>
                                </div>
                                <div className="pl-wrapper pl-content-wrap">
                                    {coreCoordinatesList &&
                                        coreCoordinatesList.length > 0 &&
                                        coreCoordinatesList.map((item, index) => (
                                            <div
                                                className={`pl-row ${item.energyEarn > 0 ? "find" : "no-find"
                                                    }`}
                                            >
                                                <div className="pl-number">{item.nodeName}</div>
                                                {item.depthTo < 0 ? (
                                                    <div className="pl-status">IN PROGRESS</div>
                                                ) : (
                                                    <div className="pl-status">COMPLETE</div>
                                                )}
                                                <div className="pl-depth">
                                                    <img src={depthIcons[item.depth - 1]} alt="Depth" />
                                                </div>

                                                {item.depthTo < 0 ? (
                                                    <div className="pl-result">
                                                        <span className="result inProgress">
                                                            <img src={Wave} alt="wave" />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="pl-result">
                                                        <span
                                                            className={`result ${item.energyEarn > 0 ? "find" : "none"
                                                                }`}
                                                        >
                                                            {item.energyEarn > 0
                                                                ? "Find"
                                                                : item.energyEarn == 0
                                                                    ? "No Find"
                                                                    : "IN PROGRESS"}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    {selectedGrid &&
                                        selectedGrid.length > 0 &&
                                        selectedGrid.map((item, index) =>
                                            coreCoordinatesList == null ||
                                                coreCoordinatesList.find(
                                                    (x) => x.nodeName == item.nodeName
                                                ) == null ? (
                                                <div className="pl-row ready">
                                                    <div
                                                        className={`pl-number ${energyLevel == "red" ? energyLevel : ""
                                                            }`}
                                                    >
                                                        {item.nodeName}
                                                    </div>
                                                    <div
                                                        className={`pl-status ${energyLevel == "red" ? energyLevel : ""
                                                            }`}
                                                    >
                                                        {userToBeBalance < 0 ? "Error!" : "Ready"}
                                                    </div>
                                                    <div className="pl-depth">
                                                        <img src={depthIcons[item.depth - 1]} alt="Depth" />
                                                    </div>
                                                    {item.depth1 < 0 ? (
                                                        <div className="pl-result">
                                                            <span className="result inProgress">
                                                                <img src={Wave} alt="wave" />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="pl-result">
                                                            <span
                                                                className={`result ${energyLevel == "red" ? energyLevel : ""
                                                                    } ready`}
                                                            >
                                                                {userToBeBalance < 0 ? "Error!" : "Ready"}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div
                                                        className="remove-plan"
                                                        onClick={() => handleRemoveSelectedGrid(item.id)}
                                                    >
                                                        <span>Remove from plan</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        )}
                                   

                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>E8-04</div>*/}
                                    {/*    <div className='pl-status'>Ready</div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row ready'>*/}
                                    {/*    <div className='pl-number'>E8-03</div>*/}
                                    {/*    <div className='pl-status'>Ready</div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth2} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result ready'>Ready</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row find'>*/}
                                    {/*    <div className='pl-number'>E8-02</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth3} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
                                    {/*</div>*/}
                                    {/*<div className='pl-row find'>*/}
                                    {/*    <div className='pl-number'>E8-01</div>*/}
                                    {/*    <div className='pl-status'>COMPLETE</div>*/}
                                    {/*    <div className='pl-depth'><img src={Depth1} alt='Depth' /></div>*/}
                                    {/*    <div className='pl-result'><span className='result find'>Find</span></div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            {!isReady && isOpenOppup ? (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => handleCoreReportModel(true)}
                                >
                                    Read Report
                                </button>
                            ) : (
                                ""
                            )}
                            {!isReady && isOpenOppup ? (
                                <button
                                    className="btn"
                                    onClick={() =>
                                        services.authService.handleClickOnButton("basemine")
                                    }
                                >
                                    RETURN TO MAP
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="ready-wrapper">
                            <div className="status ready">
                                <MineIcon color="ready" />
                                <MineIcon color="ready" />
                                <MineIcon color="ready" />
                                <p>READY TO MINE</p>
                                <MineIcon color="ready" />
                                <MineIcon color="ready" />
                                <MineIcon color="ready" />
                            </div>
                            <div className="explore-text">
                                <span>ilyirium has been found in this grid,</span> click a grid
                                within this sector to <br /> open a new mine.
                                <br /> Click and drag to extend the size of your mine.
                            </div>
                            <button
                                    className="btn"
                                    onClick={() => {
                                        dispatch(setShowManual(3));
                                        {/*services.authService.handleClickOnButton("training-mining")*/ }
                                    }
                                }
                            >
                                OPEN MANUAL
                            </button>
                            <button
                                className="btn"
                                onClick={() =>
                                    services.authService.handleClickOnButton("basemine")
                                }
                            >
                                RETURN TO MAP
                            </button>
                        </div>
                    )}
                </div>
                {isReady ? (
                    <>
                        <div id="report-btn">
                            {/*<button className='btn' onClick={() => handleMineReportModal()}>SHOW MINE REPORT</button>*/}
                            {isShowButton ? (
                                // <button
                                //   className="btn btn-secondary"
                                //   disabled={userToBeBalance < 0}
                                //   onClick={() => handleCoreDeploy()}
                                // >
                                //   {userToBeBalance < 0 ? "CANNOT DEPLOY" : "Deploy Mines"}
                                // </button>
                                <AnimatedButton
                                    buttonText={
                                        energyLevel == "red" ? "Cannot mine" : "Deploy mines"
                                    }
                                    color={energyLevel}
                                    type="inward"
                                    disabled={userToBeBalance < 0}
                                    handleAnimationButton={() => handleCoreDeploy()}
                                />
                            ) : (
                                ""
                            )}
                            <button
                                className="btn"
                                onClick={() =>
                                    services.authService.handleClickOnButton("basemine")
                                }
                            >
                                Return to Map
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
            {isCoreReportModal || isCongratulation ? (
                <MineReport
                    setLeftOpen={props.setLeftOpen}
                    handleCoreReportModel={handleCoreReportModel}
                    id={props.id}
                />
            ) : null}
        </>
    );
};

export default Mine;
