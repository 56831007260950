import React from 'react'
import AuthService, { AdminUserModel } from '../../helper/Authservice'
const MoveSelectionModal = (props) => {
    const services = {
        authService: new AuthService()
    };
  const handleHideMoveModal = () => {
    props.setMoveModalShow('');
  }
    const moveToOtherMap = (id) => {
        if (id > 0) {
            window.location = '/coring/' + id;
        }
        else {
            alert("Restricetd Area");
        }
        //coreservices.coreService.getData(id);
    }

  return (
    <>
    <div className={`move-selection-modal ${props.moveModalShow}`}>
              <div className={`ms-modal-title ${props.isRestricted?"red":""}`}>{props.isRestricted ? "Restricted Area" : "Move Sections?"}</div>
              {props.isRestricted ? (<div className='ms-modal-info red'>Can not move to restricted area {props.mapName}?</div>) :
                  (<div className='ms-modal-info'>Are you sure you want to leave Section {props.mapName}?</div>)}
              
            <div className='ms-modal-footer'>
                  {!props.isRestricted ? <button type='button' className='btn btn-secondary ' onClick={() => moveToOtherMap(props.imageId)}>Proceed to {props.imageName}</button>:""}
                  <button type='button' className='btn' onClick={() => services.authService.handleClickOnButton('basemine')}>Return to Master Map</button>
                <button type='button' className='btn' onClick={()=>handleHideMoveModal()}>No, stay here</button>
            </div>
    </div>
    <div className={`ms-modal-backdrop ${props.moveModalShow}`}></div>
    </>
  )
}

export default MoveSelectionModal