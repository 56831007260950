import React, { useState, useEffect, useRef, useCallback } from "react";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "../../images/icons/delete.svg";
import Avatar from "../../images/icons/avartar-thumb.svg";
import Mech from "../../images/mech.png";
import IDCard from "../../images/id-card.png";
import AvatarImg from "../../images/avtar.png";
import Vector from "../../images/Vector-icon.png";
import Country from "../../images/Country-Flag.png";
import AuthService, { BASE_URL } from "../helper/Authservice";
import { ToastContainer, toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import "react-toastify/dist/ReactToastify.css";
const MechNFT = (props) => {
  const services = {
    authService: new AuthService(),
  };
  const ref = useRef(null);
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const [images, setImages] = React.useState([]);
  const [user, setUser] = useState(services.authService._CurrentUser());
  const avUrl = user.avatarUrl.includes("uploadimg")
    ? user.avatarUrl
    : AvatarImg;
  const maxNumber = 1;
  const onChange = async (imageList) => {
    var action = "";
    var imageBase64 = "";
    var imageName = "";
    if (imageList.length > 0) {
      action = "uploadImage";
      imageBase64 = imageList[0].data_url;
      imageName = imageList[0].file.name;
    } else if (imageList.length === 0) {
      action = "removeImage";
    }
    if (action != "") {
      let response2 = await fetch(
          BASE_URL +"/Account/updateUserImage",
        {
          method: "POST",
          body: JSON.stringify({
            base64Image: imageBase64,
            userId: user.userId,
            imgaeName: imageName,
            action: action,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      let data = await response2.json();
      if (response2.status === 200) {
        if (data.isSuccess) {
          //toast.success(data.errorMessage, { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
          services.authService.setUser(data.data);
          setUser(services.authService._CurrentUser());
        } else {
          //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
        }
      } else {
        //toast.error("Some error occured", { position: "top-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored" });
      }
    }
    //setImages(imageList);
  };
  console.log(ref.current);

  useEffect(() => {
    const updateHeight = () => {
      setWinHeight(window.innerHeight - 137);
    };
    updateHeight();

    onButtonClick();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    } else {
      document.getElementById("domEl").classList.remove("hide");
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        document.getElementById("domEl").classList.add("hide");
        document.getElementById("IdCard").src = dataUrl;
        //
        //const link = document.createElement('a')
        //link.download = 'my-image-name.png'
        //link.href = dataUrl
        //link.click()
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  //var node = document.getElementById('domEl');

  //if (node != null) {
  //    const dataUrl = await htmlToImage.toPng(domEl.current);
  //    htmlToImage.toPng(node)
  //        .then(function (dataUrl) {
  //
  //            download(dataUrl, 'my-node.png');
  //            //IDCard = dataUrl;
  //            /* document.body.appendChild(img);*/
  //        })
  //        .catch(function (error) {
  //
  //            console.error('oops, something went wrong!', error);
  //        });
  //}

  return (
    <div className="mech-nft-wrapper">
      <div className="mnft-content-wrap">
        <div className="mnft-wrap">
          <div className="mnft">
            <img src={Mech} alt="Mech" />
          </div>
        </div>
        <div className="mnft-content-scroller" style={{ height: winHeight }}>
          <div className="mnft-content">
            <h2>
                          Congratulations {user.userName}, <br />
              your have been appproved provisional licence
            </h2>
            <p>
              Highly mobile, and custom designed to dig at multiple depths, the
              P-48 is highly capable Mining Mech. In order to Pilot the P-48
              successfully, utilise your energy supply, and navigate the
              controls we strongly encourage you to complete your Pilot Licence
              Certification program.
            </p>

            <div className="mnft-card-image-upload-wrapper">
              <div className="mnft-card-image-upload">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({ imageList, onImageUpload, onImageRemove }) => (
                    // write your building UI

                    <>
                      <div className="mnft-user-profile-pic-wrap">
                        <div className="avatar-thumnail">
                          <img src={Avatar} alt="Avatar" />
                        </div>

                        {user.avatarUrl.includes("uploadimg") && (
                          <div key={1} className="mnft-img-wrap">
                            {/* <div className='user-name'>B</div> */}
                            <img src={user.avatarUrl} alt="" />
                            <div className="remove-btn">
                              <img
                                src={DeleteIcon}
                                alt="Delete"
                                onClick={() => onImageRemove(1)}
                              />
                            </div>
                          </div>
                        )}

                        {/*{imageList.map((image, index) => (*/}
                        {/*    <div key={index} className="mnft-img-wrap">*/}
                        {/*        */}
                        {/* <div className='user-name'>B</div> */}
                        {/*        <img src={image['data_url']} alt="" />*/}
                        {/*        <div className="remove-btn">*/}
                        {/*            <img src={DeleteIcon} alt='Delete' onClick={() => onImageRemove(index)} />*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                      </div>
                      <button onClick={onImageUpload} className="btn btn-add">
                        Add Image
                      </button>
                    </>
                  )}
                </ImageUploading>
              </div>
              <div className="mnft-id-card">
                <div class="provisional-card hide" id="domEl" ref={ref}>
                  <div class="card-left-wrap">
                    <div class="avtar-outer">
                      <img src={avUrl} alt="Avatar" onLoad={onButtonClick} />
                    </div>
                    <div class="vactor-outer">
                      <img src={Vector} alt="Vector" />
                    </div>
                    <div class="plot-no">
                      <p>Pilot No.</p>
                      <h5>{user.refferCode}</h5>
                    </div>
                    <div class="flag-outer">
                      <img src={Country} alt="Country" />
                    </div>
                  </div>
                  <div class="detail-info">
                    <div class="top-title">provisional</div>
                    <div class="user-info-group">
                                          <div class="user-name">{user.userName} </div>
                                          <div class="user-tagline">{user.fullName}</div>
                      <div class="user-address">
                        {" "}
                        Las Vegas, New Mexico, USA
                      </div>
                    </div>
                    <div class="info-group-wrap">
                      <div class="info-group-row">
                        <div class="info-group-title">Role</div>
                        <div class="info-group-detail">
                          Provisional
                          <br />
                          Pilot
                        </div>
                      </div>
                      <div class="info-group-row">
                        <div class="info-group-title">Faction</div>
                        <div class="info-group-detail">
                          The fallen
                          <br />
                          Brotherhood
                        </div>
                      </div>
                      <div class="info-group-row">
                        <div class="info-group-title">Date of issue</div>
                        <div class="info-group-detail">{user.registerDate}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <img src={IDCard} alt="IDCard" id="IdCard" />
              </div>
            </div>

            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-secondary "
                onClick={() => props.handleFaction()}
              >
                Pain pilot certification program
              </button>
              <button
                type="button"
                className="btn "
                onClick={() => props.handleFaction()}
              >
                Start mining <span>as Under provisional licence</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MechNFT;
